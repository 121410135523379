import { memo, useEffect, useId } from 'react';
import { notification, Typography } from 'antd';

import { Button } from '../button';
import { Row } from '../layout';

type TDraftModalProperties = {
  type: 'continue' | 'save';
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  onSave?: () => void;
  onDiscard?: () => void;
};

const TypeToTexts = {
  continue: {
    label: 'Continue filling?',
    description: ' Proceed with draft or add a new asset',
    topBtn: 'Continue',
    bottomBtn: 'Start new',
  },
  save: {
    label: 'Save as a draft?',
    description: 'Save your progress with a draft and finish up later',
    topBtn: 'Save',
    bottomBtn: 'Discard',
  },
};

function DraftModal({
  type = 'save',
  isOpen,
  setIsOpen,
  onSave,
  onDiscard,
}: TDraftModalProperties) {
  const [api, contextHolder] = notification.useNotification();
  const id = useId();

  useEffect(() => {
    if (!isOpen) {
      api.destroy(id);
      return;
    }

    if (isOpen) {
      api.open({
        key: id,
        message: (
          <Row direction="column">
            <Typography.Text
              style={{
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '28px',
                marginBottom: '8px',
              }}
            >
              {TypeToTexts[type].label}
            </Typography.Text>
            <Typography.Text style={{ fontSize: '14px', lineHeight: '20px' }}>
              {TypeToTexts[type].description}
            </Typography.Text>
          </Row>
        ),
        placement: 'bottom',
        onClose: () => {
          setIsOpen(false);
        },
        btn: (
          <Row
            direction={'column'}
            nowrap
            gapRow={16}
            width="336px"
            marginTop={20}
          >
            <Button
              text={TypeToTexts[type].topBtn}
              onClick={async () => {
                api.destroy(id);
                onSave?.();
              }}
            />
            <Button
              type="default"
              text={TypeToTexts[type].bottomBtn}
              onClick={() => {
                api.destroy(id);
                onDiscard?.();
              }}
            />
          </Row>
        ),
        duration: 0,
      });
    }
  }, [onSave, onDiscard, isOpen, setIsOpen]);

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            width: '100vw',
            height: '122vh',
            background: '#262625B2',
            left: 0,
            top: 0,
            zIndex: 100,
          }}
        />
      )}
      {contextHolder}
    </>
  );
}

export default memo(DraftModal);
