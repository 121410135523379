import { ReactNode, useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';

interface LoadMoreContainerProps {
  children: ReactNode;
  onLoadMore: () => Promise<void> | void;
  isLoading?: boolean;
}

let observerRefValue: Element | null = null;

export const LoadMoreContainer = ({
  children,
  onLoadMore,
  isLoading,
}: LoadMoreContainerProps) => {
  const observerTarget = useRef(null);
  const [isRun, setIsRun] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        if (entries[0].isIntersecting && !isRun) {
          try {
            setIsRun(true);
            await onLoadMore();
          } catch (e) {
            //
          } finally {
            setIsRun(false);
          }
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
      observerRefValue = observerTarget.current;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [observerTarget, onLoadMore, isRun]);

  return (
    <>
      {children}
      {isLoading && <Spin />}
      <div ref={observerTarget}></div>
    </>
  );
};
