import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import {
  assetsDocumentsPageBuilder,
  AssetsDocumentsPagePartType,
} from '@/entities/assets/helpers/assets-documents-page-builder';
import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum OverTheCounterOptionsType {
  call = 'call',
  put = 'put',
}

const OverTheCounterOptionsList = [
  {
    label: 'forms_assets:ovcOptions.enums.type.call',
    key: OverTheCounterOptionsType.call,
  },
  {
    label: 'forms_assets:ovcOptions.enums.type.put',
    key: OverTheCounterOptionsType.put,
  },
];

export enum CounterpartyType {
  legalEntity = 'legalEntity',
  person = 'person',
}

const CounterpartyList = [
  {
    label: 'forms_assets:ovcOptions.enums.counterparty.legalEntity',
    key: CounterpartyType.legalEntity,
  },
  {
    label: 'forms_assets:ovcOptions.enums.counterparty.person',
    key: CounterpartyType.person,
  },
];

export enum StrategyBasicPositionType {
  buyingCall = 'buyingCall',
  sellingCall = 'sellingCall',
  buyingPut = 'buyingPut',
  sellingPut = 'sellingPut',
}

const StrategyBasicPositionList = [
  {
    label: 'forms_assets:ovcOptions.enums.strategy.buyingCall',
    key: StrategyBasicPositionType.buyingCall,
  },
  {
    label: 'forms_assets:ovcOptions.enums.strategy.sellingCall',
    key: StrategyBasicPositionType.sellingCall,
  },
  {
    label: 'forms_assets:ovcOptions.enums.strategy.buyingPut',
    key: StrategyBasicPositionType.buyingPut,
  },
  {
    label: 'forms_assets:ovcOptions.enums.strategy.sellingPut',
    key: StrategyBasicPositionType.sellingPut,
  },
];

type OverTheCounterOptionsPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: OverTheCounterOptionsType;
  keyAssetsAmount?: number;
  purchaseValue: number;
  basicAssetName: string;
  basicAssetAmount: number;
  basicAssetHolder: string;
};

type OverTheCounterOptionsPart2 = Omit<BaseDto, 'id'> & {
  buyingPrice?: number;
  strikePrice?: number;
  counterpartyType?: string;
  legalEntityName?: string;
  personFullName?: string;
  nominalCost?: number;
  optionPeriod?: number;
  dealReserves?: boolean;
  dealReservesData?: string;
  strategyBasicPosition?: StrategyBasicPositionType;
  strategyDescription?: string;
};

type OverTheCounterOptionsHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.TEXT;
  type: QuestionType.SELECT_FROM_LIST;
  keyAssetsAmount?: QuestionType.NUMBER;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  basicAssetName: QuestionType.TEXT;
  basicAssetAmount: QuestionType.NUMBER;
  basicAssetHolder: QuestionType.TEXT;
};

type OverTheCounterOptionsHelpPart2 = {
  buyingPrice?: QuestionType.CURRENCY_CALCULATOR;
  strikePrice?: QuestionType.CURRENCY_CALCULATOR;
  counterpartyType: QuestionType.SELECT_FROM_LIST;
  legalEntityName?: QuestionType.TEXT;
  personFullName?: QuestionType.TEXT;
  optionPeriod?: QuestionType.DATE_TIME;
  dealReserves?: QuestionType.CHECKBOX;
  dealReservesData?: QuestionType.TEXT;
  strategyBasicPosition?: QuestionType.SELECT_FROM_LIST;
  strategyDescription?: QuestionType.TEXT_MULTILINE;
};

type OverTheCounterOptionsFormType = [
  OverTheCounterOptionsFormPart1Type,
  OverTheCounterOptionsFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
  AssetsDocumentsPagePartType,
];

type OverTheCounterOptionsFormPart1Type = TDtoWrapper<
  OverTheCounterOptionsPart1,
  OverTheCounterOptionsHelpPart1
>;

type OverTheCounterOptionsFormPart2Type = TDtoWrapper<
  OverTheCounterOptionsPart2,
  OverTheCounterOptionsHelpPart2
>;

const OverTheCounterOptionsFormPart1: OverTheCounterOptionsFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.type.label',
    tooltip: 'forms_assets:ovcOptions.inputs.type.tooltip',
    required: true,
    value: OverTheCounterOptionsType.put,
    items: OverTheCounterOptionsList,
  },
  keyAssetsAmount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:exchangeOptions.inputs.keyAssetsAmount.label',
    min: 0,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:ovcOptions.inputs.currentMarketValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      // eslint-disable-next-line sonarjs/no-duplicate-string
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  basicAssetName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.basicAssetName.label',
    tooltip: 'forms_assets:ovcOptions.inputs.basicAssetName.tooltip',
    value: '',
  },
  basicAssetAmount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:ovcOptions.inputs.basicAssetAmount.label',
    value: 0,
    min: 0,
  },
  basicAssetHolder: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.basicAssetHolder.label',
    value: '',
  },
};

const OverTheCounterOptionsFormPart2: OverTheCounterOptionsFormPart2Type = {
  buyingPrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:ovcOptions.inputs.buyingPrice.label',
    tooltip: 'forms_assets:ovcOptions.inputs.buyingPrice.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  strikePrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:ovcOptions.inputs.strikePrice.label',
    tooltip: 'forms_assets:ovcOptions.inputs.strikePrice.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 2,
  },
  counterpartyType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:ovcOptions.inputs.counterpartyType.label',
    tooltip: 'forms_assets:ovcOptions.inputs.counterpartyType.tooltip',
    order: 3,
    items: CounterpartyList,
  },
  legalEntityName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.legalEntityName.label',
    order: 4,
    showIf: {
      counterpartyType: {
        value: CounterpartyType.legalEntity,
      },
    },
  },
  personFullName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:ovcOptions.inputs.personFullName.label',
    order: 5,
    showIf: {
      counterpartyType: {
        value: CounterpartyType.person,
      },
    },
  },
  optionPeriod: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:ovcOptions.inputs.optionPeriod.label',
    tooltip: 'forms_assets:ovcOptions.inputs.optionPeriod.tooltip',
    order: 6,
  },
  dealReserves: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:ovcOptions.inputs.dealReserves.label',
    tooltip: 'forms_assets:ovcOptions.inputs.dealReserves.tooltip',
    order: 8,
  },
  dealReservesData: {
    type: QuestionType.TEXT,
    label: 'forms_assets:exchangeOptions.inputs.dealReservesData.label',
    order: 9,
    showIf: {
      dealReserves: {
        value: true,
      },
    },
  },
  strategyBasicPosition: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:ovcOptions.inputs.strategyBasicPosition.label',
    tooltip: 'forms_assets:ovcOptions.inputs.strategyBasicPosition.tooltip',
    order: 10,
    items: StrategyBasicPositionList,
  },
  strategyDescription: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:ovcOptions.inputs.strategyDescription.label',
    order: 11,
  },
};

const OverTheCounterOptionsForm: OverTheCounterOptionsFormType = [
  OverTheCounterOptionsFormPart1,
  OverTheCounterOptionsFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgent',
    'taxAgentOther',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
  assetsDocumentsPageBuilder().form,
];

export { OverTheCounterOptionsForm };
