import { ReactNode } from 'react';

enum MENU {
  INBOX = 'INBOX',
  DASHBOARD = 'DASHBOARD',
  NEXT_OWNERS = 'NEXT_OWNERS',
  ASSETS = 'ASSETS',
  DOCUMENTS = 'DOCUMENTS',
  ALGORITHMS = 'ALGORITHMS',
  MY_HUB = 'MY_HUB',
  CONTACTS = 'CONTACTS',
  SOWE = 'SOWE',
  DELEGATED = 'DELEGATED',
  SHARED = 'SHARED',
  TODO = 'TODO',
}

enum DELEGATED {
  STEP1 = 'delegated-step1',
}

enum INBOX {
  STEP1 = 'inbox-step1',
  STEP2 = 'inbox-step2',
}

enum DASHBOARD {
  STEP1 = 'dashboard-step1',
}

enum NEXT_OWNERS {
  STEP1 = 'nextOwners-step1',
  STEP2 = 'nextOwners-step2',
  STEP3 = 'nextOwners-step3',
  STEP4 = 'nextOwners-step4',
}

enum ASSETS {
  STEP1 = 'assets-step1',
  STEP2 = 'assets-step2',
}

enum DOCUMENTS {
  STEP1 = 'documents-step1',
  STEP2 = 'documents-step2',
}

enum CONTACTS {
  STEP1 = 'contacts-step1',
  STEP2 = 'contacts-step2',
}

enum SHARED {
  STEP1 = 'shared-step1',
}

enum TODO {
  STEP1 = 'todo-step1',
}

enum SOWE {
  STEP1 = 'sowe-step1',
  STEP2 = 'sowe-step2',
}

enum ALGORITHMS_PART1 {
  STEP1 = 'algorithms-step1',
}

enum ALGORITHMS_PART2 {
  STEP1 = 'algorithmsCreate-step1',
  STEP2 = 'algorithmsCreate-step2',
  STEP3 = 'algorithmsCreate-step3',
  STEP4 = 'algorithmsCreate-step4',
}

export const PAGE_TO_IDS: Record<
  string,
  Record<string, string> | Record<string, string>[]
> = {
  '/menu': MENU,
  '/delegated': DELEGATED,
  '/documents/inbox': INBOX,
  '/dashboard': [DASHBOARD, MENU],
  '/beneficiary': NEXT_OWNERS,
  '/assets': ASSETS,
  '/documents': DOCUMENTS,
  '/contacts': CONTACTS,
  '/shared': SHARED,
  '/todo': TODO,
  '/sowe': SOWE,
  '/algorithms': ALGORITHMS_PART1,
  '/algorithms/create': ALGORITHMS_PART2,
};

export const IDS_TO_TEXTS: Record<
  string,
  { title: ReactNode; description?: ReactNode }
> = {
  [MENU.INBOX]: {
    title: 'Inbox',
    description: 'Inbox allows you to store and distribute inbound documents',
  },
  [MENU.DASHBOARD]: {
    title: 'Dashboard',
    description:
      'Dashboard allows you to see all of your data in one single place',
  },
  [MENU.NEXT_OWNERS]: {
    title: 'Next Owners',
    description:
      'Invite and manage people who will receive your Assets, Documents and other information based on the conditions you set in Transfer Algorithms',
  },
  [MENU.ASSETS]: {
    title: 'Assets',
    description:
      'Assets contain the list of the items that are in your possession. Each one has the list of attributes allowing do the in-depth analysis and providing the analytics',
  },
  [MENU.DOCUMENTS]: {
    title: 'Documents',
    description:
      'Documents store all of the necessary information such as your IDs, agreements, contracts, etc. split by categories.',
  },
  [MENU.ALGORITHMS]: {
    title: 'Algorithms',
    description:
      'Algorithms allow you to perform the secured transfer of the information about your assets, contacts or any other data  from you to the Next Owner',
  },
  [MENU.MY_HUB]: {
    title: 'My Hub',
    description:
      'Set up your personal blockchain server to store all your capital and asset information. It will be accessible exclusively to you in read-only mode.',
  },
  [MENU.CONTACTS]: {
    title: 'My contacts',
    description:
      'Contacts is the place where you can store the information about anyone or anything you interact with. Use this data to autofill forms, assign documents to contacts and navigate through it smoothly',
  },
  [MENU.SOWE]: {
    title: 'SoWE',
    description:
      'SoWE allows you to create a Source of Wealth Essay, use it as a master file to create a various version of it with less effort.',
  },
  [MENU.DELEGATED]: {
    title: 'Delegated',
    description:
      'Securely send your Assets, IDs and other documents to people that you trust for filling them in and send back to you. Once you receive the document, you can review, approve or reject it.',
  },
  [MENU.SHARED]: {
    title: 'Shared',
    description:
      'Create and manage spaces where you can store Assets, Document etc and share it with your significant others.',
  },
  [MENU.TODO]: {
    title: 'ToDo',
    description: 'Add and see all of the action items in one single place',
  },

  [DELEGATED.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Securely send your Assets, IDs and other documents to people that you
        trust for filling them in and send back to you. Once you receive the
        document, you can review, approve or reject it.
      </div>
    ),
  },

  [INBOX.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Add your first one manually from your drive
      </div>
    ),
  },
  [INBOX.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Or send it to the dedicated mailbox.
      </div>
    ),
  },

  [DASHBOARD.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Start by adding you first Asset to see the analytics, assign it to the
        Next Owner and create Transfer Algorithm
      </div>
    ),
  },

  [NEXT_OWNERS.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>Add your first Next Owner here:</div>
    ),
  },
  [NEXT_OWNERS.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Use filters to get a c picture of status of the Next Owners
      </div>
    ),
  },
  [NEXT_OWNERS.STEP3]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Get a quick access to your favorite people
      </div>
    ),
  },
  [NEXT_OWNERS.STEP4]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        See Next Owners filters by the group or create your own
      </div>
    ),
  },

  [ASSETS.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Create your first Asset by selecting it’s name, type, country and value.
        You can always add additional details or securely delegate it for
        filling to person that you trust”.
      </div>
    ),
  },
  [ASSETS.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Use filters to get the information you need in few clicks
      </div>
    ),
  },

  [DOCUMENTS.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        IDs contain anything that identifies person and was issued by the
        government: passport, national ID, education certificate, etc.
      </div>
    ),
  },
  [DOCUMENTS.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Documents contain anything that was produced by the person or
        organization: agreements, powers of attorney, etc.
      </div>
    ),
  },

  [CONTACTS.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        You can create a different type of contacts: from Individual to Legal
        entities, Banks and others. Then store and manage them in a single
        place.
      </div>
    ),
  },
  [CONTACTS.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Use filters to quickly get the information you need:
      </div>
    ),
  },

  [SHARED.STEP1]: {
    title: <div style={{ marginTop: '20px' }}>Add new shared space here</div>,
  },

  [TODO.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        When you add anything from your documents, assets or any other places
        into My To Do list, it will be displayed here, so that you have an
        overview of work needs to be done:
      </div>
    ),
  },

  [SOWE.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Start by adding your first one SoWE here
      </div>
    ),
  },
  [SOWE.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        See the list of previously created items in the table
      </div>
    ),
  },

  [ALGORITHMS_PART1.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Here you can create your first transfer algorithm simply by adding all
        of the details step-by-step
      </div>
    ),
  },

  [ALGORITHMS_PART2.STEP1]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Choose the name for your Algorithm
      </div>
    ),
  },
  [ALGORITHMS_PART2.STEP2]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Select the type of information you want to transfer, whether it is an
        Asset or Contact
      </div>
    ),
  },
  [ALGORITHMS_PART2.STEP3]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Select who will receive this information
      </div>
    ),
  },
  [ALGORITHMS_PART2.STEP4]: {
    title: (
      <div style={{ marginTop: '20px' }}>
        Define how you want the Algorithm to be triggered
      </div>
    ),
  },
};
export const TUTORIALS = {
  MENU,
  DELEGATED,
  INBOX,
  DASHBOARD,
  NEXT_OWNERS,
  ASSETS,
  DOCUMENTS,
  CONTACTS,
  SHARED,
  TODO,
  SOWE,
  ALGORITHMS_PART1,
  ALGORITHMS_PART2,
  PAGE_TO_IDS,
  IDS_TO_TEXTS,
};
