import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';

export interface DocumentBaseDto extends BaseDto {
  type: DocumentType;
}

export enum DocumentType {
  BENEFICIARY_PERSONAL_DATA = 'BENEFICIARY_PERSONAL_DATA',
  ASSET_INFORMATION = 'ASSET_INFORMATION',
  CONTACTS = 'CONTACTS',
  PASSPORT = 'PASSPORT',
  UNKNOWN = 'UNKNOWN',

  PRIVATE_AGREEMENTS = 'PRIVATE_AGREEMENTS',
  PUBLIC_AGREEMENTS = 'PUBLIC_AGREEMENTS',
  INFORMATION = 'INFORMATION',
  BANK_DOCUMENTS = 'BANK_DOCUMENTS',
  PAYMENT_DOCUMENTS = 'PAYMENT_DOCUMENTS',
  POWERS = 'POWERS',
  CORPORATE_DOCS = 'CORPORATE_DOCS',
  FAMILY_DOCUMENTS = 'FAMILY_DOCUMENTS',
  PERSONAL_IDENTIFIERS = 'PERSONAL_IDENTIFIERS',
  PRIVATE_DOCUMENTS = 'PRIVATE_DOCUMENTS',
}
