import type { FC, KeyboardEvent } from 'react';
import { ClipboardEvent, useEffect, useRef } from 'react';
import { Form } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';

interface IInputCode {
  maxLength: number;
  onInputPinCode: (value: string) => void;
  firstInputPostfix?: string;
  triggerRepeat?: boolean;
  uniqPostfix?: string;
  autofocus?: boolean;
}

const NUMBERS_REGEX = /^\d+$/;

type TargetValueType = {
  value: string;
  id: string;
};

// eslint-disable-next-line no-empty-pattern
export const InputCode: FC<IInputCode> = ({
  onInputPinCode,
  firstInputPostfix,
  triggerRepeat,
  uniqPostfix = '',
  autofocus,
}) => {
  const initialValues = {
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',
  };
  const [form] = Form.useForm<{
    field1: number | null;
    field2: number | null;
    field3: number | null;
    field4: number | null;
    field5: number | null;
    field6: number | null;
  }>();
  const inputRef1 = useRef<HTMLInputElement>(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const inputRef3 = useRef<HTMLInputElement>(null);
  const inputRef4 = useRef<HTMLInputElement>(null);
  const inputRef5 = useRef<HTMLInputElement>(null);
  const inputRef6 = useRef<HTMLInputElement>(null);

  const sharedProps = {
    maxLength: 1,
    type: 'tel',
    pattern: '[0-9]',
  };

  function onPaste(event: ClipboardEvent<HTMLInputElement>): void {
    event.preventDefault();
    const pasted = event.clipboardData.getData('text/plain');
    const wordsArray = pasted.trim().replace(/\D/g, '').split('').slice(0, 6);
    if (!wordsArray) return;
    if (wordsArray.length > 0 && inputRef1.current)
      inputRef1.current.value = wordsArray[0];
    if (wordsArray.length > 1 && inputRef2.current)
      inputRef2.current.value = wordsArray[1];
    if (wordsArray.length > 2 && inputRef3.current)
      inputRef3.current.value = wordsArray[2];
    if (wordsArray.length > 3 && inputRef4.current)
      inputRef4.current.value = wordsArray[3];
    if (wordsArray.length > 4 && inputRef5.current)
      inputRef5.current.value = wordsArray[4];
    if (wordsArray.length > 5 && inputRef6.current)
      inputRef6.current.value = wordsArray[5];
    onInputPinCode(wordsArray.join(''));
  }

  // eslint-disable-next-line complexity
  function onlyNumberInput(event: KeyboardEvent<HTMLInputElement>) {
    if (
      !NUMBERS_REGEX.test(event.key) &&
      event.code !== 'ArrowLeft' &&
      event.code !== 'ArrowRight' &&
      event.code !== 'Backspace' &&
      event.code !== 'Delete' &&
      event.code !== 'Tab' &&
      event.code !== 'ControlLeft' &&
      event.code !== 'ControlRight' &&
      event.code !== 'KeyV' &&
      event.key !== 'Backspace' &&
      event.key !== 'Enter'
    ) {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      target.value = target.value.replace(/[^\d]/g, '');
    } else if (
      !(event.target as unknown as TargetValueType).value &&
      (event.code === 'ArrowLeft' ||
        event.code === 'Backspace' ||
        event.code === 'Delete' ||
        event.key === 'Backspace' ||
        event.key === 'Delete')
    ) {
      const id = (event.target as unknown as TargetValueType).id.slice(-1);
      let fieldId = `field${uniqPostfix}${Number(id) - 1}`;

      if (id === '1') {
        if (!firstInputPostfix) return;
        fieldId = `field${firstInputPostfix}${6}`;
      }

      const el: HTMLInputElement | null = document.getElementById(
        fieldId,
      ) as HTMLInputElement | null;

      if (!el) return;

      const end = el.value.length;

      el.setSelectionRange(end, end);
      el.focus();
    }
  }

  const onChange = () => {
    let result = '';
    if (inputRef1.current?.value) {
      result += inputRef1.current?.value;
    }
    if (inputRef2.current?.value) {
      result += inputRef2.current?.value;
    }
    if (inputRef3.current?.value) {
      result += inputRef3.current?.value;
    }
    if (inputRef4.current?.value) {
      result += inputRef4.current?.value;
    }
    if (inputRef5.current?.value) {
      result += inputRef5.current?.value;
    }
    if (inputRef6.current?.value) {
      result += inputRef6.current?.value;
    }
    onInputPinCode(result);
  };

  useEffect(() => {
    if (autofocus) {
      inputRef1.current?.focus();
    }
  }, []);

  return (
    <Form
      form={form}
      style={{
        maxWidth: 600,
        display: 'flex',
        alignItems: 'center',
        gap: '1px',
      }}
      initialValues={initialValues}
      onChange={onChange}
    >
      <Form.Item name={`field${uniqPostfix}1`}>
        <Input
          className={firstInputPostfix ? 'repeatInput1' : undefined}
          data-testid={'pinCodeInput1'}
          ref={inputRef1}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (e.target.value) {
              inputRef2.current!.focus();
            }
          }}
        />
      </Form.Item>
      <Form.Item name={`field${uniqPostfix}2`}>
        <Input
          data-testid={'pinCodeInput2'}
          ref={inputRef2}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (e.target.value) {
              inputRef3.current!.focus();
            }
          }}
        />
      </Form.Item>
      <Form.Item name={`field${uniqPostfix}3`}>
        <Input
          data-testid={'pinCodeInput3'}
          ref={inputRef3}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (e.target.value) {
              inputRef4.current!.focus();
            }
          }}
        />
      </Form.Item>
      <Form.Item name={`field${uniqPostfix}4`}>
        <Input
          data-testid={'pinCodeInput4'}
          ref={inputRef4}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (e.target.value) {
              inputRef5.current!.focus();
            }
          }}
        />
      </Form.Item>
      <Form.Item name={`field${uniqPostfix}5`}>
        <Input
          data-testid={'pinCodeInput5'}
          ref={inputRef5}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (e.target.value) {
              inputRef6.current!.focus();
            }
          }}
        />
      </Form.Item>
      <Form.Item name={`field${uniqPostfix}6`}>
        <Input
          data-testid={'pinCodeInput6'}
          ref={inputRef6}
          {...sharedProps}
          onKeyDown={onlyNumberInput}
          onInput={onlyNumberInput}
          onPaste={onPaste}
          onChange={(e) => {
            if (triggerRepeat && e.target.value) {
              const nextInputCode = document.querySelector(
                '.repeatInput1',
              ) as HTMLInputElement;
              nextInputCode.focus();
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};

const Input = styled.input`
  height: 42px;
  width: 32px;
  margin-left: 4px;
  display: inline-flex;
  padding: 4px;
  text-align: center;
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  border: 1px solid ${COLORS.colorPrimaryBorder};
  border-radius: 6px;
  outline: 0;
  transition: all 0.2s;

  &:hover {
    border: 1px solid ${COLORS.colorPrimaryBorderHover};
  }

  &:focus {
    box-shadow: 0px 0px 2px 1px ${COLORS.colorPrimaryBgHover};
    border: 1px solid ${COLORS.colorPrimaryBorderHover};
  }
`;
