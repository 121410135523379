import { IconType } from '@/shared/icons/types';

export const AddIcon = ({ ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...rest}
  >
    <path
      d="M11.7235 2.32251H10.2771C10.1485 2.32251 10.0842 2.3868 10.0842 2.51537V10.085H2.90086C2.77229 10.085 2.70801 10.1493 2.70801 10.2779V11.7243C2.70801 11.8529 2.77229 11.9172 2.90086 11.9172H10.0842V19.4868C10.0842 19.6154 10.1485 19.6797 10.2771 19.6797H11.7235C11.8521 19.6797 11.9164 19.6154 11.9164 19.4868V11.9172H19.1009C19.2294 11.9172 19.2937 11.8529 19.2937 11.7243V10.2779C19.2937 10.1493 19.2294 10.085 19.1009 10.085H11.9164V2.51537C11.9164 2.3868 11.8521 2.32251 11.7235 2.32251Z"
      fill="currentColor"
    />
  </svg>
);
