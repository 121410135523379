import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';

type ISettingsState = {
  debugMode: boolean;
  setDebugMode: (debugMode: boolean) => void;
  encryptedMnemonic: string | undefined;
  setEncryptedMnemonic: (decryptedMnemonic: string, password: string) => string;
  getDecryptedKeyPair: (password: string) => Promise<KeyPair | undefined>;
  clear: () => void;
};

export const useSettingsStore = create(
  persist<ISettingsState>(
    (set, get) => ({
      debugMode: false,
      setDebugMode: (debugMode: boolean): void => set({ debugMode }),
      encryptedMnemonic: undefined,
      getDecryptedKeyPair: async (
        password: string,
      ): Promise<KeyPair | undefined> => {
        if (!password) return undefined;
        const encryptedMnemonic = get().encryptedMnemonic;
        if (!encryptedMnemonic) return undefined;
        const cryptoCore = new CryptoCore();
        const mnemonic = cryptoCore.aes
          .decrypt(encryptedMnemonic, password)
          .trim();
        if (!mnemonic) return undefined;
        return cryptoCore.mnemonic.mnemonicToKeyPair(mnemonic);
      },
      setEncryptedMnemonic: (
        decryptedMnemonic: string,
        password: string,
      ): string => {
        if (!password) return '';
        const cryptoCore = new CryptoCore();
        const encryptedMnemonic = cryptoCore.aes.encrypt(
          decryptedMnemonic,
          password,
        );
        set({ encryptedMnemonic });
        return encryptedMnemonic;
      },
      clear: (): void =>
        set({ debugMode: false, encryptedMnemonic: undefined }),
    }),
    {
      name: 'settings-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
