import { useEffect, useState } from 'react';
import * as process from 'process';

import { useBeneficiary } from '@/entities/next-owner';
import { Button, Container, CopyComponent } from '@/shared/components';
import { useRegenerateBeneficiaryInviteMutation } from '@/shared/generated/graphql';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';

const selfUrl = process.env.NEXT_PUBLIC_SELF_URL_BASE || '';

type TGenerateRegistrationLinkProperties =
  | {
      alias: 'assistant';
      itemId: string;
    }
  | { alias: 'nextOwner'; itemId: number }
  | { alias: 'sharedSpaceAssistant'; itemId: string };

export const GenerateRegistrationLink = ({
  alias = 'assistant',
  itemId,
}: TGenerateRegistrationLinkProperties) => {
  const [isGenerated, setIsGenerated] = useState(false);
  const { getBeneficiary } = useBeneficiary();
  const sjDb = useSJDatabase();
  const [regenerateNextOwnerLink] = useRegenerateBeneficiaryInviteMutation({
    variables: { beneficiaryProfileId: itemId as number },
  });
  const regenerate = async () => {
    if (alias === 'nextOwner') {
      const nextOwner = sjDb.collections.beneficiaries?.findOne(
        (no) => no.centralDbProfileId === itemId,
      );

      if (nextOwner) {
        try {
          const newLink = await regenerateNextOwnerLink();
          if (newLink.data?.regenerateBeneficiaryInvite.invitationCode) {
            sjDb.collections.beneficiaries?.update({
              ...nextOwner,
              invitationCode:
                newLink.data.regenerateBeneficiaryInvite.invitationCode,
            });
          }
        } catch (e) {
          console.warn('ERROR while regenerate new registration link: ', e);
        }
      }
    }
    setIsGenerated(false);
  };

  useEffect(() => {
    const nextOwner =
      alias === 'nextOwner' ? getBeneficiary(itemId) : undefined;
    if (nextOwner && !nextOwner.invitationCode) {
      regenerate();
    }
  }, [getBeneficiary, alias, itemId]);

  const nextOwnerLink = () => {
    return `${selfUrl}?beneficiarydata=${getBeneficiary(itemId)
      ?.invitationCode}`;
  };

  const assistantLink = `${selfUrl}?delegationRequestId=${itemId}`;

  const sharedSpaceAssistantLink = `${selfUrl}?sharedSpaceId=${itemId}`;

  const generatedText =
    alias === 'assistant'
      ? assistantLink
      : alias === 'sharedSpaceAssistant'
      ? sharedSpaceAssistantLink
      : nextOwnerLink();

  return (
    <>
      <CopyComponent
        code={generatedText}
        isGenerated={isGenerated}
        setIsGenerated={setIsGenerated}
      />
      {isGenerated && (
        <Container marginTop={24}>
          <Button text={'Regenerate link'} onClick={regenerate} />
        </Container>
      )}
    </>
  );
};
