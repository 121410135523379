import { ReactNode } from 'react';
import styled from 'styled-components';

interface IError {
  children: ReactNode;
}
export function ErrorText({ children }: IError): JSX.Element {
  return <Error>{children}</Error>;
}

const Error = styled.p`
  color: #ee0000;
  font-size: 12px;
`;
