/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type AccessStatusesResponse = {
  __typename?: 'AccessStatusesResponse';
  /** Наличие аккаунта в приложении */
  accountAvailabilityApp: Scalars['Boolean']['output'];
  /** Наличие MyHub */
  availabilityMyHub: Scalars['Boolean']['output'];
};

export type Account = {
  __typename?: 'Account';
  _count: AccountCount;
  beneficiaryAnonymousPropForEMail?: Maybe<Array<BeneficiaryAnonymousPropForEmail>>;
  confirmedPhone?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  nextOwnerPropForEmail?: Maybe<Array<NextOwnerPropForEmail>>;
  phone?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['Int']['output']>;
  sessions?: Maybe<Array<AccountSession>>;
  twoFactorQuestions: Array<TwoFactorQuestion>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AccountCount = {
  __typename?: 'AccountCount';
  beneficiaryAnonymousPropForEMail: Scalars['Int']['output'];
  nextOwnerPropForEmail: Scalars['Int']['output'];
  sessions: Scalars['Int']['output'];
};

export type AccountCreateManyProfileInput = {
  confirmedPhone?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  passwordHash: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountCreateManyProfileInputEnvelope = {
  data: Array<AccountCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
};

export type AccountCreateOrConnectWithoutProfileInput = {
  create: AccountCreateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutProfileInput = {
  beneficiaryAnonymousPropForEMail?: InputMaybe<BeneficiaryAnonymousPropForEmailCreateNestedManyWithoutAccountInput>;
  confirmedPhone?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  nextOwnerPropForEmail?: InputMaybe<NextOwnerPropForEmailCreateNestedManyWithoutAccountInput>;
  passwordHash: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<AccountSessionCreateNestedManyWithoutAccountInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountRelationFilter = {
  is?: InputMaybe<AccountWhereInput>;
  isNot?: InputMaybe<AccountWhereInput>;
};

export type AccountScalarWhereInput = {
  AND?: InputMaybe<Array<AccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountScalarWhereInput>>;
  confirmedPhone?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  passwordHash?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountSession = {
  __typename?: 'AccountSession';
  account: Account;
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  ipAddr: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
};

export type AccountSessionCreateManyAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  ipAddr: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionCreateManyAccountInputEnvelope = {
  data: Array<AccountSessionCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AccountSessionCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountSessionCreateManyAccountInputEnvelope>;
};

export type AccountSessionCreateOrConnectWithoutAccountInput = {
  create: AccountSessionCreateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  ipAddr: Scalars['String']['input'];
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionListRelationFilter = {
  every?: InputMaybe<AccountSessionWhereInput>;
  none?: InputMaybe<AccountSessionWhereInput>;
  some?: InputMaybe<AccountSessionWhereInput>;
};

export type AccountSessionScalarWhereInput = {
  AND?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export type AccountSessionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  ipAddr?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionUpdateManyWithWhereWithoutAccountInput = {
  data: AccountSessionUpdateManyMutationInput;
  where: AccountSessionScalarWhereInput;
};

export type AccountSessionUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountSessionCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<AccountSessionCreateWithoutAccountInput>>;
  createMany?: InputMaybe<AccountSessionCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountSessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountSessionWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountSessionUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<AccountSessionUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<AccountSessionUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type AccountSessionUpdateWithWhereUniqueWithoutAccountInput = {
  data: AccountSessionUpdateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionUpdateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  ipAddr?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
};

export type AccountSessionUpsertWithWhereUniqueWithoutAccountInput = {
  create: AccountSessionCreateWithoutAccountInput;
  update: AccountSessionUpdateWithoutAccountInput;
  where: AccountSessionWhereUniqueInput;
};

export type AccountSessionWhereInput = {
  AND?: InputMaybe<Array<AccountSessionWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export type AccountSessionWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountSessionWhereInput>>;
  NOT?: InputMaybe<Array<AccountSessionWhereInput>>;
  OR?: InputMaybe<Array<AccountSessionWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  ipAddr?: InputMaybe<StringFilter>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userAgent?: InputMaybe<StringNullableFilter>;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Created = 'CREATED',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE'
}

export type AccountUpdateManyMutationInput = {
  confirmedPhone?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountUpdateManyWithWhereWithoutProfileInput = {
  data: AccountUpdateManyMutationInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutProfileInput>>;
  createMany?: InputMaybe<AccountCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<AccountUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<AccountUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type AccountUpdateWithWhereUniqueWithoutProfileInput = {
  data: AccountUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpdateWithoutProfileInput = {
  beneficiaryAnonymousPropForEMail?: InputMaybe<BeneficiaryAnonymousPropForEmailUpdateManyWithoutAccountNestedInput>;
  confirmedPhone?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  nextOwnerPropForEmail?: InputMaybe<NextOwnerPropForEmailUpdateManyWithoutAccountNestedInput>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sessions?: InputMaybe<AccountSessionUpdateManyWithoutAccountNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AccountUpsertWithWhereUniqueWithoutProfileInput = {
  create: AccountCreateWithoutProfileInput;
  update: AccountUpdateWithoutProfileInput;
  where: AccountWhereUniqueInput;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  beneficiaryAnonymousPropForEMail?: InputMaybe<BeneficiaryAnonymousPropForEmailListRelationFilter>;
  confirmedPhone?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  nextOwnerPropForEmail?: InputMaybe<NextOwnerPropForEmailListRelationFilter>;
  passwordHash?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<AccountSessionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AccountWhereUniqueInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  beneficiaryAnonymousPropForEMail?: InputMaybe<BeneficiaryAnonymousPropForEmailListRelationFilter>;
  confirmedPhone?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  nextOwnerPropForEmail?: InputMaybe<NextOwnerPropForEmailListRelationFilter>;
  passwordHash?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileNullableRelationFilter>;
  profileId?: InputMaybe<IntNullableFilter>;
  sessions?: InputMaybe<AccountSessionListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivateAccountInput = {
  code: Scalars['String']['input'];
  codeSms?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AssistantInput = {
  name: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  /** Данное поле содержит аккаунт */
  account: Account;
  /** Данное поле содержит токен */
  token: Scalars['String']['output'];
};

export type BeneficiaryActivationInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  twoFactorQuestions: Array<TwoFactorQuestionPlainObject>;
};

export type BeneficiaryAnonymousPropForEmail = {
  __typename?: 'BeneficiaryAnonymousPropForEmail';
  account: Account;
  accountId: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
};

export type BeneficiaryAnonymousPropForEmailCreateManyAccountInput = {
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
};

export type BeneficiaryAnonymousPropForEmailCreateManyAccountInputEnvelope = {
  data: Array<BeneficiaryAnonymousPropForEmailCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeneficiaryAnonymousPropForEmailCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailCreateWithoutAccountInput>>;
  createMany?: InputMaybe<BeneficiaryAnonymousPropForEmailCreateManyAccountInputEnvelope>;
};

export type BeneficiaryAnonymousPropForEmailCreateOrConnectWithoutAccountInput = {
  create: BeneficiaryAnonymousPropForEmailCreateWithoutAccountInput;
  where: BeneficiaryAnonymousPropForEmailWhereUniqueInput;
};

export type BeneficiaryAnonymousPropForEmailCreateWithoutAccountInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BeneficiaryAnonymousPropForEmailListRelationFilter = {
  every?: InputMaybe<BeneficiaryAnonymousPropForEmailWhereInput>;
  none?: InputMaybe<BeneficiaryAnonymousPropForEmailWhereInput>;
  some?: InputMaybe<BeneficiaryAnonymousPropForEmailWhereInput>;
};

export type BeneficiaryAnonymousPropForEmailScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailScalarWhereInput>>;
  accountId?: InputMaybe<IntFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
};

export type BeneficiaryAnonymousPropForEmailUpdateManyMutationInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BeneficiaryAnonymousPropForEmailUpdateManyWithWhereWithoutAccountInput = {
  data: BeneficiaryAnonymousPropForEmailUpdateManyMutationInput;
  where: BeneficiaryAnonymousPropForEmailScalarWhereInput;
};

export type BeneficiaryAnonymousPropForEmailUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailCreateWithoutAccountInput>>;
  createMany?: InputMaybe<BeneficiaryAnonymousPropForEmailCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type BeneficiaryAnonymousPropForEmailUpdateWithWhereUniqueWithoutAccountInput = {
  data: BeneficiaryAnonymousPropForEmailUpdateWithoutAccountInput;
  where: BeneficiaryAnonymousPropForEmailWhereUniqueInput;
};

export type BeneficiaryAnonymousPropForEmailUpdateWithoutAccountInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type BeneficiaryAnonymousPropForEmailUpsertWithWhereUniqueWithoutAccountInput = {
  create: BeneficiaryAnonymousPropForEmailCreateWithoutAccountInput;
  update: BeneficiaryAnonymousPropForEmailUpdateWithoutAccountInput;
  where: BeneficiaryAnonymousPropForEmailWhereUniqueInput;
};

export type BeneficiaryAnonymousPropForEmailWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
};

export type BeneficiaryAnonymousPropForEmailWhereUniqueInput = {
  AND?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryAnonymousPropForEmailWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<StringFilter>;
};

export type BeneficiaryCreateByInviteCodeInput = {
  email: Scalars['String']['input'];
  invitationCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  twoFactorQuestions: Array<TwoFactorQuestionPlainObject>;
};

export type BeneficiaryInput = {
  anonymousId?: InputMaybe<Scalars['String']['input']>;
  dontSendInviteEmail?: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeneficiaryInviteCode = {
  __typename?: 'BeneficiaryInviteCode';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isUsed: Scalars['Boolean']['output'];
  mainUserProfile: Profile;
  mainUserProfileId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  usedForProfile?: Maybe<Profile>;
  usedForProfileId?: Maybe<Scalars['Int']['output']>;
};

export type BeneficiaryInviteCodeCreateManyMainUserProfileInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  usedForProfileId?: InputMaybe<Scalars['Int']['input']>;
};

export type BeneficiaryInviteCodeCreateManyMainUserProfileInputEnvelope = {
  data: Array<BeneficiaryInviteCodeCreateManyMainUserProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeneficiaryInviteCodeCreateManyUsedForProfileInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  mainUserProfileId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BeneficiaryInviteCodeCreateManyUsedForProfileInputEnvelope = {
  data: Array<BeneficiaryInviteCodeCreateManyUsedForProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput = {
  connect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryInviteCodeCreateOrConnectWithoutMainUserProfileInput>>;
  create?: InputMaybe<Array<BeneficiaryInviteCodeCreateWithoutMainUserProfileInput>>;
  createMany?: InputMaybe<BeneficiaryInviteCodeCreateManyMainUserProfileInputEnvelope>;
};

export type BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput = {
  connect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryInviteCodeCreateOrConnectWithoutUsedForProfileInput>>;
  create?: InputMaybe<Array<BeneficiaryInviteCodeCreateWithoutUsedForProfileInput>>;
  createMany?: InputMaybe<BeneficiaryInviteCodeCreateManyUsedForProfileInputEnvelope>;
};

export type BeneficiaryInviteCodeCreateOrConnectWithoutMainUserProfileInput = {
  create: BeneficiaryInviteCodeCreateWithoutMainUserProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeCreateOrConnectWithoutUsedForProfileInput = {
  create: BeneficiaryInviteCodeCreateWithoutUsedForProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeCreateWithoutMainUserProfileInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  usedForProfile?: InputMaybe<ProfileCreateNestedOneWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
};

export type BeneficiaryInviteCodeCreateWithoutUsedForProfileInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  mainUserProfile: ProfileCreateNestedOneWithoutBeneficiaryInviteCodeAsMainUserInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BeneficiaryInviteCodeListRelationFilter = {
  every?: InputMaybe<BeneficiaryInviteCodeWhereInput>;
  none?: InputMaybe<BeneficiaryInviteCodeWhereInput>;
  some?: InputMaybe<BeneficiaryInviteCodeWhereInput>;
};

export type BeneficiaryInviteCodeScalarWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryInviteCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryInviteCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryInviteCodeScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isUsed?: InputMaybe<BoolFilter>;
  mainUserProfileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedForProfileId?: InputMaybe<IntNullableFilter>;
};

export type BeneficiaryInviteCodeUniqueBeneficiaryInviteCodeCompoundUniqueInput = {
  mainUserProfileId: Scalars['Int']['input'];
  usedForProfileId: Scalars['Int']['input'];
};

export type BeneficiaryInviteCodeUpdateManyMutationInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BeneficiaryInviteCodeUpdateManyWithWhereWithoutMainUserProfileInput = {
  data: BeneficiaryInviteCodeUpdateManyMutationInput;
  where: BeneficiaryInviteCodeScalarWhereInput;
};

export type BeneficiaryInviteCodeUpdateManyWithWhereWithoutUsedForProfileInput = {
  data: BeneficiaryInviteCodeUpdateManyMutationInput;
  where: BeneficiaryInviteCodeScalarWhereInput;
};

export type BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryInviteCodeCreateOrConnectWithoutMainUserProfileInput>>;
  create?: InputMaybe<Array<BeneficiaryInviteCodeCreateWithoutMainUserProfileInput>>;
  createMany?: InputMaybe<BeneficiaryInviteCodeCreateManyMainUserProfileInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryInviteCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryInviteCodeUpdateWithWhereUniqueWithoutMainUserProfileInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryInviteCodeUpdateManyWithWhereWithoutMainUserProfileInput>>;
  upsert?: InputMaybe<Array<BeneficiaryInviteCodeUpsertWithWhereUniqueWithoutMainUserProfileInput>>;
};

export type BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput = {
  connect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BeneficiaryInviteCodeCreateOrConnectWithoutUsedForProfileInput>>;
  create?: InputMaybe<Array<BeneficiaryInviteCodeCreateWithoutUsedForProfileInput>>;
  createMany?: InputMaybe<BeneficiaryInviteCodeCreateManyUsedForProfileInputEnvelope>;
  delete?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BeneficiaryInviteCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<BeneficiaryInviteCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<BeneficiaryInviteCodeUpdateWithWhereUniqueWithoutUsedForProfileInput>>;
  updateMany?: InputMaybe<Array<BeneficiaryInviteCodeUpdateManyWithWhereWithoutUsedForProfileInput>>;
  upsert?: InputMaybe<Array<BeneficiaryInviteCodeUpsertWithWhereUniqueWithoutUsedForProfileInput>>;
};

export type BeneficiaryInviteCodeUpdateWithWhereUniqueWithoutMainUserProfileInput = {
  data: BeneficiaryInviteCodeUpdateWithoutMainUserProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeUpdateWithWhereUniqueWithoutUsedForProfileInput = {
  data: BeneficiaryInviteCodeUpdateWithoutUsedForProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeUpdateWithoutMainUserProfileInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  usedForProfile?: InputMaybe<ProfileUpdateOneWithoutBeneficiaryInviteCodeAsUsedForProfileNestedInput>;
};

export type BeneficiaryInviteCodeUpdateWithoutUsedForProfileInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<Scalars['Boolean']['input']>;
  mainUserProfile?: InputMaybe<ProfileUpdateOneRequiredWithoutBeneficiaryInviteCodeAsMainUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BeneficiaryInviteCodeUpsertWithWhereUniqueWithoutMainUserProfileInput = {
  create: BeneficiaryInviteCodeCreateWithoutMainUserProfileInput;
  update: BeneficiaryInviteCodeUpdateWithoutMainUserProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeUpsertWithWhereUniqueWithoutUsedForProfileInput = {
  create: BeneficiaryInviteCodeCreateWithoutUsedForProfileInput;
  update: BeneficiaryInviteCodeUpdateWithoutUsedForProfileInput;
  where: BeneficiaryInviteCodeWhereUniqueInput;
};

export type BeneficiaryInviteCodeWhereInput = {
  AND?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isUsed?: InputMaybe<BoolFilter>;
  mainUserProfile?: InputMaybe<ProfileRelationFilter>;
  mainUserProfileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedForProfile?: InputMaybe<ProfileNullableRelationFilter>;
  usedForProfileId?: InputMaybe<IntNullableFilter>;
};

export type BeneficiaryInviteCodeWhereUniqueInput = {
  AND?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  NOT?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  OR?: InputMaybe<Array<BeneficiaryInviteCodeWhereInput>>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isUsed?: InputMaybe<BoolFilter>;
  mainUserProfile?: InputMaybe<ProfileRelationFilter>;
  mainUserProfileId?: InputMaybe<IntFilter>;
  uniqueBeneficiaryInviteCode?: InputMaybe<BeneficiaryInviteCodeUniqueBeneficiaryInviteCodeCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedForProfile?: InputMaybe<ProfileNullableRelationFilter>;
  usedForProfileId?: InputMaybe<IntNullableFilter>;
};

export enum BeneficiaryStatus {
  Accepted = 'ACCEPTED',
  Idle = 'IDLE',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type Collection = {
  __typename?: 'Collection';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  ver?: Maybe<Scalars['Int']['output']>;
};

export type CollectionCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ver?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionCreateManyProfileInputEnvelope = {
  data: Array<CollectionCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CollectionCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<CollectionCreateManyProfileInputEnvelope>;
};

export type CollectionCreateOrConnectWithoutProfileInput = {
  create: CollectionCreateWithoutProfileInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ver?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionListRelationFilter = {
  every?: InputMaybe<CollectionWhereInput>;
  none?: InputMaybe<CollectionWhereInput>;
  some?: InputMaybe<CollectionWhereInput>;
};

export type CollectionScalarWhereInput = {
  AND?: InputMaybe<Array<CollectionScalarWhereInput>>;
  NOT?: InputMaybe<Array<CollectionScalarWhereInput>>;
  OR?: InputMaybe<Array<CollectionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  ver?: InputMaybe<IntNullableFilter>;
};

export type CollectionUniqueVersionOfCollectionCompoundUniqueInput = {
  createdAt: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  profileId: Scalars['Int']['input'];
};

export type CollectionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ver?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionUpdateManyWithWhereWithoutProfileInput = {
  data: CollectionUpdateManyMutationInput;
  where: CollectionScalarWhereInput;
};

export type CollectionUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CollectionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<CollectionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<CollectionCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CollectionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  set?: InputMaybe<Array<CollectionWhereUniqueInput>>;
  update?: InputMaybe<Array<CollectionUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<CollectionUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<CollectionUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type CollectionUpdateWithWhereUniqueWithoutProfileInput = {
  data: CollectionUpdateWithoutProfileInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ver?: InputMaybe<Scalars['Int']['input']>;
};

export type CollectionUpsertWithWhereUniqueWithoutProfileInput = {
  create: CollectionCreateWithoutProfileInput;
  update: CollectionUpdateWithoutProfileInput;
  where: CollectionWhereUniqueInput;
};

export type CollectionWhereInput = {
  AND?: InputMaybe<Array<CollectionWhereInput>>;
  NOT?: InputMaybe<Array<CollectionWhereInput>>;
  OR?: InputMaybe<Array<CollectionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  ver?: InputMaybe<IntNullableFilter>;
};

export type CollectionWhereUniqueInput = {
  AND?: InputMaybe<Array<CollectionWhereInput>>;
  NOT?: InputMaybe<Array<CollectionWhereInput>>;
  OR?: InputMaybe<Array<CollectionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  uniqueVersionOfCollection?: InputMaybe<CollectionUniqueVersionOfCollectionCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  ver?: InputMaybe<IntNullableFilter>;
};

export type CreateBeneficiaryResponse = {
  __typename?: 'CreateBeneficiaryResponse';
  account: Account;
  /** код приглашения */
  invitationCode: Scalars['String']['output'];
};

export type CreateEmailAccount = {
  __typename?: 'CreateEmailAccount';
  email: Scalars['String']['output'];
};

export type CurrencyRate = {
  __typename?: 'CurrencyRate';
  baseCurrency: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currencyCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DeleteEmailAccount = {
  __typename?: 'DeleteEmailAccount';
  email: Scalars['String']['output'];
};

export enum DeliveryMethod {
  Email = 'Email',
  Sms = 'SMS',
  WebPush = 'WebPush'
}

export type EmailParametersInput = {
  html?: InputMaybe<Scalars['String']['input']>;
  subject?: Scalars['String']['input'];
  text: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type EmailPasswordInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type EncryptedFile = {
  __typename?: 'EncryptedFile';
  createdAt: Scalars['DateTime']['output'];
  filePath: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mainUser: Profile;
  mainUserId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EncryptedFileCreateManyMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filePath: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EncryptedFileCreateManyMainUserInputEnvelope = {
  data: Array<EncryptedFileCreateManyMainUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EncryptedFileCreateNestedManyWithoutMainUserInput = {
  connect?: InputMaybe<Array<EncryptedFileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EncryptedFileCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<EncryptedFileCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<EncryptedFileCreateManyMainUserInputEnvelope>;
};

export type EncryptedFileCreateOrConnectWithoutMainUserInput = {
  create: EncryptedFileCreateWithoutMainUserInput;
  where: EncryptedFileWhereUniqueInput;
};

export type EncryptedFileCreateWithoutMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filePath: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EncryptedFileListRelationFilter = {
  every?: InputMaybe<EncryptedFileWhereInput>;
  none?: InputMaybe<EncryptedFileWhereInput>;
  some?: InputMaybe<EncryptedFileWhereInput>;
};

export type EncryptedFileScalarWhereInput = {
  AND?: InputMaybe<Array<EncryptedFileScalarWhereInput>>;
  NOT?: InputMaybe<Array<EncryptedFileScalarWhereInput>>;
  OR?: InputMaybe<Array<EncryptedFileScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  filePath?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EncryptedFileUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EncryptedFileUpdateManyWithWhereWithoutMainUserInput = {
  data: EncryptedFileUpdateManyMutationInput;
  where: EncryptedFileScalarWhereInput;
};

export type EncryptedFileUpdateManyWithoutMainUserNestedInput = {
  connect?: InputMaybe<Array<EncryptedFileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EncryptedFileCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<EncryptedFileCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<EncryptedFileCreateManyMainUserInputEnvelope>;
  delete?: InputMaybe<Array<EncryptedFileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EncryptedFileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EncryptedFileWhereUniqueInput>>;
  set?: InputMaybe<Array<EncryptedFileWhereUniqueInput>>;
  update?: InputMaybe<Array<EncryptedFileUpdateWithWhereUniqueWithoutMainUserInput>>;
  updateMany?: InputMaybe<Array<EncryptedFileUpdateManyWithWhereWithoutMainUserInput>>;
  upsert?: InputMaybe<Array<EncryptedFileUpsertWithWhereUniqueWithoutMainUserInput>>;
};

export type EncryptedFileUpdateWithWhereUniqueWithoutMainUserInput = {
  data: EncryptedFileUpdateWithoutMainUserInput;
  where: EncryptedFileWhereUniqueInput;
};

export type EncryptedFileUpdateWithoutMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EncryptedFileUpsertWithWhereUniqueWithoutMainUserInput = {
  create: EncryptedFileCreateWithoutMainUserInput;
  update: EncryptedFileUpdateWithoutMainUserInput;
  where: EncryptedFileWhereUniqueInput;
};

export type EncryptedFileWhereInput = {
  AND?: InputMaybe<Array<EncryptedFileWhereInput>>;
  NOT?: InputMaybe<Array<EncryptedFileWhereInput>>;
  OR?: InputMaybe<Array<EncryptedFileWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  filePath?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EncryptedFileWhereUniqueInput = {
  AND?: InputMaybe<Array<EncryptedFileWhereInput>>;
  NOT?: InputMaybe<Array<EncryptedFileWhereInput>>;
  OR?: InputMaybe<Array<EncryptedFileWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EnumAccountStatusFilter = {
  equals?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  not?: InputMaybe<NestedEnumAccountStatusFilter>;
  notIn?: InputMaybe<Array<AccountStatus>>;
};

export type EnumBeneficiaryStatusFilter = {
  equals?: InputMaybe<BeneficiaryStatus>;
  in?: InputMaybe<Array<BeneficiaryStatus>>;
  not?: InputMaybe<NestedEnumBeneficiaryStatusFilter>;
  notIn?: InputMaybe<Array<BeneficiaryStatus>>;
};

export type EnumExportStatusFilter = {
  equals?: InputMaybe<ExportStatus>;
  in?: InputMaybe<Array<ExportStatus>>;
  not?: InputMaybe<NestedEnumExportStatusFilter>;
  notIn?: InputMaybe<Array<ExportStatus>>;
};

export type EnumHubTypeFilter = {
  equals?: InputMaybe<HubType>;
  in?: InputMaybe<Array<HubType>>;
  not?: InputMaybe<NestedEnumHubTypeFilter>;
  notIn?: InputMaybe<Array<HubType>>;
};

export type EnumProfileRoleNullableListFilter = {
  equals?: InputMaybe<Array<ProfileRole>>;
  has?: InputMaybe<ProfileRole>;
  hasEvery?: InputMaybe<Array<ProfileRole>>;
  hasSome?: InputMaybe<Array<ProfileRole>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumProfileTypeFilter = {
  equals?: InputMaybe<ProfileType>;
  in?: InputMaybe<Array<ProfileType>>;
  not?: InputMaybe<NestedEnumProfileTypeFilter>;
  notIn?: InputMaybe<Array<ProfileType>>;
};

export type EnumProviderFilter = {
  equals?: InputMaybe<Provider>;
  in?: InputMaybe<Array<Provider>>;
  not?: InputMaybe<NestedEnumProviderFilter>;
  notIn?: InputMaybe<Array<Provider>>;
};

export type EnumProviderNullableFilter = {
  equals?: InputMaybe<Provider>;
  in?: InputMaybe<Array<Provider>>;
  not?: InputMaybe<NestedEnumProviderNullableFilter>;
  notIn?: InputMaybe<Array<Provider>>;
};

export type EnumScenarioModeFilter = {
  equals?: InputMaybe<ScenarioMode>;
  in?: InputMaybe<Array<ScenarioMode>>;
  not?: InputMaybe<NestedEnumScenarioModeFilter>;
  notIn?: InputMaybe<Array<ScenarioMode>>;
};

export type EnumScenarioStatusFilter = {
  equals?: InputMaybe<ScenarioStatus>;
  in?: InputMaybe<Array<ScenarioStatus>>;
  not?: InputMaybe<NestedEnumScenarioStatusFilter>;
  notIn?: InputMaybe<Array<ScenarioStatus>>;
};

export type EnumScenarioTriggerTypeFilter = {
  equals?: InputMaybe<ScenarioTriggerType>;
  in?: InputMaybe<Array<ScenarioTriggerType>>;
  not?: InputMaybe<NestedEnumScenarioTriggerTypeFilter>;
  notIn?: InputMaybe<Array<ScenarioTriggerType>>;
};

export type EnumSubscriptionStatusFilter = {
  equals?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
};

export type EnumUrlForExportStatusFilter = {
  equals?: InputMaybe<UrlForExportStatus>;
  in?: InputMaybe<Array<UrlForExportStatus>>;
  not?: InputMaybe<NestedEnumUrlForExportStatusFilter>;
  notIn?: InputMaybe<Array<UrlForExportStatus>>;
};

export type EventLog = {
  __typename?: 'EventLog';
  createdAt: Scalars['DateTime']['output'];
  encryptedData: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mainUser: Profile;
  mainUserId: Scalars['Int']['output'];
  publicData: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EventLogCreateManyMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedData: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  publicData: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventLogCreateManyMainUserInputEnvelope = {
  data: Array<EventLogCreateManyMainUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EventLogCreateNestedManyWithoutMainUserInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<EventLogCreateManyMainUserInputEnvelope>;
};

export type EventLogCreateOrConnectWithoutMainUserInput = {
  create: EventLogCreateWithoutMainUserInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogCreateWithoutMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedData: Scalars['String']['input'];
  publicData: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventLogListRelationFilter = {
  every?: InputMaybe<EventLogWhereInput>;
  none?: InputMaybe<EventLogWhereInput>;
  some?: InputMaybe<EventLogWhereInput>;
};

export type EventLogPaginationType = {
  __typename?: 'EventLogPaginationType';
  items: Array<EventLog>;
  totalCount: Scalars['Float']['output'];
};

export type EventLogScalarWhereInput = {
  AND?: InputMaybe<Array<EventLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventLogScalarWhereInput>>;
  OR?: InputMaybe<Array<EventLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encryptedData?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  publicData?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedData?: InputMaybe<Scalars['String']['input']>;
  publicData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventLogUpdateManyWithWhereWithoutMainUserInput = {
  data: EventLogUpdateManyMutationInput;
  where: EventLogScalarWhereInput;
};

export type EventLogUpdateManyWithoutMainUserNestedInput = {
  connect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventLogCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<EventLogCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<EventLogCreateManyMainUserInputEnvelope>;
  delete?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  set?: InputMaybe<Array<EventLogWhereUniqueInput>>;
  update?: InputMaybe<Array<EventLogUpdateWithWhereUniqueWithoutMainUserInput>>;
  updateMany?: InputMaybe<Array<EventLogUpdateManyWithWhereWithoutMainUserInput>>;
  upsert?: InputMaybe<Array<EventLogUpsertWithWhereUniqueWithoutMainUserInput>>;
};

export type EventLogUpdateWithWhereUniqueWithoutMainUserInput = {
  data: EventLogUpdateWithoutMainUserInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogUpdateWithoutMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedData?: InputMaybe<Scalars['String']['input']>;
  publicData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EventLogUpsertWithWhereUniqueWithoutMainUserInput = {
  create: EventLogCreateWithoutMainUserInput;
  update: EventLogUpdateWithoutMainUserInput;
  where: EventLogWhereUniqueInput;
};

export type EventLogWhereInput = {
  AND?: InputMaybe<Array<EventLogWhereInput>>;
  NOT?: InputMaybe<Array<EventLogWhereInput>>;
  OR?: InputMaybe<Array<EventLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encryptedData?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  publicData?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventLogWhereUniqueInput = {
  AND?: InputMaybe<Array<EventLogWhereInput>>;
  NOT?: InputMaybe<Array<EventLogWhereInput>>;
  OR?: InputMaybe<Array<EventLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  encryptedData?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  publicData?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Export = {
  __typename?: 'Export';
  _count: ExportCount;
  awsAccessKeyId?: Maybe<Scalars['String']['output']>;
  awsSecretAccessKey?: Maybe<Scalars['String']['output']>;
  awsServerUid?: Maybe<Scalars['String']['output']>;
  awsUserId?: Maybe<Scalars['String']['output']>;
  basicAuthLogin: Scalars['String']['output'];
  basicAuthPasswordHash: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dockerImage?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  hubType: HubType;
  id: Scalars['String']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastErrorData?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  profileId: Scalars['Int']['output'];
  provider?: Maybe<Provider>;
  sjdbJsonData: Scalars['JSON']['output'];
  status: ExportStatus;
  tasks?: Maybe<Array<Tasks>>;
  updatedAt: Scalars['DateTime']['output'];
  url: UrlForExport;
  urlForExportId: Scalars['String']['output'];
};

export type ExportCount = {
  __typename?: 'ExportCount';
  tasks: Scalars['Int']['output'];
};

export type ExportCreateManyProfileInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin: Scalars['String']['input'];
  basicAuthPasswordHash: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  sjdbJsonData: Scalars['JSON']['input'];
  status: ExportStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  urlForExportId: Scalars['String']['input'];
};

export type ExportCreateManyProfileInputEnvelope = {
  data: Array<ExportCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExportCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<ExportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExportCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<ExportCreateWithoutProfileInput>>;
  createMany?: InputMaybe<ExportCreateManyProfileInputEnvelope>;
};

export type ExportCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<ExportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ExportCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ExportCreateWithoutTasksInput>;
};

export type ExportCreateOrConnectWithoutProfileInput = {
  create: ExportCreateWithoutProfileInput;
  where: ExportWhereUniqueInput;
};

export type ExportCreateOrConnectWithoutTasksInput = {
  create: ExportCreateWithoutTasksInput;
  where: ExportWhereUniqueInput;
};

export type ExportCreateWithoutProfileInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin: Scalars['String']['input'];
  basicAuthPasswordHash: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  sjdbJsonData: Scalars['JSON']['input'];
  status: ExportStatus;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutExportsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: UrlForExportCreateNestedOneWithoutExportInput;
};

export type ExportCreateWithoutTasksInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin: Scalars['String']['input'];
  basicAuthPasswordHash: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  profile: ProfileCreateNestedOneWithoutExportsInput;
  provider?: InputMaybe<Provider>;
  sjdbJsonData: Scalars['JSON']['input'];
  status: ExportStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url: UrlForExportCreateNestedOneWithoutExportInput;
};

export type ExportListRelationFilter = {
  every?: InputMaybe<ExportWhereInput>;
  none?: InputMaybe<ExportWhereInput>;
  some?: InputMaybe<ExportWhereInput>;
};

export type ExportNullableRelationFilter = {
  is?: InputMaybe<ExportWhereInput>;
  isNot?: InputMaybe<ExportWhereInput>;
};

export type ExportScalarWhereInput = {
  AND?: InputMaybe<Array<ExportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ExportScalarWhereInput>>;
  OR?: InputMaybe<Array<ExportScalarWhereInput>>;
  awsAccessKeyId?: InputMaybe<StringNullableFilter>;
  awsSecretAccessKey?: InputMaybe<StringNullableFilter>;
  awsServerUid?: InputMaybe<StringNullableFilter>;
  awsUserId?: InputMaybe<StringNullableFilter>;
  basicAuthLogin?: InputMaybe<StringFilter>;
  basicAuthPasswordHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dockerImage?: InputMaybe<StringNullableFilter>;
  error?: InputMaybe<StringNullableFilter>;
  hubType?: InputMaybe<EnumHubTypeFilter>;
  id?: InputMaybe<StringFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastErrorData?: InputMaybe<StringNullableFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderNullableFilter>;
  sjdbJsonData?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  urlForExportId?: InputMaybe<StringFilter>;
};

export enum ExportStatus {
  AssociateDomainNewHub = 'ASSOCIATE_DOMAIN_NEW_HUB',
  CreateInstanceNewHub = 'CREATE_INSTANCE_NEW_HUB',
  DataCollectedNewHub = 'DATA_COLLECTED_NEW_HUB',
  DoneCreateMoveHub = 'DONE_CREATE_MOVE_HUB',
  DoneCreateNewHub = 'DONE_CREATE_NEW_HUB',
  DoneUpdateHub = 'DONE_UPDATE_HUB',
  DoneUpdateImageHub = 'DONE_UPDATE_IMAGE_HUB',
  ErrorAssociateDomainNewHub = 'ERROR_ASSOCIATE_DOMAIN_NEW_HUB',
  ErrorCreateInstanceNewHub = 'ERROR_CREATE_INSTANCE_NEW_HUB',
  ErrorCreateMoveHub = 'ERROR_CREATE_MOVE_HUB',
  ErrorLoadingDataNewHub = 'ERROR_LOADING_DATA_NEW_HUB',
  ErrorPreparingImageNewHub = 'ERROR_PREPARING_IMAGE_NEW_HUB',
  ErrorProcessingDataNewHub = 'ERROR_PROCESSING_DATA_NEW_HUB',
  ErrorTransferDomainMoveHub = 'ERROR_TRANSFER_DOMAIN_MOVE_HUB',
  ErrorUpdateHub = 'ERROR_UPDATE_HUB',
  ErrorUpdateImageHub = 'ERROR_UPDATE_IMAGE_HUB',
  ErrorValidateAccessMoveHub = 'ERROR_VALIDATE_ACCESS_MOVE_HUB',
  LoadingDataMoveHub = 'LOADING_DATA_MOVE_HUB',
  LoadingDataNewHub = 'LOADING_DATA_NEW_HUB',
  NeedExportMoveHub = 'NEED_EXPORT_MOVE_HUB',
  NeedUpdateHub = 'NEED_UPDATE_HUB',
  NeedUpdateImageHub = 'NEED_UPDATE_IMAGE_HUB',
  PreparingImageNewHub = 'PREPARING_IMAGE_NEW_HUB',
  ProcessingDataNewHub = 'PROCESSING_DATA_NEW_HUB',
  TransferDomainMoveHub = 'TRANSFER_DOMAIN_MOVE_HUB',
  ValidateAccessMoveHub = 'VALIDATE_ACCESS_MOVE_HUB'
}

export type ExportUpdateManyMutationInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin?: InputMaybe<Scalars['String']['input']>;
  basicAuthPasswordHash?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  sjdbJsonData?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExportUpdateManyWithWhereWithoutProfileInput = {
  data: ExportUpdateManyMutationInput;
  where: ExportScalarWhereInput;
};

export type ExportUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<ExportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExportCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<ExportCreateWithoutProfileInput>>;
  createMany?: InputMaybe<ExportCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<ExportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExportWhereUniqueInput>>;
  set?: InputMaybe<Array<ExportWhereUniqueInput>>;
  update?: InputMaybe<Array<ExportUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<ExportUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<ExportUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type ExportUpdateOneWithoutTasksNestedInput = {
  connect?: InputMaybe<ExportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ExportCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ExportCreateWithoutTasksInput>;
  delete?: InputMaybe<ExportWhereInput>;
  disconnect?: InputMaybe<ExportWhereInput>;
  update?: InputMaybe<ExportUpdateToOneWithWhereWithoutTasksInput>;
  upsert?: InputMaybe<ExportUpsertWithoutTasksInput>;
};

export type ExportUpdateToOneWithWhereWithoutTasksInput = {
  data: ExportUpdateWithoutTasksInput;
  where?: InputMaybe<ExportWhereInput>;
};

export type ExportUpdateWithWhereUniqueWithoutProfileInput = {
  data: ExportUpdateWithoutProfileInput;
  where: ExportWhereUniqueInput;
};

export type ExportUpdateWithoutProfileInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin?: InputMaybe<Scalars['String']['input']>;
  basicAuthPasswordHash?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  sjdbJsonData?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<ExportStatus>;
  tasks?: InputMaybe<TasksUpdateManyWithoutExportsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<UrlForExportUpdateOneRequiredWithoutExportNestedInput>;
};

export type ExportUpdateWithoutTasksInput = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  awsServerUid?: InputMaybe<Scalars['String']['input']>;
  awsUserId?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin?: InputMaybe<Scalars['String']['input']>;
  basicAuthPasswordHash?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dockerImage?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<HubType>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastErrorData?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutExportsNestedInput>;
  provider?: InputMaybe<Provider>;
  sjdbJsonData?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  url?: InputMaybe<UrlForExportUpdateOneRequiredWithoutExportNestedInput>;
};

export type ExportUpsertWithWhereUniqueWithoutProfileInput = {
  create: ExportCreateWithoutProfileInput;
  update: ExportUpdateWithoutProfileInput;
  where: ExportWhereUniqueInput;
};

export type ExportUpsertWithoutTasksInput = {
  create: ExportCreateWithoutTasksInput;
  update: ExportUpdateWithoutTasksInput;
  where?: InputMaybe<ExportWhereInput>;
};

export type ExportWhereInput = {
  AND?: InputMaybe<Array<ExportWhereInput>>;
  NOT?: InputMaybe<Array<ExportWhereInput>>;
  OR?: InputMaybe<Array<ExportWhereInput>>;
  awsAccessKeyId?: InputMaybe<StringNullableFilter>;
  awsSecretAccessKey?: InputMaybe<StringNullableFilter>;
  awsServerUid?: InputMaybe<StringNullableFilter>;
  awsUserId?: InputMaybe<StringNullableFilter>;
  basicAuthLogin?: InputMaybe<StringFilter>;
  basicAuthPasswordHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dockerImage?: InputMaybe<StringNullableFilter>;
  error?: InputMaybe<StringNullableFilter>;
  hubType?: InputMaybe<EnumHubTypeFilter>;
  id?: InputMaybe<StringFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastErrorData?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderNullableFilter>;
  sjdbJsonData?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  tasks?: InputMaybe<TasksListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<UrlForExportRelationFilter>;
  urlForExportId?: InputMaybe<StringFilter>;
};

export type ExportWhereUniqueInput = {
  AND?: InputMaybe<Array<ExportWhereInput>>;
  NOT?: InputMaybe<Array<ExportWhereInput>>;
  OR?: InputMaybe<Array<ExportWhereInput>>;
  awsAccessKeyId?: InputMaybe<StringNullableFilter>;
  awsSecretAccessKey?: InputMaybe<StringNullableFilter>;
  awsServerUid?: InputMaybe<StringNullableFilter>;
  awsUserId?: InputMaybe<StringNullableFilter>;
  basicAuthLogin?: InputMaybe<StringFilter>;
  basicAuthPasswordHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dockerImage?: InputMaybe<StringNullableFilter>;
  error?: InputMaybe<StringNullableFilter>;
  hubType?: InputMaybe<EnumHubTypeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastErrorData?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderNullableFilter>;
  sjdbJsonData?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  tasks?: InputMaybe<TasksListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<UrlForExportRelationFilter>;
  urlForExportId?: InputMaybe<Scalars['String']['input']>;
};

export type FileMetaInfoInputType = {
  encryptedData: Scalars['String']['input'];
  encryptedSymmetricKey: Scalars['String']['input'];
};

export type Form = {
  __typename?: 'Form';
  createdAt: Scalars['DateTime']['output'];
  entityType: Scalars['String']['output'];
  formJson: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GenerateEmailCodeInput = {
  email: Scalars['String']['input'];
  typeEmailCode: TypeEmailCode;
};

export type GenerateEmailCodeResponse = {
  __typename?: 'GenerateEmailCodeResponse';
  expiresAt: Scalars['DateTime']['output'];
  result: Scalars['Boolean']['output'];
};

export type GetZendeskToken = {
  __typename?: 'GetZendeskToken';
  /** токен */
  token: Scalars['String']['output'];
};

export type HubSettings = {
  __typename?: 'HubSettings';
  abilityToDisconnect: Scalars['Boolean']['output'];
  connectedAt: Scalars['DateTime']['output'];
  country: Scalars['String']['output'];
  hubActive: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  ipAddress?: Maybe<Scalars['String']['output']>;
  lastPingAt: Scalars['DateTime']['output'];
  ownedByUser: Scalars['Boolean']['output'];
  permanentAddress: Scalars['String']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  provider: Provider;
  temporaryAddress: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updatingHub: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type HubSettingsCreateManyProfileInput = {
  abilityToDisconnect: Scalars['Boolean']['input'];
  connectedAt: Scalars['DateTime']['input'];
  country: Scalars['String']['input'];
  hubActive: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastPingAt: Scalars['DateTime']['input'];
  ownedByUser?: InputMaybe<Scalars['Boolean']['input']>;
  permanentAddress: Scalars['String']['input'];
  provider: Provider;
  temporaryAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatingHub: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type HubSettingsCreateManyProfileInputEnvelope = {
  data: Array<HubSettingsCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HubSettingsCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<HubSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HubSettingsCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<HubSettingsCreateWithoutProfileInput>>;
  createMany?: InputMaybe<HubSettingsCreateManyProfileInputEnvelope>;
};

export type HubSettingsCreateOrConnectWithoutProfileInput = {
  create: HubSettingsCreateWithoutProfileInput;
  where: HubSettingsWhereUniqueInput;
};

export type HubSettingsCreateWithoutProfileInput = {
  abilityToDisconnect: Scalars['Boolean']['input'];
  connectedAt: Scalars['DateTime']['input'];
  country: Scalars['String']['input'];
  hubActive: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastPingAt: Scalars['DateTime']['input'];
  ownedByUser?: InputMaybe<Scalars['Boolean']['input']>;
  permanentAddress: Scalars['String']['input'];
  provider: Provider;
  temporaryAddress: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatingHub: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};

export type HubSettingsListRelationFilter = {
  every?: InputMaybe<HubSettingsWhereInput>;
  none?: InputMaybe<HubSettingsWhereInput>;
  some?: InputMaybe<HubSettingsWhereInput>;
};

export type HubSettingsResponse = {
  __typename?: 'HubSettingsResponse';
  /** Возможность отключить MyHub от Owner.One */
  abilityToDisconnect: Scalars['Boolean']['output'];
  /** Дата подключения сервера к нам */
  connectedAt: Scalars['DateTime']['output'];
  /** Страна сервера */
  country: Scalars['String']['output'];
  /** Активен ли хаб */
  hubActive: Scalars['Boolean']['output'];
  /** Адресс MyHub */
  hubUrl: Scalars['String']['output'];
  /** Ip адресс */
  ipAddress: Scalars['String']['output'];
  /** Время последнего пинга сервера */
  lastPingAt: Scalars['DateTime']['output'];
  /** MyHub в собственности у пользователя */
  ownedByUser: Scalars['Boolean']['output'];
  /** ID профиля */
  profileId: Scalars['Float']['output'];
  /** Провайдер облачного хранилища */
  provider: Scalars['String']['output'];
  /** Время последнего изменения в MyHub */
  updatedAt: Scalars['DateTime']['output'];
  /** Передача данных из Owner.One в MyHub */
  updatingHub: Scalars['Boolean']['output'];
};

export type HubSettingsScalarWhereInput = {
  AND?: InputMaybe<Array<HubSettingsScalarWhereInput>>;
  NOT?: InputMaybe<Array<HubSettingsScalarWhereInput>>;
  OR?: InputMaybe<Array<HubSettingsScalarWhereInput>>;
  abilityToDisconnect?: InputMaybe<BoolFilter>;
  connectedAt?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<StringFilter>;
  hubActive?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastPingAt?: InputMaybe<DateTimeFilter>;
  ownedByUser?: InputMaybe<BoolFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderFilter>;
  temporaryAddress?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatingHub?: InputMaybe<BoolFilter>;
  value?: InputMaybe<StringFilter>;
};

export type HubSettingsUpdateManyMutationInput = {
  abilityToDisconnect?: InputMaybe<Scalars['Boolean']['input']>;
  connectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  hubActive?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastPingAt?: InputMaybe<Scalars['DateTime']['input']>;
  ownedByUser?: InputMaybe<Scalars['Boolean']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  temporaryAddress?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatingHub?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type HubSettingsUpdateManyWithWhereWithoutProfileInput = {
  data: HubSettingsUpdateManyMutationInput;
  where: HubSettingsScalarWhereInput;
};

export type HubSettingsUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<HubSettingsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HubSettingsCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<HubSettingsCreateWithoutProfileInput>>;
  createMany?: InputMaybe<HubSettingsCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<HubSettingsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HubSettingsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HubSettingsWhereUniqueInput>>;
  set?: InputMaybe<Array<HubSettingsWhereUniqueInput>>;
  update?: InputMaybe<Array<HubSettingsUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<HubSettingsUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<HubSettingsUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type HubSettingsUpdateWithWhereUniqueWithoutProfileInput = {
  data: HubSettingsUpdateWithoutProfileInput;
  where: HubSettingsWhereUniqueInput;
};

export type HubSettingsUpdateWithoutProfileInput = {
  abilityToDisconnect?: InputMaybe<Scalars['Boolean']['input']>;
  connectedAt?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  hubActive?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<Scalars['String']['input']>;
  lastPingAt?: InputMaybe<Scalars['DateTime']['input']>;
  ownedByUser?: InputMaybe<Scalars['Boolean']['input']>;
  permanentAddress?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Provider>;
  temporaryAddress?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatingHub?: InputMaybe<Scalars['Boolean']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type HubSettingsUpsertWithWhereUniqueWithoutProfileInput = {
  create: HubSettingsCreateWithoutProfileInput;
  update: HubSettingsUpdateWithoutProfileInput;
  where: HubSettingsWhereUniqueInput;
};

export type HubSettingsWhereInput = {
  AND?: InputMaybe<Array<HubSettingsWhereInput>>;
  NOT?: InputMaybe<Array<HubSettingsWhereInput>>;
  OR?: InputMaybe<Array<HubSettingsWhereInput>>;
  abilityToDisconnect?: InputMaybe<BoolFilter>;
  connectedAt?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<StringFilter>;
  hubActive?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastPingAt?: InputMaybe<DateTimeFilter>;
  ownedByUser?: InputMaybe<BoolFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderFilter>;
  temporaryAddress?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatingHub?: InputMaybe<BoolFilter>;
  value?: InputMaybe<StringFilter>;
};

export type HubSettingsWhereUniqueInput = {
  AND?: InputMaybe<Array<HubSettingsWhereInput>>;
  NOT?: InputMaybe<Array<HubSettingsWhereInput>>;
  OR?: InputMaybe<Array<HubSettingsWhereInput>>;
  abilityToDisconnect?: InputMaybe<BoolFilter>;
  connectedAt?: InputMaybe<DateTimeFilter>;
  country?: InputMaybe<StringFilter>;
  hubActive?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  lastPingAt?: InputMaybe<DateTimeFilter>;
  ownedByUser?: InputMaybe<BoolFilter>;
  permanentAddress?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  provider?: InputMaybe<EnumProviderFilter>;
  temporaryAddress?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updatingHub?: InputMaybe<BoolFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum HubType {
  External = 'EXTERNAL',
  Inner = 'INNER',
  Moved = 'MOVED'
}

export type IBeneficiaryInput = {
  acceptanceDeadline: Scalars['Float']['input'];
  priority: Scalars['Int']['input'];
  profileId: Scalars['Int']['input'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type KeysInput = {
  auth: Scalars['String']['input'];
  p256dh: Scalars['String']['input'];
};

export type Mailbox = {
  __typename?: 'Mailbox';
  createdAt: Scalars['DateTime']['output'];
  domain: Scalars['String']['output'];
  password: Scalars['String']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type MailboxCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  domain: Scalars['String']['input'];
  password: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username: Scalars['String']['input'];
};

export type MailboxCreateManyProfileInputEnvelope = {
  data: Array<MailboxCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MailboxCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<MailboxWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MailboxCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<MailboxCreateWithoutProfileInput>>;
  createMany?: InputMaybe<MailboxCreateManyProfileInputEnvelope>;
};

export type MailboxCreateOrConnectWithoutProfileInput = {
  create: MailboxCreateWithoutProfileInput;
  where: MailboxWhereUniqueInput;
};

export type MailboxCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  domain: Scalars['String']['input'];
  password: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username: Scalars['String']['input'];
};

export type MailboxListRelationFilter = {
  every?: InputMaybe<MailboxWhereInput>;
  none?: InputMaybe<MailboxWhereInput>;
  some?: InputMaybe<MailboxWhereInput>;
};

export type MailboxScalarWhereInput = {
  AND?: InputMaybe<Array<MailboxScalarWhereInput>>;
  NOT?: InputMaybe<Array<MailboxScalarWhereInput>>;
  OR?: InputMaybe<Array<MailboxScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type MailboxUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MailboxUpdateManyWithWhereWithoutProfileInput = {
  data: MailboxUpdateManyMutationInput;
  where: MailboxScalarWhereInput;
};

export type MailboxUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<MailboxWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MailboxCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<MailboxCreateWithoutProfileInput>>;
  createMany?: InputMaybe<MailboxCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<MailboxWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MailboxScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MailboxWhereUniqueInput>>;
  set?: InputMaybe<Array<MailboxWhereUniqueInput>>;
  update?: InputMaybe<Array<MailboxUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<MailboxUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<MailboxUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type MailboxUpdateWithWhereUniqueWithoutProfileInput = {
  data: MailboxUpdateWithoutProfileInput;
  where: MailboxWhereUniqueInput;
};

export type MailboxUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MailboxUpsertWithWhereUniqueWithoutProfileInput = {
  create: MailboxCreateWithoutProfileInput;
  update: MailboxUpdateWithoutProfileInput;
  where: MailboxWhereUniqueInput;
};

export type MailboxWhereInput = {
  AND?: InputMaybe<Array<MailboxWhereInput>>;
  NOT?: InputMaybe<Array<MailboxWhereInput>>;
  OR?: InputMaybe<Array<MailboxWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type MailboxWhereUniqueInput = {
  AND?: InputMaybe<Array<MailboxWhereInput>>;
  NOT?: InputMaybe<Array<MailboxWhereInput>>;
  OR?: InputMaybe<Array<MailboxWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  domain?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MetaInput = {
  language: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Активация аккаунта */
  activateAccount: Account;
  /** Активация аккаунта после сквозной регистрации */
  activateAccountAfterExternalRegistration: Scalars['Boolean']['output'];
  activateAllScenarios: Scalars['Boolean']['output'];
  /** Активация профиля бенефициара */
  activateBeneficiaryProfile: Account;
  /** Активировать сценарий */
  activateScenario?: Maybe<Scenario>;
  addBeneficiaryToScenario: Scenario;
  /** Добавление дополнительных данных (ip адрес и awsUserId ) */
  addExportAdditionalFields: Scalars['Boolean']['output'];
  /** Добавить в белый список */
  addToWhitelist: Whitelist;
  /** Блокировка аккаунта */
  blockAccount: Scalars['Boolean']['output'];
  /** Отменить сценарий */
  cancelScenario?: Maybe<Scenario>;
  /** Отменить подписку */
  cancelSubscription: Scalars['Boolean']['output'];
  /** Изменение пароля аккаунта */
  changePassword: Account;
  /** Управление активацие totp */
  changeTotpEnabled: Scalars['Boolean']['output'];
  /** Создание аккаунта ассистента */
  createAssistant: Account;
  /** Создание бенефициара */
  createBeneficiary: CreateBeneficiaryResponse;
  createBeneficiaryAnonymousPropForEmail: BeneficiaryAnonymousPropForEmail;
  /** Создание email аккаунта */
  createEmailAccount: CreateEmailAccount;
  createEventLog: EventLog;
  createNextOwnerPropForEmail: NextOwnerPropForEmail;
  /** Создать сценарий */
  createScenario: Scenario;
  /** Удаление email аккаунта */
  deleteEmailAccount: DeleteEmailAccount;
  /** Удалить файл */
  deleteFile: Scalars['Boolean']['output'];
  dropAllCollections: Scalars['Boolean']['output'];
  echoMutation: Scalars['String']['output'];
  /** Отправка кода сброса пароля на email если забыли пароль */
  forgotPassword: Scalars['Boolean']['output'];
  /** Генерация и отправка письма с генерированным кодом верификации */
  generateEmailCode: GenerateEmailCodeResponse;
  /** Генерация зашифрованного сообщения */
  generateEncryptedMessage: Scalars['String']['output'];
  /** Генерация смс кода */
  generateSmsCodeResponse: Scalars['Boolean']['output'];
  /** Вход в систему */
  login: AuthResponse;
  /** Вход в систему через расшифрованное сообщение */
  loginViaDecryptedMessage: AuthResponse;
  /**
   * Вход в систему через код из ЛК, происходит валидация кода ЛК.
   *     Во время которого создается пользователь новый если нет или обновляються данные по пользователю если есть.
   *     Также подгружаеться в базу текущая подписка если есть. Затем генерируеться токен  и создаеться запись в account-ssesion.
   *     Возвращает объект содержащий токен и аккаунт
   */
  loginViaPersonalAccountCode: AuthResponse;
  /** Выход из системы */
  logout: Scalars['Boolean']['output'];
  /** Уведомление пользователя что экспорт успешен */
  notifyUserThatExportDone: Scalars['Boolean']['output'];
  /** Отправить коллекцию */
  pushCollection: Scalars['Boolean']['output'];
  pushFileEncryptedSymmetricKey: Scalars['Boolean']['output'];
  /** Восстановить приглашение бенефициаров */
  regenerateBeneficiaryInvite: CreateBeneficiaryResponse;
  /** Регистрация пользователя */
  register: AuthResponse;
  /** Регистрация бенефициара по коду приглашения */
  registerBeneficiaryByInvitationCode: AuthResponse;
  rejectScenario: Scalars['Boolean']['output'];
  removeBeneficiaryAnonymousPropForEmail: Scalars['Boolean']['output'];
  removeNextOwnerPropForEmail: Scalars['Boolean']['output'];
  removeScenario: Scalars['Boolean']['output'];
  /** Cоздание хаба пользователя */
  requestExport: Scalars['Boolean']['output'];
  /** Сброс пароля аккаунта */
  resetPassword: Account;
  /** Сброс пароля по коду */
  resetPasswordByCode: Account;
  /** Запустить все сценарии */
  runAllScenarios: Scenario;
  /** Отправка почты */
  sendEmail: Scalars['String']['output'];
  /** Отправка приглашения в бенефициары */
  sendNewInviteToBeneficiary: Profile;
  /** Создать подписку */
  subscribe: SubscriptionEntity;
  updateBeneficiaryAnonymousPropForEmail: BeneficiaryAnonymousPropForEmail;
  updateNextOwnerPropForEmail: NextOwnerPropForEmail;
  /** Обновление профиля */
  updateProfile: Profile;
  /** Обновить сценарий */
  updateScenario: Scenario;
  /** Загрузка файла в хранилище */
  uploadFile: Scalars['String']['output'];
  /** Проверка пароля на валидность */
  verifyPassword: Scalars['Boolean']['output'];
  /** Подписаться на уведомления */
  webPushSubscribe: Scalars['Boolean']['output'];
  /** Отписаться от уведомлений */
  webPushUnsubscribe: Scalars['Boolean']['output'];
};


export type MutationActivateAccountArgs = {
  data: ActivateAccountInput;
};


export type MutationActivateAccountAfterExternalRegistrationArgs = {
  publicKey: Scalars['String']['input'];
};


export type MutationActivateBeneficiaryProfileArgs = {
  data: BeneficiaryActivationInput;
};


export type MutationActivateScenarioArgs = {
  scenarioId: Scalars['String']['input'];
};


export type MutationAddBeneficiaryToScenarioArgs = {
  acceptanceDeadline: Scalars['Float']['input'];
  beneficiaryId: Scalars['Float']['input'];
  scenarioId: Scalars['String']['input'];
};


export type MutationAddExportAdditionalFieldsArgs = {
  awsUserId: Scalars['String']['input'];
  ipAddress: Scalars['String']['input'];
};


export type MutationAddToWhitelistArgs = {
  email: Scalars['String']['input'];
  planId: Scalars['String']['input'];
};


export type MutationBlockAccountArgs = {
  emailCode: Scalars['String']['input'];
};


export type MutationCancelScenarioArgs = {
  scenarioId: Scalars['String']['input'];
};


export type MutationCancelSubscriptionArgs = {
  subscriptionId: Scalars['Float']['input'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangeTotpEnabledArgs = {
  enabled: Scalars['Boolean']['input'];
  token: Scalars['String']['input'];
};


export type MutationCreateAssistantArgs = {
  data: AssistantInput;
};


export type MutationCreateBeneficiaryArgs = {
  data: BeneficiaryInput;
};


export type MutationCreateBeneficiaryAnonymousPropForEmailArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationCreateEmailAccountArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationCreateEventLogArgs = {
  encryptedData: Scalars['String']['input'];
  publicData: Scalars['String']['input'];
};


export type MutationCreateNextOwnerPropForEmailArgs = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationCreateScenarioArgs = {
  assetAndRelatedDtoIds: Array<Scalars['String']['input']>;
  beneficiaries: Array<IBeneficiaryInput>;
  mode?: InputMaybe<ScenarioMode>;
  notificationPeriod: Scalars['Float']['input'];
  notificationTime: Scalars['DateTime']['input'];
  notificationValue: Scalars['Float']['input'];
  triggerType: ScenarioTriggerType;
  triggerValue: Scalars['Float']['input'];
};


export type MutationDeleteEmailAccountArgs = {
  username: Scalars['String']['input'];
};


export type MutationDeleteFileArgs = {
  filename: Scalars['String']['input'];
};


export type MutationEchoMutationArgs = {
  text: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationGenerateEmailCodeArgs = {
  data: GenerateEmailCodeInput;
};


export type MutationGenerateEncryptedMessageArgs = {
  publicKey: Scalars['String']['input'];
};


export type MutationGenerateSmsCodeResponseArgs = {
  number: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  data: EmailPasswordInput;
  totpToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginViaDecryptedMessageArgs = {
  decryptedMessage: Scalars['String']['input'];
};


export type MutationLoginViaPersonalAccountCodeArgs = {
  code: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  sessionIds: Array<Scalars['Float']['input']>;
};


export type MutationNotifyUserThatExportDoneArgs = {
  profileId: Scalars['Int']['input'];
};


export type MutationPushCollectionArgs = {
  data: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationPushFileEncryptedSymmetricKeyArgs = {
  encryptedFileSymmetricKey: Scalars['String']['input'];
  encryptedSymmetricKey: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
  metaInfo: FileMetaInfoInputType;
  publicKey: Scalars['String']['input'];
};


export type MutationRegenerateBeneficiaryInviteArgs = {
  beneficiaryProfileId: Scalars['Int']['input'];
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationRegisterBeneficiaryByInvitationCodeArgs = {
  input: BeneficiaryCreateByInviteCodeInput;
};


export type MutationRejectScenarioArgs = {
  scenarioId: Scalars['String']['input'];
};


export type MutationRemoveBeneficiaryAnonymousPropForEmailArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemoveNextOwnerPropForEmailArgs = {
  id: Scalars['Float']['input'];
};


export type MutationRemoveScenarioArgs = {
  scenarioId: Scalars['String']['input'];
};


export type MutationRequestExportArgs = {
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  basicAuthLogin: Scalars['String']['input'];
  basicAuthPassword: Scalars['String']['input'];
  hubType?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationResetPasswordByCodeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  twoFactorQuestionAnswer: Scalars['String']['input'];
  twoFactorQuestionId: Scalars['Int']['input'];
};


export type MutationSendEmailArgs = {
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
  to: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationSendNewInviteToBeneficiaryArgs = {
  beneficiaryId: Scalars['Int']['input'];
};


export type MutationSubscribeArgs = {
  plan: Scalars['String']['input'];
};


export type MutationUpdateBeneficiaryAnonymousPropForEmailArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['Float']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdateNextOwnerPropForEmailArgs = {
  firstName: Scalars['String']['input'];
  id: Scalars['Float']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  input: ProfileUpdateInput;
};


export type MutationUpdateScenarioArgs = {
  input: ScenarioUpdateInput;
  scenarioId: Scalars['String']['input'];
};


export type MutationUploadFileArgs = {
  fileContent: Scalars['String']['input'];
};


export type MutationVerifyPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationWebPushSubscribeArgs = {
  subscription: WebPushSubscriptionInput;
};


export type MutationWebPushUnsubscribeArgs = {
  subscription: WebPushSubscriptionInput;
};

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumAccountStatusFilter = {
  equals?: InputMaybe<AccountStatus>;
  in?: InputMaybe<Array<AccountStatus>>;
  not?: InputMaybe<NestedEnumAccountStatusFilter>;
  notIn?: InputMaybe<Array<AccountStatus>>;
};

export type NestedEnumBeneficiaryStatusFilter = {
  equals?: InputMaybe<BeneficiaryStatus>;
  in?: InputMaybe<Array<BeneficiaryStatus>>;
  not?: InputMaybe<NestedEnumBeneficiaryStatusFilter>;
  notIn?: InputMaybe<Array<BeneficiaryStatus>>;
};

export type NestedEnumExportStatusFilter = {
  equals?: InputMaybe<ExportStatus>;
  in?: InputMaybe<Array<ExportStatus>>;
  not?: InputMaybe<NestedEnumExportStatusFilter>;
  notIn?: InputMaybe<Array<ExportStatus>>;
};

export type NestedEnumHubTypeFilter = {
  equals?: InputMaybe<HubType>;
  in?: InputMaybe<Array<HubType>>;
  not?: InputMaybe<NestedEnumHubTypeFilter>;
  notIn?: InputMaybe<Array<HubType>>;
};

export type NestedEnumProfileTypeFilter = {
  equals?: InputMaybe<ProfileType>;
  in?: InputMaybe<Array<ProfileType>>;
  not?: InputMaybe<NestedEnumProfileTypeFilter>;
  notIn?: InputMaybe<Array<ProfileType>>;
};

export type NestedEnumProviderFilter = {
  equals?: InputMaybe<Provider>;
  in?: InputMaybe<Array<Provider>>;
  not?: InputMaybe<NestedEnumProviderFilter>;
  notIn?: InputMaybe<Array<Provider>>;
};

export type NestedEnumProviderNullableFilter = {
  equals?: InputMaybe<Provider>;
  in?: InputMaybe<Array<Provider>>;
  not?: InputMaybe<NestedEnumProviderNullableFilter>;
  notIn?: InputMaybe<Array<Provider>>;
};

export type NestedEnumScenarioModeFilter = {
  equals?: InputMaybe<ScenarioMode>;
  in?: InputMaybe<Array<ScenarioMode>>;
  not?: InputMaybe<NestedEnumScenarioModeFilter>;
  notIn?: InputMaybe<Array<ScenarioMode>>;
};

export type NestedEnumScenarioStatusFilter = {
  equals?: InputMaybe<ScenarioStatus>;
  in?: InputMaybe<Array<ScenarioStatus>>;
  not?: InputMaybe<NestedEnumScenarioStatusFilter>;
  notIn?: InputMaybe<Array<ScenarioStatus>>;
};

export type NestedEnumScenarioTriggerTypeFilter = {
  equals?: InputMaybe<ScenarioTriggerType>;
  in?: InputMaybe<Array<ScenarioTriggerType>>;
  not?: InputMaybe<NestedEnumScenarioTriggerTypeFilter>;
  notIn?: InputMaybe<Array<ScenarioTriggerType>>;
};

export type NestedEnumSubscriptionStatusFilter = {
  equals?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
};

export type NestedEnumUrlForExportStatusFilter = {
  equals?: InputMaybe<UrlForExportStatus>;
  in?: InputMaybe<Array<UrlForExportStatus>>;
  not?: InputMaybe<NestedEnumUrlForExportStatusFilter>;
  notIn?: InputMaybe<Array<UrlForExportStatus>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NextOwnerPropForEmail = {
  __typename?: 'NextOwnerPropForEmail';
  account: Account;
  accountId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type NextOwnerPropForEmailCreateManyAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NextOwnerPropForEmailCreateManyAccountInputEnvelope = {
  data: Array<NextOwnerPropForEmailCreateManyAccountInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NextOwnerPropForEmailCreateNestedManyWithoutAccountInput = {
  connect?: InputMaybe<Array<NextOwnerPropForEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NextOwnerPropForEmailCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<NextOwnerPropForEmailCreateWithoutAccountInput>>;
  createMany?: InputMaybe<NextOwnerPropForEmailCreateManyAccountInputEnvelope>;
};

export type NextOwnerPropForEmailCreateOrConnectWithoutAccountInput = {
  create: NextOwnerPropForEmailCreateWithoutAccountInput;
  where: NextOwnerPropForEmailWhereUniqueInput;
};

export type NextOwnerPropForEmailCreateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NextOwnerPropForEmailListRelationFilter = {
  every?: InputMaybe<NextOwnerPropForEmailWhereInput>;
  none?: InputMaybe<NextOwnerPropForEmailWhereInput>;
  some?: InputMaybe<NextOwnerPropForEmailWhereInput>;
};

export type NextOwnerPropForEmailScalarWhereInput = {
  AND?: InputMaybe<Array<NextOwnerPropForEmailScalarWhereInput>>;
  NOT?: InputMaybe<Array<NextOwnerPropForEmailScalarWhereInput>>;
  OR?: InputMaybe<Array<NextOwnerPropForEmailScalarWhereInput>>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NextOwnerPropForEmailUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NextOwnerPropForEmailUpdateManyWithWhereWithoutAccountInput = {
  data: NextOwnerPropForEmailUpdateManyMutationInput;
  where: NextOwnerPropForEmailScalarWhereInput;
};

export type NextOwnerPropForEmailUpdateManyWithoutAccountNestedInput = {
  connect?: InputMaybe<Array<NextOwnerPropForEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NextOwnerPropForEmailCreateOrConnectWithoutAccountInput>>;
  create?: InputMaybe<Array<NextOwnerPropForEmailCreateWithoutAccountInput>>;
  createMany?: InputMaybe<NextOwnerPropForEmailCreateManyAccountInputEnvelope>;
  delete?: InputMaybe<Array<NextOwnerPropForEmailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NextOwnerPropForEmailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NextOwnerPropForEmailWhereUniqueInput>>;
  set?: InputMaybe<Array<NextOwnerPropForEmailWhereUniqueInput>>;
  update?: InputMaybe<Array<NextOwnerPropForEmailUpdateWithWhereUniqueWithoutAccountInput>>;
  updateMany?: InputMaybe<Array<NextOwnerPropForEmailUpdateManyWithWhereWithoutAccountInput>>;
  upsert?: InputMaybe<Array<NextOwnerPropForEmailUpsertWithWhereUniqueWithoutAccountInput>>;
};

export type NextOwnerPropForEmailUpdateWithWhereUniqueWithoutAccountInput = {
  data: NextOwnerPropForEmailUpdateWithoutAccountInput;
  where: NextOwnerPropForEmailWhereUniqueInput;
};

export type NextOwnerPropForEmailUpdateWithoutAccountInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NextOwnerPropForEmailUpsertWithWhereUniqueWithoutAccountInput = {
  create: NextOwnerPropForEmailCreateWithoutAccountInput;
  update: NextOwnerPropForEmailUpdateWithoutAccountInput;
  where: NextOwnerPropForEmailWhereUniqueInput;
};

export type NextOwnerPropForEmailWhereInput = {
  AND?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  NOT?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  OR?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NextOwnerPropForEmailWhereUniqueInput = {
  AND?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  NOT?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  OR?: InputMaybe<Array<NextOwnerPropForEmailWhereInput>>;
  account?: InputMaybe<AccountRelationFilter>;
  accountId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OneTimeCodesResponse = {
  __typename?: 'OneTimeCodesResponse';
  emailCode?: Maybe<Scalars['String']['output']>;
  smsCode?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  _count: ProfileCount;
  accounts?: Maybe<Array<Account>>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  beneficiaryInviteCodeAsMainUser?: Maybe<Array<BeneficiaryInviteCode>>;
  beneficiaryInviteCodeAsUsedForProfile?: Maybe<Array<BeneficiaryInviteCode>>;
  bio?: Maybe<Scalars['String']['output']>;
  collections?: Maybe<Array<Collection>>;
  createdAt: Scalars['DateTime']['output'];
  dependentUsers?: Maybe<Array<Profile>>;
  encryptedFiles?: Maybe<Array<EncryptedFile>>;
  eventLogs?: Maybe<Array<EventLog>>;
  exports?: Maybe<Array<Export>>;
  firstName?: Maybe<Scalars['String']['output']>;
  hubSettings?: Maybe<Array<HubSettings>>;
  id: Scalars['Int']['output'];
  invitationLastSentTime?: Maybe<Scalars['DateTime']['output']>;
  lastActivityAt: Scalars['DateTime']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mailboxes?: Maybe<Array<Mailbox>>;
  mainUser?: Maybe<Profile>;
  mainUserId?: Maybe<Scalars['Int']['output']>;
  ocrSuppressLetsTryConfirmation: Scalars['Boolean']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: Maybe<Array<ScenarioBeneficiary>>;
  scenariosBelongingToMeAsMainUser?: Maybe<Array<Scenario>>;
  soweAppendixSuppressCancelConfirmation: Scalars['Boolean']['output'];
  status: AccountStatus;
  subscriptions?: Maybe<Array<SubscriptionEntity>>;
  tasks?: Maybe<Array<Tasks>>;
  totpEnabled: Scalars['Boolean']['output'];
  twoFactorQuestions?: Maybe<Array<TwoFactorQuestion>>;
  twoFactorQuestionsEnabled: Scalars['Boolean']['output'];
  type: ProfileType;
  updatedAt: Scalars['DateTime']['output'];
  userName?: Maybe<Scalars['String']['output']>;
  webPushSubscriptions?: Maybe<Array<WebPushSubscription>>;
};

export type ProfileCount = {
  __typename?: 'ProfileCount';
  accounts: Scalars['Int']['output'];
  beneficiaryInviteCodeAsMainUser: Scalars['Int']['output'];
  beneficiaryInviteCodeAsUsedForProfile: Scalars['Int']['output'];
  collections: Scalars['Int']['output'];
  dependentUsers: Scalars['Int']['output'];
  encryptedFiles: Scalars['Int']['output'];
  eventLogs: Scalars['Int']['output'];
  exports: Scalars['Int']['output'];
  hubSettings: Scalars['Int']['output'];
  mailboxes: Scalars['Int']['output'];
  scenarioBeneficiaries: Scalars['Int']['output'];
  scenariosBelongingToMeAsMainUser: Scalars['Int']['output'];
  subscriptions: Scalars['Int']['output'];
  tasks: Scalars['Int']['output'];
  twoFactorQuestions: Scalars['Int']['output'];
  webPushSubscriptions: Scalars['Int']['output'];
};

export type ProfileCreateManyMainUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileCreateManyMainUserInputEnvelope = {
  data: Array<ProfileCreateManyMainUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfileCreateNestedManyWithoutMainUserInput = {
  connect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<ProfileCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<ProfileCreateManyMainUserInputEnvelope>;
};

export type ProfileCreateNestedOneWithoutBeneficiaryInviteCodeAsMainUserInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsMainUserInput>;
  create?: InputMaybe<ProfileCreateWithoutBeneficiaryInviteCodeAsMainUserInput>;
};

export type ProfileCreateNestedOneWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
  create?: InputMaybe<ProfileCreateWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
};

export type ProfileCreateNestedOneWithoutDependentUsersInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutDependentUsersInput>;
  create?: InputMaybe<ProfileCreateWithoutDependentUsersInput>;
};

export type ProfileCreateNestedOneWithoutExportsInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutExportsInput>;
  create?: InputMaybe<ProfileCreateWithoutExportsInput>;
};

export type ProfileCreateNestedOneWithoutScenarioBeneficiariesInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutScenarioBeneficiariesInput>;
  create?: InputMaybe<ProfileCreateWithoutScenarioBeneficiariesInput>;
};

export type ProfileCreateNestedOneWithoutScenariosBelongingToMeAsMainUserInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutScenariosBelongingToMeAsMainUserInput>;
  create?: InputMaybe<ProfileCreateWithoutScenariosBelongingToMeAsMainUserInput>;
};

export type ProfileCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ProfileCreateWithoutTasksInput>;
};

export type ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsMainUserInput = {
  create: ProfileCreateWithoutBeneficiaryInviteCodeAsMainUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  create: ProfileCreateWithoutBeneficiaryInviteCodeAsUsedForProfileInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutDependentUsersInput = {
  create: ProfileCreateWithoutDependentUsersInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutExportsInput = {
  create: ProfileCreateWithoutExportsInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutMainUserInput = {
  create: ProfileCreateWithoutMainUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutScenarioBeneficiariesInput = {
  create: ProfileCreateWithoutScenarioBeneficiariesInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutScenariosBelongingToMeAsMainUserInput = {
  create: ProfileCreateWithoutScenariosBelongingToMeAsMainUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateOrConnectWithoutTasksInput = {
  create: ProfileCreateWithoutTasksInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileCreateWithoutBeneficiaryInviteCodeAsMainUserInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutDependentUsersInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutExportsInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutMainUserInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutScenarioBeneficiariesInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutScenariosBelongingToMeAsMainUserInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  tasks?: InputMaybe<TasksCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileCreateWithoutTasksInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutProfileInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutMainUserProfileInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeCreateNestedManyWithoutUsedForProfileInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionCreateNestedManyWithoutProfileInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileCreateNestedManyWithoutMainUserInput>;
  encryptedFiles?: InputMaybe<EncryptedFileCreateNestedManyWithoutMainUserInput>;
  eventLogs?: InputMaybe<EventLogCreateNestedManyWithoutMainUserInput>;
  exports?: InputMaybe<ExportCreateNestedManyWithoutProfileInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsCreateNestedManyWithoutProfileInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxCreateNestedManyWithoutProfileInput>;
  mainUser?: InputMaybe<ProfileCreateNestedOneWithoutDependentUsersInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioCreateNestedManyWithoutMainUserInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status: AccountStatus;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutProfileInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionCreateNestedManyWithoutProfileInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionCreateNestedManyWithoutProfileInput>;
};

export type ProfileInput = {
  where: ProfileWhereInput;
};

export type ProfileListRelationFilter = {
  every?: InputMaybe<ProfileWhereInput>;
  none?: InputMaybe<ProfileWhereInput>;
  some?: InputMaybe<ProfileWhereInput>;
};

export type ProfileNullableRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export enum ProfileRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type ProfileScalarWhereInput = {
  AND?: InputMaybe<Array<ProfileScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProfileScalarWhereInput>>;
  OR?: InputMaybe<Array<ProfileScalarWhereInput>>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  invitationLastSentTime?: InputMaybe<DateTimeNullableFilter>;
  lastActivityAt?: InputMaybe<DateTimeFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  mainUserId?: InputMaybe<IntNullableFilter>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<BoolFilter>;
  publicKey?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumProfileRoleNullableListFilter>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumAccountStatusFilter>;
  totpEnabled?: InputMaybe<BoolFilter>;
  totpSecret?: InputMaybe<StringNullableFilter>;
  twoFactorQuestionsEnabled?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumProfileTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userName?: InputMaybe<StringNullableFilter>;
};

export enum ProfileType {
  Assistant = 'ASSISTANT',
  Beneficiary = 'BENEFICIARY',
  MainUser = 'MAIN_USER'
}

export type ProfileUpdateInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateManyMutationInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileUpdateManyWithWhereWithoutMainUserInput = {
  data: ProfileUpdateManyMutationInput;
  where: ProfileScalarWhereInput;
};

export type ProfileUpdateManyWithoutMainUserNestedInput = {
  connect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProfileCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<ProfileCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<ProfileCreateManyMainUserInputEnvelope>;
  delete?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProfileScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  set?: InputMaybe<Array<ProfileWhereUniqueInput>>;
  update?: InputMaybe<Array<ProfileUpdateWithWhereUniqueWithoutMainUserInput>>;
  updateMany?: InputMaybe<Array<ProfileUpdateManyWithWhereWithoutMainUserInput>>;
  upsert?: InputMaybe<Array<ProfileUpsertWithWhereUniqueWithoutMainUserInput>>;
};

export type ProfileUpdateOneRequiredWithoutBeneficiaryInviteCodeAsMainUserNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsMainUserInput>;
  create?: InputMaybe<ProfileCreateWithoutBeneficiaryInviteCodeAsMainUserInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutBeneficiaryInviteCodeAsMainUserInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutBeneficiaryInviteCodeAsMainUserInput>;
};

export type ProfileUpdateOneRequiredWithoutExportsNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutExportsInput>;
  create?: InputMaybe<ProfileCreateWithoutExportsInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutExportsInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutExportsInput>;
};

export type ProfileUpdateOneRequiredWithoutScenarioBeneficiariesNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutScenarioBeneficiariesInput>;
  create?: InputMaybe<ProfileCreateWithoutScenarioBeneficiariesInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutScenarioBeneficiariesInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutScenarioBeneficiariesInput>;
};

export type ProfileUpdateOneRequiredWithoutScenariosBelongingToMeAsMainUserNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutScenariosBelongingToMeAsMainUserInput>;
  create?: InputMaybe<ProfileCreateWithoutScenariosBelongingToMeAsMainUserInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutScenariosBelongingToMeAsMainUserInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutScenariosBelongingToMeAsMainUserInput>;
};

export type ProfileUpdateOneRequiredWithoutTasksNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ProfileCreateWithoutTasksInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutTasksInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutTasksInput>;
};

export type ProfileUpdateOneWithoutBeneficiaryInviteCodeAsUsedForProfileNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
  create?: InputMaybe<ProfileCreateWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
  delete?: InputMaybe<ProfileWhereInput>;
  disconnect?: InputMaybe<ProfileWhereInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutBeneficiaryInviteCodeAsUsedForProfileInput>;
};

export type ProfileUpdateOneWithoutDependentUsersNestedInput = {
  connect?: InputMaybe<ProfileWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProfileCreateOrConnectWithoutDependentUsersInput>;
  create?: InputMaybe<ProfileCreateWithoutDependentUsersInput>;
  delete?: InputMaybe<ProfileWhereInput>;
  disconnect?: InputMaybe<ProfileWhereInput>;
  update?: InputMaybe<ProfileUpdateToOneWithWhereWithoutDependentUsersInput>;
  upsert?: InputMaybe<ProfileUpsertWithoutDependentUsersInput>;
};

export type ProfileUpdateToOneWithWhereWithoutBeneficiaryInviteCodeAsMainUserInput = {
  data: ProfileUpdateWithoutBeneficiaryInviteCodeAsMainUserInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  data: ProfileUpdateWithoutBeneficiaryInviteCodeAsUsedForProfileInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutDependentUsersInput = {
  data: ProfileUpdateWithoutDependentUsersInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutExportsInput = {
  data: ProfileUpdateWithoutExportsInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutScenarioBeneficiariesInput = {
  data: ProfileUpdateWithoutScenarioBeneficiariesInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutScenariosBelongingToMeAsMainUserInput = {
  data: ProfileUpdateWithoutScenariosBelongingToMeAsMainUserInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateToOneWithWhereWithoutTasksInput = {
  data: ProfileUpdateWithoutTasksInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpdateWithWhereUniqueWithoutMainUserInput = {
  data: ProfileUpdateWithoutMainUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileUpdateWithoutBeneficiaryInviteCodeAsMainUserInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutDependentUsersInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutExportsInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutMainUserInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutScenarioBeneficiariesInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutScenariosBelongingToMeAsMainUserInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  tasks?: InputMaybe<TasksUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpdateWithoutTasksInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutProfileNestedInput>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutMainUserProfileNestedInput>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeUpdateManyWithoutUsedForProfileNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  collections?: InputMaybe<CollectionUpdateManyWithoutProfileNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dependentUsers?: InputMaybe<ProfileUpdateManyWithoutMainUserNestedInput>;
  encryptedFiles?: InputMaybe<EncryptedFileUpdateManyWithoutMainUserNestedInput>;
  eventLogs?: InputMaybe<EventLogUpdateManyWithoutMainUserNestedInput>;
  exports?: InputMaybe<ExportUpdateManyWithoutProfileNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hubSettings?: InputMaybe<HubSettingsUpdateManyWithoutProfileNestedInput>;
  invitationLastSentTime?: InputMaybe<Scalars['DateTime']['input']>;
  lastActivityAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mailboxes?: InputMaybe<MailboxUpdateManyWithoutProfileNestedInput>;
  mainUser?: InputMaybe<ProfileUpdateOneWithoutDependentUsersNestedInput>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  publicKey?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<ProfileRole>>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioUpdateManyWithoutMainUserNestedInput>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<AccountStatus>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutProfileNestedInput>;
  totpEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionUpdateManyWithoutProfileNestedInput>;
  twoFactorQuestionsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<ProfileType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionUpdateManyWithoutProfileNestedInput>;
};

export type ProfileUpsertWithWhereUniqueWithoutMainUserInput = {
  create: ProfileCreateWithoutMainUserInput;
  update: ProfileUpdateWithoutMainUserInput;
  where: ProfileWhereUniqueInput;
};

export type ProfileUpsertWithoutBeneficiaryInviteCodeAsMainUserInput = {
  create: ProfileCreateWithoutBeneficiaryInviteCodeAsMainUserInput;
  update: ProfileUpdateWithoutBeneficiaryInviteCodeAsMainUserInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutBeneficiaryInviteCodeAsUsedForProfileInput = {
  create: ProfileCreateWithoutBeneficiaryInviteCodeAsUsedForProfileInput;
  update: ProfileUpdateWithoutBeneficiaryInviteCodeAsUsedForProfileInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutDependentUsersInput = {
  create: ProfileCreateWithoutDependentUsersInput;
  update: ProfileUpdateWithoutDependentUsersInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutExportsInput = {
  create: ProfileCreateWithoutExportsInput;
  update: ProfileUpdateWithoutExportsInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutScenarioBeneficiariesInput = {
  create: ProfileCreateWithoutScenarioBeneficiariesInput;
  update: ProfileUpdateWithoutScenarioBeneficiariesInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutScenariosBelongingToMeAsMainUserInput = {
  create: ProfileCreateWithoutScenariosBelongingToMeAsMainUserInput;
  update: ProfileUpdateWithoutScenariosBelongingToMeAsMainUserInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileUpsertWithoutTasksInput = {
  create: ProfileCreateWithoutTasksInput;
  update: ProfileUpdateWithoutTasksInput;
  where?: InputMaybe<ProfileWhereInput>;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeListRelationFilter>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dependentUsers?: InputMaybe<ProfileListRelationFilter>;
  encryptedFiles?: InputMaybe<EncryptedFileListRelationFilter>;
  eventLogs?: InputMaybe<EventLogListRelationFilter>;
  exports?: InputMaybe<ExportListRelationFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  hubSettings?: InputMaybe<HubSettingsListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  invitationLastSentTime?: InputMaybe<DateTimeNullableFilter>;
  lastActivityAt?: InputMaybe<DateTimeFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  mailboxes?: InputMaybe<MailboxListRelationFilter>;
  mainUser?: InputMaybe<ProfileNullableRelationFilter>;
  mainUserId?: InputMaybe<IntNullableFilter>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<BoolFilter>;
  publicKey?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumProfileRoleNullableListFilter>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryListRelationFilter>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioListRelationFilter>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumAccountStatusFilter>;
  subscriptions?: InputMaybe<SubscriptionListRelationFilter>;
  tasks?: InputMaybe<TasksListRelationFilter>;
  totpEnabled?: InputMaybe<BoolFilter>;
  totpSecret?: InputMaybe<StringNullableFilter>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionListRelationFilter>;
  twoFactorQuestionsEnabled?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumProfileTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userName?: InputMaybe<StringNullableFilter>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionListRelationFilter>;
};

export type ProfileWhereUniqueInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  avatarUrl?: InputMaybe<StringNullableFilter>;
  beneficiaryInviteCodeAsMainUser?: InputMaybe<BeneficiaryInviteCodeListRelationFilter>;
  beneficiaryInviteCodeAsUsedForProfile?: InputMaybe<BeneficiaryInviteCodeListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  collections?: InputMaybe<CollectionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dependentUsers?: InputMaybe<ProfileListRelationFilter>;
  encryptedFiles?: InputMaybe<EncryptedFileListRelationFilter>;
  eventLogs?: InputMaybe<EventLogListRelationFilter>;
  exports?: InputMaybe<ExportListRelationFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  hubSettings?: InputMaybe<HubSettingsListRelationFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  invitationLastSentTime?: InputMaybe<DateTimeNullableFilter>;
  lastActivityAt?: InputMaybe<DateTimeFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  mailboxes?: InputMaybe<MailboxListRelationFilter>;
  mainUser?: InputMaybe<ProfileNullableRelationFilter>;
  mainUserId?: InputMaybe<IntNullableFilter>;
  ocrSuppressLetsTryConfirmation?: InputMaybe<BoolFilter>;
  publicKey?: InputMaybe<StringNullableFilter>;
  roles?: InputMaybe<EnumProfileRoleNullableListFilter>;
  scenarioBeneficiaries?: InputMaybe<ScenarioBeneficiaryListRelationFilter>;
  scenariosBelongingToMeAsMainUser?: InputMaybe<ScenarioListRelationFilter>;
  soweAppendixSuppressCancelConfirmation?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumAccountStatusFilter>;
  subscriptions?: InputMaybe<SubscriptionListRelationFilter>;
  tasks?: InputMaybe<TasksListRelationFilter>;
  totpEnabled?: InputMaybe<BoolFilter>;
  totpSecret?: InputMaybe<StringNullableFilter>;
  twoFactorQuestions?: InputMaybe<TwoFactorQuestionListRelationFilter>;
  twoFactorQuestionsEnabled?: InputMaybe<BoolFilter>;
  type?: InputMaybe<EnumProfileTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userName?: InputMaybe<StringNullableFilter>;
  webPushSubscriptions?: InputMaybe<WebPushSubscriptionListRelationFilter>;
};

export enum Provider {
  Aws = 'AWS',
  Alibaba = 'Alibaba',
  Yandex = 'Yandex'
}

export type Query = {
  __typename?: 'Query';
  /** Генерация двух факторных вопросов */
  RandomTwoFactorQuestion: RandomTwoFactorQuestion;
  beneficiaryAnonymousPropForEmail: Array<BeneficiaryAnonymousPropForEmail>;
  /** Получить бенефециару url регистрации, доступно пользователю с ролью ADMIN для отладки */
  beneficiaryRegistrationUrl: Scalars['String']['output'];
  /** Получить курс валют */
  currencyRates: Array<CurrencyRate>;
  /** Получить данные по текущему хабу пользователя */
  currentExportRequest?: Maybe<SafeExport>;
  /** Возвращает текущую ссесию пользователя и аккаунт пользователя */
  currentSession: AccountSession;
  /** Получить  информацию для отладки */
  debug: Scalars['JSON']['output'];
  echoQuery: Scalars['String']['output'];
  /** Проверка нахождения почты в белом списке */
  existInWhitelist: Scalars['Boolean']['output'];
  /** Get a form by id */
  form: Form;
  /** Get all forms for a given entity type */
  forms: Array<Form>;
  /** Генерация totp secret */
  generateTotpSecret: Scalars['String']['output'];
  /** Возвращает статусы доступа в приложение по email пользователя */
  getAccessStatuses: AccessStatusesResponse;
  /** Получить настройки всех хабов пользователей */
  getAllHubSettings: Array<HubSettingsResponse>;
  /** Получить ассистентов */
  getAssistants: Array<Profile>;
  /** Получить бенефициаров */
  getBeneficiaries: Array<Profile>;
  getEventLogs: EventLogPaginationType;
  /** Получить файл из хранилища */
  getFile: Scalars['String']['output'];
  /** @deprecated Получить файл как администратор */
  getFileAsAdmin: Scalars['String']['output'];
  /** Получить настройки хаба пользователя */
  getHubSettings: HubSettingsResponse;
  /** Получить настройки хаба по url адресу хаба */
  getHubSettingsbyUrl: HubSettingsResponse;
  getProfileId: Scalars['Int']['output'];
  /** Получить все подписки пользователя */
  getSubscriptions: Array<SubscriptionEntity>;
  getTopicSubscribersCount: Scalars['Int']['output'];
  /** Возвращает zendesk token */
  getZendeskToken: GetZendeskToken;
  /** Проверка на наличие профиля бенефициара */
  isBeneficiaryProfileExists: Scalars['Boolean']['output'];
  /** Проверка занят ли email аккаунт */
  isEmailAccountTaken: Scalars['Boolean']['output'];
  /** Проверка на наличие уже зарегестрированного аккаунта на данную почту */
  isEmailExists: Scalars['Boolean']['output'];
  /**
   * Проверка доступности url для экспорта данных
   * @deprecated Метод не рекомендуеться использовать, так как он не актуален в связи с имзменением логики получения новых url адресов
   */
  isUrlAvailable: Scalars['Boolean']['output'];
  /** Получить все почтовые ящики */
  mailboxes: Array<Mailbox>;
  nextOwnersPropsForEmail: Array<NextOwnerPropForEmail>;
  /** Получить единоразовые коды, доступно пользователю с ролью ADMIN для отладки */
  oneTimeCodes: OneTimeCodesResponse;
  /** Получить профиль */
  profile: Profile;
  /** Получить коллецию */
  pullCollection?: Maybe<Collection>;
  /** Получить коллекции */
  pullCollections: Array<Collection>;
  /** Получить сценарий */
  scenario?: Maybe<Scenario>;
  /** Получить все сценарии */
  scenarios: Array<Scenario>;
  /** Отправка уведомления посредством sms или электронной почтой */
  sendNotification: Scalars['Boolean']['output'];
  /** Тест отправки уведомлений  */
  testBroadcast: Scalars['Boolean']['output'];
  /** Тест перевода для отладки */
  testTranslation: Scalars['String']['output'];
  /** Проверка кода верификации высланного на указанный email */
  verifyEmailCode: Scalars['Boolean']['output'];
  /** Проверка кода верификации из sms собщения отправленного на указанный номер телефона */
  verifySmsCode: Scalars['Boolean']['output'];
  /** Верификация ответов для  вопросов из двухфакторной аутентификации */
  verifyTwoFactorQuestion: Scalars['Boolean']['output'];
  /** Возвращает аккаунт по authorization token  */
  whoami: Account;
};


export type QueryRandomTwoFactorQuestionArgs = {
  email: Scalars['String']['input'];
};


export type QueryBeneficiaryRegistrationUrlArgs = {
  beneficiaryEmail: Scalars['String']['input'];
};


export type QueryEchoQueryArgs = {
  text: Scalars['String']['input'];
};


export type QueryExistInWhitelistArgs = {
  email: Scalars['String']['input'];
};


export type QueryFormArgs = {
  id: Scalars['Float']['input'];
};


export type QueryFormsArgs = {
  entityType: Scalars['String']['input'];
};


export type QueryGenerateTotpSecretArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAccessStatusesArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetAssistantsArgs = {
  filterByName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetEventLogsArgs = {
  forDtoId?: InputMaybe<Scalars['String']['input']>;
  fromDateTime: Scalars['DateTime']['input'];
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
  toDateTime: Scalars['DateTime']['input'];
};


export type QueryGetFileArgs = {
  filename: Scalars['String']['input'];
};


export type QueryGetFileAsAdminArgs = {
  filename: Scalars['String']['input'];
};


export type QueryGetHubSettingsbyUrlArgs = {
  url: Scalars['String']['input'];
};


export type QueryGetProfileIdArgs = {
  publicKey: Scalars['String']['input'];
};


export type QueryGetTopicSubscribersCountArgs = {
  topic: Scalars['String']['input'];
};


export type QueryIsBeneficiaryProfileExistsArgs = {
  email: Scalars['String']['input'];
  jwt: Scalars['String']['input'];
};


export type QueryIsEmailAccountTakenArgs = {
  search: Scalars['String']['input'];
};


export type QueryIsEmailExistsArgs = {
  email: Scalars['String']['input'];
};


export type QueryOneTimeCodesArgs = {
  emailOrPhoneNumber: Scalars['String']['input'];
};


export type QueryProfileArgs = {
  input: ProfileInput;
};


export type QueryPullCollectionArgs = {
  name: Scalars['String']['input'];
};


export type QueryScenarioArgs = {
  scenarioId: Scalars['String']['input'];
};


export type QuerySendNotificationArgs = {
  deliveryMethod: DeliveryMethod;
  emailData?: InputMaybe<EmailParametersInput>;
  smsData?: InputMaybe<SmsParametersInput>;
};


export type QueryTestTranslationArgs = {
  username: Scalars['String']['input'];
};


export type QueryVerifyEmailCodeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


export type QueryVerifySmsCodeArgs = {
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type QueryVerifyTwoFactorQuestionArgs = {
  email: Scalars['String']['input'];
  emailCode: Scalars['String']['input'];
  twoFactorQuestionAnswer: Scalars['String']['input'];
  twoFactorQuestionId: Scalars['Int']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RandomTwoFactorQuestion = {
  __typename?: 'RandomTwoFactorQuestion';
  id: Scalars['Float']['output'];
  question: Scalars['String']['output'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  meta?: InputMaybe<MetaInput>;
  password: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  twoFactorQuestions: Array<TwoFactorQuestionPlainObject>;
};

export type ResetPasswordInput = {
  email: Scalars['String']['input'];
  emailCode: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type SafeExport = {
  __typename?: 'SafeExport';
  createdAt: Scalars['DateTime']['output'];
  hubType?: Maybe<HubType>;
  id: Scalars['String']['output'];
  status: ExportStatus;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type Scenario = {
  __typename?: 'Scenario';
  _count: ScenarioCount;
  beneficiaries?: Maybe<Array<ScenarioBeneficiary>>;
  createdAt: Scalars['DateTime']['output'];
  currentNotificationValue: Scalars['BigInt']['output'];
  dtoIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  mainUser: Profile;
  mainUserId: Scalars['Int']['output'];
  mode: ScenarioMode;
  notificationPeriod: Scalars['BigInt']['output'];
  notificationTime: Scalars['DateTime']['output'];
  notificationValue: Scalars['BigInt']['output'];
  status: ScenarioStatus;
  triggerType: ScenarioTriggerType;
  triggerValue: Scalars['BigInt']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ScenarioBeneficiary = {
  __typename?: 'ScenarioBeneficiary';
  acceptanceDeadline: Scalars['BigInt']['output'];
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  beneficiary: Profile;
  beneficiaryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  scenario: Scenario;
  scenarioId: Scalars['String']['output'];
  status: BeneficiaryStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type ScenarioBeneficiaryCreateManyBeneficiaryInput = {
  acceptanceDeadline: Scalars['BigInt']['input'];
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  scenarioId: Scalars['String']['input'];
  status: BeneficiaryStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryCreateManyBeneficiaryInputEnvelope = {
  data: Array<ScenarioBeneficiaryCreateManyBeneficiaryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScenarioBeneficiaryCreateManyScenarioInput = {
  acceptanceDeadline: Scalars['BigInt']['input'];
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  beneficiaryId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  status: BeneficiaryStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryCreateManyScenarioInputEnvelope = {
  data: Array<ScenarioBeneficiaryCreateManyScenarioInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScenarioBeneficiaryCreateNestedManyWithoutBeneficiaryInput = {
  connect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioBeneficiaryCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<ScenarioBeneficiaryCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<ScenarioBeneficiaryCreateManyBeneficiaryInputEnvelope>;
};

export type ScenarioBeneficiaryCreateNestedManyWithoutScenarioInput = {
  connect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioBeneficiaryCreateOrConnectWithoutScenarioInput>>;
  create?: InputMaybe<Array<ScenarioBeneficiaryCreateWithoutScenarioInput>>;
  createMany?: InputMaybe<ScenarioBeneficiaryCreateManyScenarioInputEnvelope>;
};

export type ScenarioBeneficiaryCreateOrConnectWithoutBeneficiaryInput = {
  create: ScenarioBeneficiaryCreateWithoutBeneficiaryInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryCreateOrConnectWithoutScenarioInput = {
  create: ScenarioBeneficiaryCreateWithoutScenarioInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryCreateWithoutBeneficiaryInput = {
  acceptanceDeadline: Scalars['BigInt']['input'];
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  scenario: ScenarioCreateNestedOneWithoutBeneficiariesInput;
  status: BeneficiaryStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryCreateWithoutScenarioInput = {
  acceptanceDeadline: Scalars['BigInt']['input'];
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  beneficiary: ProfileCreateNestedOneWithoutScenarioBeneficiariesInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  status: BeneficiaryStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryListRelationFilter = {
  every?: InputMaybe<ScenarioBeneficiaryWhereInput>;
  none?: InputMaybe<ScenarioBeneficiaryWhereInput>;
  some?: InputMaybe<ScenarioBeneficiaryWhereInput>;
};

export type ScenarioBeneficiaryScalarWhereInput = {
  AND?: InputMaybe<Array<ScenarioBeneficiaryScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioBeneficiaryScalarWhereInput>>;
  OR?: InputMaybe<Array<ScenarioBeneficiaryScalarWhereInput>>;
  acceptanceDeadline?: InputMaybe<BigIntFilter>;
  activatedAt?: InputMaybe<DateTimeNullableFilter>;
  beneficiaryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntFilter>;
  scenarioId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBeneficiaryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScenarioBeneficiaryUniqueBeneficiaryInScenarioCompoundUniqueInput = {
  beneficiaryId: Scalars['Int']['input'];
  priority: Scalars['Int']['input'];
  scenarioId: Scalars['String']['input'];
};

export type ScenarioBeneficiaryUpdateManyMutationInput = {
  acceptanceDeadline?: InputMaybe<Scalars['BigInt']['input']>;
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<BeneficiaryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryUpdateManyWithWhereWithoutBeneficiaryInput = {
  data: ScenarioBeneficiaryUpdateManyMutationInput;
  where: ScenarioBeneficiaryScalarWhereInput;
};

export type ScenarioBeneficiaryUpdateManyWithWhereWithoutScenarioInput = {
  data: ScenarioBeneficiaryUpdateManyMutationInput;
  where: ScenarioBeneficiaryScalarWhereInput;
};

export type ScenarioBeneficiaryUpdateManyWithoutBeneficiaryNestedInput = {
  connect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioBeneficiaryCreateOrConnectWithoutBeneficiaryInput>>;
  create?: InputMaybe<Array<ScenarioBeneficiaryCreateWithoutBeneficiaryInput>>;
  createMany?: InputMaybe<ScenarioBeneficiaryCreateManyBeneficiaryInputEnvelope>;
  delete?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScenarioBeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<Array<ScenarioBeneficiaryUpdateWithWhereUniqueWithoutBeneficiaryInput>>;
  updateMany?: InputMaybe<Array<ScenarioBeneficiaryUpdateManyWithWhereWithoutBeneficiaryInput>>;
  upsert?: InputMaybe<Array<ScenarioBeneficiaryUpsertWithWhereUniqueWithoutBeneficiaryInput>>;
};

export type ScenarioBeneficiaryUpdateManyWithoutScenarioNestedInput = {
  connect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioBeneficiaryCreateOrConnectWithoutScenarioInput>>;
  create?: InputMaybe<Array<ScenarioBeneficiaryCreateWithoutScenarioInput>>;
  createMany?: InputMaybe<ScenarioBeneficiaryCreateManyScenarioInputEnvelope>;
  delete?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScenarioBeneficiaryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  set?: InputMaybe<Array<ScenarioBeneficiaryWhereUniqueInput>>;
  update?: InputMaybe<Array<ScenarioBeneficiaryUpdateWithWhereUniqueWithoutScenarioInput>>;
  updateMany?: InputMaybe<Array<ScenarioBeneficiaryUpdateManyWithWhereWithoutScenarioInput>>;
  upsert?: InputMaybe<Array<ScenarioBeneficiaryUpsertWithWhereUniqueWithoutScenarioInput>>;
};

export type ScenarioBeneficiaryUpdateWithWhereUniqueWithoutBeneficiaryInput = {
  data: ScenarioBeneficiaryUpdateWithoutBeneficiaryInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryUpdateWithWhereUniqueWithoutScenarioInput = {
  data: ScenarioBeneficiaryUpdateWithoutScenarioInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryUpdateWithoutBeneficiaryInput = {
  acceptanceDeadline?: InputMaybe<Scalars['BigInt']['input']>;
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  scenario?: InputMaybe<ScenarioUpdateOneRequiredWithoutBeneficiariesNestedInput>;
  status?: InputMaybe<BeneficiaryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryUpdateWithoutScenarioInput = {
  acceptanceDeadline?: InputMaybe<Scalars['BigInt']['input']>;
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  beneficiary?: InputMaybe<ProfileUpdateOneRequiredWithoutScenarioBeneficiariesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<BeneficiaryStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioBeneficiaryUpsertWithWhereUniqueWithoutBeneficiaryInput = {
  create: ScenarioBeneficiaryCreateWithoutBeneficiaryInput;
  update: ScenarioBeneficiaryUpdateWithoutBeneficiaryInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryUpsertWithWhereUniqueWithoutScenarioInput = {
  create: ScenarioBeneficiaryCreateWithoutScenarioInput;
  update: ScenarioBeneficiaryUpdateWithoutScenarioInput;
  where: ScenarioBeneficiaryWhereUniqueInput;
};

export type ScenarioBeneficiaryWhereInput = {
  AND?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  OR?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  acceptanceDeadline?: InputMaybe<BigIntFilter>;
  activatedAt?: InputMaybe<DateTimeNullableFilter>;
  beneficiary?: InputMaybe<ProfileRelationFilter>;
  beneficiaryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntFilter>;
  scenario?: InputMaybe<ScenarioRelationFilter>;
  scenarioId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBeneficiaryStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScenarioBeneficiaryWhereUniqueInput = {
  AND?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  OR?: InputMaybe<Array<ScenarioBeneficiaryWhereInput>>;
  acceptanceDeadline?: InputMaybe<BigIntFilter>;
  activatedAt?: InputMaybe<DateTimeNullableFilter>;
  beneficiary?: InputMaybe<ProfileRelationFilter>;
  beneficiaryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<IntFilter>;
  scenario?: InputMaybe<ScenarioRelationFilter>;
  scenarioId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBeneficiaryStatusFilter>;
  uniqueBeneficiaryInScenario?: InputMaybe<ScenarioBeneficiaryUniqueBeneficiaryInScenarioCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScenarioCount = {
  __typename?: 'ScenarioCount';
  beneficiaries: Scalars['Int']['output'];
};

export type ScenarioCreateManyMainUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue: Scalars['BigInt']['input'];
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mode: ScenarioMode;
  notificationPeriod: Scalars['BigInt']['input'];
  notificationTime: Scalars['DateTime']['input'];
  notificationValue: Scalars['BigInt']['input'];
  status: ScenarioStatus;
  triggerType: ScenarioTriggerType;
  triggerValue: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioCreateManyMainUserInputEnvelope = {
  data: Array<ScenarioCreateManyMainUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ScenarioCreateNestedManyWithoutMainUserInput = {
  connect?: InputMaybe<Array<ScenarioWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<ScenarioCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<ScenarioCreateManyMainUserInputEnvelope>;
};

export type ScenarioCreateNestedOneWithoutBeneficiariesInput = {
  connect?: InputMaybe<ScenarioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenarioCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ScenarioCreateWithoutBeneficiariesInput>;
};

export type ScenarioCreateOrConnectWithoutBeneficiariesInput = {
  create: ScenarioCreateWithoutBeneficiariesInput;
  where: ScenarioWhereUniqueInput;
};

export type ScenarioCreateOrConnectWithoutMainUserInput = {
  create: ScenarioCreateWithoutMainUserInput;
  where: ScenarioWhereUniqueInput;
};

export type ScenarioCreateWithoutBeneficiariesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue: Scalars['BigInt']['input'];
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainUser: ProfileCreateNestedOneWithoutScenariosBelongingToMeAsMainUserInput;
  mode: ScenarioMode;
  notificationPeriod: Scalars['BigInt']['input'];
  notificationTime: Scalars['DateTime']['input'];
  notificationValue: Scalars['BigInt']['input'];
  status: ScenarioStatus;
  triggerType: ScenarioTriggerType;
  triggerValue: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioCreateWithoutMainUserInput = {
  beneficiaries?: InputMaybe<ScenarioBeneficiaryCreateNestedManyWithoutScenarioInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue: Scalars['BigInt']['input'];
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mode: ScenarioMode;
  notificationPeriod: Scalars['BigInt']['input'];
  notificationTime: Scalars['DateTime']['input'];
  notificationValue: Scalars['BigInt']['input'];
  status: ScenarioStatus;
  triggerType: ScenarioTriggerType;
  triggerValue: Scalars['BigInt']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioListRelationFilter = {
  every?: InputMaybe<ScenarioWhereInput>;
  none?: InputMaybe<ScenarioWhereInput>;
  some?: InputMaybe<ScenarioWhereInput>;
};

export enum ScenarioMode {
  Normal = 'NORMAL',
  Test = 'TEST'
}

export type ScenarioRelationFilter = {
  is?: InputMaybe<ScenarioWhereInput>;
  isNot?: InputMaybe<ScenarioWhereInput>;
};

export type ScenarioScalarWhereInput = {
  AND?: InputMaybe<Array<ScenarioScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioScalarWhereInput>>;
  OR?: InputMaybe<Array<ScenarioScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentNotificationValue?: InputMaybe<BigIntFilter>;
  dtoIds?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  mode?: InputMaybe<EnumScenarioModeFilter>;
  notificationPeriod?: InputMaybe<BigIntFilter>;
  notificationTime?: InputMaybe<DateTimeFilter>;
  notificationValue?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<EnumScenarioStatusFilter>;
  triggerType?: InputMaybe<EnumScenarioTriggerTypeFilter>;
  triggerValue?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ScenarioStatus {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Idle = 'IDLE',
  InProgress = 'IN_PROGRESS',
  NotActive = 'NOT_ACTIVE'
}

export enum ScenarioTriggerType {
  AfterDatetime = 'AFTER_DATETIME',
  AfterInactivity = 'AFTER_INACTIVITY'
}

export type ScenarioUpdateInput = {
  beneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutScenarioNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainUser?: InputMaybe<ProfileUpdateOneRequiredWithoutScenariosBelongingToMeAsMainUserNestedInput>;
  mode?: InputMaybe<ScenarioMode>;
  notificationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  notificationTime?: InputMaybe<Scalars['DateTime']['input']>;
  notificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<ScenarioStatus>;
  triggerType?: InputMaybe<ScenarioTriggerType>;
  triggerValue?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<ScenarioMode>;
  notificationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  notificationTime?: InputMaybe<Scalars['DateTime']['input']>;
  notificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<ScenarioStatus>;
  triggerType?: InputMaybe<ScenarioTriggerType>;
  triggerValue?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioUpdateManyWithWhereWithoutMainUserInput = {
  data: ScenarioUpdateManyMutationInput;
  where: ScenarioScalarWhereInput;
};

export type ScenarioUpdateManyWithoutMainUserNestedInput = {
  connect?: InputMaybe<Array<ScenarioWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScenarioCreateOrConnectWithoutMainUserInput>>;
  create?: InputMaybe<Array<ScenarioCreateWithoutMainUserInput>>;
  createMany?: InputMaybe<ScenarioCreateManyMainUserInputEnvelope>;
  delete?: InputMaybe<Array<ScenarioWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScenarioScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScenarioWhereUniqueInput>>;
  set?: InputMaybe<Array<ScenarioWhereUniqueInput>>;
  update?: InputMaybe<Array<ScenarioUpdateWithWhereUniqueWithoutMainUserInput>>;
  updateMany?: InputMaybe<Array<ScenarioUpdateManyWithWhereWithoutMainUserInput>>;
  upsert?: InputMaybe<Array<ScenarioUpsertWithWhereUniqueWithoutMainUserInput>>;
};

export type ScenarioUpdateOneRequiredWithoutBeneficiariesNestedInput = {
  connect?: InputMaybe<ScenarioWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ScenarioCreateOrConnectWithoutBeneficiariesInput>;
  create?: InputMaybe<ScenarioCreateWithoutBeneficiariesInput>;
  update?: InputMaybe<ScenarioUpdateToOneWithWhereWithoutBeneficiariesInput>;
  upsert?: InputMaybe<ScenarioUpsertWithoutBeneficiariesInput>;
};

export type ScenarioUpdateToOneWithWhereWithoutBeneficiariesInput = {
  data: ScenarioUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<ScenarioWhereInput>;
};

export type ScenarioUpdateWithWhereUniqueWithoutMainUserInput = {
  data: ScenarioUpdateWithoutMainUserInput;
  where: ScenarioWhereUniqueInput;
};

export type ScenarioUpdateWithoutBeneficiariesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainUser?: InputMaybe<ProfileUpdateOneRequiredWithoutScenariosBelongingToMeAsMainUserNestedInput>;
  mode?: InputMaybe<ScenarioMode>;
  notificationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  notificationTime?: InputMaybe<Scalars['DateTime']['input']>;
  notificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<ScenarioStatus>;
  triggerType?: InputMaybe<ScenarioTriggerType>;
  triggerValue?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioUpdateWithoutMainUserInput = {
  beneficiaries?: InputMaybe<ScenarioBeneficiaryUpdateManyWithoutScenarioNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  currentNotificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  dtoIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<ScenarioMode>;
  notificationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  notificationTime?: InputMaybe<Scalars['DateTime']['input']>;
  notificationValue?: InputMaybe<Scalars['BigInt']['input']>;
  status?: InputMaybe<ScenarioStatus>;
  triggerType?: InputMaybe<ScenarioTriggerType>;
  triggerValue?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ScenarioUpsertWithWhereUniqueWithoutMainUserInput = {
  create: ScenarioCreateWithoutMainUserInput;
  update: ScenarioUpdateWithoutMainUserInput;
  where: ScenarioWhereUniqueInput;
};

export type ScenarioUpsertWithoutBeneficiariesInput = {
  create: ScenarioCreateWithoutBeneficiariesInput;
  update: ScenarioUpdateWithoutBeneficiariesInput;
  where?: InputMaybe<ScenarioWhereInput>;
};

export type ScenarioWhereInput = {
  AND?: InputMaybe<Array<ScenarioWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioWhereInput>>;
  OR?: InputMaybe<Array<ScenarioWhereInput>>;
  beneficiaries?: InputMaybe<ScenarioBeneficiaryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentNotificationValue?: InputMaybe<BigIntFilter>;
  dtoIds?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  mode?: InputMaybe<EnumScenarioModeFilter>;
  notificationPeriod?: InputMaybe<BigIntFilter>;
  notificationTime?: InputMaybe<DateTimeFilter>;
  notificationValue?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<EnumScenarioStatusFilter>;
  triggerType?: InputMaybe<EnumScenarioTriggerTypeFilter>;
  triggerValue?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScenarioWhereUniqueInput = {
  AND?: InputMaybe<Array<ScenarioWhereInput>>;
  NOT?: InputMaybe<Array<ScenarioWhereInput>>;
  OR?: InputMaybe<Array<ScenarioWhereInput>>;
  beneficiaries?: InputMaybe<ScenarioBeneficiaryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currentNotificationValue?: InputMaybe<BigIntFilter>;
  dtoIds?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  mainUser?: InputMaybe<ProfileRelationFilter>;
  mainUserId?: InputMaybe<IntFilter>;
  mode?: InputMaybe<EnumScenarioModeFilter>;
  notificationPeriod?: InputMaybe<BigIntFilter>;
  notificationTime?: InputMaybe<DateTimeFilter>;
  notificationValue?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<EnumScenarioStatusFilter>;
  triggerType?: InputMaybe<EnumScenarioTriggerTypeFilter>;
  triggerValue?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SmsParametersInput = {
  number: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionActivationHistoryItem = {
  __typename?: 'SubscriptionActivationHistoryItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isTrial: Scalars['Boolean']['output'];
  subscription: SubscriptionEntity;
  subscriptionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionActivationHistoryItemCreateManySubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isTrial: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionActivationHistoryItemCreateManySubscriptionInputEnvelope = {
  data: Array<SubscriptionActivationHistoryItemCreateManySubscriptionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionActivationHistoryItemCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionActivationHistoryItemCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<SubscriptionActivationHistoryItemCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SubscriptionActivationHistoryItemCreateManySubscriptionInputEnvelope>;
};

export type SubscriptionActivationHistoryItemCreateOrConnectWithoutSubscriptionInput = {
  create: SubscriptionActivationHistoryItemCreateWithoutSubscriptionInput;
  where: SubscriptionActivationHistoryItemWhereUniqueInput;
};

export type SubscriptionActivationHistoryItemCreateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isTrial: Scalars['Boolean']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionActivationHistoryItemListRelationFilter = {
  every?: InputMaybe<SubscriptionActivationHistoryItemWhereInput>;
  none?: InputMaybe<SubscriptionActivationHistoryItemWhereInput>;
  some?: InputMaybe<SubscriptionActivationHistoryItemWhereInput>;
};

export type SubscriptionActivationHistoryItemScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionActivationHistoryItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionActivationHistoryItemScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionActivationHistoryItemScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isTrial?: InputMaybe<BoolFilter>;
  subscriptionId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionActivationHistoryItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isTrial?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionActivationHistoryItemUpdateManyWithWhereWithoutSubscriptionInput = {
  data: SubscriptionActivationHistoryItemUpdateManyMutationInput;
  where: SubscriptionActivationHistoryItemScalarWhereInput;
};

export type SubscriptionActivationHistoryItemUpdateManyWithoutSubscriptionNestedInput = {
  connect?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionActivationHistoryItemCreateOrConnectWithoutSubscriptionInput>>;
  create?: InputMaybe<Array<SubscriptionActivationHistoryItemCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SubscriptionActivationHistoryItemCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionActivationHistoryItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriptionActivationHistoryItemUpdateWithWhereUniqueWithoutSubscriptionInput>>;
  updateMany?: InputMaybe<Array<SubscriptionActivationHistoryItemUpdateManyWithWhereWithoutSubscriptionInput>>;
  upsert?: InputMaybe<Array<SubscriptionActivationHistoryItemUpsertWithWhereUniqueWithoutSubscriptionInput>>;
};

export type SubscriptionActivationHistoryItemUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: SubscriptionActivationHistoryItemUpdateWithoutSubscriptionInput;
  where: SubscriptionActivationHistoryItemWhereUniqueInput;
};

export type SubscriptionActivationHistoryItemUpdateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isTrial?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionActivationHistoryItemUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: SubscriptionActivationHistoryItemCreateWithoutSubscriptionInput;
  update: SubscriptionActivationHistoryItemUpdateWithoutSubscriptionInput;
  where: SubscriptionActivationHistoryItemWhereUniqueInput;
};

export type SubscriptionActivationHistoryItemWhereInput = {
  AND?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isTrial?: InputMaybe<BoolFilter>;
  subscription?: InputMaybe<SubscriptionRelationFilter>;
  subscriptionId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionActivationHistoryItemWhereUniqueInput = {
  AND?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionActivationHistoryItemWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isTrial?: InputMaybe<BoolFilter>;
  subscription?: InputMaybe<SubscriptionRelationFilter>;
  subscriptionId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionCount = {
  __typename?: 'SubscriptionCount';
  activationHistoryItems: Scalars['Int']['output'];
};

export type SubscriptionCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dateEnd: Scalars['DateTime']['input'];
  dateStart: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  status: SubscriptionStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionCreateManyProfileInputEnvelope = {
  data: Array<SubscriptionCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<SubscriptionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyProfileInputEnvelope>;
};

export type SubscriptionCreateOrConnectWithoutProfileInput = {
  create: SubscriptionCreateWithoutProfileInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateWithoutProfileInput = {
  activationHistoryItems?: InputMaybe<SubscriptionActivationHistoryItemCreateNestedManyWithoutSubscriptionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dateEnd: Scalars['DateTime']['input'];
  dateStart: Scalars['DateTime']['input'];
  plan?: InputMaybe<Scalars['String']['input']>;
  status: SubscriptionStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  _count: SubscriptionCount;
  activationHistoryItems?: Maybe<Array<SubscriptionActivationHistoryItem>>;
  createdAt: Scalars['DateTime']['output'];
  dateEnd: Scalars['DateTime']['output'];
  dateStart: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  plan?: Maybe<Scalars['String']['output']>;
  profile: Profile;
  profileId: Scalars['Int']['output'];
  status: SubscriptionStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionListRelationFilter = {
  every?: InputMaybe<SubscriptionWhereInput>;
  none?: InputMaybe<SubscriptionWhereInput>;
  some?: InputMaybe<SubscriptionWhereInput>;
};

export type SubscriptionRelationFilter = {
  is?: InputMaybe<SubscriptionWhereInput>;
  isNot?: InputMaybe<SubscriptionWhereInput>;
};

export type SubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateEnd?: InputMaybe<DateTimeFilter>;
  dateStart?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumSubscriptionStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  NotActive = 'NOT_ACTIVE'
}

export type SubscriptionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart?: InputMaybe<Scalars['DateTime']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SubscriptionStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionUpdateManyWithWhereWithoutProfileInput = {
  data: SubscriptionUpdateManyMutationInput;
  where: SubscriptionScalarWhereInput;
};

export type SubscriptionUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SubscriptionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<SubscriptionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<SubscriptionUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<SubscriptionUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<SubscriptionUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type SubscriptionUpdateWithWhereUniqueWithoutProfileInput = {
  data: SubscriptionUpdateWithoutProfileInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpdateWithoutProfileInput = {
  activationHistoryItems?: InputMaybe<SubscriptionActivationHistoryItemUpdateManyWithoutSubscriptionNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  dateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateStart?: InputMaybe<Scalars['DateTime']['input']>;
  plan?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SubscriptionStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionUpsertWithWhereUniqueWithoutProfileInput = {
  create: SubscriptionCreateWithoutProfileInput;
  update: SubscriptionUpdateWithoutProfileInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionWhereInput = {
  AND?: InputMaybe<Array<SubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionWhereInput>>;
  activationHistoryItems?: InputMaybe<SubscriptionActivationHistoryItemListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateEnd?: InputMaybe<DateTimeFilter>;
  dateStart?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  plan?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumSubscriptionStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionWhereUniqueInput = {
  AND?: InputMaybe<Array<SubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionWhereInput>>;
  activationHistoryItems?: InputMaybe<SubscriptionActivationHistoryItemListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateEnd?: InputMaybe<DateTimeFilter>;
  dateStart?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  plan?: InputMaybe<StringNullableFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumSubscriptionStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Tasks = {
  __typename?: 'Tasks';
  createdAt: Scalars['DateTime']['output'];
  exportId?: Maybe<Scalars['String']['output']>;
  exports?: Maybe<Export>;
  id: Scalars['Int']['output'];
  params: Scalars['JSON']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  status: ExportStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type TasksCreateManyExportsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  params: Scalars['JSON']['input'];
  profileId: Scalars['Int']['input'];
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksCreateManyExportsInputEnvelope = {
  data: Array<TasksCreateManyExportsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TasksCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exportId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  params: Scalars['JSON']['input'];
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksCreateManyProfileInputEnvelope = {
  data: Array<TasksCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TasksCreateNestedManyWithoutExportsInput = {
  connect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TasksCreateOrConnectWithoutExportsInput>>;
  create?: InputMaybe<Array<TasksCreateWithoutExportsInput>>;
  createMany?: InputMaybe<TasksCreateManyExportsInputEnvelope>;
};

export type TasksCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TasksCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<TasksCreateWithoutProfileInput>>;
  createMany?: InputMaybe<TasksCreateManyProfileInputEnvelope>;
};

export type TasksCreateOrConnectWithoutExportsInput = {
  create: TasksCreateWithoutExportsInput;
  where: TasksWhereUniqueInput;
};

export type TasksCreateOrConnectWithoutProfileInput = {
  create: TasksCreateWithoutProfileInput;
  where: TasksWhereUniqueInput;
};

export type TasksCreateWithoutExportsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  params: Scalars['JSON']['input'];
  profile: ProfileCreateNestedOneWithoutTasksInput;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exports?: InputMaybe<ExportCreateNestedOneWithoutTasksInput>;
  params: Scalars['JSON']['input'];
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksListRelationFilter = {
  every?: InputMaybe<TasksWhereInput>;
  none?: InputMaybe<TasksWhereInput>;
  some?: InputMaybe<TasksWhereInput>;
};

export type TasksScalarWhereInput = {
  AND?: InputMaybe<Array<TasksScalarWhereInput>>;
  NOT?: InputMaybe<Array<TasksScalarWhereInput>>;
  OR?: InputMaybe<Array<TasksScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exportId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  params?: InputMaybe<JsonFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TasksUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  params?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksUpdateManyWithWhereWithoutExportsInput = {
  data: TasksUpdateManyMutationInput;
  where: TasksScalarWhereInput;
};

export type TasksUpdateManyWithWhereWithoutProfileInput = {
  data: TasksUpdateManyMutationInput;
  where: TasksScalarWhereInput;
};

export type TasksUpdateManyWithoutExportsNestedInput = {
  connect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TasksCreateOrConnectWithoutExportsInput>>;
  create?: InputMaybe<Array<TasksCreateWithoutExportsInput>>;
  createMany?: InputMaybe<TasksCreateManyExportsInputEnvelope>;
  delete?: InputMaybe<Array<TasksWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TasksScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  set?: InputMaybe<Array<TasksWhereUniqueInput>>;
  update?: InputMaybe<Array<TasksUpdateWithWhereUniqueWithoutExportsInput>>;
  updateMany?: InputMaybe<Array<TasksUpdateManyWithWhereWithoutExportsInput>>;
  upsert?: InputMaybe<Array<TasksUpsertWithWhereUniqueWithoutExportsInput>>;
};

export type TasksUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TasksCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<TasksCreateWithoutProfileInput>>;
  createMany?: InputMaybe<TasksCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<TasksWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TasksScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TasksWhereUniqueInput>>;
  set?: InputMaybe<Array<TasksWhereUniqueInput>>;
  update?: InputMaybe<Array<TasksUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<TasksUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<TasksUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type TasksUpdateWithWhereUniqueWithoutExportsInput = {
  data: TasksUpdateWithoutExportsInput;
  where: TasksWhereUniqueInput;
};

export type TasksUpdateWithWhereUniqueWithoutProfileInput = {
  data: TasksUpdateWithoutProfileInput;
  where: TasksWhereUniqueInput;
};

export type TasksUpdateWithoutExportsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  params?: InputMaybe<Scalars['JSON']['input']>;
  profile?: InputMaybe<ProfileUpdateOneRequiredWithoutTasksNestedInput>;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  exports?: InputMaybe<ExportUpdateOneWithoutTasksNestedInput>;
  params?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<ExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TasksUpsertWithWhereUniqueWithoutExportsInput = {
  create: TasksCreateWithoutExportsInput;
  update: TasksUpdateWithoutExportsInput;
  where: TasksWhereUniqueInput;
};

export type TasksUpsertWithWhereUniqueWithoutProfileInput = {
  create: TasksCreateWithoutProfileInput;
  update: TasksUpdateWithoutProfileInput;
  where: TasksWhereUniqueInput;
};

export type TasksWhereInput = {
  AND?: InputMaybe<Array<TasksWhereInput>>;
  NOT?: InputMaybe<Array<TasksWhereInput>>;
  OR?: InputMaybe<Array<TasksWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exportId?: InputMaybe<StringNullableFilter>;
  exports?: InputMaybe<ExportNullableRelationFilter>;
  id?: InputMaybe<IntFilter>;
  params?: InputMaybe<JsonFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TasksWhereUniqueInput = {
  AND?: InputMaybe<Array<TasksWhereInput>>;
  NOT?: InputMaybe<Array<TasksWhereInput>>;
  OR?: InputMaybe<Array<TasksWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  exportId?: InputMaybe<StringNullableFilter>;
  exports?: InputMaybe<ExportNullableRelationFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  params?: InputMaybe<JsonFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TwoFactorQuestion = {
  __typename?: 'TwoFactorQuestion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  question: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TwoFactorQuestionCreateManyProfileInput = {
  answerHash: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwoFactorQuestionCreateManyProfileInputEnvelope = {
  data: Array<TwoFactorQuestionCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TwoFactorQuestionCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<TwoFactorQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwoFactorQuestionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<TwoFactorQuestionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<TwoFactorQuestionCreateManyProfileInputEnvelope>;
};

export type TwoFactorQuestionCreateOrConnectWithoutProfileInput = {
  create: TwoFactorQuestionCreateWithoutProfileInput;
  where: TwoFactorQuestionWhereUniqueInput;
};

export type TwoFactorQuestionCreateWithoutProfileInput = {
  answerHash: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  question: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwoFactorQuestionListRelationFilter = {
  every?: InputMaybe<TwoFactorQuestionWhereInput>;
  none?: InputMaybe<TwoFactorQuestionWhereInput>;
  some?: InputMaybe<TwoFactorQuestionWhereInput>;
};

export type TwoFactorQuestionPlainObject = {
  answer: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export type TwoFactorQuestionScalarWhereInput = {
  AND?: InputMaybe<Array<TwoFactorQuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TwoFactorQuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<TwoFactorQuestionScalarWhereInput>>;
  answerHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  profileId?: InputMaybe<IntFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TwoFactorQuestionUpdateManyMutationInput = {
  answerHash?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwoFactorQuestionUpdateManyWithWhereWithoutProfileInput = {
  data: TwoFactorQuestionUpdateManyMutationInput;
  where: TwoFactorQuestionScalarWhereInput;
};

export type TwoFactorQuestionUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<TwoFactorQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TwoFactorQuestionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<TwoFactorQuestionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<TwoFactorQuestionCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<TwoFactorQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TwoFactorQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TwoFactorQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<TwoFactorQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<TwoFactorQuestionUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<TwoFactorQuestionUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<TwoFactorQuestionUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type TwoFactorQuestionUpdateWithWhereUniqueWithoutProfileInput = {
  data: TwoFactorQuestionUpdateWithoutProfileInput;
  where: TwoFactorQuestionWhereUniqueInput;
};

export type TwoFactorQuestionUpdateWithoutProfileInput = {
  answerHash?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TwoFactorQuestionUpsertWithWhereUniqueWithoutProfileInput = {
  create: TwoFactorQuestionCreateWithoutProfileInput;
  update: TwoFactorQuestionUpdateWithoutProfileInput;
  where: TwoFactorQuestionWhereUniqueInput;
};

export type TwoFactorQuestionWhereInput = {
  AND?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  NOT?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  OR?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  answerHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TwoFactorQuestionWhereUniqueInput = {
  AND?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  NOT?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  OR?: InputMaybe<Array<TwoFactorQuestionWhereInput>>;
  answerHash?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TypeEmailCode {
  Create = 'CREATE',
  Delete = 'DELETE'
}

export type UrlForExport = {
  __typename?: 'UrlForExport';
  createdAt: Scalars['DateTime']['output'];
  export?: Maybe<Export>;
  id: Scalars['String']['output'];
  status: UrlForExportStatus;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type UrlForExportCreateNestedOneWithoutExportInput = {
  connect?: InputMaybe<UrlForExportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UrlForExportCreateOrConnectWithoutExportInput>;
  create?: InputMaybe<UrlForExportCreateWithoutExportInput>;
};

export type UrlForExportCreateOrConnectWithoutExportInput = {
  create: UrlForExportCreateWithoutExportInput;
  where: UrlForExportWhereUniqueInput;
};

export type UrlForExportCreateWithoutExportInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status: UrlForExportStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: Scalars['String']['input'];
};

export type UrlForExportRelationFilter = {
  is?: InputMaybe<UrlForExportWhereInput>;
  isNot?: InputMaybe<UrlForExportWhereInput>;
};

export enum UrlForExportStatus {
  Available = 'AVAILABLE',
  Used = 'USED'
}

export type UrlForExportUpdateOneRequiredWithoutExportNestedInput = {
  connect?: InputMaybe<UrlForExportWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UrlForExportCreateOrConnectWithoutExportInput>;
  create?: InputMaybe<UrlForExportCreateWithoutExportInput>;
  update?: InputMaybe<UrlForExportUpdateToOneWithWhereWithoutExportInput>;
  upsert?: InputMaybe<UrlForExportUpsertWithoutExportInput>;
};

export type UrlForExportUpdateToOneWithWhereWithoutExportInput = {
  data: UrlForExportUpdateWithoutExportInput;
  where?: InputMaybe<UrlForExportWhereInput>;
};

export type UrlForExportUpdateWithoutExportInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UrlForExportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UrlForExportUpsertWithoutExportInput = {
  create: UrlForExportCreateWithoutExportInput;
  update: UrlForExportUpdateWithoutExportInput;
  where?: InputMaybe<UrlForExportWhereInput>;
};

export type UrlForExportWhereInput = {
  AND?: InputMaybe<Array<UrlForExportWhereInput>>;
  NOT?: InputMaybe<Array<UrlForExportWhereInput>>;
  OR?: InputMaybe<Array<UrlForExportWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  export?: InputMaybe<ExportNullableRelationFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumUrlForExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UrlForExportWhereUniqueInput = {
  AND?: InputMaybe<Array<UrlForExportWhereInput>>;
  NOT?: InputMaybe<Array<UrlForExportWhereInput>>;
  OR?: InputMaybe<Array<UrlForExportWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  export?: InputMaybe<ExportNullableRelationFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EnumUrlForExportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type WebPushSubscription = {
  __typename?: 'WebPushSubscription';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  profile: Profile;
  profileId: Scalars['Int']['output'];
  subscriptionData: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type WebPushSubscriptionCreateManyProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  subscriptionData: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WebPushSubscriptionCreateManyProfileInputEnvelope = {
  data: Array<WebPushSubscriptionCreateManyProfileInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WebPushSubscriptionCreateNestedManyWithoutProfileInput = {
  connect?: InputMaybe<Array<WebPushSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebPushSubscriptionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<WebPushSubscriptionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<WebPushSubscriptionCreateManyProfileInputEnvelope>;
};

export type WebPushSubscriptionCreateOrConnectWithoutProfileInput = {
  create: WebPushSubscriptionCreateWithoutProfileInput;
  where: WebPushSubscriptionWhereUniqueInput;
};

export type WebPushSubscriptionCreateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionData: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WebPushSubscriptionInput = {
  endpoint: Scalars['String']['input'];
  keys: KeysInput;
};

export type WebPushSubscriptionListRelationFilter = {
  every?: InputMaybe<WebPushSubscriptionWhereInput>;
  none?: InputMaybe<WebPushSubscriptionWhereInput>;
  some?: InputMaybe<WebPushSubscriptionWhereInput>;
};

export type WebPushSubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<WebPushSubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<WebPushSubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<WebPushSubscriptionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  profileId?: InputMaybe<IntFilter>;
  subscriptionData?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type WebPushSubscriptionUniqueSubscriptionForProfileCompoundUniqueInput = {
  profileId: Scalars['Int']['input'];
  subscriptionData: Scalars['String']['input'];
};

export type WebPushSubscriptionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WebPushSubscriptionUpdateManyWithWhereWithoutProfileInput = {
  data: WebPushSubscriptionUpdateManyMutationInput;
  where: WebPushSubscriptionScalarWhereInput;
};

export type WebPushSubscriptionUpdateManyWithoutProfileNestedInput = {
  connect?: InputMaybe<Array<WebPushSubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WebPushSubscriptionCreateOrConnectWithoutProfileInput>>;
  create?: InputMaybe<Array<WebPushSubscriptionCreateWithoutProfileInput>>;
  createMany?: InputMaybe<WebPushSubscriptionCreateManyProfileInputEnvelope>;
  delete?: InputMaybe<Array<WebPushSubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WebPushSubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WebPushSubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<WebPushSubscriptionWhereUniqueInput>>;
  update?: InputMaybe<Array<WebPushSubscriptionUpdateWithWhereUniqueWithoutProfileInput>>;
  updateMany?: InputMaybe<Array<WebPushSubscriptionUpdateManyWithWhereWithoutProfileInput>>;
  upsert?: InputMaybe<Array<WebPushSubscriptionUpsertWithWhereUniqueWithoutProfileInput>>;
};

export type WebPushSubscriptionUpdateWithWhereUniqueWithoutProfileInput = {
  data: WebPushSubscriptionUpdateWithoutProfileInput;
  where: WebPushSubscriptionWhereUniqueInput;
};

export type WebPushSubscriptionUpdateWithoutProfileInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionData?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WebPushSubscriptionUpsertWithWhereUniqueWithoutProfileInput = {
  create: WebPushSubscriptionCreateWithoutProfileInput;
  update: WebPushSubscriptionUpdateWithoutProfileInput;
  where: WebPushSubscriptionWhereUniqueInput;
};

export type WebPushSubscriptionWhereInput = {
  AND?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  subscriptionData?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type WebPushSubscriptionWhereUniqueInput = {
  AND?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  OR?: InputMaybe<Array<WebPushSubscriptionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['Int']['input']>;
  profile?: InputMaybe<ProfileRelationFilter>;
  profileId?: InputMaybe<IntFilter>;
  subscriptionData?: InputMaybe<StringFilter>;
  uniqueSubscriptionForProfile?: InputMaybe<WebPushSubscriptionUniqueSubscriptionForProfileCompoundUniqueInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Whitelist = {
  __typename?: 'Whitelist';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  planId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DropAllCollectionsMutationVariables = Exact<{ [key: string]: never; }>;


export type DropAllCollectionsMutation = { __typename?: 'Mutation', dropAllCollections: boolean };

export type ActivateAllScenariosMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateAllScenariosMutation = { __typename?: 'Mutation', activateAllScenarios: boolean };

export type CreateAssistantMutationVariables = Exact<{
  name: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
}>;


export type CreateAssistantMutation = { __typename?: 'Mutation', createAssistant: { __typename?: 'Account', id: number } };

export type CreateBeneficiaryMutationVariables = Exact<{
  email: Scalars['String']['input'];
  dontSendInviteEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateBeneficiaryMutation = { __typename?: 'Mutation', createBeneficiary: { __typename?: 'CreateBeneficiaryResponse', invitationCode: string, account: { __typename?: 'Account', id: number, profileId?: number | null } } };

export type SendNewInviteToBeneficiaryMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SendNewInviteToBeneficiaryMutation = { __typename?: 'Mutation', sendNewInviteToBeneficiary: { __typename?: 'Profile', id: number, status: AccountStatus, invitationLastSentTime?: any | null } };

export type RegisterBeneficiaryByInvitationCodeMutationVariables = Exact<{
  input: BeneficiaryCreateByInviteCodeInput;
}>;


export type RegisterBeneficiaryByInvitationCodeMutation = { __typename?: 'Mutation', registerBeneficiaryByInvitationCode: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string } } };

export type RegenerateBeneficiaryInviteMutationVariables = Exact<{
  beneficiaryProfileId: Scalars['Int']['input'];
}>;


export type RegenerateBeneficiaryInviteMutation = { __typename?: 'Mutation', regenerateBeneficiaryInvite: { __typename?: 'CreateBeneficiaryResponse', invitationCode: string, account: { __typename?: 'Account', id: number } } };

export type GenerateEncryptedMessageMutationVariables = Exact<{
  publicKey: Scalars['String']['input'];
}>;


export type GenerateEncryptedMessageMutation = { __typename?: 'Mutation', generateEncryptedMessage: string };

export type CreateLogMutationVariables = Exact<{
  publicData: Scalars['String']['input'];
  encryptedData: Scalars['String']['input'];
}>;


export type CreateLogMutation = { __typename?: 'Mutation', createEventLog: { __typename?: 'EventLog', id: number, createdAt: any, mainUserId: number, publicData: string, encryptedData: string } };

export type RequestExportMutationVariables = Exact<{
  basicAuthLogin: Scalars['String']['input'];
  basicAuthPassword: Scalars['String']['input'];
  awsAccessKeyId?: InputMaybe<Scalars['String']['input']>;
  awsSecretAccessKey?: InputMaybe<Scalars['String']['input']>;
  hubType?: InputMaybe<Scalars['String']['input']>;
}>;


export type RequestExportMutation = { __typename?: 'Mutation', requestExport: boolean };

export type UploadFileMutationVariables = Exact<{
  fileContent: Scalars['String']['input'];
}>;


export type UploadFileMutation = { __typename?: 'Mutation', uploadFile: string };

export type DeleteFileMutationVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type DeleteFileMutation = { __typename?: 'Mutation', deleteFile: boolean };

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  totpToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string } } };

export type LoginViaPersonalAccountCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type LoginViaPersonalAccountCodeMutation = { __typename?: 'Mutation', loginViaPersonalAccountCode: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string } } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type ResetPasswordByCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  twoFactorQuestionId: Scalars['Int']['input'];
  twoFactorQuestionAnswer: Scalars['String']['input'];
}>;


export type ResetPasswordByCodeMutation = { __typename?: 'Mutation', resetPasswordByCode: { __typename?: 'Account', id: number } };

export type LoginViaDecryptedMessageMutationVariables = Exact<{
  decryptedMessage: Scalars['String']['input'];
}>;


export type LoginViaDecryptedMessageMutation = { __typename?: 'Mutation', loginViaDecryptedMessage: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string } } };

export type LogoutMutationVariables = Exact<{
  sessionIds: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type GenerateSmsCodeResponseMutationVariables = Exact<{
  number: Scalars['String']['input'];
}>;


export type GenerateSmsCodeResponseMutation = { __typename?: 'Mutation', generateSmsCodeResponse: boolean };

export type CreateEmailAccountMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CreateEmailAccountMutation = { __typename?: 'Mutation', createEmailAccount: { __typename?: 'CreateEmailAccount', email: string } };

export type DeleteEmailAccountMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type DeleteEmailAccountMutation = { __typename?: 'Mutation', deleteEmailAccount: { __typename?: 'DeleteEmailAccount', email: string } };

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'Account', id: number } };

export type ChangeSoweAppendixSuppressCancelConfirmationMutationVariables = Exact<{
  soweAppendixSuppressCancelConfirmation: Scalars['Boolean']['input'];
}>;


export type ChangeSoweAppendixSuppressCancelConfirmationMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Profile', soweAppendixSuppressCancelConfirmation: boolean } };

export type ChangeOcrSuppressLetsTryConfirmationMutationVariables = Exact<{
  ocrSuppressLetsTryConfirmation: Scalars['Boolean']['input'];
}>;


export type ChangeOcrSuppressLetsTryConfirmationMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Profile', ocrSuppressLetsTryConfirmation: boolean } };

export type UpdateProfileNamesMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateProfileNamesMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Profile', firstName?: string | null, lastName?: string | null } };

export type VerifyPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type VerifyPasswordMutation = { __typename?: 'Mutation', verifyPassword: boolean };

export type BlockAccountMutationVariables = Exact<{
  emailCode: Scalars['String']['input'];
}>;


export type BlockAccountMutation = { __typename?: 'Mutation', blockAccount: boolean };

export type PushCollectionMutationVariables = Exact<{
  name: Scalars['String']['input'];
  data: Scalars['String']['input'];
}>;


export type PushCollectionMutation = { __typename?: 'Mutation', pushCollection: boolean };

export type PushFileEncryptedSymmetricKeyMutationVariables = Exact<{
  filePath: Scalars['String']['input'];
  metaInfo: FileMetaInfoInputType;
  encryptedFileSymmetricKey: Scalars['String']['input'];
  encryptedSymmetricKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
}>;


export type PushFileEncryptedSymmetricKeyMutation = { __typename?: 'Mutation', pushFileEncryptedSymmetricKey: boolean };

export type RegisterMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  twoFactorQuestions: Array<TwoFactorQuestionPlainObject> | TwoFactorQuestionPlainObject;
  language: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResponse', token: string, account: { __typename?: 'Account', id: number, email: string } } };

export type GenerateEmailCodeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  typeEmailCode: TypeEmailCode;
}>;


export type GenerateEmailCodeMutation = { __typename?: 'Mutation', generateEmailCode: { __typename?: 'GenerateEmailCodeResponse', result: boolean, expiresAt: any } };

export type ActivateAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  codeSms?: InputMaybe<Scalars['String']['input']>;
}>;


export type ActivateAccountMutation = { __typename?: 'Mutation', activateAccount: { __typename?: 'Account', email: string, twoFactorQuestions: Array<{ __typename?: 'TwoFactorQuestion', question: string }> } };

export type ActivateBeneficiaryProfileMutationVariables = Exact<{
  jwt: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
  password: Scalars['String']['input'];
  twoFactorQuestions: Array<TwoFactorQuestionPlainObject> | TwoFactorQuestionPlainObject;
}>;


export type ActivateBeneficiaryProfileMutation = { __typename?: 'Mutation', activateBeneficiaryProfile: { __typename?: 'Account', email: string } };

export type ActivateAccountAfterExternalRegistrationMutationVariables = Exact<{
  publicKey: Scalars['String']['input'];
}>;


export type ActivateAccountAfterExternalRegistrationMutation = { __typename?: 'Mutation', activateAccountAfterExternalRegistration: boolean };

export type IsBeneficiaryProfileExistsQueryVariables = Exact<{
  jwt: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type IsBeneficiaryProfileExistsQuery = { __typename?: 'Query', isBeneficiaryProfileExists: boolean };

export type CreateScenarioMutationVariables = Exact<{
  beneficiaries: Array<IBeneficiaryInput> | IBeneficiaryInput;
  triggerValue: Scalars['Float']['input'];
  assetAndRelatedDtoIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  triggerType: ScenarioTriggerType;
  mode?: InputMaybe<ScenarioMode>;
  notificationValue: Scalars['Float']['input'];
  notificationPeriod: Scalars['Float']['input'];
  notificationTime: Scalars['DateTime']['input'];
}>;


export type CreateScenarioMutation = { __typename?: 'Mutation', createScenario: { __typename?: 'Scenario', id: string, createdAt: any, updatedAt: any, mainUserId: number, dtoIds?: Array<string> | null, mode: ScenarioMode, triggerType: ScenarioTriggerType, triggerValue: any, status: ScenarioStatus, mainUser: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, beneficiaries?: Array<{ __typename?: 'ScenarioBeneficiary', beneficiaryId: number, beneficiary: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } }> | null } };

export type UpdateScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
  input: ScenarioUpdateInput;
}>;


export type UpdateScenarioMutation = { __typename?: 'Mutation', updateScenario: { __typename?: 'Scenario', id: string, createdAt: any, updatedAt: any, mainUserId: number, dtoIds?: Array<string> | null, mode: ScenarioMode, triggerType: ScenarioTriggerType, triggerValue: any, status: ScenarioStatus, mainUser: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, beneficiaries?: Array<{ __typename?: 'ScenarioBeneficiary', beneficiaryId: number, beneficiary: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } }> | null } };

export type ActivateScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type ActivateScenarioMutation = { __typename?: 'Mutation', activateScenario?: { __typename?: 'Scenario', id: string } | null };

export type CancelScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type CancelScenarioMutation = { __typename?: 'Mutation', cancelScenario?: { __typename?: 'Scenario', id: string } | null };

export type RemoveScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type RemoveScenarioMutation = { __typename?: 'Mutation', removeScenario: boolean };

export type AddBeneficiaryToScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
  beneficiaryId: Scalars['Float']['input'];
  acceptanceDeadline: Scalars['Float']['input'];
}>;


export type AddBeneficiaryToScenarioMutation = { __typename?: 'Mutation', addBeneficiaryToScenario: { __typename?: 'Scenario', id: string } };

export type AcceptScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type AcceptScenarioMutation = { __typename?: 'Mutation', removeScenario: boolean };

export type RejectScenarioMutationVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type RejectScenarioMutation = { __typename?: 'Mutation', rejectScenario: boolean };

export type WebPushSubscribeMutationVariables = Exact<{
  subscription: WebPushSubscriptionInput;
}>;


export type WebPushSubscribeMutation = { __typename?: 'Mutation', webPushSubscribe: boolean };

export type WebPushUnsubscribeMutationVariables = Exact<{
  subscription: WebPushSubscriptionInput;
}>;


export type WebPushUnsubscribeMutation = { __typename?: 'Mutation', webPushUnsubscribe: boolean };

export type AddToWhitelistMutationVariables = Exact<{
  email: Scalars['String']['input'];
  planId: Scalars['String']['input'];
}>;


export type AddToWhitelistMutation = { __typename?: 'Mutation', addToWhitelist: { __typename?: 'Whitelist', id: string } };

export type GetAssistantsQueryVariables = Exact<{
  filterByName?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAssistantsQuery = { __typename?: 'Query', getAssistants: Array<{ __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null, userName?: string | null, accounts?: Array<{ __typename?: 'Account', email: string }> | null }> };

export type CurrenciesListQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrenciesListQuery = { __typename?: 'Query', currencyRates: Array<{ __typename?: 'CurrencyRate', id: string, currencyCode: string, value: number, baseCurrency: string }> };

export type DebugQueryVariables = Exact<{ [key: string]: never; }>;


export type DebugQuery = { __typename?: 'Query', debug: any };

export type GetLogsQueryVariables = Exact<{
  fromDateTime: Scalars['DateTime']['input'];
  toDateTime: Scalars['DateTime']['input'];
  take: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
  forDtoId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLogsQuery = { __typename?: 'Query', getEventLogs: { __typename?: 'EventLogPaginationType', totalCount: number, items: Array<{ __typename?: 'EventLog', id: number, createdAt: any, updatedAt: any, mainUserId: number, publicData: string, encryptedData: string }> } };

export type GetEventLogsCountQueryVariables = Exact<{
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
  forDtoId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEventLogsCountQuery = { __typename?: 'Query', getEventLogs: { __typename?: 'EventLogPaginationType', totalCount: number } };

export type GetFileQueryVariables = Exact<{
  filename: Scalars['String']['input'];
}>;


export type GetFileQuery = { __typename?: 'Query', getFile: string };

export type GetProfileIdQueryVariables = Exact<{
  publicKey: Scalars['String']['input'];
}>;


export type GetProfileIdQuery = { __typename?: 'Query', getProfileId: number };

export type GetZendeskTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetZendeskTokenQuery = { __typename?: 'Query', getZendeskToken: { __typename?: 'GetZendeskToken', token: string } };

export type VerifyEmailCodeQueryVariables = Exact<{
  email: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type VerifyEmailCodeQuery = { __typename?: 'Query', verifyEmailCode: boolean };

export type VerifyTwoFactorQuestionQueryVariables = Exact<{
  email: Scalars['String']['input'];
  emailCode: Scalars['String']['input'];
  twoFactorQuestionId: Scalars['Int']['input'];
  twoFactorQuestionAnswer: Scalars['String']['input'];
}>;


export type VerifyTwoFactorQuestionQuery = { __typename?: 'Query', verifyTwoFactorQuestion: boolean };

export type IsEmailExistQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type IsEmailExistQuery = { __typename?: 'Query', isEmailExists: boolean };

export type IsEmailAccountTakenQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type IsEmailAccountTakenQuery = { __typename?: 'Query', isEmailAccountTaken: boolean };

export type SendEmailMutationVariables = Exact<{
  username: Scalars['String']['input'];
  to: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  text: Scalars['String']['input'];
}>;


export type SendEmailMutation = { __typename?: 'Mutation', sendEmail: string };

export type MailboxesQueryVariables = Exact<{ [key: string]: never; }>;


export type MailboxesQuery = { __typename?: 'Query', mailboxes: Array<{ __typename?: 'Mailbox', username: string, domain: string }> };

export type ProfileQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type ProfileQuery = { __typename?: 'Query', profile: { __typename?: 'Profile', id: number, publicKey?: string | null } };

export type CurrentExportRequestQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentExportRequestQuery = { __typename?: 'Query', currentExportRequest?: { __typename?: 'SafeExport', id: string, createdAt: any, updatedAt: any, url: string, status: ExportStatus, hubType?: HubType | null } | null };

export type IsExportAvailableQueryVariables = Exact<{ [key: string]: never; }>;


export type IsExportAvailableQuery = { __typename?: 'Query', isUrlAvailable: boolean };

export type GetHubSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHubSettingsQuery = { __typename?: 'Query', getHubSettings: { __typename?: 'HubSettingsResponse', hubUrl: string, ipAddress: string, provider: string, country: string, connectedAt: any, lastPingAt: any, updatedAt: any, ownedByUser: boolean } };

export type OneTimeCodesQueryVariables = Exact<{
  emailOrPhoneNumber: Scalars['String']['input'];
}>;


export type OneTimeCodesQuery = { __typename?: 'Query', oneTimeCodes: { __typename?: 'OneTimeCodesResponse', emailCode?: string | null, smsCode?: string | null } };

export type WhoamiQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoamiQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', id: number, email: string, profile?: { __typename?: 'Profile', id: number, avatarUrl?: string | null, firstName?: string | null, lastName?: string | null, status: AccountStatus, publicKey?: string | null, type: ProfileType, mainUser?: { __typename?: 'Profile', publicKey?: string | null } | null, scenariosBelongingToMeAsMainUser?: Array<{ __typename?: 'Scenario', beneficiaries?: Array<{ __typename?: 'ScenarioBeneficiary', status: BeneficiaryStatus }> | null }> | null } | null, sessions?: Array<{ __typename?: 'AccountSession', id: number }> | null } };

export type GetBeneficiariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBeneficiariesQuery = { __typename?: 'Query', getBeneficiaries: Array<{ __typename?: 'Profile', id: number, status: AccountStatus, invitationLastSentTime?: any | null, publicKey?: string | null }> };

export type GetSoweAppendixSuppressCancelConfirmationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSoweAppendixSuppressCancelConfirmationQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', profile?: { __typename?: 'Profile', soweAppendixSuppressCancelConfirmation: boolean } | null } };

export type GetOcrSuppressLetsTryConfirmationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOcrSuppressLetsTryConfirmationQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', profile?: { __typename?: 'Profile', ocrSuppressLetsTryConfirmation: boolean } | null } };

export type PullCollectionQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type PullCollectionQuery = { __typename?: 'Query', collection1?: { __typename?: 'Collection', id: number, createdAt: any, updatedAt: any, name: string, data: string, profileId: number } | null };

export type ScenariosQueryVariables = Exact<{ [key: string]: never; }>;


export type ScenariosQuery = { __typename?: 'Query', scenarios: Array<{ __typename?: 'Scenario', id: string, createdAt: any, updatedAt: any, mainUserId: number, dtoIds?: Array<string> | null, mode: ScenarioMode, notificationValue: any, notificationPeriod: any, notificationTime: any, triggerType: ScenarioTriggerType, triggerValue: any, status: ScenarioStatus, mainUser: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, beneficiaries?: Array<{ __typename?: 'ScenarioBeneficiary', beneficiaryId: number, status: BeneficiaryStatus, priority: number, acceptanceDeadline: any, beneficiary: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null, userName?: string | null, status: AccountStatus } }> | null }> };

export type ScenarioQueryVariables = Exact<{
  scenarioId: Scalars['String']['input'];
}>;


export type ScenarioQuery = { __typename?: 'Query', scenario?: { __typename?: 'Scenario', id: string, createdAt: any, updatedAt: any, mainUserId: number, dtoIds?: Array<string> | null, mode: ScenarioMode, triggerType: ScenarioTriggerType, triggerValue: any, notificationValue: any, notificationPeriod: any, notificationTime: any, status: ScenarioStatus, mainUser: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, beneficiaries?: Array<{ __typename?: 'ScenarioBeneficiary', beneficiaryId: number, status: BeneficiaryStatus, priority: number, acceptanceDeadline: any, beneficiary: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null, userName?: string | null, status: AccountStatus } }> | null } | null };

export type GetTopicSubscribersCountQueryVariables = Exact<{
  topic: Scalars['String']['input'];
}>;


export type GetTopicSubscribersCountQuery = { __typename?: 'Query', getTopicSubscribersCount: number };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', getSubscriptions: Array<{ __typename?: 'SubscriptionEntity', id: number, status: SubscriptionStatus, plan?: string | null, dateStart: any, dateEnd: any }> };

export type ExistInWhitelistQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ExistInWhitelistQuery = { __typename?: 'Query', existInWhitelist: boolean };


export const DropAllCollectionsDocument = gql`
    mutation dropAllCollections {
  dropAllCollections
}
    `;
export type DropAllCollectionsMutationFn = Apollo.MutationFunction<DropAllCollectionsMutation, DropAllCollectionsMutationVariables>;

/**
 * __useDropAllCollectionsMutation__
 *
 * To run a mutation, you first call `useDropAllCollectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDropAllCollectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dropAllCollectionsMutation, { data, loading, error }] = useDropAllCollectionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDropAllCollectionsMutation(baseOptions?: Apollo.MutationHookOptions<DropAllCollectionsMutation, DropAllCollectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DropAllCollectionsMutation, DropAllCollectionsMutationVariables>(DropAllCollectionsDocument, options);
      }
export type DropAllCollectionsMutationHookResult = ReturnType<typeof useDropAllCollectionsMutation>;
export type DropAllCollectionsMutationResult = Apollo.MutationResult<DropAllCollectionsMutation>;
export type DropAllCollectionsMutationOptions = Apollo.BaseMutationOptions<DropAllCollectionsMutation, DropAllCollectionsMutationVariables>;
export const ActivateAllScenariosDocument = gql`
    mutation activateAllScenarios {
  activateAllScenarios
}
    `;
export type ActivateAllScenariosMutationFn = Apollo.MutationFunction<ActivateAllScenariosMutation, ActivateAllScenariosMutationVariables>;

/**
 * __useActivateAllScenariosMutation__
 *
 * To run a mutation, you first call `useActivateAllScenariosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAllScenariosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAllScenariosMutation, { data, loading, error }] = useActivateAllScenariosMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateAllScenariosMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAllScenariosMutation, ActivateAllScenariosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAllScenariosMutation, ActivateAllScenariosMutationVariables>(ActivateAllScenariosDocument, options);
      }
export type ActivateAllScenariosMutationHookResult = ReturnType<typeof useActivateAllScenariosMutation>;
export type ActivateAllScenariosMutationResult = Apollo.MutationResult<ActivateAllScenariosMutation>;
export type ActivateAllScenariosMutationOptions = Apollo.BaseMutationOptions<ActivateAllScenariosMutation, ActivateAllScenariosMutationVariables>;
export const CreateAssistantDocument = gql`
    mutation createAssistant($name: String!, $publicKey: String!) {
  createAssistant(data: {name: $name, publicKey: $publicKey}) {
    id
  }
}
    `;
export type CreateAssistantMutationFn = Apollo.MutationFunction<CreateAssistantMutation, CreateAssistantMutationVariables>;

/**
 * __useCreateAssistantMutation__
 *
 * To run a mutation, you first call `useCreateAssistantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssistantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssistantMutation, { data, loading, error }] = useCreateAssistantMutation({
 *   variables: {
 *      name: // value for 'name'
 *      publicKey: // value for 'publicKey'
 *   },
 * });
 */
export function useCreateAssistantMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssistantMutation, CreateAssistantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssistantMutation, CreateAssistantMutationVariables>(CreateAssistantDocument, options);
      }
export type CreateAssistantMutationHookResult = ReturnType<typeof useCreateAssistantMutation>;
export type CreateAssistantMutationResult = Apollo.MutationResult<CreateAssistantMutation>;
export type CreateAssistantMutationOptions = Apollo.BaseMutationOptions<CreateAssistantMutation, CreateAssistantMutationVariables>;
export const CreateBeneficiaryDocument = gql`
    mutation createBeneficiary($email: String!, $dontSendInviteEmail: Boolean = false) {
  createBeneficiary(
    data: {email: $email, dontSendInviteEmail: $dontSendInviteEmail}
  ) {
    account {
      id
      profileId
    }
    invitationCode
  }
}
    `;
export type CreateBeneficiaryMutationFn = Apollo.MutationFunction<CreateBeneficiaryMutation, CreateBeneficiaryMutationVariables>;

/**
 * __useCreateBeneficiaryMutation__
 *
 * To run a mutation, you first call `useCreateBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBeneficiaryMutation, { data, loading, error }] = useCreateBeneficiaryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      dontSendInviteEmail: // value for 'dontSendInviteEmail'
 *   },
 * });
 */
export function useCreateBeneficiaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBeneficiaryMutation, CreateBeneficiaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBeneficiaryMutation, CreateBeneficiaryMutationVariables>(CreateBeneficiaryDocument, options);
      }
export type CreateBeneficiaryMutationHookResult = ReturnType<typeof useCreateBeneficiaryMutation>;
export type CreateBeneficiaryMutationResult = Apollo.MutationResult<CreateBeneficiaryMutation>;
export type CreateBeneficiaryMutationOptions = Apollo.BaseMutationOptions<CreateBeneficiaryMutation, CreateBeneficiaryMutationVariables>;
export const SendNewInviteToBeneficiaryDocument = gql`
    mutation sendNewInviteToBeneficiary($id: Int!) {
  sendNewInviteToBeneficiary(beneficiaryId: $id) {
    id
    status
    invitationLastSentTime
  }
}
    `;
export type SendNewInviteToBeneficiaryMutationFn = Apollo.MutationFunction<SendNewInviteToBeneficiaryMutation, SendNewInviteToBeneficiaryMutationVariables>;

/**
 * __useSendNewInviteToBeneficiaryMutation__
 *
 * To run a mutation, you first call `useSendNewInviteToBeneficiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewInviteToBeneficiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewInviteToBeneficiaryMutation, { data, loading, error }] = useSendNewInviteToBeneficiaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendNewInviteToBeneficiaryMutation(baseOptions?: Apollo.MutationHookOptions<SendNewInviteToBeneficiaryMutation, SendNewInviteToBeneficiaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNewInviteToBeneficiaryMutation, SendNewInviteToBeneficiaryMutationVariables>(SendNewInviteToBeneficiaryDocument, options);
      }
export type SendNewInviteToBeneficiaryMutationHookResult = ReturnType<typeof useSendNewInviteToBeneficiaryMutation>;
export type SendNewInviteToBeneficiaryMutationResult = Apollo.MutationResult<SendNewInviteToBeneficiaryMutation>;
export type SendNewInviteToBeneficiaryMutationOptions = Apollo.BaseMutationOptions<SendNewInviteToBeneficiaryMutation, SendNewInviteToBeneficiaryMutationVariables>;
export const RegisterBeneficiaryByInvitationCodeDocument = gql`
    mutation registerBeneficiaryByInvitationCode($input: BeneficiaryCreateByInviteCodeInput!) {
  registerBeneficiaryByInvitationCode(input: $input) {
    token
    account {
      id
      email
    }
  }
}
    `;
export type RegisterBeneficiaryByInvitationCodeMutationFn = Apollo.MutationFunction<RegisterBeneficiaryByInvitationCodeMutation, RegisterBeneficiaryByInvitationCodeMutationVariables>;

/**
 * __useRegisterBeneficiaryByInvitationCodeMutation__
 *
 * To run a mutation, you first call `useRegisterBeneficiaryByInvitationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterBeneficiaryByInvitationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerBeneficiaryByInvitationCodeMutation, { data, loading, error }] = useRegisterBeneficiaryByInvitationCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterBeneficiaryByInvitationCodeMutation(baseOptions?: Apollo.MutationHookOptions<RegisterBeneficiaryByInvitationCodeMutation, RegisterBeneficiaryByInvitationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterBeneficiaryByInvitationCodeMutation, RegisterBeneficiaryByInvitationCodeMutationVariables>(RegisterBeneficiaryByInvitationCodeDocument, options);
      }
export type RegisterBeneficiaryByInvitationCodeMutationHookResult = ReturnType<typeof useRegisterBeneficiaryByInvitationCodeMutation>;
export type RegisterBeneficiaryByInvitationCodeMutationResult = Apollo.MutationResult<RegisterBeneficiaryByInvitationCodeMutation>;
export type RegisterBeneficiaryByInvitationCodeMutationOptions = Apollo.BaseMutationOptions<RegisterBeneficiaryByInvitationCodeMutation, RegisterBeneficiaryByInvitationCodeMutationVariables>;
export const RegenerateBeneficiaryInviteDocument = gql`
    mutation regenerateBeneficiaryInvite($beneficiaryProfileId: Int!) {
  regenerateBeneficiaryInvite(beneficiaryProfileId: $beneficiaryProfileId) {
    account {
      id
    }
    invitationCode
  }
}
    `;
export type RegenerateBeneficiaryInviteMutationFn = Apollo.MutationFunction<RegenerateBeneficiaryInviteMutation, RegenerateBeneficiaryInviteMutationVariables>;

/**
 * __useRegenerateBeneficiaryInviteMutation__
 *
 * To run a mutation, you first call `useRegenerateBeneficiaryInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateBeneficiaryInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateBeneficiaryInviteMutation, { data, loading, error }] = useRegenerateBeneficiaryInviteMutation({
 *   variables: {
 *      beneficiaryProfileId: // value for 'beneficiaryProfileId'
 *   },
 * });
 */
export function useRegenerateBeneficiaryInviteMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateBeneficiaryInviteMutation, RegenerateBeneficiaryInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateBeneficiaryInviteMutation, RegenerateBeneficiaryInviteMutationVariables>(RegenerateBeneficiaryInviteDocument, options);
      }
export type RegenerateBeneficiaryInviteMutationHookResult = ReturnType<typeof useRegenerateBeneficiaryInviteMutation>;
export type RegenerateBeneficiaryInviteMutationResult = Apollo.MutationResult<RegenerateBeneficiaryInviteMutation>;
export type RegenerateBeneficiaryInviteMutationOptions = Apollo.BaseMutationOptions<RegenerateBeneficiaryInviteMutation, RegenerateBeneficiaryInviteMutationVariables>;
export const GenerateEncryptedMessageDocument = gql`
    mutation generateEncryptedMessage($publicKey: String!) {
  generateEncryptedMessage(publicKey: $publicKey)
}
    `;
export type GenerateEncryptedMessageMutationFn = Apollo.MutationFunction<GenerateEncryptedMessageMutation, GenerateEncryptedMessageMutationVariables>;

/**
 * __useGenerateEncryptedMessageMutation__
 *
 * To run a mutation, you first call `useGenerateEncryptedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEncryptedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEncryptedMessageMutation, { data, loading, error }] = useGenerateEncryptedMessageMutation({
 *   variables: {
 *      publicKey: // value for 'publicKey'
 *   },
 * });
 */
export function useGenerateEncryptedMessageMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEncryptedMessageMutation, GenerateEncryptedMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEncryptedMessageMutation, GenerateEncryptedMessageMutationVariables>(GenerateEncryptedMessageDocument, options);
      }
export type GenerateEncryptedMessageMutationHookResult = ReturnType<typeof useGenerateEncryptedMessageMutation>;
export type GenerateEncryptedMessageMutationResult = Apollo.MutationResult<GenerateEncryptedMessageMutation>;
export type GenerateEncryptedMessageMutationOptions = Apollo.BaseMutationOptions<GenerateEncryptedMessageMutation, GenerateEncryptedMessageMutationVariables>;
export const CreateLogDocument = gql`
    mutation CreateLog($publicData: String!, $encryptedData: String!) {
  createEventLog(publicData: $publicData, encryptedData: $encryptedData) {
    id
    createdAt
    mainUserId
    publicData
    encryptedData
  }
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      publicData: // value for 'publicData'
 *      encryptedData: // value for 'encryptedData'
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const RequestExportDocument = gql`
    mutation RequestExport($basicAuthLogin: String!, $basicAuthPassword: String!, $awsAccessKeyId: String, $awsSecretAccessKey: String, $hubType: String) {
  requestExport(
    basicAuthLogin: $basicAuthLogin
    basicAuthPassword: $basicAuthPassword
    awsAccessKeyId: $awsAccessKeyId
    awsSecretAccessKey: $awsSecretAccessKey
    hubType: $hubType
  )
}
    `;
export type RequestExportMutationFn = Apollo.MutationFunction<RequestExportMutation, RequestExportMutationVariables>;

/**
 * __useRequestExportMutation__
 *
 * To run a mutation, you first call `useRequestExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestExportMutation, { data, loading, error }] = useRequestExportMutation({
 *   variables: {
 *      basicAuthLogin: // value for 'basicAuthLogin'
 *      basicAuthPassword: // value for 'basicAuthPassword'
 *      awsAccessKeyId: // value for 'awsAccessKeyId'
 *      awsSecretAccessKey: // value for 'awsSecretAccessKey'
 *      hubType: // value for 'hubType'
 *   },
 * });
 */
export function useRequestExportMutation(baseOptions?: Apollo.MutationHookOptions<RequestExportMutation, RequestExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestExportMutation, RequestExportMutationVariables>(RequestExportDocument, options);
      }
export type RequestExportMutationHookResult = ReturnType<typeof useRequestExportMutation>;
export type RequestExportMutationResult = Apollo.MutationResult<RequestExportMutation>;
export type RequestExportMutationOptions = Apollo.BaseMutationOptions<RequestExportMutation, RequestExportMutationVariables>;
export const UploadFileDocument = gql`
    mutation uploadFile($fileContent: String!) {
  uploadFile(fileContent: $fileContent)
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      fileContent: // value for 'fileContent'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($filename: String!) {
  deleteFile(filename: $filename)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!, $totpToken: String) {
  login(data: {email: $email, password: $password}, totpToken: $totpToken) {
    token
    account {
      id
      email
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      totpToken: // value for 'totpToken'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoginViaPersonalAccountCodeDocument = gql`
    mutation loginViaPersonalAccountCode($code: String!) {
  loginViaPersonalAccountCode(code: $code) {
    token
    account {
      id
      email
    }
  }
}
    `;
export type LoginViaPersonalAccountCodeMutationFn = Apollo.MutationFunction<LoginViaPersonalAccountCodeMutation, LoginViaPersonalAccountCodeMutationVariables>;

/**
 * __useLoginViaPersonalAccountCodeMutation__
 *
 * To run a mutation, you first call `useLoginViaPersonalAccountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaPersonalAccountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaPersonalAccountCodeMutation, { data, loading, error }] = useLoginViaPersonalAccountCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginViaPersonalAccountCodeMutation(baseOptions?: Apollo.MutationHookOptions<LoginViaPersonalAccountCodeMutation, LoginViaPersonalAccountCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginViaPersonalAccountCodeMutation, LoginViaPersonalAccountCodeMutationVariables>(LoginViaPersonalAccountCodeDocument, options);
      }
export type LoginViaPersonalAccountCodeMutationHookResult = ReturnType<typeof useLoginViaPersonalAccountCodeMutation>;
export type LoginViaPersonalAccountCodeMutationResult = Apollo.MutationResult<LoginViaPersonalAccountCodeMutation>;
export type LoginViaPersonalAccountCodeMutationOptions = Apollo.BaseMutationOptions<LoginViaPersonalAccountCodeMutation, LoginViaPersonalAccountCodeMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordByCodeDocument = gql`
    mutation resetPasswordByCode($email: String!, $code: String!, $newPassword: String!, $twoFactorQuestionId: Int!, $twoFactorQuestionAnswer: String!) {
  resetPasswordByCode(
    email: $email
    code: $code
    newPassword: $newPassword
    twoFactorQuestionId: $twoFactorQuestionId
    twoFactorQuestionAnswer: $twoFactorQuestionAnswer
  ) {
    id
  }
}
    `;
export type ResetPasswordByCodeMutationFn = Apollo.MutationFunction<ResetPasswordByCodeMutation, ResetPasswordByCodeMutationVariables>;

/**
 * __useResetPasswordByCodeMutation__
 *
 * To run a mutation, you first call `useResetPasswordByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordByCodeMutation, { data, loading, error }] = useResetPasswordByCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *      newPassword: // value for 'newPassword'
 *      twoFactorQuestionId: // value for 'twoFactorQuestionId'
 *      twoFactorQuestionAnswer: // value for 'twoFactorQuestionAnswer'
 *   },
 * });
 */
export function useResetPasswordByCodeMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordByCodeMutation, ResetPasswordByCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordByCodeMutation, ResetPasswordByCodeMutationVariables>(ResetPasswordByCodeDocument, options);
      }
export type ResetPasswordByCodeMutationHookResult = ReturnType<typeof useResetPasswordByCodeMutation>;
export type ResetPasswordByCodeMutationResult = Apollo.MutationResult<ResetPasswordByCodeMutation>;
export type ResetPasswordByCodeMutationOptions = Apollo.BaseMutationOptions<ResetPasswordByCodeMutation, ResetPasswordByCodeMutationVariables>;
export const LoginViaDecryptedMessageDocument = gql`
    mutation loginViaDecryptedMessage($decryptedMessage: String!) {
  loginViaDecryptedMessage(decryptedMessage: $decryptedMessage) {
    token
    account {
      id
      email
    }
  }
}
    `;
export type LoginViaDecryptedMessageMutationFn = Apollo.MutationFunction<LoginViaDecryptedMessageMutation, LoginViaDecryptedMessageMutationVariables>;

/**
 * __useLoginViaDecryptedMessageMutation__
 *
 * To run a mutation, you first call `useLoginViaDecryptedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaDecryptedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaDecryptedMessageMutation, { data, loading, error }] = useLoginViaDecryptedMessageMutation({
 *   variables: {
 *      decryptedMessage: // value for 'decryptedMessage'
 *   },
 * });
 */
export function useLoginViaDecryptedMessageMutation(baseOptions?: Apollo.MutationHookOptions<LoginViaDecryptedMessageMutation, LoginViaDecryptedMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginViaDecryptedMessageMutation, LoginViaDecryptedMessageMutationVariables>(LoginViaDecryptedMessageDocument, options);
      }
export type LoginViaDecryptedMessageMutationHookResult = ReturnType<typeof useLoginViaDecryptedMessageMutation>;
export type LoginViaDecryptedMessageMutationResult = Apollo.MutationResult<LoginViaDecryptedMessageMutation>;
export type LoginViaDecryptedMessageMutationOptions = Apollo.BaseMutationOptions<LoginViaDecryptedMessageMutation, LoginViaDecryptedMessageMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($sessionIds: [Float!]!) {
  logout(sessionIds: $sessionIds)
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      sessionIds: // value for 'sessionIds'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GenerateSmsCodeResponseDocument = gql`
    mutation generateSmsCodeResponse($number: String!) {
  generateSmsCodeResponse(number: $number)
}
    `;
export type GenerateSmsCodeResponseMutationFn = Apollo.MutationFunction<GenerateSmsCodeResponseMutation, GenerateSmsCodeResponseMutationVariables>;

/**
 * __useGenerateSmsCodeResponseMutation__
 *
 * To run a mutation, you first call `useGenerateSmsCodeResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSmsCodeResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSmsCodeResponseMutation, { data, loading, error }] = useGenerateSmsCodeResponseMutation({
 *   variables: {
 *      number: // value for 'number'
 *   },
 * });
 */
export function useGenerateSmsCodeResponseMutation(baseOptions?: Apollo.MutationHookOptions<GenerateSmsCodeResponseMutation, GenerateSmsCodeResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateSmsCodeResponseMutation, GenerateSmsCodeResponseMutationVariables>(GenerateSmsCodeResponseDocument, options);
      }
export type GenerateSmsCodeResponseMutationHookResult = ReturnType<typeof useGenerateSmsCodeResponseMutation>;
export type GenerateSmsCodeResponseMutationResult = Apollo.MutationResult<GenerateSmsCodeResponseMutation>;
export type GenerateSmsCodeResponseMutationOptions = Apollo.BaseMutationOptions<GenerateSmsCodeResponseMutation, GenerateSmsCodeResponseMutationVariables>;
export const CreateEmailAccountDocument = gql`
    mutation createEmailAccount($username: String!, $password: String!) {
  createEmailAccount(username: $username, password: $password) {
    email
  }
}
    `;
export type CreateEmailAccountMutationFn = Apollo.MutationFunction<CreateEmailAccountMutation, CreateEmailAccountMutationVariables>;

/**
 * __useCreateEmailAccountMutation__
 *
 * To run a mutation, you first call `useCreateEmailAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailAccountMutation, { data, loading, error }] = useCreateEmailAccountMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateEmailAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailAccountMutation, CreateEmailAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailAccountMutation, CreateEmailAccountMutationVariables>(CreateEmailAccountDocument, options);
      }
export type CreateEmailAccountMutationHookResult = ReturnType<typeof useCreateEmailAccountMutation>;
export type CreateEmailAccountMutationResult = Apollo.MutationResult<CreateEmailAccountMutation>;
export type CreateEmailAccountMutationOptions = Apollo.BaseMutationOptions<CreateEmailAccountMutation, CreateEmailAccountMutationVariables>;
export const DeleteEmailAccountDocument = gql`
    mutation deleteEmailAccount($username: String!) {
  deleteEmailAccount(username: $username) {
    email
  }
}
    `;
export type DeleteEmailAccountMutationFn = Apollo.MutationFunction<DeleteEmailAccountMutation, DeleteEmailAccountMutationVariables>;

/**
 * __useDeleteEmailAccountMutation__
 *
 * To run a mutation, you first call `useDeleteEmailAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailAccountMutation, { data, loading, error }] = useDeleteEmailAccountMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useDeleteEmailAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailAccountMutation, DeleteEmailAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailAccountMutation, DeleteEmailAccountMutationVariables>(DeleteEmailAccountDocument, options);
      }
export type DeleteEmailAccountMutationHookResult = ReturnType<typeof useDeleteEmailAccountMutation>;
export type DeleteEmailAccountMutationResult = Apollo.MutationResult<DeleteEmailAccountMutation>;
export type DeleteEmailAccountMutationOptions = Apollo.BaseMutationOptions<DeleteEmailAccountMutation, DeleteEmailAccountMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $newPassword: String!) {
  changePassword(password: $password, newPassword: $newPassword) {
    id
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeSoweAppendixSuppressCancelConfirmationDocument = gql`
    mutation changeSoweAppendixSuppressCancelConfirmation($soweAppendixSuppressCancelConfirmation: Boolean!) {
  updateProfile(
    input: {soweAppendixSuppressCancelConfirmation: $soweAppendixSuppressCancelConfirmation}
  ) {
    soweAppendixSuppressCancelConfirmation
  }
}
    `;
export type ChangeSoweAppendixSuppressCancelConfirmationMutationFn = Apollo.MutationFunction<ChangeSoweAppendixSuppressCancelConfirmationMutation, ChangeSoweAppendixSuppressCancelConfirmationMutationVariables>;

/**
 * __useChangeSoweAppendixSuppressCancelConfirmationMutation__
 *
 * To run a mutation, you first call `useChangeSoweAppendixSuppressCancelConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSoweAppendixSuppressCancelConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSoweAppendixSuppressCancelConfirmationMutation, { data, loading, error }] = useChangeSoweAppendixSuppressCancelConfirmationMutation({
 *   variables: {
 *      soweAppendixSuppressCancelConfirmation: // value for 'soweAppendixSuppressCancelConfirmation'
 *   },
 * });
 */
export function useChangeSoweAppendixSuppressCancelConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSoweAppendixSuppressCancelConfirmationMutation, ChangeSoweAppendixSuppressCancelConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSoweAppendixSuppressCancelConfirmationMutation, ChangeSoweAppendixSuppressCancelConfirmationMutationVariables>(ChangeSoweAppendixSuppressCancelConfirmationDocument, options);
      }
export type ChangeSoweAppendixSuppressCancelConfirmationMutationHookResult = ReturnType<typeof useChangeSoweAppendixSuppressCancelConfirmationMutation>;
export type ChangeSoweAppendixSuppressCancelConfirmationMutationResult = Apollo.MutationResult<ChangeSoweAppendixSuppressCancelConfirmationMutation>;
export type ChangeSoweAppendixSuppressCancelConfirmationMutationOptions = Apollo.BaseMutationOptions<ChangeSoweAppendixSuppressCancelConfirmationMutation, ChangeSoweAppendixSuppressCancelConfirmationMutationVariables>;
export const ChangeOcrSuppressLetsTryConfirmationDocument = gql`
    mutation changeOcrSuppressLetsTryConfirmation($ocrSuppressLetsTryConfirmation: Boolean!) {
  updateProfile(
    input: {ocrSuppressLetsTryConfirmation: $ocrSuppressLetsTryConfirmation}
  ) {
    ocrSuppressLetsTryConfirmation
  }
}
    `;
export type ChangeOcrSuppressLetsTryConfirmationMutationFn = Apollo.MutationFunction<ChangeOcrSuppressLetsTryConfirmationMutation, ChangeOcrSuppressLetsTryConfirmationMutationVariables>;

/**
 * __useChangeOcrSuppressLetsTryConfirmationMutation__
 *
 * To run a mutation, you first call `useChangeOcrSuppressLetsTryConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOcrSuppressLetsTryConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOcrSuppressLetsTryConfirmationMutation, { data, loading, error }] = useChangeOcrSuppressLetsTryConfirmationMutation({
 *   variables: {
 *      ocrSuppressLetsTryConfirmation: // value for 'ocrSuppressLetsTryConfirmation'
 *   },
 * });
 */
export function useChangeOcrSuppressLetsTryConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOcrSuppressLetsTryConfirmationMutation, ChangeOcrSuppressLetsTryConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOcrSuppressLetsTryConfirmationMutation, ChangeOcrSuppressLetsTryConfirmationMutationVariables>(ChangeOcrSuppressLetsTryConfirmationDocument, options);
      }
export type ChangeOcrSuppressLetsTryConfirmationMutationHookResult = ReturnType<typeof useChangeOcrSuppressLetsTryConfirmationMutation>;
export type ChangeOcrSuppressLetsTryConfirmationMutationResult = Apollo.MutationResult<ChangeOcrSuppressLetsTryConfirmationMutation>;
export type ChangeOcrSuppressLetsTryConfirmationMutationOptions = Apollo.BaseMutationOptions<ChangeOcrSuppressLetsTryConfirmationMutation, ChangeOcrSuppressLetsTryConfirmationMutationVariables>;
export const UpdateProfileNamesDocument = gql`
    mutation updateProfileNames($firstName: String, $lastName: String) {
  updateProfile(input: {firstName: $firstName, lastName: $lastName}) {
    firstName
    lastName
  }
}
    `;
export type UpdateProfileNamesMutationFn = Apollo.MutationFunction<UpdateProfileNamesMutation, UpdateProfileNamesMutationVariables>;

/**
 * __useUpdateProfileNamesMutation__
 *
 * To run a mutation, you first call `useUpdateProfileNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileNamesMutation, { data, loading, error }] = useUpdateProfileNamesMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUpdateProfileNamesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileNamesMutation, UpdateProfileNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileNamesMutation, UpdateProfileNamesMutationVariables>(UpdateProfileNamesDocument, options);
      }
export type UpdateProfileNamesMutationHookResult = ReturnType<typeof useUpdateProfileNamesMutation>;
export type UpdateProfileNamesMutationResult = Apollo.MutationResult<UpdateProfileNamesMutation>;
export type UpdateProfileNamesMutationOptions = Apollo.BaseMutationOptions<UpdateProfileNamesMutation, UpdateProfileNamesMutationVariables>;
export const VerifyPasswordDocument = gql`
    mutation verifyPassword($password: String!) {
  verifyPassword(password: $password)
}
    `;
export type VerifyPasswordMutationFn = Apollo.MutationFunction<VerifyPasswordMutation, VerifyPasswordMutationVariables>;

/**
 * __useVerifyPasswordMutation__
 *
 * To run a mutation, you first call `useVerifyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPasswordMutation, { data, loading, error }] = useVerifyPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useVerifyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPasswordMutation, VerifyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPasswordMutation, VerifyPasswordMutationVariables>(VerifyPasswordDocument, options);
      }
export type VerifyPasswordMutationHookResult = ReturnType<typeof useVerifyPasswordMutation>;
export type VerifyPasswordMutationResult = Apollo.MutationResult<VerifyPasswordMutation>;
export type VerifyPasswordMutationOptions = Apollo.BaseMutationOptions<VerifyPasswordMutation, VerifyPasswordMutationVariables>;
export const BlockAccountDocument = gql`
    mutation blockAccount($emailCode: String!) {
  blockAccount(emailCode: $emailCode)
}
    `;
export type BlockAccountMutationFn = Apollo.MutationFunction<BlockAccountMutation, BlockAccountMutationVariables>;

/**
 * __useBlockAccountMutation__
 *
 * To run a mutation, you first call `useBlockAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockAccountMutation, { data, loading, error }] = useBlockAccountMutation({
 *   variables: {
 *      emailCode: // value for 'emailCode'
 *   },
 * });
 */
export function useBlockAccountMutation(baseOptions?: Apollo.MutationHookOptions<BlockAccountMutation, BlockAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockAccountMutation, BlockAccountMutationVariables>(BlockAccountDocument, options);
      }
export type BlockAccountMutationHookResult = ReturnType<typeof useBlockAccountMutation>;
export type BlockAccountMutationResult = Apollo.MutationResult<BlockAccountMutation>;
export type BlockAccountMutationOptions = Apollo.BaseMutationOptions<BlockAccountMutation, BlockAccountMutationVariables>;
export const PushCollectionDocument = gql`
    mutation PushCollection($name: String!, $data: String!) {
  pushCollection(name: $name, data: $data)
}
    `;
export type PushCollectionMutationFn = Apollo.MutationFunction<PushCollectionMutation, PushCollectionMutationVariables>;

/**
 * __usePushCollectionMutation__
 *
 * To run a mutation, you first call `usePushCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushCollectionMutation, { data, loading, error }] = usePushCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePushCollectionMutation(baseOptions?: Apollo.MutationHookOptions<PushCollectionMutation, PushCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushCollectionMutation, PushCollectionMutationVariables>(PushCollectionDocument, options);
      }
export type PushCollectionMutationHookResult = ReturnType<typeof usePushCollectionMutation>;
export type PushCollectionMutationResult = Apollo.MutationResult<PushCollectionMutation>;
export type PushCollectionMutationOptions = Apollo.BaseMutationOptions<PushCollectionMutation, PushCollectionMutationVariables>;
export const PushFileEncryptedSymmetricKeyDocument = gql`
    mutation pushFileEncryptedSymmetricKey($filePath: String!, $metaInfo: FileMetaInfoInputType!, $encryptedFileSymmetricKey: String!, $encryptedSymmetricKey: String!, $publicKey: String!) {
  pushFileEncryptedSymmetricKey(
    filePath: $filePath
    metaInfo: $metaInfo
    encryptedFileSymmetricKey: $encryptedFileSymmetricKey
    encryptedSymmetricKey: $encryptedSymmetricKey
    publicKey: $publicKey
  )
}
    `;
export type PushFileEncryptedSymmetricKeyMutationFn = Apollo.MutationFunction<PushFileEncryptedSymmetricKeyMutation, PushFileEncryptedSymmetricKeyMutationVariables>;

/**
 * __usePushFileEncryptedSymmetricKeyMutation__
 *
 * To run a mutation, you first call `usePushFileEncryptedSymmetricKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushFileEncryptedSymmetricKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushFileEncryptedSymmetricKeyMutation, { data, loading, error }] = usePushFileEncryptedSymmetricKeyMutation({
 *   variables: {
 *      filePath: // value for 'filePath'
 *      metaInfo: // value for 'metaInfo'
 *      encryptedFileSymmetricKey: // value for 'encryptedFileSymmetricKey'
 *      encryptedSymmetricKey: // value for 'encryptedSymmetricKey'
 *      publicKey: // value for 'publicKey'
 *   },
 * });
 */
export function usePushFileEncryptedSymmetricKeyMutation(baseOptions?: Apollo.MutationHookOptions<PushFileEncryptedSymmetricKeyMutation, PushFileEncryptedSymmetricKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PushFileEncryptedSymmetricKeyMutation, PushFileEncryptedSymmetricKeyMutationVariables>(PushFileEncryptedSymmetricKeyDocument, options);
      }
export type PushFileEncryptedSymmetricKeyMutationHookResult = ReturnType<typeof usePushFileEncryptedSymmetricKeyMutation>;
export type PushFileEncryptedSymmetricKeyMutationResult = Apollo.MutationResult<PushFileEncryptedSymmetricKeyMutation>;
export type PushFileEncryptedSymmetricKeyMutationOptions = Apollo.BaseMutationOptions<PushFileEncryptedSymmetricKeyMutation, PushFileEncryptedSymmetricKeyMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($email: String!, $password: String!, $publicKey: String!, $firstName: String!, $lastName: String!, $twoFactorQuestions: [TwoFactorQuestionPlainObject!]!, $language: String!) {
  register(
    data: {email: $email, password: $password, publicKey: $publicKey, firstName: $firstName, lastName: $lastName, twoFactorQuestions: $twoFactorQuestions, meta: {language: $language}}
  ) {
    token
    account {
      id
      email
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      publicKey: // value for 'publicKey'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      twoFactorQuestions: // value for 'twoFactorQuestions'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const GenerateEmailCodeDocument = gql`
    mutation generateEmailCode($email: String!, $typeEmailCode: TypeEmailCode!) {
  generateEmailCode(data: {email: $email, typeEmailCode: $typeEmailCode}) {
    result
    expiresAt
  }
}
    `;
export type GenerateEmailCodeMutationFn = Apollo.MutationFunction<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>;

/**
 * __useGenerateEmailCodeMutation__
 *
 * To run a mutation, you first call `useGenerateEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmailCodeMutation, { data, loading, error }] = useGenerateEmailCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      typeEmailCode: // value for 'typeEmailCode'
 *   },
 * });
 */
export function useGenerateEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>(GenerateEmailCodeDocument, options);
      }
export type GenerateEmailCodeMutationHookResult = ReturnType<typeof useGenerateEmailCodeMutation>;
export type GenerateEmailCodeMutationResult = Apollo.MutationResult<GenerateEmailCodeMutation>;
export type GenerateEmailCodeMutationOptions = Apollo.BaseMutationOptions<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>;
export const ActivateAccountDocument = gql`
    mutation activateAccount($email: String!, $phoneNumber: String, $code: String!, $codeSms: String) {
  activateAccount(
    data: {email: $email, phoneNumber: $phoneNumber, code: $code, codeSms: $codeSms}
  ) {
    email
    twoFactorQuestions {
      question
    }
  }
}
    `;
export type ActivateAccountMutationFn = Apollo.MutationFunction<ActivateAccountMutation, ActivateAccountMutationVariables>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *      codeSms: // value for 'codeSms'
 *   },
 * });
 */
export function useActivateAccountMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(ActivateAccountDocument, options);
      }
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<ActivateAccountMutation, ActivateAccountMutationVariables>;
export const ActivateBeneficiaryProfileDocument = gql`
    mutation activateBeneficiaryProfile($jwt: String!, $publicKey: String!, $password: String!, $twoFactorQuestions: [TwoFactorQuestionPlainObject!]!) {
  activateBeneficiaryProfile(
    data: {jwt: $jwt, publicKey: $publicKey, password: $password, twoFactorQuestions: $twoFactorQuestions}
  ) {
    email
  }
}
    `;
export type ActivateBeneficiaryProfileMutationFn = Apollo.MutationFunction<ActivateBeneficiaryProfileMutation, ActivateBeneficiaryProfileMutationVariables>;

/**
 * __useActivateBeneficiaryProfileMutation__
 *
 * To run a mutation, you first call `useActivateBeneficiaryProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBeneficiaryProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBeneficiaryProfileMutation, { data, loading, error }] = useActivateBeneficiaryProfileMutation({
 *   variables: {
 *      jwt: // value for 'jwt'
 *      publicKey: // value for 'publicKey'
 *      password: // value for 'password'
 *      twoFactorQuestions: // value for 'twoFactorQuestions'
 *   },
 * });
 */
export function useActivateBeneficiaryProfileMutation(baseOptions?: Apollo.MutationHookOptions<ActivateBeneficiaryProfileMutation, ActivateBeneficiaryProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateBeneficiaryProfileMutation, ActivateBeneficiaryProfileMutationVariables>(ActivateBeneficiaryProfileDocument, options);
      }
export type ActivateBeneficiaryProfileMutationHookResult = ReturnType<typeof useActivateBeneficiaryProfileMutation>;
export type ActivateBeneficiaryProfileMutationResult = Apollo.MutationResult<ActivateBeneficiaryProfileMutation>;
export type ActivateBeneficiaryProfileMutationOptions = Apollo.BaseMutationOptions<ActivateBeneficiaryProfileMutation, ActivateBeneficiaryProfileMutationVariables>;
export const ActivateAccountAfterExternalRegistrationDocument = gql`
    mutation activateAccountAfterExternalRegistration($publicKey: String!) {
  activateAccountAfterExternalRegistration(publicKey: $publicKey)
}
    `;
export type ActivateAccountAfterExternalRegistrationMutationFn = Apollo.MutationFunction<ActivateAccountAfterExternalRegistrationMutation, ActivateAccountAfterExternalRegistrationMutationVariables>;

/**
 * __useActivateAccountAfterExternalRegistrationMutation__
 *
 * To run a mutation, you first call `useActivateAccountAfterExternalRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountAfterExternalRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountAfterExternalRegistrationMutation, { data, loading, error }] = useActivateAccountAfterExternalRegistrationMutation({
 *   variables: {
 *      publicKey: // value for 'publicKey'
 *   },
 * });
 */
export function useActivateAccountAfterExternalRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ActivateAccountAfterExternalRegistrationMutation, ActivateAccountAfterExternalRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateAccountAfterExternalRegistrationMutation, ActivateAccountAfterExternalRegistrationMutationVariables>(ActivateAccountAfterExternalRegistrationDocument, options);
      }
export type ActivateAccountAfterExternalRegistrationMutationHookResult = ReturnType<typeof useActivateAccountAfterExternalRegistrationMutation>;
export type ActivateAccountAfterExternalRegistrationMutationResult = Apollo.MutationResult<ActivateAccountAfterExternalRegistrationMutation>;
export type ActivateAccountAfterExternalRegistrationMutationOptions = Apollo.BaseMutationOptions<ActivateAccountAfterExternalRegistrationMutation, ActivateAccountAfterExternalRegistrationMutationVariables>;
export const IsBeneficiaryProfileExistsDocument = gql`
    query isBeneficiaryProfileExists($jwt: String!, $email: String!) {
  isBeneficiaryProfileExists(jwt: $jwt, email: $email)
}
    `;

/**
 * __useIsBeneficiaryProfileExistsQuery__
 *
 * To run a query within a React component, call `useIsBeneficiaryProfileExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBeneficiaryProfileExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBeneficiaryProfileExistsQuery({
 *   variables: {
 *      jwt: // value for 'jwt'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsBeneficiaryProfileExistsQuery(baseOptions: Apollo.QueryHookOptions<IsBeneficiaryProfileExistsQuery, IsBeneficiaryProfileExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsBeneficiaryProfileExistsQuery, IsBeneficiaryProfileExistsQueryVariables>(IsBeneficiaryProfileExistsDocument, options);
      }
export function useIsBeneficiaryProfileExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsBeneficiaryProfileExistsQuery, IsBeneficiaryProfileExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsBeneficiaryProfileExistsQuery, IsBeneficiaryProfileExistsQueryVariables>(IsBeneficiaryProfileExistsDocument, options);
        }
export type IsBeneficiaryProfileExistsQueryHookResult = ReturnType<typeof useIsBeneficiaryProfileExistsQuery>;
export type IsBeneficiaryProfileExistsLazyQueryHookResult = ReturnType<typeof useIsBeneficiaryProfileExistsLazyQuery>;
export type IsBeneficiaryProfileExistsQueryResult = Apollo.QueryResult<IsBeneficiaryProfileExistsQuery, IsBeneficiaryProfileExistsQueryVariables>;
export const CreateScenarioDocument = gql`
    mutation createScenario($beneficiaries: [IBeneficiaryInput!]!, $triggerValue: Float!, $assetAndRelatedDtoIds: [String!]!, $triggerType: ScenarioTriggerType!, $mode: ScenarioMode, $notificationValue: Float!, $notificationPeriod: Float!, $notificationTime: DateTime!) {
  createScenario(
    beneficiaries: $beneficiaries
    triggerValue: $triggerValue
    assetAndRelatedDtoIds: $assetAndRelatedDtoIds
    triggerType: $triggerType
    mode: $mode
    notificationValue: $notificationValue
    notificationPeriod: $notificationPeriod
    notificationTime: $notificationTime
  ) {
    id
    createdAt
    updatedAt
    mainUserId
    dtoIds
    mode
    triggerType
    triggerValue
    status
    mainUser {
      id
      firstName
      lastName
    }
    beneficiaries {
      beneficiaryId
      beneficiary {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type CreateScenarioMutationFn = Apollo.MutationFunction<CreateScenarioMutation, CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      beneficiaries: // value for 'beneficiaries'
 *      triggerValue: // value for 'triggerValue'
 *      assetAndRelatedDtoIds: // value for 'assetAndRelatedDtoIds'
 *      triggerType: // value for 'triggerType'
 *      mode: // value for 'mode'
 *      notificationValue: // value for 'notificationValue'
 *      notificationPeriod: // value for 'notificationPeriod'
 *      notificationTime: // value for 'notificationTime'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(CreateScenarioDocument, options);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = Apollo.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<CreateScenarioMutation, CreateScenarioMutationVariables>;
export const UpdateScenarioDocument = gql`
    mutation updateScenario($scenarioId: String!, $input: ScenarioUpdateInput!) {
  updateScenario(scenarioId: $scenarioId, input: $input) {
    id
    createdAt
    updatedAt
    mainUserId
    dtoIds
    mode
    triggerType
    triggerValue
    status
    mainUser {
      id
      firstName
      lastName
    }
    beneficiaries {
      beneficiaryId
      beneficiary {
        id
        firstName
        lastName
      }
    }
  }
}
    `;
export type UpdateScenarioMutationFn = Apollo.MutationFunction<UpdateScenarioMutation, UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(UpdateScenarioDocument, options);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = Apollo.MutationResult<UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>;
export const ActivateScenarioDocument = gql`
    mutation activateScenario($scenarioId: String!) {
  activateScenario(scenarioId: $scenarioId) {
    id
  }
}
    `;
export type ActivateScenarioMutationFn = Apollo.MutationFunction<ActivateScenarioMutation, ActivateScenarioMutationVariables>;

/**
 * __useActivateScenarioMutation__
 *
 * To run a mutation, you first call `useActivateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateScenarioMutation, { data, loading, error }] = useActivateScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useActivateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<ActivateScenarioMutation, ActivateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateScenarioMutation, ActivateScenarioMutationVariables>(ActivateScenarioDocument, options);
      }
export type ActivateScenarioMutationHookResult = ReturnType<typeof useActivateScenarioMutation>;
export type ActivateScenarioMutationResult = Apollo.MutationResult<ActivateScenarioMutation>;
export type ActivateScenarioMutationOptions = Apollo.BaseMutationOptions<ActivateScenarioMutation, ActivateScenarioMutationVariables>;
export const CancelScenarioDocument = gql`
    mutation cancelScenario($scenarioId: String!) {
  cancelScenario(scenarioId: $scenarioId) {
    id
  }
}
    `;
export type CancelScenarioMutationFn = Apollo.MutationFunction<CancelScenarioMutation, CancelScenarioMutationVariables>;

/**
 * __useCancelScenarioMutation__
 *
 * To run a mutation, you first call `useCancelScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScenarioMutation, { data, loading, error }] = useCancelScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useCancelScenarioMutation(baseOptions?: Apollo.MutationHookOptions<CancelScenarioMutation, CancelScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelScenarioMutation, CancelScenarioMutationVariables>(CancelScenarioDocument, options);
      }
export type CancelScenarioMutationHookResult = ReturnType<typeof useCancelScenarioMutation>;
export type CancelScenarioMutationResult = Apollo.MutationResult<CancelScenarioMutation>;
export type CancelScenarioMutationOptions = Apollo.BaseMutationOptions<CancelScenarioMutation, CancelScenarioMutationVariables>;
export const RemoveScenarioDocument = gql`
    mutation removeScenario($scenarioId: String!) {
  removeScenario(scenarioId: $scenarioId)
}
    `;
export type RemoveScenarioMutationFn = Apollo.MutationFunction<RemoveScenarioMutation, RemoveScenarioMutationVariables>;

/**
 * __useRemoveScenarioMutation__
 *
 * To run a mutation, you first call `useRemoveScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScenarioMutation, { data, loading, error }] = useRemoveScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useRemoveScenarioMutation(baseOptions?: Apollo.MutationHookOptions<RemoveScenarioMutation, RemoveScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveScenarioMutation, RemoveScenarioMutationVariables>(RemoveScenarioDocument, options);
      }
export type RemoveScenarioMutationHookResult = ReturnType<typeof useRemoveScenarioMutation>;
export type RemoveScenarioMutationResult = Apollo.MutationResult<RemoveScenarioMutation>;
export type RemoveScenarioMutationOptions = Apollo.BaseMutationOptions<RemoveScenarioMutation, RemoveScenarioMutationVariables>;
export const AddBeneficiaryToScenarioDocument = gql`
    mutation addBeneficiaryToScenario($scenarioId: String!, $beneficiaryId: Float!, $acceptanceDeadline: Float!) {
  addBeneficiaryToScenario(
    scenarioId: $scenarioId
    beneficiaryId: $beneficiaryId
    acceptanceDeadline: $acceptanceDeadline
  ) {
    id
  }
}
    `;
export type AddBeneficiaryToScenarioMutationFn = Apollo.MutationFunction<AddBeneficiaryToScenarioMutation, AddBeneficiaryToScenarioMutationVariables>;

/**
 * __useAddBeneficiaryToScenarioMutation__
 *
 * To run a mutation, you first call `useAddBeneficiaryToScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBeneficiaryToScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBeneficiaryToScenarioMutation, { data, loading, error }] = useAddBeneficiaryToScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *      beneficiaryId: // value for 'beneficiaryId'
 *      acceptanceDeadline: // value for 'acceptanceDeadline'
 *   },
 * });
 */
export function useAddBeneficiaryToScenarioMutation(baseOptions?: Apollo.MutationHookOptions<AddBeneficiaryToScenarioMutation, AddBeneficiaryToScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBeneficiaryToScenarioMutation, AddBeneficiaryToScenarioMutationVariables>(AddBeneficiaryToScenarioDocument, options);
      }
export type AddBeneficiaryToScenarioMutationHookResult = ReturnType<typeof useAddBeneficiaryToScenarioMutation>;
export type AddBeneficiaryToScenarioMutationResult = Apollo.MutationResult<AddBeneficiaryToScenarioMutation>;
export type AddBeneficiaryToScenarioMutationOptions = Apollo.BaseMutationOptions<AddBeneficiaryToScenarioMutation, AddBeneficiaryToScenarioMutationVariables>;
export const AcceptScenarioDocument = gql`
    mutation acceptScenario($scenarioId: String!) {
  removeScenario(scenarioId: $scenarioId)
}
    `;
export type AcceptScenarioMutationFn = Apollo.MutationFunction<AcceptScenarioMutation, AcceptScenarioMutationVariables>;

/**
 * __useAcceptScenarioMutation__
 *
 * To run a mutation, you first call `useAcceptScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptScenarioMutation, { data, loading, error }] = useAcceptScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useAcceptScenarioMutation(baseOptions?: Apollo.MutationHookOptions<AcceptScenarioMutation, AcceptScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptScenarioMutation, AcceptScenarioMutationVariables>(AcceptScenarioDocument, options);
      }
export type AcceptScenarioMutationHookResult = ReturnType<typeof useAcceptScenarioMutation>;
export type AcceptScenarioMutationResult = Apollo.MutationResult<AcceptScenarioMutation>;
export type AcceptScenarioMutationOptions = Apollo.BaseMutationOptions<AcceptScenarioMutation, AcceptScenarioMutationVariables>;
export const RejectScenarioDocument = gql`
    mutation rejectScenario($scenarioId: String!) {
  rejectScenario(scenarioId: $scenarioId)
}
    `;
export type RejectScenarioMutationFn = Apollo.MutationFunction<RejectScenarioMutation, RejectScenarioMutationVariables>;

/**
 * __useRejectScenarioMutation__
 *
 * To run a mutation, you first call `useRejectScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectScenarioMutation, { data, loading, error }] = useRejectScenarioMutation({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useRejectScenarioMutation(baseOptions?: Apollo.MutationHookOptions<RejectScenarioMutation, RejectScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectScenarioMutation, RejectScenarioMutationVariables>(RejectScenarioDocument, options);
      }
export type RejectScenarioMutationHookResult = ReturnType<typeof useRejectScenarioMutation>;
export type RejectScenarioMutationResult = Apollo.MutationResult<RejectScenarioMutation>;
export type RejectScenarioMutationOptions = Apollo.BaseMutationOptions<RejectScenarioMutation, RejectScenarioMutationVariables>;
export const WebPushSubscribeDocument = gql`
    mutation webPushSubscribe($subscription: WebPushSubscriptionInput!) {
  webPushSubscribe(subscription: $subscription)
}
    `;
export type WebPushSubscribeMutationFn = Apollo.MutationFunction<WebPushSubscribeMutation, WebPushSubscribeMutationVariables>;

/**
 * __useWebPushSubscribeMutation__
 *
 * To run a mutation, you first call `useWebPushSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebPushSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webPushSubscribeMutation, { data, loading, error }] = useWebPushSubscribeMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useWebPushSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<WebPushSubscribeMutation, WebPushSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebPushSubscribeMutation, WebPushSubscribeMutationVariables>(WebPushSubscribeDocument, options);
      }
export type WebPushSubscribeMutationHookResult = ReturnType<typeof useWebPushSubscribeMutation>;
export type WebPushSubscribeMutationResult = Apollo.MutationResult<WebPushSubscribeMutation>;
export type WebPushSubscribeMutationOptions = Apollo.BaseMutationOptions<WebPushSubscribeMutation, WebPushSubscribeMutationVariables>;
export const WebPushUnsubscribeDocument = gql`
    mutation webPushUnsubscribe($subscription: WebPushSubscriptionInput!) {
  webPushUnsubscribe(subscription: $subscription)
}
    `;
export type WebPushUnsubscribeMutationFn = Apollo.MutationFunction<WebPushUnsubscribeMutation, WebPushUnsubscribeMutationVariables>;

/**
 * __useWebPushUnsubscribeMutation__
 *
 * To run a mutation, you first call `useWebPushUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebPushUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webPushUnsubscribeMutation, { data, loading, error }] = useWebPushUnsubscribeMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useWebPushUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<WebPushUnsubscribeMutation, WebPushUnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebPushUnsubscribeMutation, WebPushUnsubscribeMutationVariables>(WebPushUnsubscribeDocument, options);
      }
export type WebPushUnsubscribeMutationHookResult = ReturnType<typeof useWebPushUnsubscribeMutation>;
export type WebPushUnsubscribeMutationResult = Apollo.MutationResult<WebPushUnsubscribeMutation>;
export type WebPushUnsubscribeMutationOptions = Apollo.BaseMutationOptions<WebPushUnsubscribeMutation, WebPushUnsubscribeMutationVariables>;
export const AddToWhitelistDocument = gql`
    mutation addToWhitelist($email: String!, $planId: String!) {
  addToWhitelist(email: $email, planId: $planId) {
    id
  }
}
    `;
export type AddToWhitelistMutationFn = Apollo.MutationFunction<AddToWhitelistMutation, AddToWhitelistMutationVariables>;

/**
 * __useAddToWhitelistMutation__
 *
 * To run a mutation, you first call `useAddToWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWhitelistMutation, { data, loading, error }] = useAddToWhitelistMutation({
 *   variables: {
 *      email: // value for 'email'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useAddToWhitelistMutation(baseOptions?: Apollo.MutationHookOptions<AddToWhitelistMutation, AddToWhitelistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToWhitelistMutation, AddToWhitelistMutationVariables>(AddToWhitelistDocument, options);
      }
export type AddToWhitelistMutationHookResult = ReturnType<typeof useAddToWhitelistMutation>;
export type AddToWhitelistMutationResult = Apollo.MutationResult<AddToWhitelistMutation>;
export type AddToWhitelistMutationOptions = Apollo.BaseMutationOptions<AddToWhitelistMutation, AddToWhitelistMutationVariables>;
export const GetAssistantsDocument = gql`
    query getAssistants($filterByName: String) {
  getAssistants(filterByName: $filterByName) {
    id
    firstName
    lastName
    userName
    accounts {
      email
    }
  }
}
    `;

/**
 * __useGetAssistantsQuery__
 *
 * To run a query within a React component, call `useGetAssistantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssistantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssistantsQuery({
 *   variables: {
 *      filterByName: // value for 'filterByName'
 *   },
 * });
 */
export function useGetAssistantsQuery(baseOptions?: Apollo.QueryHookOptions<GetAssistantsQuery, GetAssistantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssistantsQuery, GetAssistantsQueryVariables>(GetAssistantsDocument, options);
      }
export function useGetAssistantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssistantsQuery, GetAssistantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssistantsQuery, GetAssistantsQueryVariables>(GetAssistantsDocument, options);
        }
export type GetAssistantsQueryHookResult = ReturnType<typeof useGetAssistantsQuery>;
export type GetAssistantsLazyQueryHookResult = ReturnType<typeof useGetAssistantsLazyQuery>;
export type GetAssistantsQueryResult = Apollo.QueryResult<GetAssistantsQuery, GetAssistantsQueryVariables>;
export const CurrenciesListDocument = gql`
    query currenciesList {
  currencyRates {
    id
    currencyCode
    value
    baseCurrency
  }
}
    `;

/**
 * __useCurrenciesListQuery__
 *
 * To run a query within a React component, call `useCurrenciesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrenciesListQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesListQuery, CurrenciesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesListQuery, CurrenciesListQueryVariables>(CurrenciesListDocument, options);
      }
export function useCurrenciesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesListQuery, CurrenciesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesListQuery, CurrenciesListQueryVariables>(CurrenciesListDocument, options);
        }
export type CurrenciesListQueryHookResult = ReturnType<typeof useCurrenciesListQuery>;
export type CurrenciesListLazyQueryHookResult = ReturnType<typeof useCurrenciesListLazyQuery>;
export type CurrenciesListQueryResult = Apollo.QueryResult<CurrenciesListQuery, CurrenciesListQueryVariables>;
export const DebugDocument = gql`
    query Debug {
  debug
}
    `;

/**
 * __useDebugQuery__
 *
 * To run a query within a React component, call `useDebugQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebugQuery({
 *   variables: {
 *   },
 * });
 */
export function useDebugQuery(baseOptions?: Apollo.QueryHookOptions<DebugQuery, DebugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebugQuery, DebugQueryVariables>(DebugDocument, options);
      }
export function useDebugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebugQuery, DebugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebugQuery, DebugQueryVariables>(DebugDocument, options);
        }
export type DebugQueryHookResult = ReturnType<typeof useDebugQuery>;
export type DebugLazyQueryHookResult = ReturnType<typeof useDebugLazyQuery>;
export type DebugQueryResult = Apollo.QueryResult<DebugQuery, DebugQueryVariables>;
export const GetLogsDocument = gql`
    query GetLogs($fromDateTime: DateTime!, $toDateTime: DateTime!, $take: Int!, $skip: Int!, $forDtoId: String) {
  getEventLogs(
    fromDateTime: $fromDateTime
    toDateTime: $toDateTime
    take: $take
    skip: $skip
    forDtoId: $forDtoId
  ) {
    totalCount
    items {
      id
      createdAt
      updatedAt
      mainUserId
      publicData
      encryptedData
    }
  }
}
    `;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *      fromDateTime: // value for 'fromDateTime'
 *      toDateTime: // value for 'toDateTime'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      forDtoId: // value for 'forDtoId'
 *   },
 * });
 */
export function useGetLogsQuery(baseOptions: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
      }
export function useGetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<GetLogsQuery, GetLogsQueryVariables>;
export const GetEventLogsCountDocument = gql`
    query getEventLogsCount($from: DateTime!, $to: DateTime!, $forDtoId: String) {
  getEventLogs(fromDateTime: $from, toDateTime: $to, forDtoId: $forDtoId) {
    totalCount
  }
}
    `;

/**
 * __useGetEventLogsCountQuery__
 *
 * To run a query within a React component, call `useGetEventLogsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventLogsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventLogsCountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      forDtoId: // value for 'forDtoId'
 *   },
 * });
 */
export function useGetEventLogsCountQuery(baseOptions: Apollo.QueryHookOptions<GetEventLogsCountQuery, GetEventLogsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventLogsCountQuery, GetEventLogsCountQueryVariables>(GetEventLogsCountDocument, options);
      }
export function useGetEventLogsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventLogsCountQuery, GetEventLogsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventLogsCountQuery, GetEventLogsCountQueryVariables>(GetEventLogsCountDocument, options);
        }
export type GetEventLogsCountQueryHookResult = ReturnType<typeof useGetEventLogsCountQuery>;
export type GetEventLogsCountLazyQueryHookResult = ReturnType<typeof useGetEventLogsCountLazyQuery>;
export type GetEventLogsCountQueryResult = Apollo.QueryResult<GetEventLogsCountQuery, GetEventLogsCountQueryVariables>;
export const GetFileDocument = gql`
    query getFile($filename: String!) {
  getFile(filename: $filename)
}
    `;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetFileQuery(baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(GetFileDocument, options);
      }
export function useGetFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(GetFileDocument, options);
        }
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<GetFileQuery, GetFileQueryVariables>;
export const GetProfileIdDocument = gql`
    query getProfileId($publicKey: String!) {
  getProfileId(publicKey: $publicKey)
}
    `;

/**
 * __useGetProfileIdQuery__
 *
 * To run a query within a React component, call `useGetProfileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileIdQuery({
 *   variables: {
 *      publicKey: // value for 'publicKey'
 *   },
 * });
 */
export function useGetProfileIdQuery(baseOptions: Apollo.QueryHookOptions<GetProfileIdQuery, GetProfileIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileIdQuery, GetProfileIdQueryVariables>(GetProfileIdDocument, options);
      }
export function useGetProfileIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileIdQuery, GetProfileIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileIdQuery, GetProfileIdQueryVariables>(GetProfileIdDocument, options);
        }
export type GetProfileIdQueryHookResult = ReturnType<typeof useGetProfileIdQuery>;
export type GetProfileIdLazyQueryHookResult = ReturnType<typeof useGetProfileIdLazyQuery>;
export type GetProfileIdQueryResult = Apollo.QueryResult<GetProfileIdQuery, GetProfileIdQueryVariables>;
export const GetZendeskTokenDocument = gql`
    query getZendeskToken {
  getZendeskToken {
    token
  }
}
    `;

/**
 * __useGetZendeskTokenQuery__
 *
 * To run a query within a React component, call `useGetZendeskTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZendeskTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZendeskTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetZendeskTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetZendeskTokenQuery, GetZendeskTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZendeskTokenQuery, GetZendeskTokenQueryVariables>(GetZendeskTokenDocument, options);
      }
export function useGetZendeskTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZendeskTokenQuery, GetZendeskTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZendeskTokenQuery, GetZendeskTokenQueryVariables>(GetZendeskTokenDocument, options);
        }
export type GetZendeskTokenQueryHookResult = ReturnType<typeof useGetZendeskTokenQuery>;
export type GetZendeskTokenLazyQueryHookResult = ReturnType<typeof useGetZendeskTokenLazyQuery>;
export type GetZendeskTokenQueryResult = Apollo.QueryResult<GetZendeskTokenQuery, GetZendeskTokenQueryVariables>;
export const VerifyEmailCodeDocument = gql`
    query verifyEmailCode($email: String!, $code: String!) {
  verifyEmailCode(email: $email, code: $code)
}
    `;

/**
 * __useVerifyEmailCodeQuery__
 *
 * To run a query within a React component, call `useVerifyEmailCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyEmailCodeQuery({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyEmailCodeQuery(baseOptions: Apollo.QueryHookOptions<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>(VerifyEmailCodeDocument, options);
      }
export function useVerifyEmailCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>(VerifyEmailCodeDocument, options);
        }
export type VerifyEmailCodeQueryHookResult = ReturnType<typeof useVerifyEmailCodeQuery>;
export type VerifyEmailCodeLazyQueryHookResult = ReturnType<typeof useVerifyEmailCodeLazyQuery>;
export type VerifyEmailCodeQueryResult = Apollo.QueryResult<VerifyEmailCodeQuery, VerifyEmailCodeQueryVariables>;
export const VerifyTwoFactorQuestionDocument = gql`
    query verifyTwoFactorQuestion($email: String!, $emailCode: String!, $twoFactorQuestionId: Int!, $twoFactorQuestionAnswer: String!) {
  verifyTwoFactorQuestion(
    email: $email
    emailCode: $emailCode
    twoFactorQuestionId: $twoFactorQuestionId
    twoFactorQuestionAnswer: $twoFactorQuestionAnswer
  )
}
    `;

/**
 * __useVerifyTwoFactorQuestionQuery__
 *
 * To run a query within a React component, call `useVerifyTwoFactorQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTwoFactorQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTwoFactorQuestionQuery({
 *   variables: {
 *      email: // value for 'email'
 *      emailCode: // value for 'emailCode'
 *      twoFactorQuestionId: // value for 'twoFactorQuestionId'
 *      twoFactorQuestionAnswer: // value for 'twoFactorQuestionAnswer'
 *   },
 * });
 */
export function useVerifyTwoFactorQuestionQuery(baseOptions: Apollo.QueryHookOptions<VerifyTwoFactorQuestionQuery, VerifyTwoFactorQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyTwoFactorQuestionQuery, VerifyTwoFactorQuestionQueryVariables>(VerifyTwoFactorQuestionDocument, options);
      }
export function useVerifyTwoFactorQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyTwoFactorQuestionQuery, VerifyTwoFactorQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyTwoFactorQuestionQuery, VerifyTwoFactorQuestionQueryVariables>(VerifyTwoFactorQuestionDocument, options);
        }
export type VerifyTwoFactorQuestionQueryHookResult = ReturnType<typeof useVerifyTwoFactorQuestionQuery>;
export type VerifyTwoFactorQuestionLazyQueryHookResult = ReturnType<typeof useVerifyTwoFactorQuestionLazyQuery>;
export type VerifyTwoFactorQuestionQueryResult = Apollo.QueryResult<VerifyTwoFactorQuestionQuery, VerifyTwoFactorQuestionQueryVariables>;
export const IsEmailExistDocument = gql`
    query isEmailExist($email: String!) {
  isEmailExists(email: $email)
}
    `;

/**
 * __useIsEmailExistQuery__
 *
 * To run a query within a React component, call `useIsEmailExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailExistQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailExistQuery(baseOptions: Apollo.QueryHookOptions<IsEmailExistQuery, IsEmailExistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailExistQuery, IsEmailExistQueryVariables>(IsEmailExistDocument, options);
      }
export function useIsEmailExistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailExistQuery, IsEmailExistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailExistQuery, IsEmailExistQueryVariables>(IsEmailExistDocument, options);
        }
export type IsEmailExistQueryHookResult = ReturnType<typeof useIsEmailExistQuery>;
export type IsEmailExistLazyQueryHookResult = ReturnType<typeof useIsEmailExistLazyQuery>;
export type IsEmailExistQueryResult = Apollo.QueryResult<IsEmailExistQuery, IsEmailExistQueryVariables>;
export const IsEmailAccountTakenDocument = gql`
    query isEmailAccountTaken($search: String!) {
  isEmailAccountTaken(search: $search)
}
    `;

/**
 * __useIsEmailAccountTakenQuery__
 *
 * To run a query within a React component, call `useIsEmailAccountTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailAccountTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailAccountTakenQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useIsEmailAccountTakenQuery(baseOptions: Apollo.QueryHookOptions<IsEmailAccountTakenQuery, IsEmailAccountTakenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsEmailAccountTakenQuery, IsEmailAccountTakenQueryVariables>(IsEmailAccountTakenDocument, options);
      }
export function useIsEmailAccountTakenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsEmailAccountTakenQuery, IsEmailAccountTakenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsEmailAccountTakenQuery, IsEmailAccountTakenQueryVariables>(IsEmailAccountTakenDocument, options);
        }
export type IsEmailAccountTakenQueryHookResult = ReturnType<typeof useIsEmailAccountTakenQuery>;
export type IsEmailAccountTakenLazyQueryHookResult = ReturnType<typeof useIsEmailAccountTakenLazyQuery>;
export type IsEmailAccountTakenQueryResult = Apollo.QueryResult<IsEmailAccountTakenQuery, IsEmailAccountTakenQueryVariables>;
export const SendEmailDocument = gql`
    mutation sendEmail($username: String!, $to: String!, $subject: String!, $text: String!) {
  sendEmail(username: $username, to: $to, subject: $subject, text: $text)
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      username: // value for 'username'
 *      to: // value for 'to'
 *      subject: // value for 'subject'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const MailboxesDocument = gql`
    query mailboxes {
  mailboxes {
    username
    domain
  }
}
    `;

/**
 * __useMailboxesQuery__
 *
 * To run a query within a React component, call `useMailboxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailboxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailboxesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMailboxesQuery(baseOptions?: Apollo.QueryHookOptions<MailboxesQuery, MailboxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MailboxesQuery, MailboxesQueryVariables>(MailboxesDocument, options);
      }
export function useMailboxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailboxesQuery, MailboxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MailboxesQuery, MailboxesQueryVariables>(MailboxesDocument, options);
        }
export type MailboxesQueryHookResult = ReturnType<typeof useMailboxesQuery>;
export type MailboxesLazyQueryHookResult = ReturnType<typeof useMailboxesLazyQuery>;
export type MailboxesQueryResult = Apollo.QueryResult<MailboxesQuery, MailboxesQueryVariables>;
export const ProfileDocument = gql`
    query profile($username: String!) {
  profile(input: {where: {mailboxes: {some: {username: {equals: $username}}}}}) {
    id
    publicKey
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfileQuery(baseOptions: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
      }
export function useProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const CurrentExportRequestDocument = gql`
    query currentExportRequest {
  currentExportRequest {
    id
    createdAt
    updatedAt
    url
    status
    hubType
  }
}
    `;

/**
 * __useCurrentExportRequestQuery__
 *
 * To run a query within a React component, call `useCurrentExportRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentExportRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentExportRequestQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentExportRequestQuery(baseOptions?: Apollo.QueryHookOptions<CurrentExportRequestQuery, CurrentExportRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentExportRequestQuery, CurrentExportRequestQueryVariables>(CurrentExportRequestDocument, options);
      }
export function useCurrentExportRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentExportRequestQuery, CurrentExportRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentExportRequestQuery, CurrentExportRequestQueryVariables>(CurrentExportRequestDocument, options);
        }
export type CurrentExportRequestQueryHookResult = ReturnType<typeof useCurrentExportRequestQuery>;
export type CurrentExportRequestLazyQueryHookResult = ReturnType<typeof useCurrentExportRequestLazyQuery>;
export type CurrentExportRequestQueryResult = Apollo.QueryResult<CurrentExportRequestQuery, CurrentExportRequestQueryVariables>;
export const IsExportAvailableDocument = gql`
    query isExportAvailable {
  isUrlAvailable
}
    `;

/**
 * __useIsExportAvailableQuery__
 *
 * To run a query within a React component, call `useIsExportAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExportAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExportAvailableQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsExportAvailableQuery(baseOptions?: Apollo.QueryHookOptions<IsExportAvailableQuery, IsExportAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsExportAvailableQuery, IsExportAvailableQueryVariables>(IsExportAvailableDocument, options);
      }
export function useIsExportAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsExportAvailableQuery, IsExportAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsExportAvailableQuery, IsExportAvailableQueryVariables>(IsExportAvailableDocument, options);
        }
export type IsExportAvailableQueryHookResult = ReturnType<typeof useIsExportAvailableQuery>;
export type IsExportAvailableLazyQueryHookResult = ReturnType<typeof useIsExportAvailableLazyQuery>;
export type IsExportAvailableQueryResult = Apollo.QueryResult<IsExportAvailableQuery, IsExportAvailableQueryVariables>;
export const GetHubSettingsDocument = gql`
    query getHubSettings {
  getHubSettings {
    hubUrl
    ipAddress
    provider
    country
    connectedAt
    lastPingAt
    updatedAt
    ownedByUser
  }
}
    `;

/**
 * __useGetHubSettingsQuery__
 *
 * To run a query within a React component, call `useGetHubSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHubSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHubSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHubSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetHubSettingsQuery, GetHubSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHubSettingsQuery, GetHubSettingsQueryVariables>(GetHubSettingsDocument, options);
      }
export function useGetHubSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHubSettingsQuery, GetHubSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHubSettingsQuery, GetHubSettingsQueryVariables>(GetHubSettingsDocument, options);
        }
export type GetHubSettingsQueryHookResult = ReturnType<typeof useGetHubSettingsQuery>;
export type GetHubSettingsLazyQueryHookResult = ReturnType<typeof useGetHubSettingsLazyQuery>;
export type GetHubSettingsQueryResult = Apollo.QueryResult<GetHubSettingsQuery, GetHubSettingsQueryVariables>;
export const OneTimeCodesDocument = gql`
    query OneTimeCodes($emailOrPhoneNumber: String!) {
  oneTimeCodes(emailOrPhoneNumber: $emailOrPhoneNumber) {
    emailCode
    smsCode
  }
}
    `;

/**
 * __useOneTimeCodesQuery__
 *
 * To run a query within a React component, call `useOneTimeCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimeCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimeCodesQuery({
 *   variables: {
 *      emailOrPhoneNumber: // value for 'emailOrPhoneNumber'
 *   },
 * });
 */
export function useOneTimeCodesQuery(baseOptions: Apollo.QueryHookOptions<OneTimeCodesQuery, OneTimeCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OneTimeCodesQuery, OneTimeCodesQueryVariables>(OneTimeCodesDocument, options);
      }
export function useOneTimeCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OneTimeCodesQuery, OneTimeCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OneTimeCodesQuery, OneTimeCodesQueryVariables>(OneTimeCodesDocument, options);
        }
export type OneTimeCodesQueryHookResult = ReturnType<typeof useOneTimeCodesQuery>;
export type OneTimeCodesLazyQueryHookResult = ReturnType<typeof useOneTimeCodesLazyQuery>;
export type OneTimeCodesQueryResult = Apollo.QueryResult<OneTimeCodesQuery, OneTimeCodesQueryVariables>;
export const WhoamiDocument = gql`
    query whoami {
  whoami {
    id
    email
    profile {
      id
      avatarUrl
      firstName
      lastName
      status
      publicKey
      type
      mainUser {
        publicKey
      }
      scenariosBelongingToMeAsMainUser {
        beneficiaries {
          status
        }
      }
    }
    sessions {
      id
    }
  }
}
    `;

/**
 * __useWhoamiQuery__
 *
 * To run a query within a React component, call `useWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoamiQuery(baseOptions?: Apollo.QueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
      }
export function useWhoamiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoamiQuery, WhoamiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoamiQuery, WhoamiQueryVariables>(WhoamiDocument, options);
        }
export type WhoamiQueryHookResult = ReturnType<typeof useWhoamiQuery>;
export type WhoamiLazyQueryHookResult = ReturnType<typeof useWhoamiLazyQuery>;
export type WhoamiQueryResult = Apollo.QueryResult<WhoamiQuery, WhoamiQueryVariables>;
export const GetBeneficiariesDocument = gql`
    query getBeneficiaries {
  getBeneficiaries {
    id
    status
    invitationLastSentTime
    publicKey
  }
}
    `;

/**
 * __useGetBeneficiariesQuery__
 *
 * To run a query within a React component, call `useGetBeneficiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeneficiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeneficiariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBeneficiariesQuery(baseOptions?: Apollo.QueryHookOptions<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>(GetBeneficiariesDocument, options);
      }
export function useGetBeneficiariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>(GetBeneficiariesDocument, options);
        }
export type GetBeneficiariesQueryHookResult = ReturnType<typeof useGetBeneficiariesQuery>;
export type GetBeneficiariesLazyQueryHookResult = ReturnType<typeof useGetBeneficiariesLazyQuery>;
export type GetBeneficiariesQueryResult = Apollo.QueryResult<GetBeneficiariesQuery, GetBeneficiariesQueryVariables>;
export const GetSoweAppendixSuppressCancelConfirmationDocument = gql`
    query getSoweAppendixSuppressCancelConfirmation {
  whoami {
    profile {
      soweAppendixSuppressCancelConfirmation
    }
  }
}
    `;

/**
 * __useGetSoweAppendixSuppressCancelConfirmationQuery__
 *
 * To run a query within a React component, call `useGetSoweAppendixSuppressCancelConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSoweAppendixSuppressCancelConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSoweAppendixSuppressCancelConfirmationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSoweAppendixSuppressCancelConfirmationQuery(baseOptions?: Apollo.QueryHookOptions<GetSoweAppendixSuppressCancelConfirmationQuery, GetSoweAppendixSuppressCancelConfirmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSoweAppendixSuppressCancelConfirmationQuery, GetSoweAppendixSuppressCancelConfirmationQueryVariables>(GetSoweAppendixSuppressCancelConfirmationDocument, options);
      }
export function useGetSoweAppendixSuppressCancelConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSoweAppendixSuppressCancelConfirmationQuery, GetSoweAppendixSuppressCancelConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSoweAppendixSuppressCancelConfirmationQuery, GetSoweAppendixSuppressCancelConfirmationQueryVariables>(GetSoweAppendixSuppressCancelConfirmationDocument, options);
        }
export type GetSoweAppendixSuppressCancelConfirmationQueryHookResult = ReturnType<typeof useGetSoweAppendixSuppressCancelConfirmationQuery>;
export type GetSoweAppendixSuppressCancelConfirmationLazyQueryHookResult = ReturnType<typeof useGetSoweAppendixSuppressCancelConfirmationLazyQuery>;
export type GetSoweAppendixSuppressCancelConfirmationQueryResult = Apollo.QueryResult<GetSoweAppendixSuppressCancelConfirmationQuery, GetSoweAppendixSuppressCancelConfirmationQueryVariables>;
export const GetOcrSuppressLetsTryConfirmationDocument = gql`
    query getOcrSuppressLetsTryConfirmation {
  whoami {
    profile {
      ocrSuppressLetsTryConfirmation
    }
  }
}
    `;

/**
 * __useGetOcrSuppressLetsTryConfirmationQuery__
 *
 * To run a query within a React component, call `useGetOcrSuppressLetsTryConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOcrSuppressLetsTryConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOcrSuppressLetsTryConfirmationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOcrSuppressLetsTryConfirmationQuery(baseOptions?: Apollo.QueryHookOptions<GetOcrSuppressLetsTryConfirmationQuery, GetOcrSuppressLetsTryConfirmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOcrSuppressLetsTryConfirmationQuery, GetOcrSuppressLetsTryConfirmationQueryVariables>(GetOcrSuppressLetsTryConfirmationDocument, options);
      }
export function useGetOcrSuppressLetsTryConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOcrSuppressLetsTryConfirmationQuery, GetOcrSuppressLetsTryConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOcrSuppressLetsTryConfirmationQuery, GetOcrSuppressLetsTryConfirmationQueryVariables>(GetOcrSuppressLetsTryConfirmationDocument, options);
        }
export type GetOcrSuppressLetsTryConfirmationQueryHookResult = ReturnType<typeof useGetOcrSuppressLetsTryConfirmationQuery>;
export type GetOcrSuppressLetsTryConfirmationLazyQueryHookResult = ReturnType<typeof useGetOcrSuppressLetsTryConfirmationLazyQuery>;
export type GetOcrSuppressLetsTryConfirmationQueryResult = Apollo.QueryResult<GetOcrSuppressLetsTryConfirmationQuery, GetOcrSuppressLetsTryConfirmationQueryVariables>;
export const PullCollectionDocument = gql`
    query PullCollection($name: String!) {
  collection1: pullCollection(name: $name) {
    id
    createdAt
    updatedAt
    name
    data
    profileId
  }
}
    `;

/**
 * __usePullCollectionQuery__
 *
 * To run a query within a React component, call `usePullCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePullCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePullCollectionQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function usePullCollectionQuery(baseOptions: Apollo.QueryHookOptions<PullCollectionQuery, PullCollectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PullCollectionQuery, PullCollectionQueryVariables>(PullCollectionDocument, options);
      }
export function usePullCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PullCollectionQuery, PullCollectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PullCollectionQuery, PullCollectionQueryVariables>(PullCollectionDocument, options);
        }
export type PullCollectionQueryHookResult = ReturnType<typeof usePullCollectionQuery>;
export type PullCollectionLazyQueryHookResult = ReturnType<typeof usePullCollectionLazyQuery>;
export type PullCollectionQueryResult = Apollo.QueryResult<PullCollectionQuery, PullCollectionQueryVariables>;
export const ScenariosDocument = gql`
    query scenarios {
  scenarios {
    id
    createdAt
    updatedAt
    mainUserId
    dtoIds
    mode
    notificationValue
    notificationPeriod
    notificationTime
    triggerType
    triggerValue
    status
    mainUser {
      id
      firstName
      lastName
    }
    beneficiaries {
      beneficiaryId
      status
      priority
      acceptanceDeadline
      beneficiary {
        id
        firstName
        lastName
        userName
        status
      }
    }
  }
}
    `;

/**
 * __useScenariosQuery__
 *
 * To run a query within a React component, call `useScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenariosQuery({
 *   variables: {
 *   },
 * });
 */
export function useScenariosQuery(baseOptions?: Apollo.QueryHookOptions<ScenariosQuery, ScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScenariosQuery, ScenariosQueryVariables>(ScenariosDocument, options);
      }
export function useScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScenariosQuery, ScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScenariosQuery, ScenariosQueryVariables>(ScenariosDocument, options);
        }
export type ScenariosQueryHookResult = ReturnType<typeof useScenariosQuery>;
export type ScenariosLazyQueryHookResult = ReturnType<typeof useScenariosLazyQuery>;
export type ScenariosQueryResult = Apollo.QueryResult<ScenariosQuery, ScenariosQueryVariables>;
export const ScenarioDocument = gql`
    query scenario($scenarioId: String!) {
  scenario(scenarioId: $scenarioId) {
    id
    createdAt
    updatedAt
    mainUserId
    dtoIds
    mode
    triggerType
    triggerValue
    notificationValue
    notificationPeriod
    notificationTime
    status
    mainUser {
      id
      firstName
      lastName
    }
    beneficiaries {
      beneficiaryId
      status
      priority
      acceptanceDeadline
      beneficiary {
        id
        firstName
        lastName
        userName
        status
      }
    }
  }
}
    `;

/**
 * __useScenarioQuery__
 *
 * To run a query within a React component, call `useScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScenarioQuery({
 *   variables: {
 *      scenarioId: // value for 'scenarioId'
 *   },
 * });
 */
export function useScenarioQuery(baseOptions: Apollo.QueryHookOptions<ScenarioQuery, ScenarioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, options);
      }
export function useScenarioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScenarioQuery, ScenarioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScenarioQuery, ScenarioQueryVariables>(ScenarioDocument, options);
        }
export type ScenarioQueryHookResult = ReturnType<typeof useScenarioQuery>;
export type ScenarioLazyQueryHookResult = ReturnType<typeof useScenarioLazyQuery>;
export type ScenarioQueryResult = Apollo.QueryResult<ScenarioQuery, ScenarioQueryVariables>;
export const GetTopicSubscribersCountDocument = gql`
    query getTopicSubscribersCount($topic: String!) {
  getTopicSubscribersCount(topic: $topic)
}
    `;

/**
 * __useGetTopicSubscribersCountQuery__
 *
 * To run a query within a React component, call `useGetTopicSubscribersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicSubscribersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicSubscribersCountQuery({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useGetTopicSubscribersCountQuery(baseOptions: Apollo.QueryHookOptions<GetTopicSubscribersCountQuery, GetTopicSubscribersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTopicSubscribersCountQuery, GetTopicSubscribersCountQueryVariables>(GetTopicSubscribersCountDocument, options);
      }
export function useGetTopicSubscribersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTopicSubscribersCountQuery, GetTopicSubscribersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTopicSubscribersCountQuery, GetTopicSubscribersCountQueryVariables>(GetTopicSubscribersCountDocument, options);
        }
export type GetTopicSubscribersCountQueryHookResult = ReturnType<typeof useGetTopicSubscribersCountQuery>;
export type GetTopicSubscribersCountLazyQueryHookResult = ReturnType<typeof useGetTopicSubscribersCountLazyQuery>;
export type GetTopicSubscribersCountQueryResult = Apollo.QueryResult<GetTopicSubscribersCountQuery, GetTopicSubscribersCountQueryVariables>;
export const GetSubscriptionsDocument = gql`
    query getSubscriptions {
  getSubscriptions {
    id
    status
    plan
    dateStart
    dateEnd
    status
  }
}
    `;

/**
 * __useGetSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
      }
export function useGetSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>(GetSubscriptionsDocument, options);
        }
export type GetSubscriptionsQueryHookResult = ReturnType<typeof useGetSubscriptionsQuery>;
export type GetSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsLazyQuery>;
export type GetSubscriptionsQueryResult = Apollo.QueryResult<GetSubscriptionsQuery, GetSubscriptionsQueryVariables>;
export const ExistInWhitelistDocument = gql`
    query existInWhitelist($email: String!) {
  existInWhitelist(email: $email)
}
    `;

/**
 * __useExistInWhitelistQuery__
 *
 * To run a query within a React component, call `useExistInWhitelistQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistInWhitelistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistInWhitelistQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useExistInWhitelistQuery(baseOptions: Apollo.QueryHookOptions<ExistInWhitelistQuery, ExistInWhitelistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistInWhitelistQuery, ExistInWhitelistQueryVariables>(ExistInWhitelistDocument, options);
      }
export function useExistInWhitelistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistInWhitelistQuery, ExistInWhitelistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistInWhitelistQuery, ExistInWhitelistQueryVariables>(ExistInWhitelistDocument, options);
        }
export type ExistInWhitelistQueryHookResult = ReturnType<typeof useExistInWhitelistQuery>;
export type ExistInWhitelistLazyQueryHookResult = ReturnType<typeof useExistInWhitelistLazyQuery>;
export type ExistInWhitelistQueryResult = Apollo.QueryResult<ExistInWhitelistQuery, ExistInWhitelistQueryVariables>;