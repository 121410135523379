import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import {
  IndividualContactAccessGroundsType,
  IndividualContactAccessGroundsTypesList,
  IndividualContactAccessToAssetTypesList,
  IndividualContactAddressType,
  IndividualContactRoleType,
  IndividualContactRoleTypesList,
} from '@/shared/lib/forms/contacts/individual';
import {
  CustomDrawerFilterItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import {
  ActivityList,
  ActivityType,
  AssetCategory,
  HighMediumLowList,
  HighMediumLowType,
  OwnershipList,
  OwnershipType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '../../sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentCategory } from '../../sj-orm/models/document/private.document.dto';

export enum LegalEntityContactAddressType {
  LEGAL = 'legal',
  OTHER = 'other',
}
const LegalEntityContactAddressTypesLst = [
  {
    label: 'forms_contacts:legalEntity.enums.addressType.legal',
    key: LegalEntityContactAddressType.LEGAL,
  },
  {
    label: 'forms_contacts:legalEntity.enums.addressType.other',
    key: LegalEntityContactAddressType.OTHER,
  },
];

type LegalEntityContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  nickname: string;
  selectedCountry: string;
  phone?: number;
  email?: string;
  addressType?: LegalEntityContactAddressType;
  address?: string;
  fullLegalName?: string;
  activityType?: ActivityType;
  trustLevel?: HighMediumLowType;
  contactPerson?: DtoId;
  associatedWithAnAsset?: boolean;
  assetId?: DtoId;
  assetCommentary?: string;
  role?: IndividualContactRoleType;
  accessType?: IndividualContactAddressType;
  accessGrounds?: IndividualContactAccessGroundsType;
  legalForm?: string;
  businessType?: string;
  dateOfIncorporation?: Date;
  taxNumber?: string;
  taxNumberIssuer?: string;
  lei?: string;
  ownershipType?: OwnershipType;
  owner?: DtoId;
  ownerDocument?: DtoId;
  // ownerOtherDocument?: DtoId;
  documents?: DtoId[];
  commentary?: string;

  myTodo?: boolean;
  myTodoComments?: string;
};

type LegalEntityContactHelpPartType = {
  nickname: QuestionType.TEXT;
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  phone?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
  addressType?: QuestionType.SELECT_FROM_LIST;
  address?: QuestionType.TEXT;
  fullLegalName?: QuestionType.TEXT;
  activityType?: QuestionType.SELECT_FROM_LIST;
  trustLevel?: QuestionType.SELECT_FROM_LIST;
  contactPerson?: QuestionType.PICK_ARRAY_OF_DTO;
  associatedWithAnAsset?: QuestionType.CHECKBOX;
  assetId?: QuestionType.PICK_ARRAY_OF_DTO;
  assetCommentary?: QuestionType.TEXT;
  role?: QuestionType.SELECT_FROM_LIST;
  accessType?: QuestionType.SELECT_FROM_LIST;
  accessGrounds?: QuestionType.SELECT_FROM_LIST;
  legalForm?: QuestionType.TEXT;
  businessType?: QuestionType.TEXT;
  dateOfIncorporation?: QuestionType.DATE_TIME;
  taxNumber?: QuestionType.TEXT;
  taxNumberIssuer?: QuestionType.TEXT;
  ownershipType?: QuestionType.SELECT_FROM_LIST;
  owner?: QuestionType.PICK_ARRAY_OF_DTO;
  ownerDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  // ownerOtherDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  documents?: QuestionType.PICK_ARRAY_OF_DTO;
  commentary?: QuestionType.TEXT_MULTILINE;

  myTodo?: QuestionType.CHECKBOX;
  myTodoComments?: QuestionType.TEXT_MULTILINE;
};

type LegalEntityContactFormType = TDtoWrapper<
  LegalEntityContactDataPartType,
  LegalEntityContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const LegalEntityContactForm: LegalEntityContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.nickname.label',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    required: true,
    value: '',
    items: CountriesList,
    withSearch: true,
    yupScheme: yupRequiredField,
  },
  fullLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.fullLegalName.label',
  },
  phone: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_contacts:common.inputs.phoneNumber.label',
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_contacts:common.inputs.email.label',
  },
  //address
  addressType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.addressType.label',
    items: LegalEntityContactAddressTypesLst,
  },
  address: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.address.label',
  },
  activityType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.activityType.label',
    items: ActivityList,
  },
  trustLevel: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.trustLevel.label',
    items: HighMediumLowList,
  },
  contactPerson: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // individual contact
    label: 'forms_contacts:legalEntity.inputs.contactPerson.label',
    items: ['contacts', 'personalIdentifiersDocuments', 'beneficiaries'],
    filter: (data) => {
      const item = data as
        | PersonalIdentifierDocumentDto
        | BeneficiaryDto
        | ContactDto;
      if ('centralDbProfileId' in item) {
        return true;
      }
      if ('personalIdentifierType' in item) {
        return (
          (item as PersonalIdentifierDocumentDto).personalIdentifierType ===
          PersonalIdentifierType.USER_DETAILS
        );
      }
      if ('selectedCountry' in item) {
        return (
          (item as ContactDto).type === ContactType.INDIVIDUAL ||
          (item as ContactDto).type === ContactType.PERSONAL
        );
      }
      return false;
    },
    customDrawerFilter: {
      filterFunc: ({ items, sjDb, filters }) => {
        const filterKey = filters[0];
        const filterMap: {
          [key: string]: (item: CustomDrawerFilterItem) => boolean;
        } = {
          nextOwner: (item: CustomDrawerFilterItem) =>
            item.value.includes('beneficiaries-'),
          myProfile: (item: CustomDrawerFilterItem) =>
            item.value.includes('personalIdentifiersDocuments-'),
          contact: (item: CustomDrawerFilterItem) =>
            sjDb.collections?.contacts?.findOne((c) => c.id === item.value)
              ?.type === ContactType.PERSONAL,
          individual: (item: CustomDrawerFilterItem) =>
            sjDb.collections?.contacts?.findOne((c) => c.id === item.value)
              ?.type === ContactType.INDIVIDUAL,
        };
        if (filterKey && filterKey in filterMap) {
          return items.filter(filterMap[filterKey]);
        }
        return items;
      },
      options: [
        {
          placeholder: "Contact person's type",
          items: [
            { label: 'My profile', value: 'myProfile' },
            { label: 'Next Owner', value: 'nextOwner' },
            { label: 'Contact', value: 'contact' },
            { label: 'Individual', value: 'individual' },
          ],
        },
      ],
    },
  },
  associatedWithAnAsset: {
    type: QuestionType.CHECKBOX,
    label: 'forms_contacts:legalEntity.inputs.associatedWithAnAsset.label',
  },
  assetId: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:legalEntity.inputs.assetId.label',
    items: 'assets',
    mode: 'multiple',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
    dtoType: AssetCategory.BANK_CELLS,
  },
  assetCommentary: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.assetCommentary.label',
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  role: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.role.label',
    items: IndividualContactRoleTypesList,
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  accessType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.accessType.label',
    items: IndividualContactAccessToAssetTypesList,
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  accessGrounds: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:individual.inputs.accessGrounds.label',
    items: IndividualContactAccessGroundsTypesList,
    showIf: {
      associatedWithAnAsset: {
        value: true,
      },
    },
  },
  legalForm: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.legalForm.label',
  },
  businessType: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.businessType.label',
  },
  dateOfIncorporation: {
    type: QuestionType.DATE_TIME,
    label: 'forms_contacts:legalEntity.inputs.dateOfIncorporation.label',
  },
  taxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.taxNumber.label',
  },
  taxNumberIssuer: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.taxNumberIssuer.label',
  },
  lei: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:legalEntity.inputs.lei.label',
  },
  // company owner info
  ownershipType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:legalEntity.inputs.ownershipType.label',
    items: OwnershipList,
  },
  owner: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_contacts:legalEntity.inputs.owner.label',
    items: ['beneficiaries', 'contacts', 'personalIdentifiersDocuments'],
    filter: (data) => {
      const item = data as
        | PersonalIdentifierDocumentDto
        | BeneficiaryDto
        | ContactDto;
      if ('centralDbProfileId' in item) {
        return true;
      }
      if ('personalIdentifierType' in item) {
        return (
          (item as PersonalIdentifierDocumentDto).personalIdentifierType ===
          PersonalIdentifierType.USER_DETAILS
        );
      }
      if ('selectedCountry' in item) {
        return (item as ContactDto).type === ContactType.INDIVIDUAL;
      }
      return false;
    },
  },
  ownerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // personal id,
    label: 'forms_contacts:legalEntity.inputs.ownerDocument.label',
    items: 'personalIdentifiersDocuments',
    mode: 'multiple',
    filter: (data) => {
      const item = data as PersonalIdentifierDocumentDto;
      return (
        item.personalIdentifierType !== PersonalIdentifierType.USER_DETAILS &&
        item.personalIdentifierType !== PersonalIdentifierType.SUBSTANCE
      );
    },
  },
  // ownerOtherDocument: {
  //   type: QuestionType.PICK_ARRAY_OF_DTO, // private,
  //   label: 'forms_contacts:legalEntity.inputs.ownerOtherDocument.label',
  //   items: 'privateDocuments',
  //   dtoType: PrivateDocumentCategory.OTHER,
  // },
  documents: {
    type: QuestionType.PICK_ARRAY_OF_DTO, // private,
    label: 'forms_contacts:legalEntity.inputs.documents.label',
    items: 'privateDocuments',
    mode: 'multiple',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  commentary: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:legalEntity.inputs.comment.label',
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'forms_contacts:common.inputs.myTodo.label',
    tooltip: 'forms_contacts:common.inputs.myTodo.tooltip',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_contacts:common.inputs.myTodoComments.label',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
};

export { LegalEntityContactForm };
