import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Flex, Modal, ModalProps, Typography } from 'antd';

import { COLORS } from '@/shared/constants/colors';
import { useIsDesktop } from '@/shared/hooks/use-is-desktop';

type ConfirmationModalProps = ModalProps & {
  hideIcon?: boolean;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  children,
  onOk,
  okButtonProps,
  okText,
  onCancel,
  cancelButtonProps,
  cancelText,
  hideIcon,
  ...props
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('common');

  return (
    <Modal {...props} onCancel={onCancel} destroyOnClose footer={null}>
      <Flex vertical gap={8} align="center" style={{ textAlign: 'center' }}>
        {!hideIcon && (
          <ExclamationCircleFilled
            style={{ fontSize: 32, color: COLORS.colorPrimary }}
          />
        )}

        {title && <Typography.Title level={5}>{title}</Typography.Title>}

        {children}

        {/* Antd footer cant show column buttons properly */}
        <Flex
          gap={8}
          justify={isDesktop ? 'end' : 'start'}
          style={{
            width: '100%',
            marginTop: 20,
            flexDirection: isDesktop ? 'row' : 'column-reverse',
          }}
        >
          {(cancelButtonProps?.onClick || onCancel) && (
            <Button
              type="text"
              {...cancelButtonProps}
              onClick={cancelButtonProps?.onClick || onCancel}
            >
              {cancelText || t('cancel')}
            </Button>
          )}

          {(okButtonProps?.onClick || onOk) && (
            <Button
              type="primary"
              {...okButtonProps}
              onClick={okButtonProps?.onClick || onOk}
            >
              {okText || t('ok')}
            </Button>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};
