import { CountriesList } from '@/shared/constants/countries-list';
import {
  ProfileList,
  ProfileType,
} from '@/shared/lib/forms/documents/personal-IDs/passport';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { dispathEvent } from '@/shared/lib/hooks/use-form-render/misc';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum CategoryType {
  a = 'a',
  a1 = 'a1',
  b = 'b',
  b1 = 'b1',
  c = 'c',
  c1 = 'c1',
  d = 'd',
  d1 = 'd1',
  be = 'be',
  ce = 'ce',
  de = 'de',
  c1e = 'c1e',
  d1e = 'd1e',
  m = 'm',
  tm = 'tm',
  tb = 'tb',
  other = 'other',
}

const CategoryList = [
  {
    label: 'A',
    key: CategoryType.a,
  },
  {
    label: 'A1',
    key: CategoryType.a1,
  },
  {
    label: 'B',
    key: CategoryType.b,
  },
  {
    label: 'B1',
    key: CategoryType.b1,
  },
  {
    label: 'C',
    key: CategoryType.c,
  },
  {
    label: 'C1',
    key: CategoryType.c1,
  },
  {
    label: 'D',
    key: CategoryType.d,
  },
  {
    label: 'D1',
    key: CategoryType.d1,
  },
  {
    label: 'BE',
    key: CategoryType.be,
  },
  {
    label: 'CE',
    key: CategoryType.ce,
  },
  {
    label: 'DE',
    key: CategoryType.de,
  },
  {
    label: 'C1E',
    key: CategoryType.c1e,
  },
  {
    label: 'D1E',
    key: CategoryType.d1e,
  },
  {
    label: 'M',
    key: CategoryType.m,
  },
  {
    label: 'Tm',
    key: CategoryType.tm,
  },
  {
    label: 'Tb',
    key: CategoryType.tb,
  },
  {
    label: 'Other',
    key: CategoryType.other,
  },
];

export type InternationalDriverLicenseDtoType = Omit<
  //полный дубль DriverLicense
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  profile: string;
  number?: string;
  dateOfIssue?: number;
  endDate?: number;
  other?: string;
  category?: CategoryType; //множественный выбор
  otherCategory?: string;
  scan?: string;
  storageLocation?: string;
  comments?: string;
};

export type InternationalDriverLicenseDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  profile: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  endDate?: QuestionType.DATE_TIME;
  other?: QuestionType.TEXT;
  category?: QuestionType.PICK_ARRAY_OF_DTO; //множественный выбор
  otherCategory?: QuestionType.TEXT;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type InternationalDriverLicenseFormType = TDtoWrapper<
  InternationalDriverLicenseDtoType,
  InternationalDriverLicenseDtoHelpType
>;

export const InternationalDriverLicenseForm: InternationalDriverLicenseFormType =
  {
    name: {
      type: QuestionType.TEXT,
      label: '*Title',
      required: true,
      placeholder: 'Enter document name',
      value: '',
      tooltip: 'Enter a document name for display within the system',
    },
    country: {
      type: QuestionType.SELECT_FROM_LIST,
      label: '*Country',
      placeholder: 'Select country',
      items: CountriesList,
      withSearch: true,
      required: true,
      value: '',
    },
    profile: {
      type: QuestionType.SELECT_FROM_LIST,
      label: '*Profile',
      placeholder: 'Select profile',
      items: ProfileList,
      required: true,
      value: '',
      onChangeEvent: {
        name: 'profileChangeEvent',
      },
    },
    names: {
      type: QuestionType.TEXT,
      label: '*First name(s)',
      placeholder: 'Enter your first name',
      required: true,
      value: '',
      drawerOptions: {
        event: {
          name: 'profileChangeEvent',
          listener: (data, fn) => {
            fn(data.detail);
          },
        },
        items: {
          [ProfileType.mainOwner]: {
            items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
            filter: (data) => {
              const item = data as PersonalIdentifierDocumentDto;
              return (
                item.personalIdentifierType ===
                PersonalIdentifierType.USER_DETAILS
              );
            },
            onChange: ({ value, setValue, sjDb }) => {
              dispathEvent('chooseIdToAttach', value);
              const item =
                sjDb.collections.personalIdentifiersDocuments?.findOne(
                  (i) => i.id === value,
                );

              if (!item) return;

              setValue('names', item.names ?? item.firstName);
              setValue('surnames', item.surnames ?? item.secondName);
            },
            dtoType: PersonalIdentifierType.USER_DETAILS,

            __single: true,
          },
          [ProfileType.nextOwner]: {
            items: CollectionName.BENEFICIARIES,
            onChange: ({ value, setValue, sjDb }) => {
              dispathEvent('chooseIdToAttach', value);
              const item = sjDb.collections.beneficiaries?.findOne(
                (i) => i.id === value,
              );

              if (!item) return;

              setValue('names', item.firstName);
              setValue('surnames', item.lastName);
            },

            __single: true,
          },
        },
      },
    },
    surnames: {
      type: QuestionType.TEXT,
      label: '*Last name(s)',
      placeholder: 'Enter your last name',
      required: true,
      value: '',
      drawerOptions: {
        event: {
          name: 'profileChangeEvent',
          listener: (data, fn) => {
            fn(data.detail);
          },
        },
        items: {
          [ProfileType.mainOwner]: {
            items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
            filter: (data) => {
              const item = data as PersonalIdentifierDocumentDto;
              return (
                item.personalIdentifierType ===
                PersonalIdentifierType.USER_DETAILS
              );
            },
            onChange: ({ value, setValue, sjDb }) => {
              dispathEvent('chooseIdToAttach', value);
              const item =
                sjDb.collections.personalIdentifiersDocuments?.findOne(
                  (i) => i.id === value,
                );

              if (!item) return;

              setValue('names', item.names ?? item.firstName);
              setValue('surnames', item.surnames ?? item.secondName);
            },

            __single: true,
          },
          [ProfileType.nextOwner]: {
            items: CollectionName.BENEFICIARIES,
            onChange: ({ value, setValue, sjDb }) => {
              dispathEvent('chooseIdToAttach', value);
              const item =
                sjDb.collections.personalIdentifiersDocuments?.findOne(
                  (i) => i.id === value,
                );

              if (!item) return;

              setValue('names', item.names ?? item.firstName);
              setValue('surnames', item.surnames ?? item.secondName);
            },

            __single: true,
          },
        },
      },
    },
    number: {
      type: QuestionType.TEXT,
      label: 'Document number',
    },
    dateOfIssue: {
      type: QuestionType.DATE_TIME,
      label: 'Start date',
    },
    endDate: {
      type: QuestionType.DATE_TIME,
      label: 'End date',
    },
    other: {
      type: QuestionType.TEXT,
      label: 'Other data',
    },
    category: {
      type: QuestionType.SELECT_FROM_LIST,
      label: 'Category',
      items: CategoryList,
    },
    otherCategory: {
      type: QuestionType.TEXT,
      label: 'Other category',
    },
    myTodo: {
      type: QuestionType.CHECKBOX,
      label: 'My ToDo',
      tooltip:
        'All planned actions on an id, for example: renting out a house, paying annual membership fees, or any other relevant actions',
    },
    myTodoComments: {
      type: QuestionType.TEXT_MULTILINE,
      label: 'My ToDo Comments',
      showIf: {
        myTodo: {
          value: true,
        },
      },
    },
    scan: {
      type: QuestionType.FILE_UPLOAD,
      label: 'Document scan',
    },
    storageLocation: {
      type: QuestionType.TEXT,
      label: 'Storage location',
    },
    comments: {
      type: QuestionType.TEXT_MULTILINE,
      label: 'Comments',
    },
  };
