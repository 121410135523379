import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import styled from 'styled-components';

export const MoreButton = ({ items }: { items: MenuProps['items'] }) => {
  return (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
      <StyledButton size="small" onClick={(e) => e.preventDefault()}>
        <MoreOutlined rotate={90} />
      </StyledButton>
    </Dropdown>
  );
};

const StyledButton = styled(Button)`
  &&& {
    border-color: rgba(6, 1, 54, 0.2);
  }
`;
