import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';

// eslint-disable-next-line sonarjs/cognitive-complexity
const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assets = store.get(CollectionName.ASSETS);

  if (assets) {
    for (const [key, item] of assets?.items || []) {
      const ownerDocuments =
        item.decryptedData.ownerDocument &&
        typeof item.decryptedData.ownerDocument === 'string'
          ? `${item.decryptedData.ownerDocument}`
          : undefined;

      const coOwners =
        item.decryptedData.coOwners &&
        Array.isArray(item.decryptedData.coOwners)
          ? item.decryptedData.coOwners.map(
              (co: { coOwnerDocument: unknown }) => ({
                ...co,
                coOwnerDocument:
                  co.coOwnerDocument && typeof co.coOwnerDocument === 'string'
                    ? [co.coOwnerDocument]
                    : co.coOwnerDocument,
              }),
            )
          : undefined;
      assets.set({
        key,
        data: {
          ...item.decryptedData,
          ownerDocument: ownerDocuments
            ? [ownerDocuments]
            : item.decryptedData.ownerDocument,
          coOwners: coOwners ? coOwners : item.decryptedData.coOwners,
        },
      });
      delete (item.decryptedData as { assistantName?: string }).assistantName;
    }
  }
};

export const migration006: TMigration = {
  version: '0.0.6',
  up,
};
