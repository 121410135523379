import * as yup from 'yup';

import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

type PersonalContactDataPartType = Omit<ContactDto, 'id' | 'type'> & {
  nickname: string;
  selectedCountry: string;
  email?: string;
  phone?: number;
};

type PersonalContactHelpPartType = {
  nickname: QuestionType.TEXT;
  selectedCountry: QuestionType.SELECT_FROM_LIST;
  phone?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
};

type PersonalContactFormType = TDtoWrapper<
  PersonalContactDataPartType,
  PersonalContactHelpPartType
>;

const yupRequiredField = yup.string().required('common:thisIsRequiredField');

const PersonalContactForm: PersonalContactFormType = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_contacts:common.inputs.nickname.label',
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  selectedCountry: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_contacts:common.inputs.selectedCountry.label',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
    yupScheme: yupRequiredField,
  },
  phone: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_contacts:individual.inputs.phoneNumber.label',
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_contacts:individual.inputs.email.label',
  },
};

export { PersonalContactForm };
