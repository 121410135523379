import { Dispatch, SetStateAction } from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { copy } from '@/shared/utils/misc';

export const CopyComponent = ({
  code,
  isGenerated,
  setIsGenerated,
}: {
  code: string;
  isGenerated: boolean;
  setIsGenerated: Dispatch<SetStateAction<boolean>>;
}) => {
  const clickCopy = async () => {
    try {
      await copy(code);
      setIsGenerated(true);
    } catch (e) {
      //
    }
  };

  return (
    <StyledInput
      data-testId={'generated'}
      value={isGenerated ? 'Link copied to clipboard' : code}
      suffix={
        <Popover content={<span>Copied</span>} trigger="click">
          <CopyOutlined onClick={clickCopy} />
        </Popover>
      }
      disabled={isGenerated}
    />
  );
};

const StyledInput = styled(Input)`
  &&& {
    input {
      color: ${COLORS.colorLink};
    }
  }
`;
