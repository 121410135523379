import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { Drawer as ANTDDrawer, Typography } from 'antd';
import { cloneDeep } from 'lodash';
import styled from 'styled-components';

import { AssetsForms } from '@/entities/assets/helpers/assets-helpers';
import { ContactsForms } from '@/entities/contact/helpers/contacts-helpers';
import { DocumentsForms } from '@/entities/document/helpers/documents-helpers';
import { NextOwnerCreateForm } from '@/entities/next-owner/helpers/next-owner-helpers';
import { Container, Icon, Row } from '@/shared/components';
import { COLORS } from '@/shared/constants/colors';
import { useCreateBeneficiaryMutation } from '@/shared/generated/graphql';
import {
  OmitedBaseDto,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { useFormRender } from '@/shared/lib/hooks/use-form-render';
import { useFormSave } from '@/shared/lib/hooks/use-form-save';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { DocumentType } from '@/shared/lib/sj-orm/models/document/document-base.dto';
import { PrivateDocumentType } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export const FormsByCollection = {
  [CollectionName.ASSETS]: AssetsForms,
  [CollectionName.CONTACTS]: ContactsForms,
  [CollectionName.PRIVATE_DOCUMENTS]: DocumentsForms,
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: DocumentsForms,
  [CollectionName.BENEFICIARIES]: NextOwnerCreateForm,
};

type TFormType = TDtoWrapper<
  OmitedBaseDto,
  { [TK in keyof OmitedBaseDto]: QuestionType }
>;

export const FormDrawerProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter();
  const [form, setForm] = useState<TFormType | undefined>();
  const [collectionName, setCollectionName] = useState<
    CollectionName | undefined
  >();
  const [type, setType] = useState<string | undefined>();

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (router.query.fastAdd) {
      const [collection, item] = (router.query.fastAdd as string).split(
        ':',
      ) as [
        (
          | CollectionName.ASSETS
          | CollectionName.CONTACTS
          | CollectionName.PRIVATE_DOCUMENTS
          | CollectionName.BENEFICIARIES
        ),
        string,
      ];

      const forms = FormsByCollection[collection] as
        | Record<string, TFormType | TFormType[] | undefined>
        | undefined;

      if (!forms) return;

      const form_ =
        collection === CollectionName.BENEFICIARIES
          ? forms
          : forms[
              CollectionName.PRIVATE_DOCUMENTS === collection
                ? PrivateDocumentType.UNILATERAL
                : item
            ];

      //   const result = []

      const resultForm: TFormType = {} as TFormType;

      const formForMap = Array.isArray(form_) ? form_[0] : form_;

      if (!formForMap) {
        setForm(undefined);
        return;
      }

      for (const [key, val] of Object.entries(formForMap)) {
        if (val.required || collection === CollectionName.BENEFICIARIES)
          resultForm[key as keyof TFormType] = val;
      }

      setCollectionName(collection);
      setForm(resultForm);
      setType(item);
    }
  }, [router.query]);

  const close = useCallback(async () => {
    setForm(undefined);
    await router.replace({
      query: {
        ...router.query,
        fastAdd: undefined,
      },
    });
  }, [router]);

  const collectionNameByType = useMemo(() => {
    switch (collectionName) {
      case CollectionName.ASSETS:
        return 'Asset';
      case CollectionName.CONTACTS:
        return 'Contact';
      case CollectionName.PRIVATE_DOCUMENTS:
        return 'Document';
      case CollectionName.BENEFICIARIES:
        return 'Next Owner';
      case CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS:
        return 'ID';
      default:
        return 'item';
    }
  }, [collectionName]);
  return (
    <>
      {!!form && !!collectionName && !!type && (
        <StyledANTDDrawer
          height={'70vh'}
          placement="bottom"
          closable={false}
          onClose={close}
          open={!!form}
        >
          <Row justifyContent="space-between" alignCenter nowrap>
            <Typography.Title level={4} style={{ margin: 0 }}>
              Add {collectionNameByType}
            </Typography.Title>

            <Icon
              icon="close"
              cursorPointer
              onClick={close}
              color={COLORS.colorLink}
            />
          </Row>
          <Container marginTop={24}>
            <Form
              form={form}
              close={close}
              collectionName={collectionName}
              type={type}
            />
          </Container>
        </StyledANTDDrawer>
      )}

      {children}
    </>
  );
};

const additionalByCollection: Partial<
  Record<CollectionName, Record<string, unknown>>
> = {
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: {
    type: DocumentType.PERSONAL_IDENTIFIERS,
    beneficiaryId: 'me',
  },
  [CollectionName.PRIVATE_DOCUMENTS]: {
    type: DocumentType.PRIVATE_DOCUMENTS,
    privateType: PrivateDocumentType.UNILATERAL,
  },
  [CollectionName.BENEFICIARIES]: {},
};

const keyByCollectionName: Partial<Record<CollectionName, string>> = {
  [CollectionName.ASSETS]: 'category',
  [CollectionName.CONTACTS]: 'type',
  [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: 'personalIdentifierType',
  [CollectionName.PRIVATE_DOCUMENTS]: 'privateCategory',
};

const Form = ({
  form,
  close,
  collectionName,
  type,
}: {
  form: TFormType;
  close: () => void;
  collectionName: CollectionName;
  type: string;
}) => {
  const { finish } = useFormSave({
    updateOrSave: 'save',
    collectionName,
    initialDtoData: {
      [keyByCollectionName[collectionName] ?? 'type']: type,
      ...(additionalByCollection[collectionName] || {}),
    },
  });

  const [createBeneficiaryMutation] = useCreateBeneficiaryMutation();

  const onSave = async (data: OmitedBaseDto & { sendInvite?: boolean }) => {
    let additionalData = {};

    if (collectionName === CollectionName.BENEFICIARIES) {
      const centralDbBeneficiary = await createBeneficiaryMutation({
        variables: {
          email: (data as unknown as { email: string }).email,
          dontSendInviteEmail: !cloneDeep(data.sendInvite) ?? true,
        },
      });

      additionalData = {
        centralDbProfileId:
          centralDbBeneficiary.data?.createBeneficiary.account.id,
        invitationCode:
          centralDbBeneficiary.data?.createBeneficiary.invitationCode,
        linkedCountryCode: '',
      };
    }

    if (data.sendInvite) {
      delete data.sendInvite;
    }
    console.log('on save', { ...data, ...additionalData });

    finish({ ...data, ...additionalData });
    close();
  };

  const { component } = useFormRender({ form, onSubmit: onSave });

  return component;
};

const StyledANTDDrawer = styled(ANTDDrawer)`
  .ant-drawer-body::-webkit-scrollbar {
    display: none !important;
  }

  .ant-drawer-body {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  .ant-drawer-footer {
    padding: 24px !important;
    border-top: unset !important;
  }
`;
