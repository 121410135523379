import { useEffect, useMemo, useState } from 'react';
import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import * as Sentry from '@sentry/nextjs';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';

import { BlockLink } from '@/shared/services/apollo/block-link';
import { useAuthStore } from '@/shared/store/auth.store';
// import { log } from '@/shared/utils/log';

type ApolloClientType = ApolloClient<NormalizedCacheObject>;
interface ApolloClientParameters {
  cache?: ApolloCache<NormalizedCacheObject>;
  authorizationHeader?: string;
}

// https://www.apollographql.com/docs/react/api/link/introduction/
// https://blog.sentry.io/handling-graphql-errors-using-sentry/
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      Sentry.withScope((scope) => {
        scope.setTag('code', String(extensions.code));
        scope.setExtra('code', String(extensions.code));
        scope.setExtra('message', message);
        Sentry.captureException(new Error('graphQLErrors'));
      });
    });
  }

  if (networkError) {
    Sentry.withScope(() => {
      Sentry.captureException(new Error('Network error'));
    });
  }
});

function getLink(authHeader: string | undefined): ApolloLink | undefined {
  // log.debug(
  //   'getLink, process.env.NEXT_PUBLIC_APP_MODE',
  //   process.env.NEXT_PUBLIC_APP_MODE,
  // );

  if (process.env.NEXT_PUBLIC_APP_MODE === 'EXPORTED') {
    return new BlockLink();
  } else {
    const isMergedEnabled =
      process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_ENABLED === 'true';
    const isBrowser = typeof window !== 'undefined';

    if (isMergedEnabled && isBrowser) {
      return new BatchHttpLink({
        uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
        batchMax: Number.parseInt(
          process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_MAX || '20',
        ), // No more than 7 operations per batch
        batchInterval: Number.parseInt(
          process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_BATCH_INTERVAL ||
            '20',
        ), // Wait no more than 20ms after first batched operation
      });
    }
  }
  return from([
    errorLink,
    new HttpLink({
      uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
      ...(authHeader && {
        headers: {
          authorization: authHeader,
        },
      }),
    }),
  ]);
}

export function getApolloClient({
  cache,
  authorizationHeader,
}: ApolloClientParameters): ApolloClientType {
  return new ApolloClient({
    link: getLink(authorizationHeader),
    ...(authorizationHeader && {
      headers: {
        authorization: authorizationHeader,
      },
    }),
    uri:
      process.env.NEXT_PUBLIC_MERGED_GRAPHQL_REQUESTS_ENABLED === 'true' &&
      typeof window !== 'undefined'
        ? undefined
        : process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
    cache: cache || new InMemoryCache(),
    connectToDevTools: process.env.NEXT_PUBLIC_DEBUG === 'true',
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

export function useApolloClient(): ApolloClientType {
  //todo refactor

  const authStore = useAuthStore();
  const [client, setClient] = useState(
    getApolloClient({
      authorizationHeader: authStore.token,
    }),
  );
  //костыльно обновил старницу чтоб стейт обновился ибо токена нет в хэдерах)
  useEffect(() => {
    (async (): Promise<void> => {
      if (process.env.NEXT_PUBLIC_PERSIST_CACHE === 'true') {
        await persistCache({
          cache: client.cache,
          storage: new LocalStorageWrapper(window.localStorage),
        });
      }

      setClient(
        getApolloClient({
          cache: client.cache,
          authorizationHeader: authStore.token,
        }),
      );
    })();
  }, [authStore.token]);

  return useMemo(() => {
    // if (process.env.NEXT_PUBLIC_PERSIST_CACHE === 'true') {
    //   await persistCache({
    //     cache: client.cache,
    //     storage: new LocalStorageWrapper(window.localStorage),
    //   });
    // }

    return getApolloClient({
      cache: client.cache,
      authorizationHeader: authStore.token,
    });
  }, [authStore]);

  // return client;
}
