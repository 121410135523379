import { ReactNode } from 'react';
import styled from 'styled-components';

import { Container, IContainer } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';

export const ContainerBG = ({
  color,
  colorMobile,
  borderDesktop = true,
  borderMobile = true,
  boxShadowDesktop = true,
  boxShadowMobile = true,
  height,
  children,
  ...props
}: {
  borderDesktop?: boolean;
  borderMobile?: boolean;
  boxShadowDesktop?: boolean;
  boxShadowMobile?: boolean;
  color?: string;
  colorMobile?: string;
  height?: string;
  children: ReactNode;
}) => {
  return (
    <Div
      width="100%"
      color={color}
      colorMobile={colorMobile}
      borderDesktop={borderDesktop}
      borderMobile={borderMobile}
      boxShadowDesktop={boxShadowDesktop}
      boxShadowMobile={boxShadowMobile}
      height={height}
      {...props}
    >
      {children}
    </Div>
  );
};

const Div = styled(Container)<IContainer>`
  height: ${({ height }) => (height ? height : '')};

  @media screen and (min-width: 992px) {
    background-color: ${({ color }) =>
      color ? color : COLORS.colorBgContainer};
    box-shadow: ${(p) =>
      p.boxShadowDesktop
        ? `
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    `
        : ''};
    border: ${(p) =>
      p.borderDesktop ? `1px solid ${COLORS.colorBorder}` : ''};
  }

  @media screen and (max-width: 991px) {
    background-color: ${({ colorMobile }) =>
      colorMobile ? colorMobile : COLORS.colorBgContainer};
    box-shadow: ${(p) =>
      p.boxShadowMobile
        ? `
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08);
    `
        : ''};
    border: ${(p) => (p.borderMobile ? `1px solid ${COLORS.colorBorder}` : '')};
  }
`;
