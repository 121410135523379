import type { ChangeEvent, FC } from 'react';
import { Input, Select } from 'antd';

import { Row } from '@/shared/components/layout';
import { PhoneCodesList } from '@/shared/constants/countries-list';

interface IInputPhonerNumber {
  placeholder: string;
  codes?: Array<string>;
  setPhoneNumber: (value: string) => void;
  setPhoneCode: (value: string) => void;
}

export const InputPhoneNumber: FC<IInputPhonerNumber> = ({
  placeholder,
  setPhoneNumber,
  setPhoneCode,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhoneNumber(e.target.value);
  };

  const handleChange = (value: string) => {
    setPhoneCode(value);
  };

  return (
    <Row nowrap gapColumn={4}>
      <Select
        onChange={handleChange}
        placeholder={'+000'}
        options={PhoneCodesList}
        size="large"
      />
      <Input type="number" placeholder={placeholder} onChange={onChange} />
    </Row>
  );
};
