import { useCallback, useMemo, useState } from 'react';

import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

import { OmitedBaseDto } from '../forms/form-wrapper.types';

type UseFormSaveInputType = {
  collectionName: CollectionName;
  initialDtoData?: Record<string, unknown>;
  additionalPublicKeys?: PublicKey[];
  updateOrSave?: 'save' | 'update';
};

export const useFormSave = ({
  collectionName,
  additionalPublicKeys = [],
  initialDtoData = {},
  updateOrSave = 'save',
}: UseFormSaveInputType) => {
  const [formCache, setFormCache] = useState<
    Omit<BaseDto, 'id'> | Record<string, unknown>
  >(initialDtoData);
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();

  const onSubmit = useCallback((data: BaseDto | OmitedBaseDto) => {
    setFormCache((prevState) => ({ ...prevState, ...data }));
  }, []);

  const finish = useCallback(
    (overrideData?: BaseDto | OmitedBaseDto) => {
      const operation = updateOrSave === 'update' ? 'update' : 'create';

      const id =
        updateOrSave === 'save'
          ? sjDb.generateId(collectionName)
          : (overrideData as BaseDto)?.id;

      console.log('save params', {
        id,
        ...formCache,
        ...(overrideData ? overrideData : {}),
      });

      const allData = {
        id,
        ...formCache,
        ...(overrideData ?? {}),
      } as BaseDto & { name?: string; nickname?: string; nickName?: string };

      // eslint-disable-next-line security/detect-object-injection
      return sjDb.collections[collectionName]?.[operation](
        allData as never,
        [keyPair?.publicKey || ''].concat(additionalPublicKeys),
      );
    },
    [formCache, sjDb],
  );

  return useMemo(
    () => ({ onSubmit, finish, formCache }),
    [onSubmit, finish, formCache, sjDb],
  );
};

export const TODO = 2;
