import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { withExportedCheck } from '@/shared/utils/with-exported-check';

export const FavoritesButton = ({
  onClick,
  isFavorite,
}: {
  onClick?: (element: React.MouseEvent) => void;
  isFavorite: boolean | undefined;
}) => {
  return (
    <ButtonWrapper onClick={withExportedCheck(onClick) || undefined}>
      <img
        src={
          isFavorite
            ? '/assets/icons/star_filled.svg'
            : '/assets/icons/star_outline.svg'
        }
        alt=""
      />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  background-color: transparent;
  border: 1px solid ${COLORS.colorBorder};
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${withExportedCheck('pointer')};

  &:hover {
    cursor: pointer;
  }
`;
