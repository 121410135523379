import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { isExported } from '@/shared/constants/constants';
import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';

type IDecryptedKeyPairState = {
  keyPair: KeyPair | undefined;
  setKeyPair: (keyPair: KeyPair) => void;
  clear: () => void;
};

export const useKeyPairStore = isExported
  ? create(
      persist<IDecryptedKeyPairState>(
        (set) => ({
          keyPair: undefined,
          setKeyPair: (keyPair: KeyPair): void => set({ keyPair }),
          clear: (): void => set({ keyPair: undefined }),
        }),
        {
          name: 'keypair-storage',
          storage: createJSONStorage(() => sessionStorage),
        },
      ),
    )
  : create<IDecryptedKeyPairState>((set) => ({
      keyPair: undefined,
      setKeyPair: (keyPair: KeyPair): void => set({ keyPair }),
      clear: (): void => set({ keyPair: undefined }),
    }));
