import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import {
  assetsDocumentsPageBuilder,
  AssetsDocumentsPagePartType,
} from '@/entities/assets/helpers/assets-documents-page-builder';
import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { ContactType } from '../../sj-orm/models/contact.dto';

export enum SecuritiesAndStocksEnum {
  adr = 'adr',
  edr = 'edr',
  gdr = 'gdr',
  depositaryReceipts = 'depositaryReceipts',
  preferredStock = 'preferredStock',
  regular = 'regular',
}

const SecuritiesAndStocksList = [
  {
    label: 'forms_assets:stocks.enums.type.adr',
    key: SecuritiesAndStocksEnum.adr,
  },
  {
    label: 'forms_assets:stocks.enums.type.edr',
    key: SecuritiesAndStocksEnum.edr,
  },
  {
    label: 'forms_assets:stocks.enums.type.gdr',
    key: SecuritiesAndStocksEnum.gdr,
  },
  {
    label: 'forms_assets:stocks.enums.type.depositaryReceipts',
    key: SecuritiesAndStocksEnum.depositaryReceipts,
  },
  {
    label: 'forms_assets:stocks.enums.type.preferredStock',
    key: SecuritiesAndStocksEnum.preferredStock,
  },
  {
    label: 'forms_assets:stocks.enums.type.regular',
    key: SecuritiesAndStocksEnum.regular,
  },
];

enum TradeType {
  etc = 'etc',
  otc = 'otc',
}

const TradeTypesList = [
  { label: 'forms_assets:stocks.enums.tradeType.etc', key: TradeType.etc },
  { label: 'forms_assets:stocks.enums.tradeType.otc', key: TradeType.otc },
];

export enum ManagerialRightsType {
  yes = 'yes',
  no = 'no',
  proportionally = 'proportionally',
  disproportionateUpward = 'disproportionateUpward',
  disproportionateDownward = 'disproportionateDownward',
}

const ManagerialRightsTypesList = [
  {
    label: 'forms_assets:stocks.enums.managementRights.yes',
    key: ManagerialRightsType.yes,
  },
  {
    label: 'forms_assets:stocks.enums.managementRights.no',
    key: ManagerialRightsType.no,
  },
  {
    label: 'forms_assets:stocks.enums.managementRights.proportionally',
    key: ManagerialRightsType.proportionally,
  },
  {
    label: 'forms_assets:stocks.enums.managementRights.disproportionateUpward',
    key: ManagerialRightsType.disproportionateUpward,
  },
  {
    label:
      'forms_assets:stocks.enums.managementRights.disproportionateDownward',
    key: ManagerialRightsType.disproportionateDownward,
  },
];

export enum ClassOfSharesType {
  A = 'classA',
  B = 'classB',
  C = 'classC',
}

const ClassOfSharesTypesList = [
  { label: 'forms_assets:stocks.enums.class.A', key: ClassOfSharesType.A },
  { label: 'forms_assets:stocks.enums.class.B', key: ClassOfSharesType.B },
  { label: 'forms_assets:stocks.enums.class.C', key: ClassOfSharesType.C },
];

export enum StrategyType {
  growthStock = 'growthStock',
  valueStock = 'valueStock',
}

const StrategyTypesList = [
  {
    label: 'forms_assets:stocks.enums.strategy.growthStock',
    key: StrategyType.growthStock,
  },
  {
    label: 'forms_assets:stocks.enums.strategy.valueStock',
    key: StrategyType.valueStock,
  },
];

type SecuritiesAndStocksPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  type: SecuritiesAndStocksEnum;
  purchaseValue: number;
  keyAssets: string;
  amount: number;
  holder: DtoId[];
};

type SecuritiesAndStocksPart2Type = Omit<BaseDto, 'id'> & {
  purchasePrice?: number;
  isin?: string;
  ticker?: string;
  issuer?: string;
  acquisitionDate?: Date;
  brokerageAccountNumber?: string;
  depositaryName?: string;
  depositaryAccountNumber?: string;
  tradeType?: TradeType;
  seller?: string;
  tradingPlatform?: string;
  shareOfOwnership?: number;
  managementRights?: ManagerialRightsType;
  classOfShares?: ClassOfSharesType;
  strategyType?: StrategyType;
  strategyDescription?: string;
};

type SecuritiesAndStocksHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR; // TODO: add currency
  keyAssets: QuestionType.TEXT;
  amount: QuestionType.NUMBER;
  holder: QuestionType.PICK_ARRAY_OF_DTO;
};

type SecuritiesAndStocksHelpPart2Type = {
  //asset data block
  purchasePrice?: QuestionType.CURRENCY_CALCULATOR; // or QuestionType.CURRENCY_AMOUNT ?!
  isin?: QuestionType.TEXT;
  ticker?: QuestionType.TEXT;
  issuer?: QuestionType.TEXT;
  acquisitionDate?: QuestionType.DATE_TIME;
  brokerageAccountNumber?: QuestionType.TEXT;
  depositaryName?: QuestionType.TEXT;
  depositaryAccountNumber?: QuestionType.TEXT;
  tradeType?: QuestionType.SELECT_FROM_LIST;
  seller?: QuestionType.TEXT;
  tradingPlatform?: QuestionType.TEXT;
  managementRights?: QuestionType.SELECT_FROM_LIST;
  classOfShares?: QuestionType.SELECT_FROM_LIST;
  strategyType?: QuestionType.SELECT_FROM_LIST;
  strategyDescription?: QuestionType.TEXT_MULTILINE;
};

type SecuritiesAndStocksFormPart1Type = TDtoWrapper<
  SecuritiesAndStocksPart1Type,
  SecuritiesAndStocksHelpPart1Type
>;

type SecuritiesAndStocksFormPart2Type = TDtoWrapper<
  SecuritiesAndStocksPart2Type,
  SecuritiesAndStocksHelpPart2Type
>;

export type SecuritiesAndStocksFormType = [
  SecuritiesAndStocksFormPart1Type,
  SecuritiesAndStocksFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
  AssetsDocumentsPagePartType,
];

const SecuritiesAndStocksFormPart1: SecuritiesAndStocksFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.type.label',
    tooltip: 'forms_assets:stocks.inputs.type.tooltip',
    value: SecuritiesAndStocksEnum.adr,
    required: true,
    items: SecuritiesAndStocksList,
  },
  amount: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:stocks.inputs.amount.label',
    value: 0,
    min: 0,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:stocks.inputs.marketValue.label',
    tooltip: 'forms_assets:stocks.inputs.marketValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
  keyAssets: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.keyAssets.label',
    tooltip: 'forms_assets:stocks.inputs.keyAssets.tooltip',
    value: '',
  },
  holder: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:stocks.inputs.holder.label',
    items: 'contacts',
    value: [],
    tooltip: 'forms_assets:stocks.inputs.holder.tooltip',
    dtoType: ContactType.BANK,
  },
};
const SecuritiesAndStocksFormPart2: SecuritiesAndStocksFormPart2Type = {
  purchasePrice: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:stocks.inputs.purchasePrice.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    order: 1,
  },
  isin: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.isin.label',
    tooltip: 'forms_assets:stocks.inputs.isin.tooltip',
    order: 2,
  },
  ticker: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.ticker.label',
    tooltip: 'forms_assets:stocks.inputs.ticker.tooltip',
    order: 3,
  },
  issuer: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.issuer.label',
    tooltip: 'forms_assets:stocks.inputs.issuer.tooltip',
    order: 4,
  },
  acquisitionDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:stocks.inputs.acquisitionDate.label',
    order: 5,
  },
  brokerageAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.brokerageAccountNumber.label',
    order: 6,
  },
  depositaryName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.depositaryName.label',
    order: 8,
  },
  depositaryAccountNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.depositaryAccountNumber.label',
    order: 9,
  },
  tradeType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:stocks.inputs.tradeType.label',
    items: TradeTypesList,
    order: 10,
  },
  seller: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.seller.label',
    showIf: {
      tradeType: {
        value: TradeType.otc,
      },
    },
    order: 11,
  },
  tradingPlatform: {
    type: QuestionType.TEXT,
    label: 'forms_assets:stocks.inputs.tradingPlatform.label',
    showIf: {
      tradeType: {
        value: TradeType.etc,
      },
    },
    order: 12,
  },
  managementRights: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:stocks.inputs.managementRights.label',
    tooltip: 'forms_assets:stocks.inputs.managementRights.tooltip',
    items: ManagerialRightsTypesList,
    order: 14,
  },
  classOfShares: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:stocks.inputs.classOfShares.label',
    tooltip: 'forms_assets:stocks.inputs.classOfShares.tooltip',
    items: ClassOfSharesTypesList,
    order: 15,
  },
  strategyType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:stocks.inputs.strategyType.label',
    items: StrategyTypesList,
    order: 16,
  },
  strategyDescription: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:stocks.inputs.strategyDescription.label',
    order: 17,
  },
};

const SecuritiesAndStocksForm: SecuritiesAndStocksFormType = [
  SecuritiesAndStocksFormPart1,
  SecuritiesAndStocksFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'specificsOfTaxation',
    'profitability',
    'publicFundTaxAgent',
    'publicTaxAgentOther',
    'taxAgent',
    'taxAgentOther',
  ]).form,
  assetsDocumentsPageBuilder().form,
];

export { SecuritiesAndStocksForm };
