import { useCallback, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';

import { useAssistantStore } from '@/entities/assistant/stores/assistant-store';
import { UploadFileDocument } from '@/shared/generated/graphql';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { EncryptedFileKeyDto } from '@/shared/lib/sj-orm/models/encrypted-file-key.dto';
import { Source } from '@/shared/lib/sj-orm/models/file-meta-info.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

export type FilePath = string;
export const useFileEncryptionUpload = () => {
  const cryptoCore = new CryptoCore();
  const gqlClient = useApolloClient();
  const sjDatabase = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const { requestId } = useAssistantStore();

  const encryptAndUploadFile = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (file: File | null): Promise<FilePath> => {
      if (file && keyPair) {
        const symmetricKey = cryptoCore.aes.generateKey();
        const encryptedFile = await cryptoCore.aes.encryptFile(
          file,
          symmetricKey,
        );
        const encryptedKey = cryptoCore.rsa.encrypt(
          symmetricKey,
          keyPair.publicKey,
        );
        let encryptedKeyRequest;
        if (requestId)
          encryptedKeyRequest = cryptoCore.rsa.encrypt(
            symmetricKey,
            sjDatabase.collections.delegatingRequests
              ?.getPublicKeys(requestId)
              ?.find((key) => key !== keyPair?.publicKey) ?? '',
          );
        const mutationResult = await gqlClient.mutate({
          mutation: UploadFileDocument,
          variables: {
            fileContent: encryptedFile,
          },
        });
        const newFileDto: EncryptedFileKeyDto = {
          id: `${mutationResult.data?.uploadFile}:${
            requestId
              ? sjDatabase.collections.delegatingRequests
                  ?.getPublicKeys(requestId)
                  ?.find((key) => key !== keyPair?.publicKey)
              : keyPair.publicKey
          }`, // format: <filePath>:<publicKey>
          encryptedSymmetricKey: requestId
            ? encryptedKeyRequest ?? ''
            : encryptedKey,
        };

        sjDatabase.collections.encryptedFileKey?.create(newFileDto, [
          keyPair.publicKey,
          ...(requestId && [
            sjDatabase.collections.delegatingRequests
              ?.getPublicKeys(requestId)
              ?.find((key) => key !== keyPair?.publicKey) ?? '',
          ]),
        ]);
        sjDatabase.collections.fileMetaInfos?.create(
          {
            id: sjDatabase.generateId(CollectionName.FILE_META_INFO),
            name: file.name,
            extension: `.${file.name.split('.').pop() ?? 'unknown'}`,
            filePath: mutationResult.data?.uploadFile,
            source: Source.FRONTEND,
          },
          [
            keyPair.publicKey,
            ...(requestId && [
              sjDatabase.collections.delegatingRequests
                ?.getPublicKeys(requestId)
                ?.find((key) => key !== keyPair?.publicKey) ?? '',
            ]),
          ],
        );
        return mutationResult.data?.uploadFile;
      } else {
        throw new Error('File or keyPair is not defined');
      }
    },
    [keyPair, gqlClient, cryptoCore, sjDatabase],
  );

  const getFileMeta = useCallback(
    (id: string) => {
      return sjDatabase.collections.fileMetaInfos?.findOne(
        (item) => item.id === id,
      );
    },
    [sjDatabase],
  );

  return useMemo(
    () => ({
      encryptAndUploadFile,
      getFileMeta,
    }),
    [encryptAndUploadFile, getFileMeta],
  );
};
