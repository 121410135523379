import { NotificationType } from '@/shared/lib/sj-orm/models/notification.dto';

export const NotificationTypeToText: Record<NotificationType, string> = {
  [NotificationType.TEST_SCENARIO_LAUNCHED]: 'Algorithm Launched in Test Mode',
  [NotificationType.TEST_SCENARIO_ACCEPTED]:
    'Test Algorithm Accepted by the Next Owner',
  [NotificationType.SCENARIO_CREATED]: 'Algorithm Created',
  [NotificationType.SCENARIO_ACTIVATED]: 'Algorithm Activated ',
  [NotificationType.SCENARIO_RESOLVED]: 'Algorithm Resolved ',
  [NotificationType.SCENARIO_DEACTIVATED]: 'Algorithm Turned Off ',
  [NotificationType.TRIGGER_SCENARIO_INFO]: 'Algorithm Trigger Activated',
  [NotificationType.SCENARIO_EXECUTED]: 'Algorithm Completed',

  [NotificationType.BENEFICIARY_CREATED]: 'Next Owner Profile Created',
  [NotificationType.BENEFICIARY_UPDATED]: 'Next Owner Profile Updated',
  [NotificationType.BENEFICIARY_DELETED]: 'Next Owner Profile Deleted',
  [NotificationType.BENEFICIARY_SENT_INVITATION]:
    'Invitation Sent to Next Owner',
  [NotificationType.BENEFICIARY_ACCEPT_INVITATION]:
    'Next Owner Accepted Invitation',

  [NotificationType.PASSWORD_CHANGE]: 'Password Changed',
  [NotificationType.CHECK_PLAN]: 'Check plan',
  [NotificationType.DATA_TRANSFERRED_TO_MY_HUB]: 'Data Uploaded to My HUB',
  [NotificationType.MY_HUB_CREATED]: 'My HUB is ready',

  [NotificationType.SENT_REQUEST_TO_ASSISTANT]: 'Request Sent to Assistant',
  [NotificationType.ASSISTANT_FILLED_FORM]: 'Assistant Completed Filling In',

  [NotificationType.DOCUMENT_CREATED]: 'Document Created',
  [NotificationType.DOCUMENT_DELETED]: 'Document Deleted',
  [NotificationType.FILED_ADDED_TO_INBOX]: 'New files added to Inbox',
  [NotificationType.FILED_DELETED_FROM_INBOX]: 'Files deleted from Inbox',
  [NotificationType.DOCUMENTS_BY_ASSISTANT]: 'Documents by assistant',

  [NotificationType.CONTACT_CREATED]: 'Contact Created',
  [NotificationType.CONTACT_DELETED]: 'Contact Deleted',

  [NotificationType.ASSET_DELETED]: 'Asset Deleted',
  [NotificationType.ASSET_CREATED]: 'Asset Created',
  [NotificationType.ASSET_UPDATED]: 'Asset Data Updated',

  [NotificationType.SCENARIO_BENEFICIARY_ACCEPT_INVITATION]:
    'Next Owner Accept Invitation',
  [NotificationType.SCENARIO_ACCEPTED]:
    'Next Owner Accepted Transfer Algorithm',
  [NotificationType.BENEFICIARY_RECEIVE_ASSET]: 'Receive Asset',
  [NotificationType.BENEFICIARY_REJECT_ASSET]:
    'Next Owner Reject Transfer Algorithm',
  [NotificationType.NEW_ITEM_SHARED]: 'New item shared',
  [NotificationType.NO_MODIFY_SHARED_ITEM]: 'Next Owner Modify Shared Item',
};
