import { useRouter } from 'next/router';
import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { TBackButtonProps } from '@/shared/components/back-button/types';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
export function BackButton({
  overrideOnclick,
  iconColor,
  children,
}: TBackButtonProps): JSX.Element {
  const router = useRouter();

  return (
    <Button
      ghost
      style={{ height: 40 }}
      onClick={() => (overrideOnclick ? overrideOnclick() : router.back())}
      type="link"
      data-testid={'backBtn'}
    >
      <Row nowrap alignCenter gapColumn={8}>
        <LeftOutlined
          color={iconColor ? iconColor : COLORS.colorLink}
          style={{ fontSize: 18 }}
        />

        {children}
      </Row>
    </Button>
  );
}
