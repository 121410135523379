export const COLORS = {
  black: '#000000',
  grey: '#0d172459',
  grey50percent: '#0d172480',
  white: '#FFFFFF',

  colorLink: '#00043099',
  colorLinkHover: '#000430',
  colorLinkActive: '#000430a3',

  colorIcon: '#000430a3',
  colorIconHover: '#26251C',

  colorPrimaryText: '#000430',
  colorPrimaryTextActive: '#0f1723cc',
  colorPrimaryTextHover: '#0f1723cc',

  colorTextBase: '#0F1723',
  colorTextSecondary: '#2F4F7C',
  colorTextTertiary: '#2F4F7C',
  colorTextQuaternary: '#2F4F7C',

  colorBgBase: '#FFFFFF',
  colorBgLayout: '#FFFFFF',
  colorBgSpotlight: '#17150D',
  controlItemBgHover: '#0004303d',
  controlItemBgActive: '#0004301f',
  controlItemBgActiveHover: '#2F4F7C',

  colorPrimaryActive: '#000430',
  colorPrimary: '#000430',
  colorPrimaryHover: '#000430cc',
  colorPrimaryBorder: '#0004301f',
  colorPrimaryBorderFocus: '#000430cc',
  colorPrimaryBorderHover: '#000430cc',

  colorPrimaryBg: '#F4F7FB',
  colorPrimaryBgHover: '#0004301f',

  colorBgContainer: '#FFFFFF',

  colorFillContent: '#0004301f',
  colorTextDescription: '#000430a3',
  colorNeutral: '#595956',
  colorNeutral2: '#F2F2EB',
  colorBorder: '#0004301f',

  colorSelectActiveOption: '#E6EEFF',

  colorButton: '#0D1724',

  light300: '#EAEDF1',
  colorGrey: '#9A9585',
  colorCustom: '#BF951C',
  colorBlue: '#0F75BD',
  colorRed: '#EF3E36',
  colorGreen: '#52d14e',

  colorText: '#000',
  colorPrimary3: '#E5C97B',
  colorPrimary5: '#CAB16C',
  colorPrimary10: '#8C7B4B',
  colorTextLight: '#FFFFFF',
};

// dark-navi  12%: #0004301f
// dark-navi  24%: #0004303d
// dark-navi  64%: #000430a3
// dark-navi  80%: #000430cc
// dark-navi 100%: #000430

// red #ff0000
