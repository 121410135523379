import { useCallback, useMemo } from 'react';

import { useCreateLogMutation, useGetEventLogsCountLazyQuery, useGetLogsLazyQuery } from '@/shared/generated/graphql';
import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { log } from '@/shared/utils/log';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { SJDatabase } from '@/shared/lib/sj-orm/core';

export function useEventLog() {
  const [createLogMutation] = useCreateLogMutation();
  const [loadLogs] = useGetLogsLazyQuery({ fetchPolicy: 'no-cache' });
  const [loadLogsCount] = useGetEventLogsCountLazyQuery({ fetchPolicy: 'no-cache' });
  const keyPairStore = useKeyPairStore();
  const cryptoCore = new CryptoCore();

  const getLogsCount = useCallback(async (from: Date, to: Date, dtoId?: string) => {
    return (await loadLogsCount({
      variables: dtoId ? { from, to, forDtoId: dtoId } : { from, to },
      fetchPolicy: 'no-cache',
    })).data?.getEventLogs.totalCount ?? 0;
  }, [loadLogsCount]);


  const createLog = useCallback(
    async (publicData: object, dataForEncrypt: object | { name: string, description: string }) => {
      try {
        const decryptedSymmetricKey = cryptoCore.aes.generateKey();
        const encryptedSymmetricKey = cryptoCore.rsa.encrypt(
          decryptedSymmetricKey,
          keyPairStore.keyPair!.publicKey,
        );
        const publicDataString = JSON.stringify({
          ...publicData,
          __encryptedSymmetricKey: encryptedSymmetricKey,
        });
        const dataForEncryptString = JSON.stringify(dataForEncrypt);

        const encryptedData = cryptoCore.aes.encrypt(
          dataForEncryptString,
          decryptedSymmetricKey,
        );

        const { data } = await createLogMutation({
          variables: { publicData: publicDataString, encryptedData },
        });
        return data?.createEventLog;
      } catch (e) {
        log.error('[EVENT_LOG]: Error create event log', e);
        return undefined;
      }
    },
    [keyPairStore.keyPair],
  );

  const getDtoByDtoId = (dtoId: string, sjdb: SJDatabase): BaseDto | undefined => {
    const collectionName = dtoId.split('-')[0] as CollectionName;
    return sjdb.collections[collectionName]?.findOne((item) => item.id === dtoId);
  }

  const getLogs = useCallback(
    async (
      fromDateTime: Date,
      toDateTime: Date,
      take: number,
      skip: number,
      sjdb: SJDatabase,
      dtoId?: string,
    ) => {
      const { data } = await loadLogs({
        variables: dtoId ? { fromDateTime, toDateTime, take, skip, forDtoId: dtoId } : {
          fromDateTime,
          toDateTime,
          take,
          skip,
        },
        fetchPolicy: 'no-cache',
      });

      const items = data?.getEventLogs?.items.map((log) => {
        try {
          const publicData = JSON.parse(log.publicData);

          console.log(log, 'public data ');

          const decryptedSymmetricKey = cryptoCore.rsa.decrypt(
            publicData.__encryptedSymmetricKey,
            keyPairStore.keyPair!.privateKey,
          );

          const decryptedData = cryptoCore.aes.decrypt(
            log.encryptedData,
            decryptedSymmetricKey,
          );

          const parsedData = JSON.parse(decryptedData);
          let dtoName = '[Unknown]';
          if (parsedData.dtoId) {
            const dto = getDtoByDtoId(parsedData.dtoId, sjdb);
            if (dto){
              const dtoAsAny = dto as any;
              dtoName = dtoAsAny.name || dtoAsAny.nickname || dtoAsAny.nickName || `Item with id ${dtoId}`;
            }
          }

          for (const key in parsedData) {
            if (typeof parsedData[key] === 'string') {
              parsedData[key] = parsedData[key].replace(/undefined/g, dtoName);
            }
          }

          return {
            ...log,
            encryptedData: parsedData as { name: string, description: string },
            publicData,
          };
        } catch (e) {
          console.warn('[EVENT_LOG]: Error decrypt event log', e);
          return { ...log, encryptedData: {} as { name: string, description: string }, publicData: {} };
        }
      });

      return {
        totalCount: data?.getEventLogs?.totalCount,
        items,
      };
    },
    [loadLogs, keyPairStore.keyPair],
  );

  return useMemo(() => ({
    createLog,
    getLogs,
    getLogsCount,
  }), [createLog, getLogs, getLogsCount]);
}
