import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { SJDatabase } from '@/shared/lib/sj-orm/core';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { log } from '@/shared/utils/log';

export const getDtoRelatedToTargetDto = (
  sjdb: SJDatabase,
  targetDto: BaseDto,
  searchForIncludedDtos: boolean = true,
  currentMap: Map<CollectionName, Set<BaseDto>> = new Map(),
  initialDto?: BaseDto,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Map<CollectionName, Set<BaseDto>> => {
  // eslint-disable-next-line no-param-reassign
  if (!initialDto) initialDto = targetDto;
  // eslint-disable-next-line guard-for-in
  for (const currentCollectionName in sjdb.collections) {
    const collectionName = currentCollectionName as CollectionName;

    const allowedCollections = [
      CollectionName.SOWE,
      CollectionName.SOWE_ATTACHMENTS,
      CollectionName.ENCRYPTED_FILE_KEY,
      CollectionName.CONTACTS,
      CollectionName.FILE_META_INFO,
      CollectionName.PASSPORT_DOCUMENT,
      CollectionName.UNKNOWN_DOCUMENT,
      CollectionName.ASSET_INFORMATION_DOCUMENT,
      CollectionName.SOWE_ATTACHED_PERSONAL_IDENTIFIER,
      CollectionName.PRIVATE_DOCUMENTS,
      CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
      ...(!searchForIncludedDtos ? [CollectionName.ASSETS] : []),
      ...(!searchForIncludedDtos
        ? [CollectionName.ASSET_INFORMATION_DOCUMENT]
        : []),
    ];
    // If the collection is not in the allowedCollections, skip it
    if (!allowedCollections.includes(collectionName)) continue;

    const collection = sjdb.collections[collectionName];

    const allDtos: Array<BaseDto> = collection?.findMany(() => true) || [];

    for (const dto of allDtos) {
      if (isDtoAlreadyInMap(dto, currentMap)) continue;
      if (dto.id === initialDto.id) continue;

      if (isDtoRelatedToTargetDto(targetDto, dto, searchForIncludedDtos)) {
        const dtoSet = currentMap.get(collectionName) || new Set<BaseDto>();
        dtoSet.add(dto);
        currentMap.set(collectionName, dtoSet);

        log.trace(
          `Found related dto: ${dto.id} in collection ${collectionName}`,
        );

        // Prevent scanning 2+ nested levels
        // getDtoRelatedToTargetDto(sjdb, dto, currentMap, initialDto);
      }
    }
  }

  log.trace('getDtoRelatedToTargetDto', currentMap);
  return currentMap;
};

function isDtoAlreadyInMap(
  dto: BaseDto,
  map: Map<CollectionName, Set<BaseDto>>,
): boolean {
  for (const [, dtoSet] of map) {
    if (dtoSet.has(dto)) return true;
  }

  return false;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function isDtoRelatedToTargetDto(
  targetDto: BaseDto,
  expectedRelatedDto: BaseDto,
  searchForIncludedDtos: boolean,
): boolean {
  // Related conditions:
  // targetDto.[field] === expectedRelatedDto.id
  // expectedRelatedDto.[field] === targetDto.id
  // targetDto.[field].includes(expectedRelatedDto.id)
  // expectedRelatedDto.[field].includes(targetDto.id)
  // targetDto.id !== expectedRelatedDto.id

  const targetDtoFields = Object.keys(targetDto);
  const expectedRelatedDtoFields = Object.keys(expectedRelatedDto);

  if (searchForIncludedDtos) {
    for (const targetDtoField of targetDtoFields) {
      const targetDtoFieldValue =
        targetDto[targetDtoField as keyof typeof targetDto];
      if (Array.isArray(targetDtoFieldValue)) {
        for (const value of targetDtoFieldValue) {
          if (value === expectedRelatedDto.id) return true;
        }
      } else if (targetDtoFieldValue === expectedRelatedDto.id) return true;
    }
  }

  for (const expectedRelatedDtoField of expectedRelatedDtoFields) {
    const expectedRelatedDtoFieldValue =
      expectedRelatedDto[
        expectedRelatedDtoField as keyof typeof expectedRelatedDto
      ];
    if (Array.isArray(expectedRelatedDtoFieldValue)) {
      for (const value of expectedRelatedDtoFieldValue) {
        if (value === targetDto.id) return true;
      }
    } else if (expectedRelatedDtoFieldValue === targetDto.id) return true;
  }

  return targetDto.id === expectedRelatedDto.id;
}
