import { useMemo } from 'react';

import { GraphQLLoader } from '@/shared/lib/synchronizer/loaders/graphql-loader';
import { useApolloClient } from '@/shared/services/apollo/apollo-client.service';
import { useAuthStore } from '@/shared/store/auth.store';

export function useGqlLoader(): GraphQLLoader {
  const apolloClient = useApolloClient();
  const { token } = useAuthStore();
  return useMemo(() => new GraphQLLoader(apolloClient), [apolloClient, token]);
}
