import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import {
  assetsDocumentsPageBuilder,
  AssetsDocumentsPagePartType,
} from '@/entities/assets/helpers/assets-documents-page-builder';
import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import {
  AssetCategory,
  OwnershipList,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { PrivateDocumentCategory } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { ContactType } from '../../sj-orm/models/contact.dto';
import { PersonalIdentifierType } from '../../sj-orm/models/document/personal-identifier.document.dto';

export enum MyFundType {
  revocable = 'revocable',
  irrevocable = 'irrevocable',
}

const MyFundList = [
  {
    label: 'forms_assets:myFund.enums.fundType.revocable',
    key: MyFundType.revocable,
  },
  {
    label: 'forms_assets:myFund.enums.fundType.irrevocable',
    key: MyFundType.irrevocable,
  },
];

export enum TaskOfTheFundType {
  assetsProtection = 'assetProtection',
  assetsManagement = 'assetsManagement',
  assetTransfer = 'assetTransfer',
  confidentialityAssurance = 'confidentialityAssurance',
  financialOptimization = 'financialOptimization',
  other = 'other',
}

const TaskOfTheFundList = [
  {
    label: 'forms_assets:myFund.enums.task.assetsProtection',
    key: TaskOfTheFundType.assetsProtection,
  },
  {
    label: 'forms_assets:myFund.enums.task.assetsManagement',
    key: TaskOfTheFundType.assetsManagement,
  },
  {
    label: 'forms_assets:myFund.enums.task.assetTransfer',
    key: TaskOfTheFundType.assetTransfer,
  },
  {
    label: 'forms_assets:myFund.enums.task.confidentialityAssurance',
    key: TaskOfTheFundType.confidentialityAssurance,
  },
  {
    label: 'forms_assets:myFund.enums.task.financialOptimization',
    key: TaskOfTheFundType.financialOptimization,
  },
  {
    label: 'forms_assets:myFund.enums.task.other',
    key: TaskOfTheFundType.other,
  },
];

export enum ConfidentialityLevelType {
  openEnded = 'openEnded',
  closeEnded = 'closeEnded',
}

const ConfidentialityLevelList = [
  {
    label: 'forms_assets:myFund.enums.confidentialityLevel.openEnded',
    key: ConfidentialityLevelType.openEnded,
  },
  {
    label: 'forms_assets:myFund.enums.confidentialityLevel.closeEnded',
    key: ConfidentialityLevelType.closeEnded,
  },
];

export enum ManagementStructureType {
  direct = 'direct',
  trustAgreement = 'trustAgreement',
  nominalOwnership = 'nominalOwnership',
}

const ManagementStructureList = [
  {
    label: 'forms_assets:myFund.enums.managementStructure.direct',
    key: ManagementStructureType.direct,
  },
  {
    label: 'forms_assets:myFund.enums.managementStructure.trustAgreement',
    key: ManagementStructureType.trustAgreement,
  },
  {
    label: 'forms_assets:myFund.enums.managementStructure.nominalOwnership',
    key: ManagementStructureType.nominalOwnership,
  },
];

export enum ManagementBodyType {
  manager = 'manager',
  boardOfDirectors = 'boardOfDirectors',
  managementCompany = 'managementCompany',
  other = 'other',
}

const ManagementBodyList = [
  {
    label: 'forms_assets:myFund.enums.managementBody.manager',
    key: ManagementBodyType.manager,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.boardOfDirectors',
    key: ManagementBodyType.boardOfDirectors,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.managementCompany',
    key: ManagementBodyType.managementCompany,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.other',
    key: ManagementBodyType.other,
  },
];

export enum FundUsageStrategyType {
  strategic = 'strategic',
  personal = 'personal',
  commercial = 'commercial',
  investment = 'investment',
  other = 'other',
}

export const FundUsageStrategyList = [
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.strategic',
    key: FundUsageStrategyType.strategic,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.personal',
    key: FundUsageStrategyType.personal,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.investment',
    key: FundUsageStrategyType.investment,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.commercial',
    key: FundUsageStrategyType.commercial,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.other',
    key: FundUsageStrategyType.other,
  },
];

type MyFundPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  type: MyFundType;
  purchaseValue: number;
  fullLegalName: string;
};

type MyFundPart2 = Omit<BaseDto, 'id'> & {
  fundAssets?: DtoId[];
  taskOfTheFund?: TaskOfTheFundType;
  otherTaskOfTheFund?: string;
  keyCommitments?: string;
  typeOfBusiness?: string;
  organizationLegalForm?: string;
  fundUsageStrategy?: FundUsageStrategyType;
  otherFundUsageStrategy?: string;
  strategyDescription?: string;
  incorporationDate?: number;
  fundDuration?: boolean;
  fundDurationDate?: Date;
  prolongation?: boolean;
  prolongationDate?: number;
  taxNumber?: string;
  taxNumberIssuedBy?: string;
  lei?: string;
  legalEntityAddressLabel?: FormItemLabelType;
  district?: string;
  zipCode?: string;
  city?: string;
  street?: string;
  buildingNumber?: string;
  apartmentNumber?: string;
  otherAddressInfo?: string;
  fundRestrictions?: boolean;
  fundRestrictionsComments?: string;
  founderRestrictions?: boolean;
  founderRestrictionsComments?: string;
};

type MyFundPart3 = Omit<BaseDto, 'id'> & {
  // ownershipShare?: string;
  // beneficiaries?: boolean;
  // beneficiariesList?: DtoId[];
  confidentialityLevel?: ConfidentialityLevelType;
  managementStructure?: ManagementStructureType;
  managementBody?: ManagementBodyType;
  manager?: DtoId[];
  boardOfDirectors?: DtoId[];
  managementCompany?: DtoId[];
  other?: string;
  ownershipType?: string;
  owner?: DtoId;
  coOwner?: DtoId[];
  coOwnerDocument?: DtoId[];
  coOwnerShare?: number[];
  isWithCoOwners?: boolean;
  ownerShare?: number;
  coOwners?: { coOwner: DtoId; coOwnerDocument: DtoId; coOwnerShare: number }[];
  ownerDocument?: DtoId;
  otherOwnerDocuments?: DtoId[];
  fundFounder?: DtoId;
  fundFounderDocument?: DtoId;
  otherDocuments?: DtoId[];
  contactInfoLabel?: FormItemLabelType;
  address?: string;
  phoneNumber?: number;
  email?: string;
};

type MyFundHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.TEXT;
  type: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  fullLegalName: QuestionType.TEXT;
};

type MyFundHelpPart2 = {
  fundAssets?: QuestionType.PICK_ARRAY_OF_DTO;
  taskOfTheFund?: QuestionType.SELECT_FROM_LIST;
  otherTaskOfTheFund?: QuestionType.TEXT;
  keyCommitments?: QuestionType.TEXT;
  typeOfBusiness?: QuestionType.TEXT;
  organizationLegalForm?: QuestionType.TEXT;
  fundUsageStrategy?: QuestionType.SELECT_FROM_LIST;
  otherFundUsageStrategy?: QuestionType.TEXT;
  strategyDescription?: QuestionType.TEXT_MULTILINE;
  incorporationDate?: QuestionType.DATE_TIME;
  fundDuration?: QuestionType.CHECKBOX;
  fundDurationDate?: QuestionType.DATE_TIME;
  prolongation?: QuestionType.CHECKBOX;
  prolongationDate?: QuestionType.DATE_TIME;
  taxNumber?: QuestionType.TEXT;
  taxNumberIssuedBy?: QuestionType.TEXT;
  lei?: QuestionType.TEXT;
  district?: QuestionType.TEXT;
  zipCode?: QuestionType.TEXT;
  city?: QuestionType.TEXT;
  street?: QuestionType.TEXT;
  buildingNumber?: QuestionType.TEXT;
  apartmentNumber?: QuestionType.TEXT;
  otherAddressInfo?: QuestionType.TEXT_MULTILINE;
  fundRestrictions?: QuestionType.CHECKBOX;
  fundRestrictionsComments?: QuestionType.TEXT_MULTILINE;
  founderRestrictions?: QuestionType.CHECKBOX;
  founderRestrictionsComments?: QuestionType.TEXT_MULTILINE;
};

type MyFundHelpPart3 = {
  // ownershipShare?: QuestionType.TEXT;
  // beneficiaries?: QuestionType.CHECKBOX;
  // beneficiariesList?: QuestionType.PICK_ARRAY_OF_DTO;
  confidentialityLevel?: QuestionType.SELECT_FROM_LIST;
  managementStructure?: QuestionType.SELECT_FROM_LIST;
  managementBody?: QuestionType.SELECT_FROM_LIST;
  manager?: QuestionType.PICK_ARRAY_OF_DTO;
  boardOfDirectors?: QuestionType.PICK_ARRAY_OF_DTO;
  managementCompany?: QuestionType.PICK_ARRAY_OF_DTO;
  other?: QuestionType.TEXT;
  ownershipType?: QuestionType.SELECT_FROM_LIST;
  coOwners?: QuestionType.RENDER_NEW_BUTTON;
  isWithCoOwners?: QuestionType.CHECKBOX;
  owner?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwner?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwnerDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwnerShare?: QuestionType.NUMBER;
  ownerShare?: QuestionType.NUMBER;
  ownerDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  otherOwnerDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  fundFounder?: QuestionType.PICK_ARRAY_OF_DTO;
  fundFounderDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  address?: QuestionType.TEXT;
  phoneNumber?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
};

type MyFundFormType = [
  MyFundFormPart1Type,
  MyFundFormPart2Type,
  MyFundFormPart3Type,
  AssetsStatisticsPartType,
  AssetsDocumentsPagePartType,
];

type MyFundFormPart1Type = TDtoWrapper<MyFundPart1, MyFundHelpPart1>;
type MyFundFormPart2Type = TDtoWrapper<MyFundPart2, MyFundHelpPart2>;
type MyFundFormPart3Type = TDtoWrapper<MyFundPart3, MyFundHelpPart3>;

const MyFundFormPart1: MyFundFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  fullLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.fullLegalName.label',
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.type.label',
    tooltip: 'forms_assets:myFund.inputs.type.tooltip',
    required: true,
    value: MyFundType.revocable,
    items: MyFundList,
  },

  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:myFund.inputs.fundCapital.label',
    tooltip: 'forms_assets:myFund.inputs.fundCapital.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
};

const MyFundFormPart2: MyFundFormPart2Type = {
  fundAssets: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:myFund.inputs.fundAssets.label',
    order: 1,
    items: 'assets',
    mode: 'multiple',
    dtoType: AssetCategory.MY_FUND,
  },
  taskOfTheFund: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:myFund.inputs.taskOfTheFund.label',
    order: 2,
    items: TaskOfTheFundList,
  },
  otherTaskOfTheFund: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.otherTaskOfTheFund.label',
    order: 3,
    showIf: {
      taskOfTheFund: {
        value: TaskOfTheFundType.other,
      },
    },
  },
  keyCommitments: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.keyCommitments.label',
    tooltip: 'forms_assets:myFund.inputs.keyCommitments.tooltip',
    order: 4,
  },
  typeOfBusiness: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.typeOfBusiness.label',
    order: 5,
  },
  organizationLegalForm: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.organizationLegalForm.label',
    order: 6,
  },
  fundUsageStrategy: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:myFund.inputs.fundUsageStrategy.label',
    tooltip: 'forms_assets:myFund.inputs.fundUsageStrategy.tooltip',
    items: FundUsageStrategyList,
    order: 7,
  },
  otherFundUsageStrategy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.otherFundUsageStrategy.label',
    showIf: {
      fundUsageStrategy: {
        value: FundUsageStrategyType.other,
      },
    },
    order: 8,
  },
  strategyDescription: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:myFund.inputs.strategyDescription.label',
    order: 9,
  },
  incorporationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:myFund.inputs.incorporationDate.label',
    tooltip: 'forms_assets:myFund.inputs.incorporationDate.tooltip',
    order: 10,
  },
  fundDuration: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:myFund.inputs.fundDuration.label',
    order: 11,
  },
  fundDurationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:myFund.inputs.fundDurationDate.label',
    tooltip: 'forms_assets:myFund.inputs.fundDurationDate.tooltip',
    order: 12,
    showIf: {
      fundDuration: {
        value: true,
      },
    },
  },
  prolongation: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:myFund.inputs.prolongation.label',
    order: 13,
  },
  prolongationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:myFund.inputs.prolongationDate.label',
    tooltip: 'forms_assets:myFund.inputs.prolongationDate.tooltip',
    order: 14,
    showIf: {
      prolongation: {
        value: true,
      },
    },
  },
  taxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.taxNumber.label',
    order: 15,
  },
  taxNumberIssuedBy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.taxNumberIssuedBy.label',
    order: 16,
  },
  lei: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.lei.label',
    tooltip: 'forms_assets:common.inputs.lei.tooltip',
    order: 17,
  },
  fundRestrictions: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:myFund.inputs.fundRestrictions.label',
    order: 18,
  },
  fundRestrictionsComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:myFund.inputs.fundRestrictionsComments.label',
    order: 19,
    showIf: {
      fundRestrictions: {
        value: true,
      },
    },
  },
  founderRestrictions: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:myFund.inputs.founderRestrictions.label',
    order: 20,
  },
  founderRestrictionsComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:myFund.inputs.founderRestrictionsComments.label',
    order: 21,
    showIf: {
      founderRestrictions: {
        value: true,
      },
    },
  },
  legalEntityAddressLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:myFund.inputs.legalEntityAddressLabel.label',
    order: 22,
  },
  district: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.district.label',
    order: 23,
  },
  zipCode: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.zipCode.label',
    order: 24,
  },
  city: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.city.label',
    order: 25,
  },
  street: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.street.label',
    order: 26,
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.buildingNumber.label',
    order: 27,
  },
  apartmentNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.apartment.label',
    order: 28,
  },
  otherAddressInfo: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:myFund.inputs.otherAddressInfo.label',
    order: 29,
  },
};

const MyFundFormPart3: MyFundFormPart3Type = {
  // ownershipShare: {
  //   type: QuestionType.TEXT,
  //   label: 'forms_assets:myFund.inputs.ownershipShare.label',
  // },
  // beneficiaries: {
  //   type: QuestionType.CHECKBOX,
  //   label: 'forms_assets:myFund.inputs.beneficiaries.label',
  // },
  // beneficiariesList: {
  //   type: QuestionType.PICK_ARRAY_OF_DTO,
  //   label: 'forms_assets:myFund.inputs.beneficiariesList.label',
  //   items: 'contacts',
  //   mode: 'multiple',
  //   showIf: {
  //     beneficiaries: {
  //       value: true,
  //     },
  //   },
  // },
  confidentialityLevel: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:myFund.inputs.confidentialityLevel.label',
    items: ConfidentialityLevelList,
  },
  managementStructure: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:myFund.inputs.managementStructure.label',
    items: ManagementStructureList,
  },
  managementBody: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:publicFund.inputs.managementBody.label',
    items: ManagementBodyList,
  },
  manager: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.manager.label',
    items: 'contacts',
    showIf: {
      managementBody: {
        value: ManagementBodyType.manager,
      },
    },
    dtoType: ContactType.BANK,
  },
  boardOfDirectors: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.boardOfDirectors.label',
    items: 'contacts',
    mode: 'multiple',
    showIf: {
      managementBody: {
        value: ManagementBodyType.boardOfDirectors,
      },
    },
    dtoType: ContactType.BANK,
  },
  managementCompany: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.managementCompany.label',
    items: 'contacts',
    showIf: {
      managementBody: {
        value: ManagementBodyType.managementCompany,
      },
    },
    dtoType: ContactType.BANK,
  },
  other: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.other.label',
    showIf: {
      managementBody: {
        value: ManagementBodyType.other,
      },
    },
  },
  ownershipType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.ownerShipType.label',
    items: OwnershipList,
  },
  owner: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:myFund.inputs.owner.label',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  ownerShare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:common.inputs.ownershipShare.label',
    max: 100,
    min: 0,
  },
  ownerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.ownerDocument.label',
    items: 'privateDocuments',
    dtoType: PrivateDocumentCategory.OTHER,
  },
  otherOwnerDocuments: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.otherOwnerDocuments.label',
    items: 'personalIdentifiersDocuments',
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  contactInfoLabel: {
    type: FormLabelItem.label,
    label: 'Contact info',
  },
  address: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.ownerAddress.label',
  },
  phoneNumber: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_assets:common.inputs.phoneNumber.label',
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_assets:common.inputs.email.label',
  },
  isWithCoOwners: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:myFund.inputs.beneficiaries.label',
  },
  coOwner: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:myFund.inputs.beneficiary.label',
    items: 'personalIdentifiersDocuments',
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  coOwnerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:myFund.inputs.beneficiaryDocument.label',
    items: 'personalIdentifiersDocuments',
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
    dtoType: PersonalIdentifierType.BIRTH_CERTIFICATE,
  },
  coOwnerShare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:myFund.inputs.beneficiaryShare.label',
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
    max: 100,
    min: 0,
  },
  coOwners: {
    type: QuestionType.RENDER_NEW_BUTTON,
    label: 'Add new co-owner',
    renderItems: ['coOwner', 'coOwnerDocument', 'coOwnerShare'],
    uniqKeyPrefix: 'new-co-owner',
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
  },
};

const MyFundForm: MyFundFormType = [
  MyFundFormPart1,
  MyFundFormPart2,
  MyFundFormPart3,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
  assetsDocumentsPageBuilder().form,
];
export { MyFundForm };
