import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { DocumentBaseDto } from '@/shared/lib/sj-orm/models/document/document-base.dto';

export interface PrivateDocumentDto extends DocumentBaseDto {
  privateType: PrivateDocumentType;
  privateCategory: PrivateDocumentCategory;
  name: string;
  country: string;
  commentary?: string;
  scan?: string;
  assetId?: DtoId;
  beneficiaryId?: DtoId;
  isFavorite?: boolean;
  signatory1?: string[];

  storageLocation?: string;
}

export enum PrivateDocumentType {
  UNILATERAL = 'UNILATERAL',
  BILATERAL = 'BILATERAL',
  MULTILATERAL = 'MULTILATERAL',
}

export enum PrivateDocumentCategory {
  PRIVATE_AGREEMENTS = 'PRIVATE_AGREEMENTS',
  PUBLIC_AGREEMENTS = 'PUBLIC_AGREEMENTS',
  INFORMATION_SHEETS = 'INFORMATION_SHEETS',
  BANK_DOCUMENTS = 'BANK_DOCUMENTS',
  PAYMENT_DOCUMENTS = 'PAYMENT_DOCUMENTS',
  POWERS = 'POWERS',
  CORPORATE_DOCUMENTS = 'CORPORATE_DOCUMENTS',
  FAMILY_DOCUMENTS = 'FAMILY_DOCUMENTS',
  OTHER = 'OTHER',
}
