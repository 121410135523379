import {
  AC,
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  // AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  // BV,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  // EU,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  // IC,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TF,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
} from 'country-flag-icons/react/3x2';

import { Row } from '@/shared/components/layout';

export type CountriesListType = {
  label: string;
  key: string;
  icon: JSX.Element;
  mrzCode?: string;
};

type PhoneListType = {
  label: string;
  value: string;
};

export const CountriesList: CountriesListType[] = [
  {
    label: 'Andorra',
    key: 'AD',
    icon: <AD width={20} height={20} />,
    mrzCode: 'AND',
  },
  {
    label: 'Afghanistan',
    key: 'AF',
    icon: <AF width={20} height={20} />,
    mrzCode: 'AFG',
  },
  {
    label: 'Aland Islands',
    key: 'AX',
    icon: <AX width={20} height={20} />,
    mrzCode: 'ALA',
  },
  {
    label: 'Albania',
    key: 'AL',
    icon: <AL width={20} height={20} />,
    mrzCode: 'ALB',
  },
  {
    label: 'Algeria',
    key: 'DZ',
    icon: <DZ width={20} height={20} />,
    mrzCode: 'DZA',
  },
  {
    label: 'American Samoa',
    key: 'AS',
    icon: <AS width={20} height={20} />,
    mrzCode: 'ASM',
  },
  {
    label: 'Angola',
    key: 'AO',
    icon: <AO width={20} height={20} />,
    mrzCode: 'AGO',
  },
  {
    label: 'Anguilla',
    key: 'AI',
    icon: <AI width={20} height={20} />,
    mrzCode: 'AIA',
  },
  // {
  //   label: 'Antarctica',
  //   key: 'AQ',
  //   icon: <AQ width={20} height={20} />,
  //   mrzCode: 'ATA',
  // },
  {
    label: 'Antigua and Barbuda',
    key: 'AG',
    icon: <AG width={20} height={20} />,
    mrzCode: 'ATG',
  },
  {
    label: 'Argentina',
    key: 'AR',
    icon: <AR width={20} height={20} />,
    mrzCode: 'ARG',
  },
  {
    label: 'Armenia',
    key: 'AM',
    icon: <AM width={20} height={20} />,
    mrzCode: 'ARM',
  },
  {
    label: 'Aruba',
    key: 'AW',
    icon: <AW width={20} height={20} />,
    mrzCode: 'ABW',
  },
  {
    label: 'Ascension',
    key: 'AC',
    icon: <AC width={20} height={20} />,
  },
  {
    label: 'Australia',
    key: 'AU',
    icon: <AU width={20} height={20} />,
    mrzCode: 'AUS',
  },
  {
    label: 'Austria',
    key: 'AT',
    icon: <AT width={20} height={20} />,
    mrzCode: 'AUT',
  },
  {
    label: 'Azerbaijan',
    key: 'AZ',
    icon: <AZ width={20} height={20} />,
    mrzCode: 'AZE',
  },
  {
    label: 'Bahamas',
    key: 'BS',
    icon: <BS width={20} height={20} />,
    mrzCode: 'BHS',
  },
  {
    label: 'Bahrain',
    key: 'BH',
    icon: <BH width={20} height={20} />,
    mrzCode: 'BHR',
  },
  {
    label: 'Bangladesh',
    key: 'BD',
    icon: <BD width={20} height={20} />,
    mrzCode: 'BGD',
  },
  {
    label: 'Barbados',
    key: 'BB',
    icon: <BB width={20} height={20} />,
    mrzCode: 'BRB',
  },
  {
    label: 'Belarus',
    key: 'BY',
    icon: <BY width={20} height={20} />,
    mrzCode: 'BLR',
  },
  {
    label: 'Belgium',
    key: 'BE',
    icon: <BE width={20} height={20} />,
    mrzCode: 'BEL',
  },
  {
    label: 'Belize',
    key: 'BZ',
    icon: <BZ width={20} height={20} />,
    mrzCode: 'BLZ',
  },
  {
    label: 'Benin',
    key: 'BJ',
    icon: <BJ width={20} height={20} />,
    mrzCode: 'BEN',
  },
  {
    label: 'Bermuda',
    key: 'BM',
    icon: <BM width={20} height={20} />,
    mrzCode: 'BMU',
  },
  {
    label: 'Bhutan',
    key: 'BT',
    icon: <BT width={20} height={20} />,
    mrzCode: 'BTN',
  },
  {
    label: 'Bolivia',
    key: 'BO',
    icon: <BO width={20} height={20} />,
    mrzCode: 'BOL',
  },
  {
    label: 'Bonaire',
    key: 'BQ-BO',
    icon: <BQ width={20} height={20} />,
  },
  {
    label: 'Bosnia and Herzegovina',
    key: 'BA',
    icon: <BA width={20} height={20} />,
    mrzCode: 'BIH',
  },
  {
    label: 'Botswana',
    key: 'BW',
    icon: <BW width={20} height={20} />,
    mrzCode: 'BWA',
  },
  {
    label: 'Brazil',
    key: 'BR',
    icon: <BR width={20} height={20} />,
    mrzCode: 'BRA',
  },
  {
    label: 'British Indian Ocean Territory',
    key: 'IO',
    icon: <IO width={20} height={20} />,
    mrzCode: 'IOT',
  },
  {
    label: 'Brunei Darussalam',
    key: 'BN',
    icon: <BN width={20} height={20} />,
    mrzCode: 'BRN',
  },
  {
    label: 'Bulgaria',
    key: 'BG',
    icon: <BG width={20} height={20} />,
    mrzCode: 'BGR',
  },
  {
    label: 'Burkina Faso',
    key: 'BF',
    icon: <BF width={20} height={20} />,
    mrzCode: 'BFA',
  },
  {
    label: 'Burundi',
    key: 'BI',
    icon: <BI width={20} height={20} />,
    mrzCode: 'BDI',
  },
  {
    label: 'Cambodia',
    key: 'KH',
    icon: <KH width={20} height={20} />,
    mrzCode: 'KHM',
  },
  {
    label: 'Cameroon',
    key: 'CM',
    icon: <CM width={20} height={20} />,
    mrzCode: 'CMR',
  },
  {
    label: 'Canada',
    key: 'CA',
    icon: <CA width={20} height={20} />,
    mrzCode: 'CAN',
  },
  {
    label: 'Cape Verde',
    key: 'CV',
    icon: <CV width={20} height={20} />,
    mrzCode: 'CPV',
  },
  {
    label: 'Cayman Islands',
    key: 'KY',
    icon: <KY width={20} height={20} />,
    mrzCode: 'CYM',
  },
  {
    label: 'Central African Republic',
    key: 'CF',
    icon: <CF width={20} height={20} />,
    mrzCode: 'CAF',
  },
  {
    label: 'Chad',
    key: 'TD',
    icon: <TD width={20} height={20} />,
    mrzCode: 'TCD',
  },
  {
    label: 'Chile',
    key: 'CL',
    icon: <CL width={20} height={20} />,
    mrzCode: 'CHL',
  },
  {
    label: 'China',
    key: 'CN',
    icon: <CN width={20} height={20} />,
    mrzCode: 'CHN',
  },
  {
    label: 'Christmas Island',
    key: 'CX',
    icon: <CX width={20} height={20} />,
    mrzCode: 'CXR',
  },
  {
    label: 'Cocos (Keeling) Islands',
    key: 'CC',
    icon: <CC width={20} height={20} />,
    mrzCode: 'CCK',
  },
  {
    label: 'Colombia',
    key: 'CO',
    icon: <CO width={20} height={20} />,
    mrzCode: 'COL',
  },
  {
    label: 'Comoros',
    key: 'KM',
    icon: <KM width={20} height={20} />,
    mrzCode: 'COM',
  },
  {
    label: 'Congo, the Democratic Republic of the',
    key: 'CD',
    icon: <CD width={20} height={20} />,
    mrzCode: 'COD',
  },
  {
    label: 'Congo',
    key: 'CG',
    icon: <CG width={20} height={20} />,
    mrzCode: 'COG',
  },
  {
    label: 'Cook Islands',
    key: 'CK',
    icon: <CK width={20} height={20} />,
    mrzCode: 'COK',
  },
  {
    label: 'Costa Rica',
    key: 'CR',
    icon: <CR width={20} height={20} />,
    mrzCode: 'CRI',
  },
  {
    label: "Ivory Coast (Côte d'Ivoire)",
    key: 'CI',
    icon: <CI width={20} height={20} />,
    mrzCode: 'CIV',
  },
  {
    label: 'Croatia',
    key: 'HR',
    icon: <HR width={20} height={20} />,
    mrzCode: 'HRV',
  },
  {
    label: 'Cuba',
    key: 'CU',
    icon: <CU width={20} height={20} />,
    mrzCode: 'CUB',
  },
  {
    label: 'Curaçao',
    key: 'CW',
    icon: <CW width={20} height={20} />,
    mrzCode: 'CUW',
  },
  {
    label: 'Cyprus',
    key: 'CY',
    icon: <CY width={20} height={20} />,
    mrzCode: 'CYP',
  },
  {
    label: 'Czech Republic',
    key: 'CZ',
    icon: <CZ width={20} height={20} />,
    mrzCode: 'CZE',
  },
  {
    label: 'Denmark',
    key: 'DK',
    icon: <DK width={20} height={20} />,
    mrzCode: 'DNK',
  },
  {
    label: 'Djibouti',
    key: 'DJ',
    icon: <DJ width={20} height={20} />,
    mrzCode: 'DJI',
  },
  {
    label: 'Dominica',
    key: 'DM',
    icon: <DM width={20} height={20} />,
    mrzCode: 'DMA',
  },
  {
    label: 'Dominican Republic',
    key: 'DO',
    icon: <DO width={20} height={20} />,
    mrzCode: 'DOM',
  },
  {
    label: 'Ecuador',
    key: 'EC',
    icon: <EC width={20} height={20} />,
    mrzCode: 'ECU',
  },
  {
    label: 'Egypt',
    key: 'EG',
    icon: <EG width={20} height={20} />,
    mrzCode: 'EGY',
  },
  {
    label: 'El Salvador',
    key: 'SV',
    icon: <SV width={20} height={20} />,
    mrzCode: 'SLV',
  },
  {
    label: 'Equatorial Guinea',
    key: 'GQ',
    icon: <GQ width={20} height={20} />,
    mrzCode: 'GNQ',
  },
  {
    label: 'Eritrea',
    key: 'ER',
    icon: <ER width={20} height={20} />,
    mrzCode: 'ERI',
  },
  {
    label: 'Estonia',
    key: 'EE',
    icon: <EE width={20} height={20} />,
    mrzCode: 'EST',
  },
  {
    label: 'Eswatini',
    key: 'SZ',
    icon: <SZ width={20} height={20} />,
    mrzCode: 'SWZ',
  },
  {
    label: 'Ethiopia',
    key: 'ET',
    icon: <ET width={20} height={20} />,
    mrzCode: 'ETH',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    key: 'FK',
    icon: <FK width={20} height={20} />,
    mrzCode: 'FLK',
  },
  {
    label: 'Faroe Islands',
    key: 'FO',
    icon: <FO width={20} height={20} />,
    mrzCode: 'FRO',
  },
  {
    label: 'Fiji',
    key: 'FJ',
    icon: <FJ width={20} height={20} />,
    mrzCode: 'FJI',
  },
  {
    label: 'Finland',
    key: 'FI',
    icon: <FI width={20} height={20} />,
    mrzCode: 'FIN',
  },
  {
    label: 'France',
    key: 'FR',
    icon: <FR width={20} height={20} />,
    mrzCode: 'FRA',
  },
  {
    label: 'French Guiana',
    key: 'GF',
    icon: <GF width={20} height={20} />,
    mrzCode: 'GUF',
  },
  {
    label: 'French Polynesia',
    key: 'PF',
    icon: <PF width={20} height={20} />,
    mrzCode: 'PYF',
  },
  {
    label: 'French Southern Territories',
    key: 'TF',
    icon: <TF width={20} height={20} />,
    mrzCode: 'ATF',
  },
  {
    label: 'Gabon',
    key: 'GA',
    icon: <GA width={20} height={20} />,
    mrzCode: 'GAB',
  },
  {
    label: 'Gambia',
    key: 'GM',
    icon: <GM width={20} height={20} />,
    mrzCode: 'GMB',
  },
  {
    label: 'Georgia',
    key: 'GE',
    icon: <GE width={20} height={20} />,
    mrzCode: 'GEO',
  },
  {
    label: 'Germany',
    key: 'DE',
    icon: <DE width={20} height={20} />,
    mrzCode: 'D',
  },
  {
    label: 'Ghana',
    key: 'GH',
    icon: <GH width={20} height={20} />,
    mrzCode: 'GHA',
  },
  {
    label: 'Gibraltar',
    key: 'GI',
    icon: <GI width={20} height={20} />,
    mrzCode: 'GIB',
  },
  {
    label: 'Greece',
    key: 'GR',
    icon: <GR width={20} height={20} />,
    mrzCode: 'GRC',
  },
  {
    label: 'Greenland',
    key: 'GL',
    icon: <GL width={20} height={20} />,
    mrzCode: 'GRL',
  },
  {
    label: 'Grenada',
    key: 'GD',
    icon: <GD width={20} height={20} />,
    mrzCode: 'GRD',
  },
  {
    label: 'Guadeloupe',
    key: 'GP',
    icon: <GP width={20} height={20} />,
    mrzCode: 'GLP',
  },
  {
    label: 'Guam',
    key: 'GU',
    icon: <GU width={20} height={20} />,
    mrzCode: 'GUM',
  },
  {
    label: 'Guatemala',
    key: 'GT',
    icon: <GT width={20} height={20} />,
    mrzCode: 'GTM',
  },
  {
    label: 'Guernsey',
    key: 'GG',
    icon: <GG width={20} height={20} />,
    mrzCode: 'GGY',
  },
  {
    label: 'Guinea',
    key: 'GN',
    icon: <GN width={20} height={20} />,
    mrzCode: 'GIN',
  },
  {
    label: 'Guinea-Bissau',
    key: 'GW',
    icon: <GW width={20} height={20} />,
    mrzCode: 'GNB',
  },
  {
    label: 'Guyana',
    key: 'GY',
    icon: <GY width={20} height={20} />,
    mrzCode: 'GUY',
  },
  {
    label: 'Haiti',
    key: 'HT',
    icon: <HT width={20} height={20} />,
    mrzCode: 'HTI',
  },
  {
    label: 'Heard Island and McDonald Islands',
    key: 'HM',
    icon: <HM width={20} height={20} />,
    mrzCode: 'HMD',
  },
  {
    label: 'Honduras',
    key: 'HN',
    icon: <HN width={20} height={20} />,
    mrzCode: 'HND',
  },
  {
    label: 'Hong Kong',
    key: 'HK',
    icon: <HK width={20} height={20} />,
    mrzCode: 'HKG',
  },
  {
    label: 'Hungary',
    key: 'HU',
    icon: <HU width={20} height={20} />,
    mrzCode: 'HUN',
  },
  {
    label: 'Iceland',
    key: 'IS',
    icon: <IS width={20} height={20} />,
    mrzCode: 'ISL',
  },
  {
    label: 'India',
    key: 'IN',
    icon: <IN width={20} height={20} />,
    mrzCode: 'IND',
  },
  {
    label: 'Indonesia',
    key: 'ID',
    icon: <ID width={20} height={20} />,
    mrzCode: 'IDN',
  },
  {
    label: 'Iran',
    key: 'IR',
    icon: <IR width={20} height={20} />,
    mrzCode: 'IRN',
  },
  {
    label: 'Iraq',
    key: 'IQ',
    icon: <IQ width={20} height={20} />,
    mrzCode: 'IRQ',
  },
  {
    label: 'Ireland',
    key: 'IE',
    icon: <IE width={20} height={20} />,
    mrzCode: 'IRL',
  },
  {
    label: 'Isle of Man',
    key: 'IM',
    icon: <IM width={20} height={20} />,
    mrzCode: 'IMN',
  },
  {
    label: 'Israel',
    key: 'IL',
    icon: <IL width={20} height={20} />,
    mrzCode: 'ISR',
  },
  {
    label: 'Italy',
    key: 'IT',
    icon: <IT width={20} height={20} />,
    mrzCode: 'ITA',
  },
  {
    label: 'Jamaica',
    key: 'JM',
    icon: <JM width={20} height={20} />,
    mrzCode: 'JAM',
  },
  {
    label: 'Japan',
    key: 'JP',
    icon: <JP width={20} height={20} />,
    mrzCode: 'JPN',
  },
  {
    label: 'Jersey',
    key: 'JE',
    icon: <JE width={20} height={20} />,
    mrzCode: 'JEY',
  },
  {
    label: 'Jordan',
    key: 'JO',
    icon: <JO width={20} height={20} />,
    mrzCode: 'JOR',
  },
  {
    label: 'Kazakhstan',
    key: 'KZ',
    icon: <KZ width={20} height={20} />,
    mrzCode: 'KAZ',
  },
  {
    label: 'Kenya',
    key: 'KE',
    icon: <KE width={20} height={20} />,
    mrzCode: 'KEN',
  },
  {
    label: 'Kiribati',
    key: 'KI',
    icon: <KI width={20} height={20} />,
    mrzCode: 'KIR',
  },
  {
    label: 'Korea, North',
    key: 'KP',
    icon: <KP width={20} height={20} />,
    mrzCode: 'PRK',
  },
  {
    label: 'Korea, South',
    key: 'KR',
    icon: <KR width={20} height={20} />,
    mrzCode: 'KOR',
  },
  {
    label: 'Kosovo',
    key: 'XK',
    icon: <XK width={20} height={20} />,
  },
  {
    label: 'Kuwait',
    key: 'KW',
    icon: <KW width={20} height={20} />,
    mrzCode: 'KWT',
  },
  {
    label: 'Kyrgyzstan',
    key: 'KG',
    icon: <KG width={20} height={20} />,
    mrzCode: 'KGZ',
  },
  {
    label: 'Laos',
    key: 'LA',
    icon: <LA width={20} height={20} />,
    mrzCode: 'LAO',
  },
  {
    label: 'Latvia',
    key: 'LV',
    icon: <LV width={20} height={20} />,
    mrzCode: 'LVA',
  },
  {
    label: 'Lebanon',
    key: 'LB',
    icon: <LB width={20} height={20} />,
    mrzCode: 'LBN',
  },
  {
    label: 'Lesotho',
    key: 'LS',
    icon: <LS width={20} height={20} />,
    mrzCode: 'LSO',
  },
  {
    label: 'Liberia',
    key: 'LR',
    icon: <LR width={20} height={20} />,
    mrzCode: 'LBR',
  },
  {
    label: 'Libya',
    key: 'LY',
    icon: <LY width={20} height={20} />,
    mrzCode: 'LBY',
  },
  {
    label: 'Liechtenstein',
    key: 'LI',
    icon: <LI width={20} height={20} />,
    mrzCode: 'LIE',
  },
  {
    label: 'Lithuania',
    key: 'LT',
    icon: <LT width={20} height={20} />,
    mrzCode: 'LTU',
  },
  {
    label: 'Luxembourg',
    key: 'LU',
    icon: <LU width={20} height={20} />,
    mrzCode: 'LUX',
  },
  {
    label: 'Macau',
    key: 'MO',
    icon: <MO width={20} height={20} />,
    mrzCode: 'MAC',
  },
  {
    label: 'Madagascar',
    key: 'MG',
    icon: <MG width={20} height={20} />,
    mrzCode: 'MDG',
  },
  {
    label: 'Malawi',
    key: 'MW',
    icon: <MW width={20} height={20} />,
    mrzCode: 'MWI',
  },
  {
    label: 'Malaysia',
    key: 'MY',
    icon: <MY width={20} height={20} />,
    mrzCode: 'MYS',
  },
  {
    label: 'Maldives',
    key: 'MV',
    icon: <MV width={20} height={20} />,
    mrzCode: 'MDV',
  },
  {
    label: 'Mali',
    key: 'ML',
    icon: <ML width={20} height={20} />,
    mrzCode: 'MLI',
  },
  {
    label: 'Malta',
    key: 'MT',
    icon: <MT width={20} height={20} />,
    mrzCode: 'MLT',
  },
  {
    label: 'Marshall Islands',
    key: 'MH',
    icon: <MH width={20} height={20} />,
    mrzCode: 'MHL',
  },
  {
    label: 'Martinique',
    key: 'MQ',
    icon: <MQ width={20} height={20} />,
    mrzCode: 'MTQ',
  },
  {
    label: 'Mauritania',
    key: 'MR',
    icon: <MR width={20} height={20} />,
    mrzCode: 'MRT',
  },
  {
    label: 'Mauritius',
    key: 'MU',
    icon: <MU width={20} height={20} />,
    mrzCode: 'MUS',
  },
  {
    label: 'Mayotte',
    key: 'YT',
    icon: <YT width={20} height={20} />,
    mrzCode: 'MYT',
  },
  {
    label: 'Mexico',
    key: 'MX',
    icon: <MX width={20} height={20} />,
    mrzCode: 'MEX',
  },
  {
    label: 'Micronesia, Federated States of',
    key: 'FM',
    icon: <FM width={20} height={20} />,
    mrzCode: 'FSM',
  },
  {
    label: 'Moldova',
    key: 'MD',
    icon: <MD width={20} height={20} />,
    mrzCode: 'MDA',
  },
  {
    label: 'Monaco',
    key: 'MC',
    icon: <MC width={20} height={20} />,
    mrzCode: 'MCO',
  },
  {
    label: 'Mongolia',
    key: 'MN',
    icon: <MN width={20} height={20} />,
    mrzCode: 'MNG',
  },
  {
    label: 'Montenegro',
    key: 'ME',
    icon: <ME width={20} height={20} />,
    mrzCode: 'MNE',
  },
  {
    label: 'Montserrat',
    key: 'MS',
    icon: <MS width={20} height={20} />,
    mrzCode: 'MSR',
  },
  {
    label: 'Morocco',
    key: 'MA',
    icon: <MA width={20} height={20} />,
    mrzCode: 'MAR',
  },
  {
    label: 'Mozambique',
    key: 'MZ',
    icon: <MZ width={20} height={20} />,
    mrzCode: 'MOZ',
  },
  {
    label: 'Myanmar',
    key: 'MM',
    icon: <MM width={20} height={20} />,
    mrzCode: 'MMR',
  },
  {
    label: 'Namibia',
    key: 'NA',
    icon: <NA width={20} height={20} />,
    mrzCode: 'NAM',
  },
  {
    label: 'Nauru',
    key: 'NR',
    icon: <NR width={20} height={20} />,
    mrzCode: 'NRU',
  },
  {
    label: 'Nepal',
    key: 'NP',
    icon: <NP width={20} height={20} />,
    mrzCode: 'NPL',
  },
  {
    label: 'Netherlands',
    key: 'NL',
    icon: <NL width={20} height={20} />,
    mrzCode: 'NLD',
  },
  {
    label: 'New Caledonia',
    key: 'NC',
    icon: <NC width={20} height={20} />,
    mrzCode: 'NCL',
  },
  {
    label: 'New Zealand',
    key: 'NZ',
    icon: <NZ width={20} height={20} />,
    mrzCode: 'NZL',
  },
  {
    label: 'Nicaragua',
    key: 'NI',
    icon: <NI width={20} height={20} />,
    mrzCode: 'NIC',
  },
  {
    label: 'Niger',
    key: 'NE',
    icon: <NE width={20} height={20} />,
    mrzCode: 'NER',
  },
  {
    label: 'Nigeria',
    key: 'NG',
    icon: <NG width={20} height={20} />,
    mrzCode: 'NGA',
  },
  {
    label: 'Niue',
    key: 'NU',
    icon: <NU width={20} height={20} />,
    mrzCode: 'NIU',
  },
  {
    label: 'Norfolk Island',
    key: 'NF',
    icon: <NF width={20} height={20} />,
    mrzCode: 'NFK',
  },
  {
    label: 'North Macedonia',
    key: 'MK',
    icon: <MK width={20} height={20} />,
    mrzCode: 'MKD',
  },
  {
    label: 'Northern Ireland',
    key: 'GB-NIR',
    icon: <GB width={20} height={20} />,
  },
  {
    label: 'Northern Mariana Islands',
    key: 'MP',
    icon: <MP width={20} height={20} />,
    mrzCode: 'MNP',
  },
  {
    label: 'Norway',
    key: 'NO',
    icon: <NO width={20} height={20} />,
    mrzCode: 'NOR',
  },
  {
    label: 'Oman',
    key: 'OM',
    icon: <OM width={20} height={20} />,
    mrzCode: 'OMN',
  },
  {
    label: 'Pakistan',
    key: 'PK',
    icon: <PK width={20} height={20} />,
    mrzCode: 'PAK',
  },
  {
    label: 'Palau',
    key: 'PW',
    icon: <PW width={20} height={20} />,
    mrzCode: 'PLW',
  },
  {
    label: 'Palestine',
    key: 'PS',
    icon: <PS width={20} height={20} />,
    mrzCode: 'PSE',
  },
  {
    label: 'Panama',
    key: 'PA',
    icon: <PA width={20} height={20} />,
    mrzCode: 'PAN',
  },
  {
    label: 'Papua New Guinea',
    key: 'PG',
    icon: <PG width={20} height={20} />,
    mrzCode: 'PNG',
  },
  {
    label: 'Paraguay',
    key: 'PY',
    icon: <PY width={20} height={20} />,
    mrzCode: 'PRY',
  },
  {
    label: 'Peru',
    key: 'PE',
    icon: <PE width={20} height={20} />,
    mrzCode: 'PER',
  },
  {
    label: 'Philippines',
    key: 'PH',
    icon: <PH width={20} height={20} />,
    mrzCode: 'PHL',
  },
  {
    label: 'Pitcairn Islands',
    key: 'PN',
    icon: <PN width={20} height={20} />,
    mrzCode: 'PCN',
  },
  {
    label: 'Poland',
    key: 'PL',
    icon: <PL width={20} height={20} />,
    mrzCode: 'POL',
  },
  {
    label: 'Portugal',
    key: 'PT',
    icon: <PT width={20} height={20} />,
    mrzCode: 'PRT',
  },
  {
    label: 'Puerto Rico',
    key: 'PR',
    icon: <PR width={20} height={20} />,
    mrzCode: 'PRI',
  },
  {
    label: 'Qatar',
    key: 'QA',
    icon: <QA width={20} height={20} />,
    mrzCode: 'QAT',
  },
  {
    label: 'Réunion',
    key: 'RE',
    icon: <RE width={20} height={20} />,
    mrzCode: 'REU',
  },
  {
    label: 'Romania',
    key: 'RO',
    icon: <RO width={20} height={20} />,
    mrzCode: 'ROU',
  },
  {
    label: 'Russia',
    key: 'RU',
    icon: <RU width={20} height={20} />,
    mrzCode: 'RUS',
  },
  {
    label: 'Rwanda',
    key: 'RW',
    icon: <RW width={20} height={20} />,
    mrzCode: 'RWA',
  },
  {
    label: 'Saba',
    key: 'BQ-SA',
    icon: <BQ width={20} height={20} />,
  },
  {
    label: 'Saint Barthélemy',
    key: 'BL',
    icon: <BL width={20} height={20} />,
    mrzCode: 'BLM',
  },
  {
    label: 'Saint Helena',
    key: 'SH',
    icon: <SH width={20} height={20} />,
    mrzCode: 'SHN',
  },
  {
    label: 'Saint Kitts and Nevis',
    key: 'KN',
    icon: <KN width={20} height={20} />,
    mrzCode: 'KNA',
  },
  {
    label: 'Saint Lucia',
    key: 'LC',
    icon: <LC width={20} height={20} />,
    mrzCode: 'LCA',
  },
  {
    label: 'Saint Martin (France)',
    key: 'MF',
    icon: <MF width={20} height={20} />,
    mrzCode: 'MAF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    key: 'PM',
    icon: <PM width={20} height={20} />,
    mrzCode: 'SPM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    key: 'VC',
    icon: <VC width={20} height={20} />,
    mrzCode: 'VCT',
  },
  {
    label: 'Samoa',
    key: 'WS',
    icon: <WS width={20} height={20} />,
    mrzCode: 'WSM',
  },
  {
    label: 'San Marino',
    key: 'SM',
    icon: <SM width={20} height={20} />,
    mrzCode: 'SMR',
  },
  {
    label: 'Sao Tome and Principe',
    key: 'ST',
    icon: <ST width={20} height={20} />,
    mrzCode: 'STP',
  },
  {
    label: 'Saudi Arabia',
    key: 'SA',
    icon: <SA width={20} height={20} />,
    mrzCode: 'SAU',
  },
  {
    label: 'Senegal',
    key: 'SN',
    icon: <SN width={20} height={20} />,
    mrzCode: 'SEN',
  },
  {
    label: 'Serbia',
    key: 'RS',
    icon: <RS width={20} height={20} />,
    mrzCode: 'SRB',
  },
  {
    label: 'Seychelles',
    key: 'SC',
    icon: <SC width={20} height={20} />,
    mrzCode: 'SYC',
  },
  {
    label: 'Sierra Leone',
    key: 'SL',
    icon: <SL width={20} height={20} />,
    mrzCode: 'SLE',
  },
  {
    label: 'Singapore',
    key: 'SG',
    icon: <SG width={20} height={20} />,
    mrzCode: 'SGP',
  },
  {
    label: 'Sint Eustatius',
    key: 'BQ-SE',
    icon: <BQ width={20} height={20} />,
  },
  {
    label: 'Sint Maarten (Netherlands)',
    key: 'SX',
    icon: <SX width={20} height={20} />,
    mrzCode: 'SXM',
  },
  {
    label: 'Slovakia',
    key: 'SK',
    icon: <SK width={20} height={20} />,
    mrzCode: 'SVK',
  },
  {
    label: 'Slovenia',
    key: 'SI',
    icon: <SI width={20} height={20} />,
    mrzCode: 'SVN',
  },
  {
    label: 'Solomon Islands',
    key: 'SB',
    icon: <SB width={20} height={20} />,
    mrzCode: 'SLB',
  },
  {
    label: 'Somalia',
    key: 'SO',
    icon: <SO width={20} height={20} />,
    mrzCode: 'SOM',
  },
  {
    label: 'South Africa',
    key: 'ZA',
    icon: <ZA width={20} height={20} />,
    mrzCode: 'ZAF',
  },
  {
    label: 'South Georgia and \n the South Sandwich Islands',
    key: 'GS',
    icon: <GS width={20} height={20} />,
    mrzCode: 'SGS',
  },
  {
    label: 'South Sudan',
    key: 'SS',
    icon: <SS width={20} height={20} />,
    mrzCode: 'SSD',
  },
  {
    label: 'Spain',
    key: 'ES',
    icon: <ES width={20} height={20} />,
    mrzCode: 'ESP',
  },
  {
    label: 'Sri Lanka',
    key: 'LK',
    icon: <LK width={20} height={20} />,
    mrzCode: 'LKA',
  },
  {
    label: 'Sudan',
    key: 'SD',
    icon: <SD width={20} height={20} />,
    mrzCode: 'SDN',
  },
  {
    label: 'Suriname',
    key: 'SR',
    icon: <SR width={20} height={20} />,
    mrzCode: 'SUR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    key: 'SJ',
    icon: <SJ width={20} height={20} />,
    mrzCode: 'SJM',
  },
  {
    label: 'Swaziland',
    key: 'SVZ',
    icon: <SZ width={20} height={20} />,
  },
  {
    label: 'Sweden',
    key: 'SE',
    icon: <SE width={20} height={20} />,
    mrzCode: 'SWE',
  },
  {
    label: 'Switzerland',
    key: 'CH',
    icon: <CH width={20} height={20} />,
    mrzCode: 'CHE',
  },
  {
    label: 'Syria',
    key: 'SY',
    icon: <SY width={20} height={20} />,
    mrzCode: 'SYR',
  },
  {
    label: 'Taiwan',
    key: 'TW',
    icon: <TW width={20} height={20} />,
    mrzCode: 'TWN',
  },
  {
    label: 'Tajikistan',
    key: 'TJ',
    icon: <TJ width={20} height={20} />,
    mrzCode: 'TJK',
  },
  {
    label: 'Tanzania',
    key: 'TZ',
    icon: <TZ width={20} height={20} />,
    mrzCode: 'TZA',
  },
  {
    label: 'Thailand',
    key: 'TH',
    icon: <TH width={20} height={20} />,
    mrzCode: 'THA',
  },
  {
    label: 'East Timor (Timor-Leste)',
    key: 'TL',
    icon: <TL width={20} height={20} />,
    mrzCode: 'TLS',
  },
  {
    label: 'Togo',
    key: 'TG',
    icon: <TG width={20} height={20} />,
    mrzCode: 'TGO',
  },
  {
    label: 'Tokelau',
    key: 'TK',
    icon: <TK width={20} height={20} />,
    mrzCode: 'TKL',
  },
  {
    label: 'Tonga',
    key: 'TO',
    icon: <TO width={20} height={20} />,
    mrzCode: 'TON',
  },
  {
    label: 'Trinidad and Tobago',
    key: 'TT',
    icon: <TT width={20} height={20} />,
    mrzCode: 'TTO',
  },
  {
    label: 'Tristan da Cunha',
    key: 'TA',
    icon: <TA width={20} height={20} />,
  },
  {
    label: 'Tunisia',
    key: 'TN',
    icon: <TN width={20} height={20} />,
    mrzCode: 'TUN',
  },
  {
    label: 'Turkey',
    key: 'TR',
    icon: <TR width={20} height={20} />,
    mrzCode: 'TUR',
  },
  {
    label: 'Turkmenistan',
    key: 'TM',
    icon: <TM width={20} height={20} />,
    mrzCode: 'TKM',
  },
  {
    label: 'Turks and Caicos Islands',
    key: 'TC',
    icon: <TC width={20} height={20} />,
    mrzCode: 'TCA',
  },
  {
    label: 'Tuvalu',
    key: 'TV',
    icon: <TV width={20} height={20} />,
    mrzCode: 'TUV',
  },
  {
    label: 'Uganda',
    key: 'UG',
    icon: <UG width={20} height={20} />,
    mrzCode: 'UGA',
  },
  {
    label: 'Ukraine',
    key: 'UA',
    icon: <UA width={20} height={20} />,
    mrzCode: 'UKR',
  },
  {
    label: 'United Arab Emirates',
    key: 'AE',
    icon: <AE width={20} height={20} />,
    mrzCode: 'ARE',
  },
  {
    label: 'United Kingdom',
    key: 'GB',
    icon: <GB width={20} height={20} />,
    mrzCode: 'GBR',
  },
  {
    label: 'United States Minor Outlying Islands',
    key: 'UM',
    icon: <UM width={20} height={20} />,
    mrzCode: 'UMI',
  },
  {
    label: 'United States',
    key: 'US',
    icon: <US width={20} height={20} />,
    mrzCode: 'USA',
  },
  {
    label: 'Uruguay',
    key: 'UY',
    icon: <UY width={20} height={20} />,
    mrzCode: 'URY',
  },
  {
    label: 'Uzbekistan',
    key: 'UZ',
    icon: <UZ width={20} height={20} />,
    mrzCode: 'UZB',
  },
  {
    label: 'Vanuatu',
    key: 'VU',
    icon: <VU width={20} height={20} />,
    mrzCode: 'VUT',
  },
  {
    label: 'Vatican City State (Holy See)',
    key: 'VA',
    icon: <VA width={20} height={20} />,
    mrzCode: 'VAT',
  },
  {
    label: 'Venezuela',
    key: 'VE',
    icon: <VE width={20} height={20} />,
    mrzCode: 'VEN',
  },
  {
    label: 'Vietnam',
    key: 'VN',
    icon: <VN width={20} height={20} />,
    mrzCode: 'VNM',
  },
  {
    label: 'Virgin Islands, British',
    key: 'VG',
    icon: <VG width={20} height={20} />,
    mrzCode: 'VGB',
  },
  {
    label: 'Virgin Islands, U.S.',
    key: 'VI',
    icon: <VI width={20} height={20} />,
    mrzCode: 'VIR',
  },
  {
    label: 'Wallis and Futuna',
    key: 'WF',
    icon: <WF width={20} height={20} />,
    mrzCode: 'WLF',
  },
  {
    label: 'Western Sahara',
    key: 'EH',
    icon: <EH width={20} height={20} />,
    mrzCode: 'ESH',
  },
  {
    label: 'Yemen',
    key: 'YE',
    icon: <YE width={20} height={20} />,
    mrzCode: 'YEM',
  },
  {
    label: 'Zambia',
    key: 'ZM',
    icon: <ZM width={20} height={20} />,
    mrzCode: 'ZMB',
  },
  {
    label: 'Zimbabwe',
    key: 'ZW',
    icon: <ZW width={20} height={20} />,
    mrzCode: 'ZWE',
  },
];

export const resolveCountry = (
  key: string,
  withFallback = true,
  withIcon = true,
  hideLabel = false,
) => {
  const country = CountriesList.find((_country) => _country.key === key);

  if (!country) {
    return withFallback ? <span>Unknown country.</span> : '';
  }

  return (
    <Row alignCenter gap={4} style={{ margin: 0 }}>
      {withIcon && country.icon}
      {!hideLabel && <span>{country.label}</span>}
    </Row>
  );
};

export const PhoneCodesList: PhoneListType[] = [
  {
    label: '+1',
    value: '+1',
  },
  {
    label: '+1242',
    value: '+1242',
  },
  {
    label: '+1246',
    value: '+1246',
  },
  {
    label: '+1264',
    value: '+1264',
  },
  {
    label: '+1268',
    value: '+1268',
  },
  {
    label: '+1284',
    value: '+1284',
  },
  {
    label: '+1340',
    value: '+1340',
  },
  {
    label: '+1441',
    value: '+1441',
  },
  {
    label: '+1473',
    value: '+1473',
  },
  {
    label: '+1649',
    value: '+1649',
  },
  {
    label: '+1664',
    value: '+1664',
  },
  {
    label: '+1670',
    value: '+1670',
  },
  {
    label: '+1671',
    value: '+1671',
  },
  {
    label: '+1684',
    value: '+1684',
  },
  {
    label: '+1758',
    value: '+1758',
  },
  {
    label: '+1767',
    value: '+1767',
  },
  {
    label: '+1784',
    value: '+1784',
  },
  {
    label: '+1849',
    value: '+1849',
  },
  {
    label: '+1868',
    value: '+1868',
  },
  {
    label: '+1869',
    value: '+1869',
  },
  {
    label: '+1876',
    value: '+1876',
  },
  {
    label: '+1939',
    value: '+1939',
  },
  {
    label: '+20',
    value: '+20',
  },
  {
    label: '+211',
    value: '+211',
  },
  {
    label: '+212',
    value: '+212',
  },
  {
    label: '+213',
    value: '+213',
  },
  {
    label: '+216',
    value: '+216',
  },
  {
    label: '+218',
    value: '+218',
  },
  {
    label: '+220',
    value: '+220',
  },
  {
    label: '+221',
    value: '+221',
  },
  {
    label: '+222',
    value: '+222',
  },
  {
    label: '+223',
    value: '+223',
  },
  {
    label: '+224',
    value: '+224',
  },
  {
    label: '+225',
    value: '+225',
  },
  {
    label: '+226',
    value: '+226',
  },
  {
    label: '+227',
    value: '+227',
  },
  {
    label: '+228',
    value: '+228',
  },
  {
    label: '+229',
    value: '+229',
  },
  {
    label: '+230',
    value: '+230',
  },
  {
    label: '+231',
    value: '+231',
  },
  {
    label: '+232',
    value: '+232',
  },
  {
    label: '+233',
    value: '+233',
  },
  {
    label: '+234',
    value: '+234',
  },
  {
    label: '+235',
    value: '+235',
  },
  {
    label: '+236',
    value: '+236',
  },
  {
    label: '+237',
    value: '+237',
  },
  {
    label: '+238',
    value: '+238',
  },
  {
    label: '+239',
    value: '+239',
  },
  {
    label: '+240',
    value: '+240',
  },
  {
    label: '+241',
    value: '+241',
  },
  {
    label: '+242',
    value: '+242',
  },
  {
    label: '+243',
    value: '+243',
  },
  {
    label: '+244',
    value: '+244',
  },
  {
    label: '+245',
    value: '+245',
  },
  {
    label: '+246',
    value: '+246',
  },
  {
    label: '+248',
    value: '+248',
  },
  {
    label: '+249',
    value: '+249',
  },
  {
    label: '+250',
    value: '+250',
  },
  {
    label: '+251',
    value: '+251',
  },
  {
    label: '+252',
    value: '+252',
  },
  {
    label: '+253',
    value: '+253',
  },
  {
    label: '+254',
    value: '+254',
  },
  {
    label: '+255',
    value: '+255',
  },
  {
    label: '+256',
    value: '+256',
  },
  {
    label: '+257',
    value: '+257',
  },
  {
    label: '+258',
    value: '+258',
  },
  {
    label: '+260',
    value: '+260',
  },
  {
    label: '+261',
    value: '+261',
  },
  {
    label: '+262',
    value: '+262',
  },
  {
    label: '+263',
    value: '+263',
  },
  {
    label: '+264',
    value: '+264',
  },
  {
    label: '+265',
    value: '+265',
  },
  {
    label: '+266',
    value: '+266',
  },
  {
    label: '+267',
    value: '+267',
  },
  {
    label: '+268',
    value: '+268',
  },
  {
    label: '+269',
    value: '+269',
  },
  {
    label: '+27',
    value: '+27',
  },
  {
    label: '+290',
    value: '+290',
  },
  {
    label: '+291',
    value: '+291',
  },
  {
    label: '+297',
    value: '+297',
  },
  {
    label: '+298',
    value: '+298',
  },
  {
    label: '+299',
    value: '+299',
  },
  {
    label: '+30',
    value: '+30',
  },
  {
    label: '+31',
    value: '+31',
  },
  {
    label: '+32',
    value: '+32',
  },
  {
    label: '+33',
    value: '+33',
  },
  {
    label: '+34',
    value: '+34',
  },
  {
    label: '+345',
    value: '+345',
  },
  {
    label: '+350',
    value: '+350',
  },
  {
    label: '+351',
    value: '+351',
  },
  {
    label: '+352',
    value: '+352',
  },
  {
    label: '+353',
    value: '+353',
  },
  {
    label: '+354',
    value: '+354',
  },
  {
    label: '+355',
    value: '+355',
  },
  {
    label: '+356',
    value: '+356',
  },
  {
    label: '+357',
    value: '+357',
  },
  {
    label: '+358',
    value: '+358',
  },
  {
    label: '+359',
    value: '+359',
  },
  {
    label: '+36',
    value: '+36',
  },
  {
    label: '+370',
    value: '+370',
  },
  {
    label: '+371',
    value: '+371',
  },
  {
    label: '+372',
    value: '+372',
  },
  {
    label: '+373',
    value: '+373',
  },
  {
    label: '+374',
    value: '+374',
  },
  {
    label: '+375',
    value: '+375',
  },
  {
    label: '+376',
    value: '+376',
  },
  {
    label: '+377',
    value: '+377',
  },
  {
    label: '+378',
    value: '+378',
  },
  {
    label: '+379',
    value: '+379',
  },
  {
    label: '+380',
    value: '+380',
  },
  {
    label: '+381',
    value: '+381',
  },
  {
    label: '+382',
    value: '+382',
  },
  {
    label: '+385',
    value: '+385',
  },
  {
    label: '+386',
    value: '+386',
  },
  {
    label: '+387',
    value: '+387',
  },
  {
    label: '+389',
    value: '+389',
  },
  {
    label: '+39',
    value: '+39',
  },
  {
    label: '+40',
    value: '+40',
  },
  {
    label: '+41',
    value: '+41',
  },
  {
    label: '+420',
    value: '+420',
  },
  {
    label: '+421',
    value: '+421',
  },
  {
    label: '+423',
    value: '+423',
  },
  {
    label: '+43',
    value: '+43',
  },
  {
    label: '+44',
    value: '+44',
  },
  {
    label: '+45',
    value: '+45',
  },
  {
    label: '+46',
    value: '+46',
  },
  {
    label: '+47',
    value: '+47',
  },
  {
    label: '+48',
    value: '+48',
  },
  {
    label: '+49',
    value: '+49',
  },
  {
    label: '+500',
    value: '+500',
  },
  {
    label: '+501',
    value: '+501',
  },
  {
    label: '+502',
    value: '+502',
  },
  {
    label: '+503',
    value: '+503',
  },
  {
    label: '+504',
    value: '+504',
  },
  {
    label: '+505',
    value: '+505',
  },
  {
    label: '+506',
    value: '+506',
  },
  {
    label: '+507',
    value: '+507',
  },
  {
    label: '+508',
    value: '+508',
  },
  {
    label: '+509',
    value: '+509',
  },
  {
    label: '+51',
    value: '+51',
  },
  {
    label: '+52',
    value: '+52',
  },
  {
    label: '+53',
    value: '+53',
  },
  {
    label: '+54',
    value: '+54',
  },
  {
    label: '+55',
    value: '+55',
  },
  {
    label: '+56',
    value: '+56',
  },
  {
    label: '+57',
    value: '+57',
  },
  {
    label: '+58',
    value: '+58',
  },
  {
    label: '+590',
    value: '+590',
  },
  {
    label: '+591',
    value: '+591',
  },
  {
    label: '+593',
    value: '+593',
  },
  {
    label: '+594',
    value: '+594',
  },
  {
    label: '+595',
    value: '+595',
  },
  {
    label: '+596',
    value: '+596',
  },
  {
    label: '+597',
    value: '+597',
  },
  {
    label: '+598',
    value: '+598',
  },
  {
    label: '+599',
    value: '+599',
  },
  {
    label: '+60',
    value: '+60',
  },
  {
    label: '+61',
    value: '+61',
  },
  {
    label: '+62',
    value: '+62',
  },
  {
    label: '+63',
    value: '+63',
  },
  {
    label: '+64',
    value: '+64',
  },
  {
    label: '+65',
    value: '+65',
  },
  {
    label: '+66',
    value: '+66',
  },
  {
    label: '+670',
    value: '+670',
  },
  {
    label: '+672',
    value: '+672',
  },
  {
    label: '+673',
    value: '+673',
  },
  {
    label: '+674',
    value: '+674',
  },
  {
    label: '+675',
    value: '+675',
  },
  {
    label: '+676',
    value: '+676',
  },
  {
    label: '+677',
    value: '+677',
  },
  {
    label: '+678',
    value: '+678',
  },
  {
    label: '+679',
    value: '+679',
  },
  {
    label: '+680',
    value: '+680',
  },
  {
    label: '+681',
    value: '+681',
  },
  {
    label: '+682',
    value: '+682',
  },
  {
    label: '+683',
    value: '+683',
  },
  {
    label: '+685',
    value: '+685',
  },
  {
    label: '+686',
    value: '+686',
  },
  {
    label: '+687',
    value: '+687',
  },
  {
    label: '+688',
    value: '+688',
  },
  {
    label: '+689',
    value: '+689',
  },
  {
    label: '+690',
    value: '+690',
  },
  {
    label: '+691',
    value: '+691',
  },
  {
    label: '+692',
    value: '+692',
  },
  {
    label: '+7',
    value: '+7',
  },
  {
    label: '+77',
    value: '+77',
  },
  {
    label: '+81',
    value: '+81',
  },
  {
    label: '+82',
    value: '+82',
  },
  {
    label: '+84',
    value: '+84',
  },
  {
    label: '+850',
    value: '+850',
  },
  {
    label: '+852',
    value: '+852',
  },
  {
    label: '+853',
    value: '+853',
  },
  {
    label: '+855',
    value: '+855',
  },
  {
    label: '+856',
    value: '+856',
  },
  {
    label: '+86',
    value: '+86',
  },
  {
    label: '+872',
    value: '+872',
  },
  {
    label: '+880',
    value: '+880',
  },
  {
    label: '+886',
    value: '+886',
  },
  {
    label: '+90',
    value: '+90',
  },
  {
    label: '+91',
    value: '+91',
  },
  {
    label: '+92',
    value: '+92',
  },
  {
    label: '+93',
    value: '+93',
  },
  {
    label: '+94',
    value: '+94',
  },
  {
    label: '+95',
    value: '+95',
  },
  {
    label: '+960',
    value: '+960',
  },
  {
    label: '+961',
    value: '+961',
  },
  {
    label: '+962',
    value: '+962',
  },
  {
    label: '+963',
    value: '+963',
  },
  {
    label: '+964',
    value: '+964',
  },
  {
    label: '+965',
    value: '+965',
  },
  {
    label: '+966',
    value: '+966',
  },
  {
    label: '+967',
    value: '+967',
  },
  {
    label: '+968',
    value: '+968',
  },
  {
    label: '+970',
    value: '+970',
  },
  {
    label: '+971',
    value: '+971',
  },
  {
    label: '+972',
    value: '+972',
  },
  {
    label: '+973',
    value: '+973',
  },
  {
    label: '+974',
    value: '+974',
  },
  {
    label: '+975',
    value: '+975',
  },
  {
    label: '+976',
    value: '+976',
  },
  {
    label: '+977',
    value: '+977',
  },
  {
    label: '+98',
    value: '+98',
  },
  {
    label: '+992',
    value: '+992',
  },
  {
    label: '+993',
    value: '+993',
  },
  {
    label: '+994',
    value: '+994',
  },
  {
    label: '+995',
    value: '+995',
  },
  {
    label: '+996',
    value: '+996',
  },
  {
    label: '+998',
    value: '+998',
  },
];

export const PhoneCodesListForm = PhoneCodesList.map((code) => ({
  ...code,
  key: code.value,
}));
