import { IconType } from '@/shared/icons/types';

export const Sort = ({ ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    {...rest}
  >
    <path
      d="M3.2454 0.67385C3.22736 0.650799 3.20431 0.632157 3.17799 0.619337C3.15168 0.606516 3.12279 0.599854 3.09352 0.599854C3.06425 0.599854 3.03536 0.606516 3.00905 0.619337C2.98274 0.632157 2.95969 0.650799 2.94165 0.67385L0.241647 4.09465C0.140397 4.22242 0.232004 4.40805 0.393522 4.40805H2.22567V17.1848C2.22567 17.2909 2.31245 17.3777 2.41852 17.3777H3.76852C3.87459 17.3777 3.96138 17.2909 3.96138 17.1848V4.40805H5.79352C5.95504 4.40805 6.04665 4.22001 5.9454 4.09465L3.2454 0.67385Z"
      fill="currentColor"
    />
    <path
      d="M12.6081 17.3037C12.6262 17.3267 12.6492 17.3454 12.6755 17.3582C12.7018 17.371 12.7307 17.3777 12.76 17.3777C12.7893 17.3777 12.8182 17.371 12.8445 17.3582C12.8708 17.3454 12.8938 17.3267 12.9119 17.3037L15.6119 13.8829C15.7131 13.7551 15.6215 13.5695 15.46 13.5695L13.6279 13.5695L13.6279 0.792703C13.6279 0.686632 13.5411 0.599848 13.435 0.599848L12.085 0.599847C11.9789 0.599847 11.8921 0.686632 11.8921 0.792703L11.8921 13.5695L10.06 13.5695C9.89848 13.5695 9.80687 13.7575 9.90812 13.8829L12.6081 17.3037Z"
      fill="currentColor"
    />
  </svg>
);
