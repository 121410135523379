import { separator } from '../use-form-render-old/constants';

export const dispathEvent = (name: string, data: unknown) => {
  if (typeof window === 'undefined') return;

  const event = new CustomEvent(name, {
    detail: data,
  });

  window.dispatchEvent(event);
};

export const isAddNew = (key: string) => {
  return key.startsWith('add-new');
};

export const getUniqName = (key: string) => {
  return isAddNew(key) ? key.split(separator).at(-1) : undefined;
};
