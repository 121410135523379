import type { FC } from 'react';
import { Progress } from 'antd';
import styled from 'styled-components';

import { media } from '@/shared/components/layout';

interface IProgressBar {
  steps: number;
  currentStep: number;
}

export const ProgressBar: FC<IProgressBar> = ({ steps, currentStep }) => {
  return (
    <CustomProgress
      steps={steps}
      percent={Math.floor((currentStep / steps) * 100)}
      showInfo={false}
      size={'small'}
    />
  );
};

const CustomProgress = styled(Progress)`
  &&& {
    max-width: 390px;
    width: 100%;
    .ant-progress-steps-item {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      width: unset !important;
      box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.07) inset;
    }
    .ant-progress-steps-item.ant-progress-steps-item-active {
      box-shadow: unset;
    }
    .ant-progress-steps-outer .ant-progress-steps-item:last-child {
      margin-inline-end: unset;
    }
    ${media.desktop} {
      max-width: unset;
    }
  }
`;
