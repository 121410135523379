import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Row, Typography } from 'antd';

import { Icon } from '../../icon';
import { Container } from '../../layout';

export const DrawerSharedToNextOwner = ({
  isOpen,
  onCloseModal,
  sharedType,
}: {
  sharedType: string;
  isOpen: boolean;
  onCloseModal: () => void;
}) => {
  return (
    <Drawer
      placement={'bottom'}
      closable={false}
      height={'auto'}
      open={isOpen}
      onClose={() => onCloseModal()}
    >
      <Row justify={'space-between'} align={'middle'}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {sharedType} shared
        </Typography.Title>

        <Icon
          icon="close"
          cursorPointer
          onClick={onCloseModal}
          color="rgba(6, 1, 54, 1)"
        />
      </Row>

      <Container marginTop={8}>
        <Flex gap={8} align="flex-start">
          <InfoCircleOutlined
            style={{ fontSize: 24, color: 'rgba(6, 1, 54, 1)' }}
          />

          <Typography.Text>
            The information about {sharedType} [..] was shared with you
          </Typography.Text>
        </Flex>
      </Container>

      <Container marginTop={24}>
        <Button block size="large" type="primary" onClick={onCloseModal}>
          Go to {sharedType}
        </Button>

        <Container marginTop={12}>
          <Button block size="large" onClick={onCloseModal}>
            Close
          </Button>
        </Container>
      </Container>
    </Drawer>
  );
};
