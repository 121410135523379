import { useMemo } from 'react';

import { useEventLog } from '@/entities/logs';
import {
  ISecureJsonCollectionsStore,
  useSecureJsonCollectionsStore,
} from '@/shared/lib/stores/secure-json-collections.store';

import { SJDatabase } from '../core';

export const getSJDatabase = (
  store: ISecureJsonCollectionsStore,
  createLog: (
    publicData: object,
    dataForEncrypt: object | { name: string; description: string },
  ) => Promise<
    | {
        id: number;
        createdAt: Date;
        mainUserId: number;
        publicData: string;
        encryptedData: string;
      }
    | undefined
  >,
): SJDatabase => {
  return new SJDatabase(store, createLog);
};

export const useSJDatabase = (): SJDatabase => {
  const store = useSecureJsonCollectionsStore();
  const { createLog } = useEventLog();
  return useMemo(() => getSJDatabase(store, createLog), [store, createLog]);
};
