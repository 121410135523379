import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Account } from '@/shared/generated/graphql';
import { DeepPartial } from '@/shared/types/utility-types';

type IAuthState = {
  token: string | undefined;
  setToken: (token: string | undefined) => void;
  isBeneficiary: boolean;
  setIsBeneficiary: (is: boolean) => void;
  isAssistant: boolean;
  setIsAssistant: (is: boolean) => void;
  account: DeepPartial<Account> | undefined;
  setAccount: (account: DeepPartial<Account> | undefined) => void;
  phoneNumber: string | undefined;
  setPhoneNumber: (phoneNumber: string) => void;
  mainUserPublicKey: string | undefined | null;
  setMainUserPublicKey: (mainUserPublicKey: string | undefined | null) => void;
  clear: () => void;
};

export const useAuthStore = create(
  persist<IAuthState>(
    (set) => ({
      token: undefined,
      setToken: (token: string | undefined): void => set({ token }),
      isBeneficiary: false,
      setIsBeneficiary: (is) => set({ isBeneficiary: is }),
      isAssistant: false,
      setIsAssistant: (is) => set({ isAssistant: is }),
      account: undefined,
      setAccount: (account): void => set({ account }),
      phoneNumber: undefined,
      setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
      mainUserPublicKey: undefined,
      setMainUserPublicKey: (mainUserPublicKey) => set({ mainUserPublicKey }),
      clear: (): void =>
        set({
          token: undefined,
          account: undefined,
          isBeneficiary: false,
          phoneNumber: undefined,
          mainUserPublicKey: undefined,
        }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
