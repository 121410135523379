import { IconType } from '@/shared/icons/types';

export const IdCard = ({ width = 22, height = 22, ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    width={`${width}px`}
    fill="none"
    {...rest}
  >
    <g clipPath="url(#clip0_913_6837)">
      <path
        d="M21.2143 2.35278H0.785714C0.351116 2.35278 0 2.7039 0 3.1385V18.8528C0 19.2874 0.351116 19.6385 0.785714 19.6385H21.2143C21.6489 19.6385 22 19.2874 22 18.8528V3.1385C22 2.7039 21.6489 2.35278 21.2143 2.35278ZM20.2321 17.8706H1.76786V4.12064H20.2321V17.8706ZM13.4136 10.1117H16.4435C16.4754 10.1117 16.5 10.0233 16.5 9.91528V8.73671C16.5 8.62868 16.4754 8.54028 16.4435 8.54028H13.4136C13.3817 8.54028 13.3571 8.62868 13.3571 8.73671V9.91528C13.3571 10.0233 13.3817 10.1117 13.4136 10.1117ZM13.5315 13.6474H18.0911C18.1868 13.6474 18.2654 13.559 18.2654 13.451V12.2724C18.2654 12.1644 18.1868 12.076 18.0911 12.076H13.5315C13.4357 12.076 13.3571 12.1644 13.3571 12.2724V13.451C13.3571 13.559 13.4357 13.6474 13.5315 13.6474ZM3.92857 14.9488H5.00647C5.1096 14.9488 5.19308 14.8677 5.20045 14.7646C5.29375 13.5247 6.32991 12.5425 7.58705 12.5425C8.8442 12.5425 9.88036 13.5247 9.97366 14.7646C9.98103 14.8677 10.0645 14.9488 10.1676 14.9488H11.2455C11.2722 14.9488 11.2985 14.9434 11.323 14.9329C11.3475 14.9225 11.3696 14.9071 11.388 14.8878C11.4064 14.8685 11.4207 14.8457 11.4299 14.8207C11.4392 14.7957 11.4433 14.7691 11.442 14.7425C11.3732 13.4338 10.6562 12.2945 9.61027 11.6463C10.0715 11.1393 10.3264 10.478 10.3248 9.79252C10.3248 8.27265 9.09955 7.04252 7.58951 7.04252C6.07946 7.04252 4.85424 8.27265 4.85424 9.79252C4.85424 10.507 5.12433 11.1552 5.56875 11.6463C5.03625 11.9763 4.59153 12.4302 4.27257 12.9694C3.9536 13.5086 3.76986 14.1169 3.73705 14.7425C3.72723 14.8555 3.81562 14.9488 3.92857 14.9488ZM7.58705 8.51573C8.28683 8.51573 8.85647 9.08783 8.85647 9.79252C8.85647 10.4972 8.28683 11.0693 7.58705 11.0693C6.88728 11.0693 6.31763 10.4972 6.31763 9.79252C6.31763 9.08783 6.88728 8.51573 7.58705 8.51573Z"
        fill="#262625"
      />
    </g>
    <defs>
      <clipPath id="clip0_913_6837">
        <rect width={`${width}`} height={`${height}`} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
