import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { AssetCategory, AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import {
  ContactDto,
  ContactType,
} from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';

type PrevAssetType = AssetDto & {
  legalEntityIdentifier?: string;
  LEI?: string;
};

type PrevContactType = ContactDto & {
  scan?: string;
};

type PrevPersonalIdentifierDocumentDto = PersonalIdentifierDocumentDto & {
  storageLocation?: string[];
};

// eslint-disable-next-line sonarjs/cognitive-complexity,complexity
const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assets: SecureJsonBase<AssetDto> | undefined = store.get(
    CollectionName.ASSETS,
  );

  if (assets) {
    for (const [key, item] of (assets?.items as Map<
      string,
      { decryptedData: PrevAssetType }
    >) || []) {
      if (
        [AssetCategory.LEGAL_ENTITIES, AssetCategory.PUBLIC_FUND].includes(
          item.decryptedData.category,
        )
      ) {
        const doc = item.decryptedData;

        const lei = doc.legalEntityIdentifier
          ? `${doc.legalEntityIdentifier}`
          : doc.LEI
          ? `${doc.LEI}`
          : undefined;
        assets.set({
          key,
          data: {
            ...item.decryptedData,
            lei,
          },
        });
        delete item.decryptedData.legalEntityIdentifier;
      }
    }
  }

  const contacts: SecureJsonBase<ContactDto> | undefined = store.get(
    CollectionName.CONTACTS,
  );

  if (contacts) {
    for (const [key, item] of (contacts?.items as Map<
      string,
      { decryptedData: PrevContactType }
    >) || []) {
      if (ContactType.INDIVIDUAL === item.decryptedData.type) {
        const contact = item.decryptedData;

        const photo = contact.scan ? `${contact.scan}` : undefined;
        contacts.set({
          key,
          data: {
            ...item.decryptedData,
            photo,
          },
        });
        delete item.decryptedData.scan;
      }
    }
  }

  const documents: SecureJsonBase<PersonalIdentifierDocumentDto> | undefined =
    store.get(CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS);

  if (documents) {
    for (const [key, item] of (contacts?.items as Map<
      string,
      { decryptedData: PrevPersonalIdentifierDocumentDto }
    >) || []) {
      if (
        PersonalIdentifierType.NATIONAL_ID ===
        item.decryptedData.personalIdentifierType
      ) {
        const doc = item.decryptedData;

        const storageLocation =
          doc.storageLocation &&
          Array.isArray(doc.storageLocation) &&
          doc.storageLocation.length
            ? `${doc.storageLocation[0]}`
            : undefined;
        documents.set({
          key,
          data: {
            ...item.decryptedData,
            storageLocation,
          },
        });
      }
    }
  }
};

export const migration007: TMigration = {
  version: '0.0.7',
  up,
};
