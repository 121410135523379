import { IconType } from '@/shared/icons/types';

export const Filter = ({ ...rest }: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    {...rest}
  >
    <path
      d="M18.8727 0.372559H1.12503C0.534404 0.372559 0.165565 1.01622 0.462083 1.5297L6.0694 11.0617V16.8618C6.0694 17.2885 6.41173 17.6333 6.83601 17.6333H13.1617C13.586 17.6333 13.9283 17.2885 13.9283 16.8618V11.0617L19.5381 1.5297C19.8322 1.01622 19.4633 0.372559 18.8727 0.372559ZM12.2023 15.8976H7.79548V12.1368H12.2047V15.8976H12.2023ZM12.4337 10.1938L12.2047 10.594H7.79307L7.56405 10.1938L2.7836 2.10827H17.2141L12.4337 10.1938Z"
      fill="currentColor"
    />
  </svg>
);
