import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BloodType {
  A_PLUS = 'A+',
  A_MINUS = 'A-',
  B_PLUS = 'B+',
  B_MINUS = 'B-',
  O_PLUS = 'O+',
  O_MINUS = 'O-',
  AB_PLUS = 'AB+',
  AB_MINUS = 'AB-',
}

const BloodTypeList = [
  {
    label: BloodType.A_PLUS,
    key: BloodType.A_PLUS,
  },
  {
    label: BloodType.A_MINUS,
    key: BloodType.A_MINUS,
  },
  {
    label: BloodType.B_PLUS,
    key: BloodType.B_PLUS,
  },
  {
    label: BloodType.B_MINUS,
    key: BloodType.B_MINUS,
  },
  {
    label: BloodType.O_PLUS,
    key: BloodType.O_PLUS,
  },
  {
    label: BloodType.O_MINUS,
    key: BloodType.O_MINUS,
  },
  {
    label: BloodType.AB_PLUS,
    key: BloodType.AB_PLUS,
  },
  {
    label: BloodType.AB_MINUS,
    key: BloodType.AB_MINUS,
  },
];

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

const GenderList = [
  {
    label: 'Male',
    key: GenderType.MALE,
  },
  {
    label: 'Female',
    key: GenderType.FEMALE,
  },
  {
    label: 'Other',
    key: GenderType.OTHER,
  },
];

export type UserDetailsDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  mainInfoTitle?: FormItemLabelType;
  name: string;
  surnames: string;
  names: string;
  details?: FormItemLabelType;
  middleName?: string;
  fullName?: string;
  gender?: GenderType;
  bloodType?: BloodType;
  cityOfBirth?: string;
  countryOfBirth?: string;
  birthDate?: number;
  scan?: string;
};

export type UserDetailsDtoHelpType = {
  // mainInfoTitle?: FormLabelItem.label;
  name: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  names: QuestionType.TEXT;
  // details?: FormLabelItem.label;
  middleName?: QuestionType.TEXT;
  fullName?: QuestionType.TEXT;
  gender?: QuestionType.SELECT_FROM_LIST;
  bloodType?: QuestionType.SELECT_FROM_LIST;
  cityOfBirth?: QuestionType.TEXT;
  countryOfBirth?: QuestionType.SELECT_FROM_LIST;
  birthDate?: QuestionType.DATE_TIME;
  scan?: QuestionType.FILE_UPLOAD;
};

type UserDetailsFormType = TDtoWrapper<
  UserDetailsDtoType,
  UserDetailsDtoHelpType
>;

export const UserDetailsForm: UserDetailsFormType = {
  mainInfoTitle: {
    type: FormLabelItem.label,
    label: 'Main information',
  },
  name: {
    type: QuestionType.TEXT,
    label: '*Short name(s)',
    placeholder: 'Enter your short name',
    required: true,
    value: '',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*First name(s)',
    placeholder: 'Enter your first name',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Last name(s)',
    placeholder: 'Enter your last name',
    required: true,
    value: '',
  },
  details: {
    type: FormLabelItem.label,
    label: 'Details',
  },
  middleName: {
    type: QuestionType.TEXT,
    label: 'Middle name(s)',
  },
  fullName: {
    type: QuestionType.TEXT,
    label: 'Full name(s) in native language',
  },
  gender: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Gender',
    items: GenderList,
  },
  bloodType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Blood type',
    items: BloodTypeList,
  },
  countryOfBirth: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Country of birth',
    items: CountriesList,
    withSearch: true,
  },
  cityOfBirth: {
    type: QuestionType.TEXT,
    label: 'City of birth',
  },
  birthDate: {
    type: QuestionType.DATE_TIME,
    label: 'Birth date',
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My ToDo',
    tooltip:
      'All planned actions on an id, for example: renting out a house, paying annual membership fees, or any other relevant actions',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My ToDo Comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Photo',
    tooltip: 'Take or upload a photo/scan of your document',
  },
};
