import { useMemo, useState } from 'react';
import { Drawer, Input, Modal, Typography } from 'antd';
import styled from 'styled-components';

import { useMultiCurrencies } from '@/entities/currencies';
import { Button, IconButton } from '@/shared/components';
import { CurrenciesSelect } from '@/shared/components/currencies-select';
import { Row } from '@/shared/components/layout';
import { COLORS } from '@/shared/constants/colors';
import { useCurrencies } from '@/shared/hooks/use-currencies';

export const ConfigureCurrency = ({
  openDrawer,
  setOpenDrawer,
}: {
  openDrawer: boolean;
  setOpenDrawer: (arg: boolean) => void;
}) => {
  const { getBaseCurrencies, list } = useCurrencies();
  const { setCustomCurrency, getCurrencies } = useMultiCurrencies();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    getBaseCurrencies[0]?.baseCurrency || 'USD',
  );
  const [selectedValue, setSelectedValue] = useState<number>(
    +getBaseCurrencies[0]?.value || 1,
  );
  const sortedList = [...list].sort((a, b) =>
    a.currencyCode.localeCompare(b.currencyCode),
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOkModal = () => {
    setIsModalOpen(false);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setSelectedCurrency('USD');
    setSelectedValue(1);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const onCurrencyChange = (e: string) => {
    const currencyObject = list.find((item) => item.currencyCode === e);
    if (currencyObject) {
      setSelectedCurrency(e);
      setSelectedValue(+currencyObject.value);
    }
  };

  const saveCurrency = () => {
    const currencyObject = list.find(
      (item) => item.currencyCode === selectedCurrency,
    );
    if (currencyObject && +currencyObject.value !== selectedValue) {
      setCustomCurrency(selectedCurrency, selectedValue);
      handleCancelModal();
    }
  };

  const customCurrencies = useMemo(() => {
    return getCurrencies.filter((cur) => cur.isCustom);
  }, [getCurrencies]);

  return (
    <>
      <StyledDrawer
        title="Configure currency"
        placement="bottom"
        onClose={onCloseDrawer}
        open={openDrawer}
        style={{ backgroundColor: COLORS.white }}
        height="95%"
      >
        <Row direction="column" justifyContent="space-between" height="100%">
          <Row direction="column" gapRow={24}>
            <Row
              alignCenter
              gapColumn={16}
              onClick={showModal}
              style={{ cursor: 'pointer' }}
            >
              <IconButton icon={'add'} shape={'rectangle'} />
              <Typography.Text
                style={{ color: COLORS.colorTextTertiary, fontSize: '12px' }}
              >
                Add a currency pair - for quick use
              </Typography.Text>
            </Row>

            <Row direction="column" nowrap>
              {customCurrencies &&
                customCurrencies.map((currency, index) => (
                  <CurrencyRow key={index}>
                    <Typography.Text strong>
                      1 {currency.currencyCode}
                    </Typography.Text>
                    <Typography.Text>{currency.value}</Typography.Text>
                    <Typography.Text strong>1 USD</Typography.Text>
                  </CurrencyRow>
                ))}
            </Row>
          </Row>

          <Row direction="column" gapRow={10} alignCenter>
            <Button text="Save and update" onClick={onCloseDrawer} />

            <Typography.Text
              style={{ color: COLORS.colorTextTertiary, fontSize: '12px' }}
            >
              This will automatically update every asset value
            </Typography.Text>
          </Row>
        </Row>
      </StyledDrawer>
      <StyledModal
        width={390}
        title="Configure exchange rate"
        open={isModalOpen}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        footer={null}
        centered
      >
        <Row marginTop={24}>
          <Typography.Text style={{ color: COLORS.colorTextSecondary }}>
            Set the custom exchange rate for nominal and equivalent currencies
          </Typography.Text>
        </Row>

        <Row
          nowrap
          alignCenter
          gapColumn={8}
          width="100%"
          justifyContent="space-between"
          marginTop={24}
          marginBottom={48}
        >
          <CurrenciesSelect
            showSearch={true}
            placeholder={'USD'}
            options={sortedList.map((item) => ({
              key: item.currencyCode,
              label: item.currencyCode,
              value: item.currencyCode,
            }))}
            onChange={onCurrencyChange}
            defaultValue={selectedCurrency}
            dataCy="currencyDropdown"
            style={{ width: '30%' }}
          />
          <Input
            type="number"
            style={{ width: '30%' }}
            onChange={(e) => setSelectedValue(+e.target.value)}
            value={+selectedValue.toFixed(4)}
            disabled={selectedCurrency === 'USD'}
          />

          <Typography.Text style={{ width: '30%', textAlign: 'center' }}>
            1 USD
          </Typography.Text>
        </Row>

        <Row justifyContent="space-between">
          <Button
            type="default"
            block={false}
            text="Cancel"
            onClick={handleCancelModal}
            style={{ width: '48%' }}
            size="middle"
          />
          <Button
            text="Save"
            block={false}
            disabled={selectedValue === 0}
            onClick={saveCurrency}
            style={{ width: '48%' }}
            size="middle"
          />
        </Row>
      </StyledModal>
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: none !important;
    padding: 24px 24px 12px !important;
  }
  .ant-drawer-body {
    padding-top: 12px !important;
  }
  .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 18px !important;

    svg {
      fill: #8c7b4b;
    }
  }

  .ant-drawer-title {
    font-size: 20px !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 16px 24px !important;
  }

  .ant-modal-title {
    font-size: 20px !important;
  }
`;

const CurrencyRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-top: 1px solid ${COLORS.colorBorder};

  &:nth-last-child(1) {
    border-bottom: 1px solid ${COLORS.colorBorder};
  }
`;
