import { useEffect, useMemo, useState } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { useSynchronizerStoreStore } from '@/_app/providers/stores-initialization-provider';
import { useAssets } from '@/entities/assets/hooks/use-assets';
import { useBeneficiary } from '@/entities/next-owner/hooks/use-beneficiary';
import useAwaitableTransition from '@/shared/hooks/use-awaitable-transition';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';

export const useDashboard = () => {
  const store = useDashboardStore();

  const { isLoading: isSyncLoading, isInitialized: isSyncInitialized } =
    useSynchronizerStoreStore();

  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState<AssetDto[]>(store.assets);
  const [beneficiaries, setBeneficiaries] = useState<BeneficiaryDto[]>(
    store.beneficiaries,
  );
  const [beneficiariesValues, setBeneficiariesValues] = useState<
    (BeneficiaryDto & { value: number; isActivated: boolean })[]
  >(store.beneficiariesValues);
  const { getAssetsList, loading: assetsLoading } = useAssets();
  const {
    getBeneficiariesList,
    getBeneficiariesWithValue,
    loading: beneficiariesLoading,
  } = useBeneficiary();
  const [, startTransition] = useAwaitableTransition();

  useEffect(() => {
    if (store.assets && store.assets?.length && !assets) {
      setAssets(store.assets);
      setLoading(false);
    }
  }, [store.assets]);

  useEffect(() => {
    const fetch = async () => {
      const _assets = getAssetsList('all');
      const _beneficiaries = getBeneficiariesList('all');
      const _beneficiariesWithValues =
        (await getBeneficiariesWithValue()) || [];
      if (_assets.length) setAssets(_assets);
      if (_beneficiaries.length) setBeneficiaries(_beneficiaries);
      if (_beneficiariesWithValues.length)
        setBeneficiariesValues(_beneficiariesWithValues);

      store.setData({
        assets: _assets.length ? _assets : undefined,
        beneficiaries: _beneficiaries.length ? _beneficiaries : undefined,
        beneficiariesValues: _beneficiariesWithValues.length
          ? _beneficiariesWithValues
          : undefined,
      });
    };

    setTimeout(
      () =>
        window.requestAnimationFrame(() =>
          startTransition(() => fetch().finally(() => setLoading(false))),
        ),
      2_000,
    );
  }, [
    getAssetsList,
    getBeneficiariesList,
    getBeneficiariesWithValue,
    store.setData,
  ]);

  return useMemo(
    () => ({
      assets,
      beneficiaries,
      beneficiariesValues,
      clear: store.clear,
      loading:
        loading ||
        assetsLoading ||
        beneficiariesLoading ||
        isSyncLoading ||
        !isSyncInitialized,
    }),
    [
      assets,
      beneficiaries,
      beneficiariesValues,
      store.clear,
      loading,
      isSyncLoading,
      isSyncInitialized,
      assetsLoading,
      beneficiariesLoading,
    ],
  );
};

type AssistantStoreType = {
  assets: AssetDto[];
  beneficiaries: BeneficiaryDto[];
  beneficiariesValues: (BeneficiaryDto & {
    value: number;
    isActivated: boolean;
  })[];
  setData: (
    data: Partial<{
      assets: AssetDto[];
      beneficiaries: BeneficiaryDto[];
      beneficiariesValues: (BeneficiaryDto & {
        value: number;
        isActivated: boolean;
      })[];
    }>,
  ) => void;
  clear: () => void;
};

export const useDashboardStore = create(
  persist<AssistantStoreType>(
    (set) => ({
      assets: [],
      beneficiaries: [],
      beneficiariesValues: [],
      setData: (
        data: Partial<{
          assets: AssetDto[];
          beneficiaries: BeneficiaryDto[];
          beneficiariesValues: (BeneficiaryDto & {
            value: number;
            isActivated: boolean;
          })[];
        }>,
      ) => {
        if (!data.assets) delete data.assets;
        if (!data.beneficiaries) delete data.beneficiaries;
        if (!data.beneficiariesValues) delete data.beneficiariesValues;
        set({
          beneficiaries: data.beneficiaries,
          assets: data.assets,
          beneficiariesValues: data.beneficiariesValues,
        });
      },
      clear: (): void =>
        set({ beneficiariesValues: [], beneficiaries: [], assets: [] }),
    }),
    {
      name: 'dashboard-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
