import { BankCellForm } from '@/shared/lib/forms/asset-form/bank-cell';
import { BondsForm } from '@/shared/lib/forms/asset-form/bonds';
import { BrokerageAccountForm } from '@/shared/lib/forms/asset-form/brokerage-account';
import { CentralizedWalletForm } from '@/shared/lib/forms/asset-form/centralized-wallet';
import { CommercialRealEstateForm } from '@/shared/lib/forms/asset-form/commercial-real-estate';
import { CommodityAssetTokenForm } from '@/shared/lib/forms/asset-form/commodity-asset-token';
import { DebitAccountForm } from '@/shared/lib/forms/asset-form/debit-account';
import { DecentralizedWalletForm } from '@/shared/lib/forms/asset-form/decentralized-wallet';
import { ExchangeOptionsForm } from '@/shared/lib/forms/asset-form/exchange-options';
import { ForwardsForm } from '@/shared/lib/forms/asset-form/forwards';
import { FuturesForm } from '@/shared/lib/forms/asset-form/futures';
import { JewelryForm } from '@/shared/lib/forms/asset-form/jewelry';
import { LandForm } from '@/shared/lib/forms/asset-form/land';
import { LegalEntityForm } from '@/shared/lib/forms/asset-form/legal-entity';
import { MetalAccountForm } from '@/shared/lib/forms/asset-form/metal-account';
import { MyFundForm } from '@/shared/lib/forms/asset-form/my-fund';
import { OverTheCounterOptionsForm } from '@/shared/lib/forms/asset-form/over-the-counter options';
import { PreciousGemstonesForm } from '@/shared/lib/forms/asset-form/precious-gemstones';
import { PublicFundForm } from '@/shared/lib/forms/asset-form/public-fund';
import { ResidentialRealEstateForm } from '@/shared/lib/forms/asset-form/residential-real-estate';
import { SecuritiesAndStocksForm } from '@/shared/lib/forms/asset-form/securities-and-stocks';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { AssetCategory } from '@/shared/lib/sj-orm/models/asset.dto';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { USDollar } from '@/shared/utils/misc';

export const AssetCategoryToText: Record<AssetCategory, string> = {
  [AssetCategory.DEBIT_ACCOUNTS]: 'assets:assetTypesEnum.debit_accounts',
  [AssetCategory.RESIDENTIAL_REAL_ESTATE]:
    'assets:assetTypesEnum.residential_real_estate',
  [AssetCategory.COMMERCIAL_REAL_ESTATE]:
    'assets:assetTypesEnum.commercial_real_estate',
  [AssetCategory.LAND]: 'assets:assetTypesEnum.land',
  [AssetCategory.LEGAL_ENTITIES]: 'assets:assetTypesEnum.legal_entities',
  [AssetCategory.JEWELLERY]: 'assets:assetTypesEnum.jewellery',
  [AssetCategory.BANK_CELLS]: 'assets:assetTypesEnum.bank_cells',
  [AssetCategory.METAL_ACCOUNTS]: 'assets:assetTypesEnum.metal_accounts',
  [AssetCategory.CENTRALIZED_WALLET]:
    'assets:assetTypesEnum.centralized_wallet',
  [AssetCategory.DECENTRALIZED_WALLET]:
    'assets:assetTypesEnum.decentralized_wallet',
  [AssetCategory.PRECIOUS_STONES]: 'assets:assetTypesEnum.precious_stones',
  [AssetCategory.COMMODITY_ASSET_TOKENS]:
    'assets:assetTypesEnum.commodity_asset_tokens',
  [AssetCategory.BROKERAGE_ACCOUNT]: 'assets:assetTypesEnum.brokerage_account',
  [AssetCategory.SECURITIES_AND_STOCKS]:
    'assets:assetTypesEnum.securities_and_stocks',
  [AssetCategory.BONDS]: 'assets:assetTypesEnum.bonds',
  [AssetCategory.EXCHANGE_OPTIONS]: 'assets:assetTypesEnum.exchange_options',
  [AssetCategory.OVER_THE_COUNTER_OPTIONS]: 'assets:assetTypesEnum.the',
  [AssetCategory.FORWARDS]: 'assets:assetTypesEnum.forwards',
  [AssetCategory.FUTURES]: 'assets:assetTypesEnum.futures',
  [AssetCategory.MY_FUND]: 'assets:assetTypesEnum.my_fund',
  [AssetCategory.PUBLIC_FUND]: 'assets:assetTypesEnum.public_fund',
};

export const AssetsForms: Record<
  AssetCategory,
  Array<TDtoWrapper<Omit<BaseDto, 'id'>, NonNullable<unknown>>> | null
> = {
  [AssetCategory.DEBIT_ACCOUNTS]: DebitAccountForm, // +
  [AssetCategory.RESIDENTIAL_REAL_ESTATE]: ResidentialRealEstateForm, // +
  [AssetCategory.COMMERCIAL_REAL_ESTATE]: CommercialRealEstateForm, // +
  [AssetCategory.LAND]: LandForm, // +
  [AssetCategory.PUBLIC_FUND]: PublicFundForm, // +
  [AssetCategory.JEWELLERY]: JewelryForm, // +
  [AssetCategory.LEGAL_ENTITIES]: LegalEntityForm, // +
  [AssetCategory.SECURITIES_AND_STOCKS]: SecuritiesAndStocksForm, // +
  [AssetCategory.BROKERAGE_ACCOUNT]: BrokerageAccountForm, // +
  [AssetCategory.BANK_CELLS]: BankCellForm, // +
  [AssetCategory.METAL_ACCOUNTS]: MetalAccountForm, //MetalAccountForm,
  [AssetCategory.PRECIOUS_STONES]: PreciousGemstonesForm, //PreciousGemstonesForm
  [AssetCategory.CENTRALIZED_WALLET]: CentralizedWalletForm, //CentralizedWalletForm,
  [AssetCategory.DECENTRALIZED_WALLET]: DecentralizedWalletForm, //DecentralizedWalletForm,
  [AssetCategory.BONDS]: BondsForm, //BondsForm,
  [AssetCategory.EXCHANGE_OPTIONS]: ExchangeOptionsForm, //ExchangeOptionsForm,
  [AssetCategory.OVER_THE_COUNTER_OPTIONS]: OverTheCounterOptionsForm, // OverTheCounterOptionsForm
  [AssetCategory.FORWARDS]: ForwardsForm, //ForwardsForm,
  [AssetCategory.FUTURES]: FuturesForm, //FuturesForm,
  [AssetCategory.MY_FUND]: MyFundForm, //MyFundForm
  [AssetCategory.COMMODITY_ASSET_TOKENS]: CommodityAssetTokenForm, //CommodityAssetTokenForm
};

// export const AssetCategoryDtoParser: Record<AssetCategory, () => unknown> = {
//   [AssetCategory.BANK_CELLS]: parser,
// };

// const relatedDocumentsText = (order: number): FormItemLabelType<unknown> => ({
//   type: FormLabelItem.label,
//   label: 'Assets related documents',
//   order,
// });

export const AssetFormsTexts: Record<
  AssetCategory,
  Array<{ [key: string]: FormItemLabelType<unknown> }> | null
> = {
  [AssetCategory.BROKERAGE_ACCOUNT]: [
    {},
    {
      investmentLimit: {
        type: FormLabelItem.text,
        label: {
          prefix: 'forms_assets:brokerageAccount.inputs.investedAssets.label',
          fieldKey: 'purchaseValue.calculatedValue',
          postfix: '',
          formator: (data: string) =>
            isNaN(Number(data)) ? data : USDollar.format(Number(data)),
        },
        order: 1,
      },
      // assetRelatedDocuments: relatedDocumentsText(13),
    },
  ],
  [AssetCategory.DEBIT_ACCOUNTS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(6),
    },
  ],
  [AssetCategory.COMMERCIAL_REAL_ESTATE]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(10),
    },
  ],
  [AssetCategory.COMMODITY_ASSET_TOKENS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(17),
    },
  ],
  [AssetCategory.LAND]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(10),
    },
  ],
  [AssetCategory.CENTRALIZED_WALLET]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(5),
    },
  ],
  [AssetCategory.DECENTRALIZED_WALLET]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(4),
    },
  ],
  [AssetCategory.JEWELLERY]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(31),
    },
  ],
  [AssetCategory.LEGAL_ENTITIES]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(30),
    },
  ],
  [AssetCategory.METAL_ACCOUNTS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(8),
    },
  ],
  [AssetCategory.SECURITIES_AND_STOCKS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(18),
    },
  ],
  [AssetCategory.PRECIOUS_STONES]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(34),
    },
  ],
  [AssetCategory.RESIDENTIAL_REAL_ESTATE]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(10),
    },
  ],
  [AssetCategory.BANK_CELLS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(8),
    },
  ],
  [AssetCategory.BONDS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(16),
    },
  ],
  [AssetCategory.EXCHANGE_OPTIONS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(16),
    },
  ],
  [AssetCategory.OVER_THE_COUNTER_OPTIONS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(12),
    },
  ],
  [AssetCategory.FORWARDS]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(10),
    },
  ],
  [AssetCategory.FUTURES]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(14),
    },
  ],
  [AssetCategory.MY_FUND]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(30),
    },
  ],
  [AssetCategory.PUBLIC_FUND]: [
    {},
    {
      // assetRelatedDocuments: relatedDocumentsText(24),
    },
  ],
};
