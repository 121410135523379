import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import {
  PullCollectionDocument,
  PushCollectionDocument,
} from '@/shared/generated/graphql';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { Loader } from '@/shared/lib/synchronizer/loaders/types';

import { SerializedCollection } from '../core/types';

export class GraphQLLoader implements Loader {
  constructor(private readonly client: ApolloClient<NormalizedCacheObject>) {}

  async push(
    collectionName: CollectionName,
    collection: SerializedCollection,
  ): Promise<boolean> {
    const mutationResult = await this.client.mutate({
      mutation: PushCollectionDocument,
      variables: {
        name: collectionName,
        data: collection,
      },
      fetchPolicy: 'network-only',
    });

    return mutationResult.data.pushCollection;
  }

  async pull(
    collectionName: CollectionName,
  ): Promise<SerializedCollection | null> {
    const queryResult = await this.client.query({
      query: PullCollectionDocument,
      variables: {
        name: collectionName,
      },
      fetchPolicy: 'network-only',
    });

    return queryResult.data.collection1?.data;
  }
}
