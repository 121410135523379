import forge from 'node-forge';

import {
  KeyPair,
  PrivateKey,
  PublicKey,
} from '@/shared/lib/secure-json/core/crypto-core/types';

export class Rsa {
  public static trimKey<T extends PublicKey | PrivateKey>(key: T): T {
    return key.replace(/\r\n|\r|\n/g, '') as T;
  }

  public generateKeyPair(): KeyPair {
    const keyPair = forge.pki.rsa.generateKeyPair({ bits: 2048 });
    const publicKey = forge.pki.publicKeyToPem(keyPair.publicKey);
    const privateKey = forge.pki.privateKeyToPem(keyPair.privateKey);
    const publicKeySingleLine = Rsa.trimKey<PublicKey>(publicKey);
    const privateKeySingleLine = Rsa.trimKey<PrivateKey>(privateKey);
    return {
      publicKey: publicKeySingleLine,
      privateKey: privateKeySingleLine,
    };
  }

  public derivePublicKey(privateKey: PrivateKey): PublicKey {
    const privateKeyObject = forge.pki.privateKeyFromPem(privateKey);
    const publicKeyObject = forge.pki.setRsaPublicKey(
      privateKeyObject.n,
      privateKeyObject.e,
    );

    const publicKey = forge.pki.publicKeyToPem(publicKeyObject);
    return Rsa.trimKey<PublicKey>(publicKey);
  }

  public encrypt(plaintext: string, publicKey: PublicKey): string {
    const publicKeyObject = forge.pki.publicKeyFromPem(publicKey);

    return forge.util.encode64(publicKeyObject.encrypt(plaintext, 'RSA-OAEP'));
  }

  public decrypt(encryptedData: string, privateKey: PrivateKey): string {
    const privateKeyObject = forge.pki.privateKeyFromPem(privateKey);

    return privateKeyObject.decrypt(
      forge.util.decode64(encryptedData),
      'RSA-OAEP',
    );
  }
}
