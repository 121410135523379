import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';

import { BaseDto } from '../../sj-orm/models/base.dto';

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const contacts: SecureJsonBase<AssetDto> | undefined = store.get(
    CollectionName.CONTACTS,
  );

  if (contacts) {
    for (const [, item] of (contacts?.items as Map<
      string,
      { decryptedData: BaseDto }
    >) || []) {
      if (item.decryptedData.id === '') contacts.items.delete('');
    }
  }
};

export const migration009: TMigration = {
  version: '0.0.9',
  up,
};
