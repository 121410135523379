import { SecureJsonCollection } from './secure-json-collection';

export class SecureJsonSerializer {
  // eslint-disable-next-line camelcase
  static isISO8601_Z = new RegExp(
    '^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}.\\d+(Z|\\+\\d{2}:\\d{2})$',
  );
  public toString(collection: SecureJsonCollection): string {
    const safeCollection = new SecureJsonCollection();
    safeCollection.items = collection.items;
    return JSON.stringify(safeCollection, this.replacer);
  }

  public fromString(serializedCollection: string): SecureJsonCollection {
    return JSON.parse(serializedCollection, this.reviver);
  }

  private replacer(_: string, value: unknown): unknown {
    if (value instanceof Map) {
      return {
        __dataType: 'Map',
        value: [...value.entries()],
      };
    } else if (value instanceof Set) {
      return {
        __dataType: 'Set',
        value: [...value.values()],
      };
    } else {
      return value;
    }
  }

  private reviver(
    _: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: { __dataType: string; value: Map<unknown, unknown> } | any,
  ): unknown {
    if (value && typeof value === 'object' && value.__dataType === 'Map') {
      return new Map(value.value);
    } else if (
      value &&
      typeof value === 'object' &&
      value.__dataType === 'Set'
    ) {
      return new Set(value.value);
    } else {
      // eslint-disable-next-line camelcase
      if (
        typeof value === 'string' &&
        SecureJsonSerializer.isISO8601_Z.test(value)
      ) {
        return new Date(value);
      }
      return value;
    }
  }
}
