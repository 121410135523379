import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import {
  assetsDocumentsPageBuilder,
  AssetsDocumentsPagePartType,
} from '@/entities/assets/helpers/assets-documents-page-builder';
import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { ContactType } from '../../sj-orm/models/contact.dto';

export enum ManagementBodyType {
  manager = 'manager',
  boardOfDirectors = 'boardOfDirectors',
  managementCompany = 'managementCompany',
  other = 'other',
}

const ManagementBodyList = [
  {
    label: 'forms_assets:myFund.enums.managementBody.manager',
    key: ManagementBodyType.manager,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.boardOfDirectors',
    key: ManagementBodyType.boardOfDirectors,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.managementCompany',
    key: ManagementBodyType.managementCompany,
  },
  {
    label: 'forms_assets:myFund.enums.managementBody.other',
    key: ManagementBodyType.other,
  },
];

export enum PublicFundType {
  VentureCapitalFund = 'VentureCapitalFund',
  HedgeFund = 'HedgeFund',
  EquityFund = 'EquityFund',
  RealEstateFund = 'RealEstateFund',
  IndexedFund = 'IndexedFund',
  Other = 'Other',
}

const PublicFundTypesList = [
  {
    label: 'forms_assets:publicFund.enums.fundType.VentureCapitalFund',
    key: PublicFundType.VentureCapitalFund,
  },
  {
    label: 'forms_assets:publicFund.enums.fundType.HedgeFund',
    key: PublicFundType.HedgeFund,
  },
  {
    label: 'forms_assets:publicFund.enums.fundType.EquityFund',
    key: PublicFundType.EquityFund,
  },
  {
    label: 'forms_assets:publicFund.enums.fundType.RealEstateFund',
    key: PublicFundType.RealEstateFund,
  },
  {
    label: 'forms_assets:publicFund.enums.fundType.IndexedFund',
    key: PublicFundType.IndexedFund,
  },
  {
    label: 'forms_assets:publicFund.enums.fundType.Other',
    key: PublicFundType.Other,
  },
];

export enum FundUsageStrategyType {
  strategic = 'strategic',
  personal = 'personal',
  commercial = 'commercial',
  investment = 'investment',
  other = 'other',
}

export const FundUsageStrategyList = [
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.strategic',
    key: FundUsageStrategyType.strategic,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.personal',
    key: FundUsageStrategyType.personal,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.investment',
    key: FundUsageStrategyType.investment,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.commercial',
    key: FundUsageStrategyType.commercial,
  },
  {
    label: 'forms_assets:myFund.enums.fundUsageStrategy.other',
    key: FundUsageStrategyType.other,
  },
];

type PublicFundPart1Type = Omit<BaseDto, 'id'> & {
  nickname: string;
  country: string;
  type: PublicFundType;
  otherType: string;
  purchaseValue: number;
  fullLegalName: string;
};

type PublicFundPart2Type = Omit<BaseDto, 'id'> & {
  managementBody?: ManagementBodyType;
  manager?: DtoId[];
  boardOfDirectors?: DtoId[];
  managementCompany?: DtoId[];
  other?: string;
  fundFounders?: DtoId[];
  typeofBusiness?: string;
  legalEntityStructure?: string;
  incorporationDate?: Date;
  fundUsageStrategy?: FundUsageStrategyType;
  strategyDescription?: string;
  otherFundUsageStrategy?: string;
  owner?: DtoId[];
  powers?: DtoId[];
  taxNumber?: string;
  taxNumberIssuer?: string;
  lei?: string;
  legalEntityAddress?: FormItemLabelType;
  province?: string;
  zip?: number;
  city?: string;
  street?: string;
  buildingNumber?: string;
  apartments?: string;
  otherAddressInfo?: string;
};

type PublicFundHelpPart1Type = {
  nickname: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  type: QuestionType.SELECT_FROM_LIST;
  otherType: QuestionType.TEXT;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
  fullLegalName: QuestionType.TEXT;
};

type PublicFundHelpPart2Type = {
  managementBody?: QuestionType.SELECT_FROM_LIST;
  manager?: QuestionType.PICK_ARRAY_OF_DTO;
  boardOfDirectors?: QuestionType.PICK_ARRAY_OF_DTO;
  managementCompany?: QuestionType.PICK_ARRAY_OF_DTO;
  other?: QuestionType.TEXT;
  fundFounders?: QuestionType.PICK_ARRAY_OF_DTO;
  typeofBusiness?: QuestionType.TEXT;
  legalEntityStructure?: QuestionType.TEXT;
  incorporationDate?: QuestionType.DATE_TIME;
  fundUsageStrategy?: QuestionType.SELECT_FROM_LIST;
  otherFundUsageStrategy?: QuestionType.TEXT;
  strategyDescription?: QuestionType.TEXT_MULTILINE;
  taxNumber?: QuestionType.TEXT;
  taxNumberIssuer?: QuestionType.TEXT;
  lei?: QuestionType.TEXT;
  province?: QuestionType.TEXT;
  zip?: QuestionType.TEXT;
  city?: QuestionType.TEXT;
  street?: QuestionType.TEXT;
  buildingNumber?: QuestionType.TEXT;
  apartments?: QuestionType.TEXT;
  otherAddressInfo?: QuestionType.TEXT_MULTILINE;
};

type PublicFundFormPart1Type = TDtoWrapper<
  PublicFundPart1Type,
  PublicFundHelpPart1Type
>;

type PublicFundFormPart2Type = TDtoWrapper<
  PublicFundPart2Type,
  PublicFundHelpPart2Type
>;

export type PublicFundFormType = [
  PublicFundFormPart1Type,
  PublicFundFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
  AssetsDocumentsPagePartType,
];

const PublicFundFormPart1: PublicFundFormPart1Type = {
  nickname: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
    order: 1,
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
    order: 1,
  },
  type: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.type.label',
    tooltip: 'forms_assets:publicFund.inputs.type.tooltip',
    value: PublicFundType.HedgeFund,
    required: true,
    items: PublicFundTypesList,
    order: 1,
  },
  otherType: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.otherType.label',
    order: 1,
    showIf: {
      type: {
        value: PublicFundType.Other,
      },
    },
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:publicFund.inputs.investmentUnitInTheFund.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
    order: 1,
  },
  fullLegalName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.fullLegalName.label',
    value: '',
    order: 1,
  },
};

const PublicFundFormPart2: PublicFundFormPart2Type = {
  managementBody: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:publicFund.inputs.managementBody.label',
    items: ManagementBodyList,
    order: 1,
  },
  manager: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.manager.label',
    items: 'contacts',
    order: 2,
    showIf: {
      managementBody: {
        value: ManagementBodyType.manager,
      },
    },
    dtoType: ContactType.BANK,
  },
  boardOfDirectors: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.boardOfDirectors.label',
    items: 'contacts',
    mode: 'multiple',
    order: 3,
    showIf: {
      managementBody: {
        value: ManagementBodyType.boardOfDirectors,
      },
    },
    dtoType: ContactType.BANK,
  },
  managementCompany: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.managementCompany.label',
    order: 4,
    items: 'contacts',
    showIf: {
      managementBody: {
        value: ManagementBodyType.managementCompany,
      },
    },
    dtoType: ContactType.BANK,
  },
  other: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.other.label',
    order: 5,
    showIf: {
      managementBody: {
        value: ManagementBodyType.other,
      },
    },
  },
  fundFounders: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:publicFund.inputs.fundFounders.label',
    order: 6,
    items: 'contacts',
    mode: 'multiple',
    dtoType: ContactType.BANK,
  },
  typeofBusiness: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.typeofBusiness.label',
    order: 7,
  },
  legalEntityStructure: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.legalEntityStructure.label',
    order: 8,
  },
  incorporationDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:publicFund.inputs.incorporationDate.label',
    tooltip: 'forms_assets:publicFund.inputs.incorporationDate.tooltip',
    order: 9,
  },
  fundUsageStrategy: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:publicFund.inputs.fundUsageStrategy.label',
    tooltip: 'forms_assets:publicFund.inputs.fundUsageStrategy.tooltip',
    items: FundUsageStrategyList,
    order: 10,
  },
  otherFundUsageStrategy: {
    type: QuestionType.TEXT,
    label: 'forms_assets:myFund.inputs.otherFundUsageStrategy.label',
    showIf: {
      fundUsageStrategy: {
        value: FundUsageStrategyType.other,
      },
    },
    order: 11,
  },
  strategyDescription: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:myFund.inputs.strategyDescription.label',
    order: 12,
  },
  taxNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.taxNumber.label',
    order: 13,
  },
  taxNumberIssuer: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.taxNumberIssuer.label',
    order: 14,
  },
  lei: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.lei.label',
    tooltip: 'forms_assets:common.inputs.lei.tooltip',
    order: 15,
  },
  legalEntityAddress: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.legalEntityAddress.label',
    order: 16,
  },
  province: {
    type: QuestionType.TEXT,
    label: 'forms_assets:publicFund.inputs.province.label',
    order: 17,
  },
  zip: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.zipCode.label',
    order: 18,
  },
  city: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.city.label',
    order: 19,
  },
  street: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.street.label',
    order: 20,
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.buildingNumber.label',
    order: 21,
  },
  apartments: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.apartment.label',
    order: 22,
  },
  otherAddressInfo: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'forms_assets:publicFund.inputs.otherAddressInfo.label',
    order: 23,
  },
};

const PublicFundForm: PublicFundFormType = [
  PublicFundFormPart1,
  PublicFundFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgentWithBrokerOther',
    'taxAgent',
    'taxAgentOther',
    'specificsOfTaxation',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
  assetsDocumentsPageBuilder().form,
];

export { PublicFundForm };
