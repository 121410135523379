import { DefaultIconsSize, IconType } from '@/shared/icons/types';

export const User = ({
  width = DefaultIconsSize.width,
  height = DefaultIconsSize.height,
  ...rest
}: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    width={`${width}px`}
    fill="none"
    {...rest}
  >
    <path
      d="M21.2805 19.2359C20.7752 18.0391 20.0419 16.9519 19.1216 16.035C18.204 15.1155 17.117 14.3824 15.9207 13.8761C15.9099 13.8708 15.8992 13.8681 15.8885 13.8627C17.5573 12.6574 18.6421 10.694 18.6421 8.4788C18.6421 4.80915 15.6689 1.83594 11.9992 1.83594C8.32959 1.83594 5.35637 4.80915 5.35637 8.4788C5.35637 10.694 6.44119 12.6574 8.10994 13.8654C8.09923 13.8708 8.08852 13.8734 8.0778 13.8788C6.8778 14.385 5.80102 15.1109 4.87691 16.0377C3.95738 16.9553 3.22424 18.0423 2.71798 19.2386C2.22063 20.4098 1.9524 21.6655 1.9278 22.9377C1.92709 22.9663 1.9321 22.9948 1.94255 23.0214C1.953 23.048 1.96867 23.0723 1.98864 23.0927C2.00861 23.1132 2.03247 23.1295 2.05883 23.1406C2.08518 23.1517 2.11349 23.1574 2.14209 23.1574H3.74923C3.86709 23.1574 3.96084 23.0636 3.96352 22.9484C4.01709 20.8806 4.84744 18.944 6.3153 17.4761C7.83405 15.9574 9.85102 15.1217 11.9992 15.1217C14.1474 15.1217 16.1644 15.9574 17.6832 17.4761C19.151 18.944 19.9814 20.8806 20.0349 22.9484C20.0376 23.0663 20.1314 23.1574 20.2492 23.1574H21.8564C21.885 23.1574 21.9133 23.1517 21.9396 23.1406C21.966 23.1295 21.9899 23.1132 22.0098 23.0927C22.0298 23.0723 22.0455 23.048 22.0559 23.0214C22.0664 22.9948 22.0714 22.9663 22.0707 22.9377C22.0439 21.6574 21.7787 20.4118 21.2805 19.2359ZM11.9992 13.0859C10.7698 13.0859 9.61262 12.6065 8.74209 11.7359C7.87155 10.8654 7.39209 9.70826 7.39209 8.4788C7.39209 7.24933 7.87155 6.09219 8.74209 5.22165C9.61262 4.35112 10.7698 3.87165 11.9992 3.87165C13.2287 3.87165 14.3858 4.35112 15.2564 5.22165C16.1269 6.09219 16.6064 7.24933 16.6064 8.4788C16.6064 9.70826 16.1269 10.8654 15.2564 11.7359C14.3858 12.6065 13.2287 13.0859 11.9992 13.0859Z"
      fill="#262625"
    />
  </svg>
);
