import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { dispathEvent } from '@/shared/lib/hooks/use-form-render/misc';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import {
  AssetsOwnershipPart,
  OwnershipList,
  OwnershipType,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { ContactType } from '@/shared/lib/sj-orm/models/contact.dto';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

type AssetsOwnershipHelpPart = {
  ownerShipType?: QuestionType.SELECT_FROM_LIST;
  firstName?: QuestionType.TEXT;
  secondName?: QuestionType.TEXT;
  coOwners?: QuestionType.RENDER_NEW_BUTTON;
  isWithCoOwners?: QuestionType.CHECKBOX;
  owner?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwner?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwnerDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  coOwnerShare?: QuestionType.NUMBER;
  ownerShare?: QuestionType.NUMBER;
  ownerDocument?: QuestionType.PICK_ARRAY_OF_DTO;
  otherOwnerDocuments?: QuestionType.PICK_ARRAY_OF_DTO;
  address?: QuestionType.TEXT;
  phoneNumber?: QuestionType.PHONE_NUMBER;
  email?: QuestionType.EMAIL;
};

export type AssetsOwnershipPartType = TDtoWrapper<
  AssetsOwnershipPart,
  AssetsOwnershipHelpPart
>;

export const AssetsOwnershipFormPartFull: AssetsOwnershipPartType = {
  ownerShipType: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.ownerShipType.label',
    items: OwnershipList,
    order: 1,
  },
  firstName: {
    type: QuestionType.TEXT,
    label: 'First name',
    order: 2,
    showIf: {
      ownerShipType: { value: [OwnershipType.DIRECT, OwnershipType.INDIRECT] },
    },
    drawerOptions: {
      items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
      filter: (data) => {
        const item = data as PersonalIdentifierDocumentDto;
        return (
          item.personalIdentifierType === PersonalIdentifierType.USER_DETAILS
        );
      },
      onChange: ({ value, setValue, sjDb }) => {
        dispathEvent('chooseIdToAttach', value);
        const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
          (i) => i.id === value,
        );

        if (!item) return;

        setValue('firstName', item.names ?? item.firstName);
        setValue('secondName', item.surnames ?? item.secondName);
      },
      dtoType: PersonalIdentifierType.USER_DETAILS,

      __single: true,
    },
  },
  secondName: {
    type: QuestionType.TEXT,
    label: 'Second name',
    order: 2,
    showIf: {
      ownerShipType: { value: [OwnershipType.DIRECT, OwnershipType.INDIRECT] },
    },
    drawerOptions: {
      items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
      filter: (data) => {
        const item = data as PersonalIdentifierDocumentDto;
        return (
          item.personalIdentifierType === PersonalIdentifierType.USER_DETAILS
        );
      },
      onChange: ({ value, setValue, sjDb }) => {
        dispathEvent('chooseIdToAttach', value);
        const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
          (i) => i.id === value,
        );

        if (!item) return;

        setValue('firstName', item.names ?? item.firstName);
        setValue('secondName', item.surnames ?? item.secondName);
      },
      dtoType: PersonalIdentifierType.USER_DETAILS,

      __single: true,
    },
  },
  // owner: {
  //   type: QuestionType.PICK_ARRAY_OF_DTO,
  //   label: 'forms_assets:common.inputs.owner.label',
  //   items: 'personalIdentifiersDocuments',
  //   mode: 'multiple',
  //   order: 2,
  // },
  ownerShare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:common.inputs.ownershipShare.label',
    order: 2,
    max: 100,
    min: 0,
  },
  ownerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.ownerDocument.label',
    items: ['contacts', 'personalIdentifiersDocuments', 'privateDocuments'],
    mode: 'multiple',
    order: 3,
    dtoType: ContactType.INDIVIDUAL,
  },
  // otherOwnerDocuments: {
  //   type: QuestionType.PICK_ARRAY_OF_DTO,
  //   label: 'forms_assets:common.inputs.otherOwnerDocuments.label',
  //   items: 'personalIdentifiersDocuments',
  //   mode: 'multiple',
  //   order: 4,
  // },
  contactInfoLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.contactInfoLabel.label',
    order: 4,
  },
  contactInfoLabelDescription: {
    type: FormLabelItem.text,
    label: 'forms_assets:common.inputs.contactInfoLabelDescription.label',
    order: 5,
  },
  address: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.ownerAddress.label',
    order: 6,
  },
  phoneNumber: {
    type: QuestionType.PHONE_NUMBER,
    label: 'forms_assets:common.inputs.phoneNumber.label',
    order: 7,
  },
  email: {
    type: QuestionType.EMAIL,
    label: 'forms_assets:common.inputs.email.label',
    order: 8,
  },
  isWithCoOwners: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:common.inputs.isWithCoOwners.label',
    order: 9,
  },
  // coOwner: {
  //   type: QuestionType.PICK_ARRAY_OF_DTO,
  //   label: 'forms_assets:common.inputs.coOwner.label',
  //   items: 'personalIdentifiersDocuments',
  //   mode: 'multiple',
  //   order: 10,
  //   showIf: {
  //     isWithCoOwners: {
  //       value: true,
  //     },
  //   },
  // },
  coOwnerDocument: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.coOwnerDocument.label',
    items: ['personalIdentifiersDocuments', 'privateDocuments', 'contacts'],
    mode: 'multiple',
    order: 11,
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
    dtoType: PersonalIdentifierType.USER_DETAILS,
    deps: ['coOwnerShare'],
  },
  coOwnerShare: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:common.inputs.coOwnerShare.label',
    order: 11,
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
    max: 100,
    min: 0,
    deps: ['coOwnerDocument'],
  },
  coOwners: {
    type: QuestionType.RENDER_NEW_BUTTON,
    label: 'forms_assets:common.inputs.coOwners.label',
    renderItems: ['coOwnerDocument', 'coOwnerShare'],
    uniqKeyPrefix: 'new-co-owner',
    order: 13,
    showIf: {
      isWithCoOwners: {
        value: true,
      },
    },
  },
};

export const AssetsOwnershipFormTextsPartFull: {
  [key: string]: FormItemLabelType<unknown>;
} = {
  profitabilityText: {
    type: FormLabelItem.label,
    label: {
      prefix:
        'forms_assets:brokerageAccount.inputs.valueInTheTransactions.label',
      fieldKey: 'purchaseValue',
      postfix: '',
    },
    order: 16,
  },
};

export const assetsOwnershipBuilder = (
  excludeItems?: Array<keyof AssetsOwnershipPart>,
) => {
  if (!Array.isArray(excludeItems) || !excludeItems.length) {
    return {
      form: AssetsOwnershipFormPartFull,
      texts: AssetsOwnershipFormTextsPartFull,
    };
  }

  const copy = Object.assign({}, AssetsOwnershipFormPartFull);

  let cursor = 0;
  const length = excludeItems.length;

  while (cursor < length) {
    delete copy[excludeItems[cursor]];
    cursor++;
  }

  return {
    form: copy,
    texts: AssetsOwnershipFormTextsPartFull,
  };
};
