import type { FC } from 'react';
import { Drawer, List } from 'antd';

import { Button } from '@/shared/components';
import { IChooseList } from '@/shared/components/choose-list/types';

export const ChooseList: FC<IChooseList> = ({
  title,
  open,
  onClose,
  data,
  onClick,
}: IChooseList): JSX.Element => {
  return (
    <Drawer
      title={title}
      open={open}
      onClose={onClose}
      placement={'bottom'}
      height={'auto'}
      closeIcon={null}
    >
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Button
              text={item}
              type={'default'}
              textAlign={'left'}
              onClick={() => onClick(item)}
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
};
