import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Spin } from 'antd';
import { styled } from 'styled-components';

import { isExported } from '@/shared/constants/constants';
import { useWhoamiLazyQuery } from '@/shared/generated/graphql';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { useSettingsStore } from '@/shared/store/settings.store';

export const publicRoutes = [
  '/',
  '',
  'auth',
  '/test/new-form',
  'jyo4h37bm3wrfkrdo9ea02buclok0uj1',
  'sysver',
  'thanks',
];

export default function AuthProvider({ children }: { children: ReactElement }) {
  const { push } = useRouter();
  const { encryptedMnemonic } = useSettingsStore();
  const { keyPair } = useKeyPairStore();
  const [loading, setLoading] = useState<boolean>(true);
  const router = useRouter();
  const [whoami] = useWhoamiLazyQuery();

  const pathIsPublic = useMemo(
    () => publicRoutes.includes(router.pathname.split('/')[1]),
    [router.pathname],
  );

  // eslint-disable-next-line complexity
  const resolvePath = async () => {
    console.log('start');
    const authStorageString =
      window.localStorage.getItem('auth-storage') || '{}';
    console.log('start authStorageString', authStorageString);

    const authStorage = JSON.parse(authStorageString);
    console.log('start authStorage', authStorage);

    const token = authStorage?.state?.token;
    console.log('zero-', token, pathIsPublic, router.pathname);

    if (
      !token &&
      (!pathIsPublic ||
        // eslint-disable-next-line sonarjs/no-duplicate-string
        router.pathname === '/auth/login/enter-pin-code') &&
      !isExported
    ) {
      console.log('zero', token, pathIsPublic, router.pathname);
      console.warn('TRIGGERED ZERO LOCALSTORAGE CLEAR');
      localStorage.clear();

      push('/');
      return;
    }

    if (
      (token || isExported) &&
      encryptedMnemonic &&
      !keyPair &&
      (!pathIsPublic ||
        ['/', '/auth/login/enter-pin-code'].includes(router.pathname))
    ) {
      console.log('second', token, isExported, encryptedMnemonic, keyPair);

      try {
        const resultWhoami = await whoami({
          context: {
            headers: {
              authorization: token,
            },
          },
        });
        console.log('second whoami', resultWhoami);
        if (!resultWhoami.data) {
          push('/');
          console.warn('TRIGGERED SECOND LOCALSTORAGE CLEAR');
          localStorage.clear();
          return;
        }
      } catch (error) {
        console.log('error second', error);
        console.warn('TRIGGERED SECOND ERRO LOCALSTORAGE CLEAR');
        localStorage.clear();
        push('/');
        return;
      }
      push('/auth/login/enter-pin-code');
      return;
    }

    if (isExported && !keyPair) {
      console.log('third', isExported, keyPair);
      push('/');
      return;
    }

    setLoading(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line promise/catch-or-return
      resolvePath().finally(() => setLoading(false));
    }
  }, [encryptedMnemonic, keyPair, pathIsPublic]);

  if (loading) {
    return (
      <Loader>
        <Spin size={'large'} />
      </Loader>
    );
  }

  return <>{children}</>;
}

const Loader = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
