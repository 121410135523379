import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import styled from 'styled-components';

type TDropdownParams = {
  placeholder: string;
  options: Array<{
    key: string;
    label: string;
    value: string;
  }>;
  onChange?: (event: string) => void;
  height?: number;
  defaultValue?: string;
  dataCy?: string;
  showSearch?: boolean;
  style?: CSSProperties;
};

export const CurrenciesSelect = ({
  placeholder,
  options,
  onChange,
  defaultValue,
  dataCy,
  showSearch,
  ...props
}: TDropdownParams) => {
  const _defaultValue = useMemo(() => {
    return options.find((item) => item.key === defaultValue);
  }, [options, defaultValue]);

  const [chosen, setChosen] = useState(_defaultValue?.label || placeholder);

  useEffect(() => {
    if (_defaultValue) setChosen(_defaultValue.label);
  }, [options, defaultValue]);

  const handleMenuClick = (e: string) => {
    onChange?.(e);
    setChosen(options?.find((item) => item.key === e)?.label || '');
  };

  return (
    <StyledSelect
      showSearch={showSearch}
      options={options}
      onChange={handleMenuClick}
      placeholder={placeholder}
      value={chosen}
      {...props}
    >
      <StyledButton data-testid={dataCy}>
        {chosen}
        <DownOutlined />
      </StyledButton>
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
