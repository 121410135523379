import { CountriesList } from '@/shared/constants/countries-list';
import { LanguagesList } from '@/shared/constants/languages-list';
import {
  CustomDrawerFilterItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import {
  DisclosureType,
  HighMediumLowList,
  HighMediumLowType,
  InformationDisclosureList,
} from '@/shared/lib/sj-orm/models/asset.dto';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum CategoryType {
  family = 'family',
  money = 'money',
  personal = 'personal',
}

export const CategoryList = [
  {
    label: 'Family',
    key: CategoryType.family,
  },
  {
    label: 'Money',
    key: CategoryType.money,
  },
  {
    label: 'Personal',
    key: CategoryType.personal,
  },
];

export enum CertifiedByType {
  none = 'none',
  notary = 'notary',
  governmentAuthority = 'governmentAuthority',
  consulate = 'consulate',
  signatureWitness = 'signatureWitness',
  other = 'other',
}

export const CertifiedByList = [
  {
    label: 'None',
    key: CertifiedByType.none,
  },
  {
    label: 'Notary',
    key: CertifiedByType.notary,
  },
  {
    label: 'Government authority',
    key: CertifiedByType.governmentAuthority,
  },
  {
    label: 'Signature witness',
    key: CertifiedByType.signatureWitness,
  },
  {
    label: 'Other',
    key: CertifiedByType.other,
  },
];

export type UnilateralDtoType = Omit<
  PrivateDocumentDto,
  'id' | 'privateType' | 'type' | 'privateCategory'
> & {
  name: string;
  country: string;
  content?: string;
  category?: CategoryType;
  signatory1?: DtoId[]; //////////////
  number?: string;
  startDate?: number;
  stopDate?: number;
  updateRequirementsReason?: string;
  updateRequirementsDate?: number;
  informationDisclosure?: DisclosureType;
  informationDisclosureDetails?: string;
  language?: string;
  certifiedBy?: CertifiedByType;
  myTodo?: boolean;
  myTodoComments?: string;
  myTodoFavorite?: string;
  // notes?: string;
  scan?: string;
  storageLocation?: string;
  comments?: string;
  priority?: HighMediumLowType;
};

export type UnilateralDtoHelpType = {
  name: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  content?: QuestionType.TEXT;
  category?: QuestionType.SELECT_FROM_LIST;
  signatory1?: QuestionType.PICK_ARRAY_OF_DTO; //////////////
  number?: QuestionType.TEXT;
  startDate?: QuestionType.DATE_TIME;
  stopDate?: QuestionType.DATE_TIME;
  updateRequirementsReason?: QuestionType.TEXT;
  updateRequirementsDate?: QuestionType.DATE_TIME;
  informationDisclosure?: QuestionType.SELECT_FROM_LIST;
  informationDisclosureDetails?: QuestionType.TEXT_MULTILINE;
  language?: QuestionType.SELECT_FROM_LIST;
  certifiedBy?: QuestionType.SELECT_FROM_LIST;
  myTodo?: QuestionType.CHECKBOX;
  myTodoComments?: QuestionType.TEXT_MULTILINE;
  // notes?: QuestionType.TEXT_MULTILINE;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
  priority?: QuestionType.SELECT_FROM_LIST;
};

type UnilateralFormType = TDtoWrapper<UnilateralDtoType, UnilateralDtoHelpType>;

export const UnilateralForm: UnilateralFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Title',
    required: true,
    value: '',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  content: {
    type: QuestionType.TEXT,
    label: 'Content',
  },
  category: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Category',
    items: CategoryList,
  },
  signatory1: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Signatories',
    mode: 'multiple',
    items: ['personalIdentifiersDocuments', 'privateDocuments'],
    customDrawerFilter: {
      filterFunc: ({ items, sjDb, filters }) => {
        const getDocument = (d: CustomDrawerFilterItem) => {
          if (d.value.includes('personalIdentifiersDocuments')) {
            return sjDb.collections.personalIdentifiersDocuments?.findOne(
              (item) => item.id === d.value,
            );
          } else {
            return sjDb.collections.privateDocuments?.findOne(
              (item) => item.id === d.value,
            );
          }
        };

        const hasBeneficiary = (
          doc: PersonalIdentifierDocumentDto | PrivateDocumentDto,
          searchString: string,
        ): boolean => {
          if (doc?.beneficiaryId?.includes(searchString)) {
            return true;
          }
          if ('signatory1' in doc && Array.isArray(doc.signatory1)) {
            return doc.signatory1.some((signatoryId: string) => {
              const signatoryDoc =
                sjDb.collections.personalIdentifiersDocuments?.findOne(
                  (item) => item.id === signatoryId,
                );
              return signatoryDoc?.beneficiaryId?.includes(searchString);
            });
          }
          return false;
        };

        const filterByOwner = (
          d: CustomDrawerFilterItem,
          searchString: string,
        ) => {
          const doc = getDocument(d);
          if (doc) {
            return hasBeneficiary(doc, searchString);
          }
          return false;
        };

        const filterByDocumentType = (
          d: CustomDrawerFilterItem,
          type: string,
        ) => d.value.includes(type);

        let dtos = items;

        if (filters[0] === 'nextOwner') {
          dtos = dtos.filter((d) => filterByOwner(d, 'beneficiaries-'));
        } else if (filters[0] === 'mainOwner') {
          dtos = dtos.filter((d) =>
            filterByOwner(d, 'personalIdentifiersDocuments-'),
          );
        }

        if (filters[1] === 'documents') {
          dtos = dtos.filter((d) =>
            filterByDocumentType(d, 'privateDocuments-'),
          );
        } else if (filters[1] === 'id') {
          dtos = dtos.filter((d) =>
            filterByDocumentType(d, 'personalIdentifiersDocuments-'),
          );
        }

        return dtos;
      },
      options: [
        {
          placeholder: 'Signature type',
          items: [
            { label: 'Main Owner', value: 'mainOwner' },
            { label: 'Next Owner', value: 'nextOwner' },
            // { label: 'Individual', value: 'individual' },
            // { label: 'Legal Entity', value: 'legalEntity' },
            // { label: 'Bank', value: 'bank' },
            // { label: 'Broker', value: 'broker' },
          ],
        },
        {
          placeholder: 'Type of document',
          items: [
            { label: 'Documents', value: 'documents' },
            { label: 'ID', value: 'id' },
          ],
        },
      ],
    },
    filter: (data) => {
      const item = data as PersonalIdentifierDocumentDto;
      return (
        item.personalIdentifierType !== PersonalIdentifierType.USER_DETAILS &&
        item.personalIdentifierType !== PersonalIdentifierType.SUBSTANCE
      );
    },
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  startDate: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  stopDate: {
    type: QuestionType.DATE_TIME,
    label: 'Stop date',
  },
  updateRequirementsReason: {
    type: QuestionType.TEXT,
    label: 'Update requirements: reason',
  },
  updateRequirementsDate: {
    type: QuestionType.DATE_TIME,
    label: 'Update requirements: date',
  },
  informationDisclosure: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Information disclosure',
    items: InformationDisclosureList,
  },
  informationDisclosureDetails: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Information disclosure details',
  },
  language: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Document language',
    items: LanguagesList,
  },
  certifiedBy: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Certified by',
    items: CertifiedByList,
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My todo',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My todo comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  // notes: {
  //   type: QuestionType.TEXT_MULTILINE,
  //   label: 'Notes',
  // },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
  priority: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Priority',
    items: HighMediumLowList,
  },
};
