import { v4 as uuidv4 } from 'uuid';

import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName, ICollections } from '@/shared/lib/sj-orm/constants';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { AssistantMnemonicDto } from '@/shared/lib/sj-orm/models/assistant-mnemonic.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { CurrenciesDto } from '@/shared/lib/sj-orm/models/currencies.dto';
import { AssetInformationDocumentDto } from '@/shared/lib/sj-orm/models/document/asset-information.document.dto';
import { BeneficiaryPersonalDataDocumentDto } from '@/shared/lib/sj-orm/models/document/beneficiary-personal-data.document.dto';
import { PassportDocumentDto } from '@/shared/lib/sj-orm/models/document/passport.document.dto';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { PrivateDocumentDto } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { SoweAttachedPersonalIdentifierDto } from '@/shared/lib/sj-orm/models/document/sowe-attached-personal-identifier.document.dto';
import { UnknownDocumentDto } from '@/shared/lib/sj-orm/models/document/unknown.document.dto';
import { EncryptedFileKeyDto } from '@/shared/lib/sj-orm/models/encrypted-file-key.dto';
import { FileMetaInfoDto } from '@/shared/lib/sj-orm/models/file-meta-info.dto';
import { MigrationApplyResultDto } from '@/shared/lib/sj-orm/models/migration-apply-result.dto';
import { MyHubDto } from '@/shared/lib/sj-orm/models/my-hub.dto';
import { NotificationDto } from '@/shared/lib/sj-orm/models/notification.dto';
import { SharedSpacesDto } from '@/shared/lib/sj-orm/models/shared-spaces.dto';
import { SoweAttachmentDto } from '@/shared/lib/sj-orm/models/sowe/sowe-attachment.dto';
import { SJCollection } from '@/shared/lib/sj-orm/sj-collection';
import { ISecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';

import { DashboardItemDto } from '../models/dashboard-item.dto';
import { DelegatingRequestDto } from '../models/delegating-request.dto';
import { DraftDto } from '../models/draft.dto';
import { NextOwnerGroupDto } from '../models/next-owner-group.dto';
import { ScenarioMetaInfoDto } from '../models/scenario-meta-info.dto';
import { SharedDto } from '../models/shared.dto';
import { SoweDto } from '../models/sowe/sowe.dto';
import { TutorialDto } from '../models/tutorial.dto';

export class SJDatabase {
  collections: ICollections = {
    assets: undefined,
    sowe: undefined,
    soweAttachments: undefined,
    encryptedFileKey: undefined,
    beneficiaries: undefined,
    delegatingRequests: undefined,
    contacts: undefined,
    passportDocuments: undefined,
    unknownDocuments: undefined,
    beneficiaryPersonalDataDocuments: undefined,
    assetInformationDocument: undefined,
    soweAttachedPersonalIdentifier: undefined,
    assistantMnemonic: undefined,
    notification: undefined,
    privateDocuments: undefined,
    personalIdentifiersDocuments: undefined,
    fileMetaInfos: undefined,
    scenarioMetaInfos: undefined,
    myHub: undefined,
    migrations: undefined,
    drafts: undefined,
    currencies: undefined,
    nextOwnersGroups: undefined,
    dashboardItems: undefined,
    shared: undefined,
    sharedSpaces: undefined,
    tutorials: undefined,
  };

  get loadedDashboardCollections() {
    const allItems = Object.entries(this.collections).filter(
      ([key]) =>
        key === CollectionName.ASSETS ||
        key === CollectionName.BENEFICIARIES ||
        key === CollectionName.CONTACTS ||
        key === CollectionName.PRIVATE_DOCUMENTS ||
        key === CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
    );
    const filteredAllItems = allItems.filter((value) => !!value[1]);
    return allItems.length === filteredAllItems.length;
  }

  get size() {
    const allItems = Object.entries(this.collections)
      .filter(
        ([key]) =>
          key === CollectionName.ASSETS ||
          key === CollectionName.BENEFICIARIES ||
          key === CollectionName.CONTACTS ||
          key === CollectionName.PRIVATE_DOCUMENTS ||
          key === CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => (value ? value.secureJsonBase.items.size : 0))
      .flat();

    return allItems.reduce((acc, cur) => acc + cur, 0);
  }

  // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
  constructor(
    readonly store: ISecureJsonCollectionsStore,
    private readonly createLog: (
      publicData: object,
      dataForEncrypt: object | { name: string; description: string },
    ) => Promise<
      | {
          id: number;
          createdAt: Date;
          mainUserId: number;
          publicData: string;
          encryptedData: string;
        }
      | undefined
    >,
  ) {
    const assetCollection = store.collections.get(CollectionName.ASSETS);
    const myHubCollection = store.collections.get(CollectionName.MY_HUB);

    const encryptedFileKeyCollection = store.collections.get(
      CollectionName.ENCRYPTED_FILE_KEY,
    );
    const beneficiariesCollection = store.collections.get(
      CollectionName.BENEFICIARIES,
    );
    const delegatingRequestsCollection = store.collections.get(
      CollectionName.DELEGATING_REQUESTS,
    );
    const passportDocumentsCollection = store.collections.get(
      CollectionName.PASSPORT_DOCUMENT,
    );
    const unknownDocumentsCollection = store.collections.get(
      CollectionName.UNKNOWN_DOCUMENT,
    );
    const beneficiaryPersonalDataDocumentsCollection = store.collections.get(
      CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
    );
    const assetInformationDocumentCollection = store.collections.get(
      CollectionName.ASSET_INFORMATION_DOCUMENT,
    );
    const soweAttachedPersonalIdentifiersCollection = store.collections.get(
      CollectionName.SOWE_ATTACHED_PERSONAL_IDENTIFIER,
    );
    const sowe = store.collections.get(CollectionName.SOWE);
    const soweAttachmentCollection = store.collections.get(
      CollectionName.SOWE_ATTACHMENTS,
    );
    const assistantMnemonicCollection = store.collections.get(
      CollectionName.ASSISTANT_MNEMONIC,
    );
    const contactsCollection = store.collections.get(CollectionName.CONTACTS);
    const privateDocumentsCollection = store.collections.get(
      CollectionName.PRIVATE_DOCUMENTS,
    );
    const personalIdentifiersDocumentsCollection = store.collections.get(
      CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
    );
    const fileMetaInfosCollection = store.collections.get(
      CollectionName.FILE_META_INFO,
    );
    const notificationCollection = store.collections.get(
      CollectionName.NOTIFICATION,
    );
    const scenarioMetaInfosCollection = store.collections.get(
      CollectionName.SCENARIO_META_INFO,
    );
    const draftsCollection = store.collections.get(CollectionName.DRAFTS);
    const currenciesCollection = store.collections.get(
      CollectionName.CURRENCIES,
    );
    const nextOwnerGroupsCollection = store.collections.get(
      CollectionName.NEXT_OWNER_GROUPS,
    );
    const dashboardItemsCollection = store.collections.get(
      CollectionName.DASHBOARD_ITEMS,
    );
    const sharedCollection = store.collections.get(CollectionName.SHARED);
    const sharedSpacesCollection = store.collections.get(
      CollectionName.SHARED_SPACES,
    );

    if (assetCollection) {
      this.collections.assets = new SJCollection<AssetDto>(
        CollectionName.ASSETS,
        assetCollection,
        store.setCollection,
        this.createLog,
      );
    }

    if (myHubCollection) {
      this.collections.myHub = new SJCollection<MyHubDto>(
        CollectionName.MY_HUB,
        myHubCollection,
        store.setCollection,
        this.createLog,
      );
    }

    if (sowe) {
      this.collections.sowe = new SJCollection<SoweDto>(
        CollectionName.SOWE,
        sowe,
        store.setCollection,
        this.createLog,
      );
    }

    if (soweAttachmentCollection) {
      this.collections.soweAttachments = new SJCollection<SoweAttachmentDto>(
        CollectionName.SOWE_ATTACHMENTS,
        soweAttachmentCollection,
        store.setCollection,
        this.createLog,
      );
    }

    if (encryptedFileKeyCollection) {
      this.collections.encryptedFileKey = new SJCollection<EncryptedFileKeyDto>(
        CollectionName.ENCRYPTED_FILE_KEY,
        encryptedFileKeyCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (beneficiariesCollection) {
      this.collections.beneficiaries = new SJCollection<BeneficiaryDto>(
        CollectionName.BENEFICIARIES,
        beneficiariesCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (delegatingRequestsCollection) {
      this.collections.delegatingRequests =
        new SJCollection<DelegatingRequestDto>(
          CollectionName.DELEGATING_REQUESTS,
          delegatingRequestsCollection,
          store.setCollection,
          this.createLog,
        );
    }
    if (passportDocumentsCollection) {
      this.collections.passportDocuments =
        new SJCollection<PassportDocumentDto>(
          CollectionName.PASSPORT_DOCUMENT,
          passportDocumentsCollection,
          store.setCollection,
          this.createLog,
        );
    }
    if (unknownDocumentsCollection) {
      this.collections.unknownDocuments = new SJCollection<UnknownDocumentDto>(
        CollectionName.UNKNOWN_DOCUMENT,
        unknownDocumentsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (beneficiaryPersonalDataDocumentsCollection) {
      this.collections.beneficiaryPersonalDataDocuments =
        new SJCollection<BeneficiaryPersonalDataDocumentDto>(
          CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT,
          beneficiaryPersonalDataDocumentsCollection,
          store.setCollection,
          this.createLog,
        );
    }
    if (assetInformationDocumentCollection) {
      this.collections.assetInformationDocument =
        new SJCollection<AssetInformationDocumentDto>(
          CollectionName.ASSET_INFORMATION_DOCUMENT,
          assetInformationDocumentCollection,
          store.setCollection,
          this.createLog,
        );
    }
    if (soweAttachedPersonalIdentifiersCollection) {
      this.collections.soweAttachedPersonalIdentifier =
        new SJCollection<SoweAttachedPersonalIdentifierDto>(
          CollectionName.SOWE_ATTACHED_PERSONAL_IDENTIFIER,
          soweAttachedPersonalIdentifiersCollection,
          store.setCollection,
          this.createLog,
        );
    }

    if (assistantMnemonicCollection) {
      this.collections.assistantMnemonic =
        new SJCollection<AssistantMnemonicDto>(
          CollectionName.ASSISTANT_MNEMONIC,
          assistantMnemonicCollection,
          store.setCollection,
          this.createLog,
        );
    }

    if (notificationCollection) {
      this.collections.notification = new SJCollection<NotificationDto>(
        CollectionName.NOTIFICATION,
        notificationCollection,
        store.setCollection,
        this.createLog,
      );
    }

    if (contactsCollection) {
      this.collections.contacts = new SJCollection<ContactDto>(
        CollectionName.CONTACTS,
        contactsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (privateDocumentsCollection) {
      this.collections.privateDocuments = new SJCollection<PrivateDocumentDto>(
        CollectionName.PRIVATE_DOCUMENTS,
        privateDocumentsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (personalIdentifiersDocumentsCollection) {
      this.collections.personalIdentifiersDocuments =
        new SJCollection<PersonalIdentifierDocumentDto>(
          CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          personalIdentifiersDocumentsCollection,
          store.setCollection,
          this.createLog,
        );
    }
    if (fileMetaInfosCollection) {
      this.collections.fileMetaInfos = new SJCollection<FileMetaInfoDto>(
        CollectionName.FILE_META_INFO,
        fileMetaInfosCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (scenarioMetaInfosCollection) {
      this.collections.scenarioMetaInfos =
        new SJCollection<ScenarioMetaInfoDto>(
          CollectionName.SCENARIO_META_INFO,
          scenarioMetaInfosCollection,
          store.setCollection,
          this.createLog,
        );
    }
    const userMigrationsCollection = store.collections.get(
      CollectionName.MIGRATIONS,
    ) as SecureJsonBase<MigrationApplyResultDto> | undefined;

    if (userMigrationsCollection) {
      this.collections.migrations = new SJCollection<MigrationApplyResultDto>(
        CollectionName.MIGRATIONS,
        userMigrationsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (draftsCollection) {
      this.collections.drafts = new SJCollection<DraftDto>(
        CollectionName.DRAFTS,
        draftsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (currenciesCollection) {
      this.collections.currencies = new SJCollection<CurrenciesDto>(
        CollectionName.CURRENCIES,
        currenciesCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (nextOwnerGroupsCollection) {
      this.collections.nextOwnersGroups = new SJCollection<NextOwnerGroupDto>(
        CollectionName.NEXT_OWNER_GROUPS,
        nextOwnerGroupsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (dashboardItemsCollection) {
      this.collections.dashboardItems = new SJCollection<DashboardItemDto>(
        CollectionName.DASHBOARD_ITEMS,
        dashboardItemsCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (sharedCollection) {
      this.collections.shared = new SJCollection<SharedDto>(
        CollectionName.SHARED,
        sharedCollection,
        store.setCollection,
        this.createLog,
      );
    }
    if (sharedSpacesCollection) {
      this.collections.sharedSpaces = new SJCollection<SharedSpacesDto>(
        CollectionName.SHARED_SPACES,
        sharedSpacesCollection,
        store.setCollection,
        this.createLog,
      );
    }
    const tutorialsCollection = store.collections.get(
      CollectionName.TUTORIALS,
    ) as SecureJsonBase<TutorialDto> | undefined;

    if (tutorialsCollection) {
      this.collections.tutorials = new SJCollection<TutorialDto>(
        CollectionName.TUTORIALS,
        tutorialsCollection,
        store.setCollection,
        this.createLog,
      );
    }
  }

  public generateId(prefix: CollectionName): string {
    return SJDatabase.generateId(prefix);
  }

  static generateId(prefix: CollectionName): string {
    return `${prefix || 'id'}-${uuidv4()}`;
  }
}
