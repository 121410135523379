import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryExecFunction } from '@apollo/client';
import dayjs from 'dayjs';

import { NotificationContent } from '@/entities/notification/hooks/use-notifications/use-notifications.types';
import {
  Exact,
  ScenarioQuery,
  useScenarioLazyQuery,
} from '@/shared/generated/graphql';
import { PublicKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { SJDatabase } from '@/shared/lib/sj-orm/core';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { ContactDto } from '@/shared/lib/sj-orm/models/contact.dto';
import { BeneficiaryPersonalDataDocumentDto } from '@/shared/lib/sj-orm/models/document/beneficiary-personal-data.document.dto';
import {
  NotificationDto,
  NotificationType,
  TNotificationType,
} from '@/shared/lib/sj-orm/models/notification.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';
import { lowerTextAndCapitalizeFirstLetter } from '@/shared/utils/misc';

export const useNotifications = () => {
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const [scenarioLazyQuery] = useScenarioLazyQuery();
  const { t } = useTranslation(['notifications']);
  const [total, setTotal] = useState(0);

  const notificationsList = useCallback(
    async (isVisible = true, cursor?: { from: number; to: number }) => {
      const items = (
        sjDb.collections.notification?.findMany(
          (notification) => notification.isVisible === isVisible,
        ) || []
      ).reverse();

      setTotal(items.length);

      if (!items.length) return [];

      const result: Array<
        | (NotificationContent & {
            id: string;
            isRead: boolean;
            type: NotificationType;
          })
        | null
      > = [];

      const sortedItems = items.sort(
        (n1, n2) => new Date(n2.date).getTime() - new Date(n1.date).getTime(),
      );

      const partItems = cursor
        ? sortedItems.slice(cursor.from, cursor.to + 1)
        : sortedItems;

      for (const item of partItems) {
        const notification = await resolveNotificationType(
          item,
          sjDb,
          scenarioLazyQuery,
          t,
        );
        result.push(
          notification
            ? {
                ...notification,
                id: item.id,
                isRead: item.isRead[keyPair?.publicKey || ''],
                type: item.type,
              }
            : notification,
        );
      }

      return result;
    },
    [sjDb, keyPair],
  );

  const getNotification = useCallback(
    async (
      id: string,
    ): Promise<
      (NotificationContent & { id: string; isRead: boolean }) | null
    > => {
      const notification = sjDb.collections.notification?.findOne(
        (_notification) => _notification.id === id,
      );
      if (notification) {
        const resolvedNotification = await resolveNotificationType(
          notification,
          sjDb,
          scenarioLazyQuery,
          t,
        );
        return resolvedNotification
          ? {
              ...resolvedNotification,
              id: notification.id,
              isRead: notification.isRead[keyPair?.publicKey || ''],
            }
          : null;
      }
      return null;
    },
    [sjDb, keyPair],
  );

  const updateRead = useCallback(
    (id: string, state: boolean = true, publicKey?: string) => {
      try {
        const notification = sjDb.collections.notification?.findOne(
          (_notification) => _notification.id === id,
        );
        if (!notification) return false;

        const key = publicKey ?? keyPair?.publicKey;

        if (!key) return false;

        if (notification.isRead[key] !== state) {
          notification.isRead[key] = state;

          sjDb.collections.notification?.update(notification);
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    [sjDb, keyPair],
  );

  const createNotification = useCallback(
    (
      data: TNotificationType,
      isVisible = true,
      additionalPublicKeys?: PublicKey[],
      withCurrentUser = true,
    ) => {
      const publicKeys = additionalPublicKeys ? additionalPublicKeys : [];

      if (keyPair && withCurrentUser) {
        publicKeys.unshift(keyPair.publicKey);
      }

      const notification: NotificationDto = Object.assign({}, data, {
        id: sjDb.generateId(CollectionName.NOTIFICATION),
        relatedDtoList: [],
        isRead: {},
        isVisible,
        date: new Date(),
      });
      return sjDb.collections.notification?.create(notification, publicKeys);
    },
    [keyPair, sjDb],
  );

  return useMemo(
    () => ({
      notificationsList,
      getNotification,
      updateRead,
      createNotification,
      total,
    }),
    [notificationsList, getNotification, updateRead, createNotification, total],
  );
};

// eslint-disable-next-line complexity
const resolveNotificationType = async (
  notification: NotificationDto,
  sjDb: SJDatabase,
  scenarioLazyQuery: LazyQueryExecFunction<
    ScenarioQuery,
    Exact<{ scenarioId: string }>
  >,
  t: (key: string) => string,
  // eslint-disable-next-line consistent-return,sonarjs/cognitive-complexity
): Promise<NotificationContent | null> => {
  // const notificationActionTextDeleted = t(
  //   'notifications:notificationTypeText.deleted',
  // );
  // const notificationActionTextCreated = t(
  //   'notifications:notificationTypeText.created',
  // );

  switch (notification.type) {
    case NotificationType.ASSET_CREATED:
    case NotificationType.ASSET_UPDATED:
    case NotificationType.ASSET_DELETED: {
      const asset = sjDb.collections.assets?.findOne(
        (asset_) => asset_.id === notification.assetId,
      );

      const assetName = asset?.name || asset?.nickName || asset?.nickname;

      return {
        notificationActionText:
          notification.type === NotificationType.ASSET_DELETED
            ? `${t('notifications:notificationTypeText.assetDeleted.start')} "${
                assetName || 'Asset'
              }" ${t('notifications:notificationTypeText.assetDeleted.finish')}`
            : notification.type === NotificationType.ASSET_UPDATED
            ? `${t('notifications:notificationTypeText.assetUpdated.start')} "${
                assetName || 'Asset'
              }" ${t('notifications:notificationTypeText.assetUpdated.finish')}`
            : `"${assetName || 'Asset'}" ${t(
                'notifications:notificationTypeText.assetCreated',
              )}`,
        title: assetName,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.CONTACT_CREATED:
    case NotificationType.CONTACT_DELETED: {
      const contact = sjDb.collections.contacts?.findOne(
        (contact_) => contact_.id === notification.contactId,
      );

      const contactName = contact?.name || contact?.nickname;

      return {
        notificationActionText:
          notification.type === NotificationType.CONTACT_DELETED
            ? `${t(
                'notifications:notificationTypeText.contactDeleted.start',
              )} "${contactName || 'Contact'}" ${t(
                'notifications:notificationTypeText.contactDeleted.finish',
              )}`
            : `${t(
                'notifications:notificationTypeText.contactCreated.start',
              )} "${contactName || 'Contact'}" ${t(
                'notifications:notificationTypeText.contactCreated.finish',
              )}`,
        title: contactName,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DOCUMENT_DELETED:
    case NotificationType.DOCUMENT_CREATED: {
      let isFound = false;
      let documentName: string | undefined;
      let assetName: string | undefined;
      let beneficiaryName: string | undefined;
      const privateDocument = sjDb.collections.privateDocuments?.findOne(
        (document_) => document_.id === notification.documentId,
      );

      if (privateDocument) {
        isFound = true;
        documentName = privateDocument.name;

        if (privateDocument.assetId) {
          const asset = sjDb.collections.assets?.findOne(
            (_asset) => _asset.id === privateDocument.assetId,
          );
          assetName = asset?.name;
        }

        if (privateDocument.beneficiaryId) {
          const beneficiary = sjDb.collections.beneficiaries?.findOne(
            (_beneficiary) =>
              _beneficiary?.id === privateDocument.beneficiaryId,
          );
          beneficiaryName =
            beneficiary?.nickName ??
            beneficiary?.lastName ??
            beneficiary?.firstName;
        }
      }

      if (!isFound) {
        const personalIdentifiersDocument =
          sjDb.collections.personalIdentifiersDocuments?.findOne(
            (document_) => document_.id === notification.documentId,
          );
        if (personalIdentifiersDocument) {
          isFound = true;

          documentName = personalIdentifiersDocument.name;

          if (personalIdentifiersDocument.assetId) {
            const asset = sjDb.collections.assets?.findOne(
              (_asset) => _asset.id === personalIdentifiersDocument.assetId,
            );
            assetName = asset?.name;
          }

          if (personalIdentifiersDocument.beneficiaryId) {
            const beneficiary = sjDb.collections.beneficiaries?.findOne(
              (_beneficiary) =>
                _beneficiary?.id === personalIdentifiersDocument.beneficiaryId,
            );
            beneficiaryName =
              beneficiary?.nickName ??
              beneficiary?.lastName ??
              beneficiary?.firstName;
          }
        }
      }

      const text = {
        [NotificationType.DOCUMENT_DELETED]: `"${documentName}" ${t(
          'notifications:notificationTypeText.deleted',
        )}`,
        [NotificationType.DOCUMENT_CREATED]: `"${documentName}" ${t(
          'notifications:notificationTypeText.created',
        )}`,
      };

      return {
        notificationActionText: documentName || 'document',
        title: text[notification.type],
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        description: `${assetName ? `${assetName} / ` : ''}${
          beneficiaryName ?? ''
        }`,
        date: notification.createdAt || new Date(),
      };
    }

    case NotificationType.SENT_REQUEST_TO_ASSISTANT:
    case NotificationType.ASSISTANT_FILLED_FORM: {
      let documentName: string | undefined;
      let assistantName = '';

      const getDocumentName = (id: string): string | undefined => {
        const pid = sjDb.collections.personalIdentifiersDocuments?.findOne(
          (item) => item.id === id,
        );
        if (pid)
          return lowerTextAndCapitalizeFirstLetter(pid.personalIdentifierType);
        const pd = sjDb.collections.privateDocuments?.findOne(
          (item) => item.id === id,
        );
        if (pd) return lowerTextAndCapitalizeFirstLetter(pd.privateType);
        const bpdd = sjDb.collections.beneficiaryPersonalDataDocuments?.findOne(
          (item) => item.id === id,
        );
        if (bpdd)
          return sjDb.collections.beneficiaries?.findOne(
            (item) => item.id === bpdd.beneficiaryDtoId,
          )?.nickName;
        return undefined;
      };

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === notification.documentId,
      );

      const assetName = asset?.name ?? asset?.nickName ?? asset?.nickname;

      if (!assetName) {
        documentName = getDocumentName(notification.documentId);
      }

      const assistant = sjDb.collections.assistantMnemonic?.findOne(
        (assis) =>
          assis.assistantProfileId ===
          notification.assistantProfileId.toString(),
      );
      if (assistant) {
        assistantName = assistant.name;
      }

      const text = {
        [NotificationType.SENT_REQUEST_TO_ASSISTANT]: `${t(
          'notifications:notificationTypeText.requestSent',
        )} "${assistantName}"`,
        [NotificationType.ASSISTANT_FILLED_FORM]: `"${assistantName}" ${t(
          `notifications:notificationTypeText.filledForm`,
        )} ${assetName || documentName} for review.`,
      };

      return {
        notificationActionText: text[notification.type],
        description: `${assetName ?? documentName ?? ''}`,
        date: notification.createdAt || new Date(),
        ...(NotificationType.ASSISTANT_FILLED_FORM
          ? {
              delegationRequest:
                notification.type !== NotificationType.SENT_REQUEST_TO_ASSISTANT
                  ? notification?.delegatingRequest
                  : undefined,
            }
          : undefined),
      };
    }
    case NotificationType.FILED_ADDED_TO_INBOX: {
      return {
        notificationActionText: `${notification.amount} file ${t(
          'notifications:notificationTypeText.filedAddedToInbox',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.FILED_DELETED_FROM_INBOX: {
      return {
        notificationActionText: `${notification.amount} file ${t(
          'notifications:notificationTypeText.filedDeletedFromInbox',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DOCUMENTS_BY_ASSISTANT: {
      return {
        notificationActionText: `${notification.amount} ${t(
          'notifications:notificationTypeText.documentsByAssistant',
        )}`,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.CHECK_PLAN: {
      return {
        notificationActionText: t(
          'notifications:notificationTypeText.checkPlan',
        ),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.PASSWORD_CHANGE: {
      return {
        notificationActionText: t(
          'notifications:notificationTypeText.passwordChange',
        ),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.DATA_TRANSFERRED_TO_MY_HUB: {
      return {
        notificationActionText: t(
          'notifications:notificationTypeText.dataTransferredToMyHub',
        ),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.MY_HUB_CREATED: {
      return {
        notificationActionText: t(
          'notifications:notificationTypeText.myHubCreated',
        ),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.BENEFICIARY_CREATED:
    case NotificationType.BENEFICIARY_UPDATED:
    case NotificationType.BENEFICIARY_DELETED:
    case NotificationType.BENEFICIARY_ACCEPT_INVITATION:
    case NotificationType.BENEFICIARY_SENT_INVITATION: {
      const texts = {
        [NotificationType.BENEFICIARY_CREATED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.beneficiaryCreated.start',
          )} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryCreated.finish',
          )}`,
        [NotificationType.BENEFICIARY_UPDATED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.beneficiaryUpdated.start',
          )} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryUpdated.finish',
          )}`,
        [NotificationType.BENEFICIARY_DELETED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.beneficiaryDeleted.start',
          )} "${text}" ${t(
            'notifications:notificationTypeText.beneficiaryDeleted.finish',
          )}`,
        [NotificationType.BENEFICIARY_ACCEPT_INVITATION]: (text: string) =>
          `"${text}" ${t(
            'notifications:notificationTypeText.beneficiaryAcceptInvitation',
          )}`,
        [NotificationType.BENEFICIARY_SENT_INVITATION]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.beneficiarySentInvitation',
          )} "${text}"`,
      };

      const beneficiary = sjDb.collections.beneficiaries?.findOne(
        (_beneficiary) => _beneficiary.id === notification.beneficiaryId,
      );

      return {
        title: beneficiary?.nickName,
        notificationActionText: texts[notification.type](
          beneficiary?.nickName || 'Next owner',
        ),
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.SCENARIO_CREATED:
    case NotificationType.TEST_SCENARIO_LAUNCHED:
    case NotificationType.TEST_SCENARIO_ACCEPTED:
    case NotificationType.SCENARIO_ACTIVATED:
    case NotificationType.SCENARIO_RESOLVED:
    case NotificationType.SCENARIO_DEACTIVATED:
    case NotificationType.TRIGGER_SCENARIO_INFO: {
      const texts = {
        [NotificationType.SCENARIO_CREATED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.scenarioCreated',
          )} "${text}"`,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [NotificationType.TEST_SCENARIO_LAUNCHED]: (_text: string) =>
          `${t(
            'notifications:notificationTypeText.testScenarioLaunched.start',
          )} "${_text}" ${t(
            'notifications:notificationTypeText.testScenarioLaunched.finish',
          )}`,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [NotificationType.TEST_SCENARIO_ACCEPTED]: (_text: string) =>
          `${t('notifications:notificationTypeText.testScenarioAccepted')} `,
        [NotificationType.SCENARIO_ACTIVATED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.scenarioActivated',
          )} "${text}"`,
        [NotificationType.SCENARIO_RESOLVED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.scenarioResolved',
          )} "${text}"`,
        [NotificationType.SCENARIO_DEACTIVATED]: (text: string) =>
          `${t(
            'notifications:notificationTypeText.scenarioDeactivated',
          )} "${text}"`,
        [NotificationType.TRIGGER_SCENARIO_INFO]: (
          text: string,
          date: string = '',
        ) =>
          `${t(
            'notifications:notificationTypeText.triggerScenarioInfo.start',
          )} "${text}" ${t(
            'notifications:notificationTypeText.triggerScenarioInfo.final',
          )} ${dayjs(+date).format('DD MMMM YYYY')}`,
      };

      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      if (!scenario.data?.scenario) {
        return {
          notificationActionText: texts[notification.type]('Asset'),
          date: notification.createdAt || new Date(),
        };
      }

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      if (!asset) return null;

      // const scenarioName = sjDb.collections.scenarioMetaInfos?.findOne(
      //   (_scenario) => _scenario.centralDbId === scenario.data?.scenario?.id,
      // );

      if (
        [
          NotificationType.SCENARIO_ACTIVATED,
          NotificationType.SCENARIO_CREATED,
          NotificationType.TEST_SCENARIO_LAUNCHED,
          NotificationType.SCENARIO_DEACTIVATED,
        ].includes(notification.type)
      ) {
        return {
          description: asset?.name || asset?.nickName || asset?.nickname,
          notificationActionText: texts[notification.type](
            asset?.name || asset?.nickName || asset?.nickname || '',
          ),
          date: notification.createdAt || new Date(),
        };
      }

      const beneficiary = sjDb.collections.beneficiaries?.findOne(
        (_beneficiary) =>
          scenario.data?.scenario?.beneficiaries
            ? scenario.data?.scenario?.beneficiaries
                .map((no) => no.beneficiaryId)
                .includes(_beneficiary.centralDbProfileId)
            : false,
      );

      return {
        title:
          beneficiary?.nickName ||
          beneficiary?.lastName ||
          beneficiary?.firstName,
        notificationActionText: texts[notification.type](
          asset ? asset.name || asset.nickName || asset.nickname || '' : '',
          String(scenario.data.scenario.triggerValue),
        ),
        description: asset?.name || asset?.nickName || asset?.nickname,
        date: notification.createdAt || new Date(),
      };
    }
    case NotificationType.SCENARIO_EXECUTED: {
      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      if (!asset) return null;

      return {
        title: 'Algorithm Completed',
        notificationActionText: `Algorithm has been completed for "${
          asset?.name || asset?.nickName || asset?.nickname || ''
        }"`,
        date: notification.date,
        blockchainTransactionHash: notification.blockchainTransactionHash,
      };
    }
    case NotificationType.BENEFICIARY_RECEIVE_ASSET: {
      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      const contact = sjDb.collections.contacts?.findOne(
        (_contact) => _contact.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      const entityName =
        asset?.name ||
        asset?.nickName ||
        asset?.nickname ||
        contact?.nickname ||
        '';

      return {
        title: `Receive ${asset ? 'Asset' : 'Contact'} - ${entityName}`,
        notificationActionText: `${notification.mainUserName} has sent you the information about the ${entityName}`,
        date: notification.date,
      };
    }
    case NotificationType.BENEFICIARY_REJECT_ASSET:
    case NotificationType.SCENARIO_ACCEPTED: {
      const scenario = await scenarioLazyQuery({
        variables: {
          scenarioId: notification.scenarioId,
        },
      });

      const asset = sjDb.collections.assets?.findOne(
        (_asset) => _asset.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      const contact = sjDb.collections.contacts?.findOne(
        (_contact) => _contact.id === scenario.data?.scenario?.dtoIds?.[0],
      );

      const entityName =
        asset?.name ||
        asset?.nickName ||
        asset?.nickname ||
        contact?.nickname ||
        '';

      const algoMetaInfo = sjDb.collections.scenarioMetaInfos?.findOne(
        (_algo) => _algo.centralDbId === notification.scenarioId,
      );

      const no = sjDb.collections.beneficiaries?.findOne(
        (_no) => _no.centralDbProfileId === Number(notification.beneficiaryId),
      );

      const notificationActionText =
        notification.type === NotificationType.SCENARIO_ACCEPTED
          ? `${
              no?.nickName ?? ''
            } has accepted ${entityName} delivered by [TA name].`
          : `${
              no?.nickName ?? ''
            } has not accepted ${entityName} delivered by ${
              algoMetaInfo?.name ?? ''
            } within the specified time frame`;

      const title =
        notification.type === NotificationType.SCENARIO_ACCEPTED
          ? `${no?.nickName ?? ''} has accepted algorithm`
          : `${no?.nickName ?? ''} has not accepted algorithm`;

      return {
        title,
        notificationActionText,
        date: notification.date,
      };
    }
    case NotificationType.SCENARIO_BENEFICIARY_ACCEPT_INVITATION: {
      const no = sjDb.collections.beneficiaries?.findOne(
        (_no) => _no.id === notification.beneficiaryId,
      );
      const algoMetaInfo = sjDb.collections.scenarioMetaInfos?.findOne(
        (_algo) => _algo.centralDbId === notification.scenarioId,
      );
      const notificationActionText = `${
        no?.nickName ?? ''
      } has registered successfully. The transfer algorithm ${
        algoMetaInfo?.name ?? ''
      } assigned to them can be activated now.`;
      return {
        title: `${no?.nickName ?? ''} has registered successfully.`,
        notificationActionText,
        date: notification.date,
      };
    }
    case NotificationType.NEW_ITEM_SHARED: {
      return {
        title:
          //todo - update with main owner first/last names
          "We would like to inform you that a new element has been shared with you. Please log in to the system and navigate to the 'Shared' section to view it",
        notificationActionText: '',
        date: notification.date,
      };
    }
    case NotificationType.NO_MODIFY_SHARED_ITEM: {
      const item = sjDb.collections[notification.collection]?.findOne(
        (item_) => item_.id === notification.itemId,
      );

      const madeBy = sjDb.collections.beneficiaries?.findOne(
        (no) => no.centralDbProfileId === notification.noId,
      );

      if (!item || !madeBy) return null;

      const entityNameByCollection = {
        [CollectionName.ASSETS]: 'Asset',
        [CollectionName.CONTACTS]: 'Contact',
        [CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS]: 'ID',
        [CollectionName.PRIVATE_DOCUMENTS]: 'Document',
        [CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT]:
          'Next Owner`s data',
      };

      const noIfNoDoc =
        notification.collection ===
        CollectionName.BENEFICIARY_PERSONAL_DATA_DOCUMENT
          ? sjDb.collections.beneficiaries?.findOne(
              (no) =>
                (item as BeneficiaryPersonalDataDocumentDto)
                  .beneficiaryDtoId === no.id,
            )
          : undefined;

      return {
        title: `We would like to inform you that the ${
          entityNameByCollection[
            notification.collection as keyof typeof entityNameByCollection
          ] ?? ''
        } ${
          noIfNoDoc?.nickName ??
          (item as AssetDto).nickName ??
          (item as AssetDto).nickname ??
          (item as AssetDto).name ??
          (item as ContactDto).nickname ??
          ''
        } has been modified by the Next Owner, ${
          madeBy.nickName
        }. Please log in to the application and navigate to the “Shared” section to review and accept the changes`,
        notificationActionText: '',
        date: notification.date,
      };
    }
    default: {
      console.error(
        t('notifications:notificationTypeText.errors.unknownNotification'),
      );
      return {
        notificationActionText: t(
          'notifications:notificationTypeText.errors.unknownNotification',
        ),
        date: new Date(),
      };
    }
  }
};
