import { useCallback, useMemo } from 'react';

import {
  AuthResponse,
  TwoFactorQuestionPlainObject,
  useActivateBeneficiaryProfileMutation,
  useLoginMutation,
} from '@/shared/generated/graphql';
import { log } from '@/shared/utils/log';

type BeneficiaryLoginInputType = {
  jwt: string;
  publicKey: string;
  password: string;
  email: string;
  twoFactorQuestions: TwoFactorQuestionPlainObject[];
};

export const useBeneficiaryLogin = () => {
  const [activateBeneficiaryProfile] = useActivateBeneficiaryProfileMutation();
  const [loginMutation] = useLoginMutation();

  const login = useCallback(
    async ({
      jwt,
      publicKey,
      password,
      email,
      twoFactorQuestions,
    }: BeneficiaryLoginInputType): Promise<AuthResponse | undefined> => {
      const activatedProfile = await activateBeneficiaryProfile({
        variables: { publicKey, password, jwt, twoFactorQuestions },
      });

      if (!activatedProfile.data?.activateBeneficiaryProfile) return undefined;

      try {
        const loginData = await loginMutation({
          variables: { email, password },
        });

        if (!loginData.data?.login) return undefined;

        return loginData.data.login as AuthResponse;
      } catch (e) {
        log.error('Error while login via encrypted message');
        return undefined;
      }
    },
    [activateBeneficiaryProfile, loginMutation],
  );

  return useMemo(() => ({ login }), [login]);
};
