import migrations from '@/shared/lib/migrator/migrations';
import { TMigrationVersion } from '@/shared/lib/migrator/types';
import { migrationError } from '@/shared/lib/migrator/utils';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { MigrationApplyResultDto } from '@/shared/lib/sj-orm/models/migration-apply-result.dto';

export class Migrator {
  constructor(private readonly publicKey: string) {}

  up(
    collections: Map<CollectionName, SecureJsonBase>,
  ): Map<CollectionName, SecureJsonBase> {
    const userMigrationsCollection = collections.get(
      CollectionName.MIGRATIONS,
    ) as SecureJsonBase<MigrationApplyResultDto> | undefined;

    if (userMigrationsCollection) {
      for (const migration of migrations) {
        const userMigration = userMigrationsCollection?.get(
          this.generateMigrationKey(migration.version),
        );

        if (userMigration?.isApplied) continue;

        let isApplied = false;
        try {
          migration.up(collections);
          isApplied = true;
        } catch (e) {
          throw migrationError((e as Error).message);
        }

        userMigrationsCollection?.set({
          key: this.generateMigrationKey(migration.version),
          data: {
            version: migration.version,
            isApplied,
          },
          publicKeys: [this.publicKey],
        });
      }
    }

    return collections;
  }

  private generateMigrationKey(version: TMigrationVersion) {
    return `${CollectionName.MIGRATIONS}-${version}`;
  }
}
