import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';

import { USDollar } from '@/shared/utils/misc';

ChartJS.register(ArcElement, Tooltip);

export const DoughnutChart = ({
  list,
  value,
  amounts,
  cutout = '50%',
  isMainDashboard = false,
}: {
  list: Array<string>;
  value: Array<number>;
  amounts?: Array<number>;
  cutout?: string;
  isMainDashboard?: boolean;
}) => {
  const options = {
    parsing: {
      key: 'value',
    },
    ...(amounts && {
      plugins: {
        tooltip: {
          usePointStyle: true,
          callbacks: {
            usePointStyle: true,
            // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
            label: function (context: any) {
              if (context.raw) {
                const label = ` ${USDollar.format(context.raw.value)} • ${
                  context.raw.key.size
                }`;
                return label ?? undefined;
              }
              return undefined;
            },
          },
        },
      },
    }),
  };
  const data = {
    labels: list,
    datasets: [
      {
        cutout: cutout,
        label: '',
        data: amounts
          ? value.map((item, index) => {
              return { key: amounts[index], value: item };
            })
          : value,
        backgroundColor: isMainDashboard
          ? [
              '#000000',
              '#000080',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ]
          : [
              '#00AEEF',
              '#0F75BD',
              '#283890',
              '#662E91',
              '#92278F',
              '#A01F62',
              '#D81B5D',
              '#ED008C',
              '#EF2768',
              '#EF3E36',
              '#F3582D',
              '#F8931F',
              '#FCB040',
              '#F5ED30',
              '#D7DF22',
              '#8CC63E',
            ],
        hoverOffset: 4,
      },
    ],
  };
  return <Doughnut data={data} options={options} />;
};
