import { CountriesList } from '@/shared/constants/countries-list';
import {
  ProfileList,
  ProfileType,
} from '@/shared/lib/forms/documents/personal-IDs/passport';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { dispathEvent } from '@/shared/lib/hooks/use-form-render/misc';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BasisOfObtainingType {
  openingACompany = 'openingACompany',
  employment = 'employment',
  studies = 'studies',
  marriage = 'marriage',
  buyingRealEstate = 'buyingRealEstate',
  medicalTreatment = 'medicalTreatment',
  familyReunification = 'familyReunification',
}

const BasisOfObtainingList = [
  {
    label: 'Opening a company',
    key: BasisOfObtainingType.openingACompany,
  },
  {
    label: 'Employment',
    key: BasisOfObtainingType.employment,
  },
  {
    label: 'Studies',
    key: BasisOfObtainingType.studies,
  },
  {
    label: 'Marriage',
    key: BasisOfObtainingType.marriage,
  },
  {
    label: 'Buying real estate',
    key: BasisOfObtainingType.buyingRealEstate,
  },
  {
    label: 'Medical treatment',
    key: BasisOfObtainingType.medicalTreatment,
  },
  {
    label: 'Family reunification',
    key: BasisOfObtainingType.familyReunification,
  },
];

export type ResidencePermitDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  profile: string;
  citizenship?: string;
  number?: string;
  cardAccessNumber?: string;
  dateOfIssue?: number;
  isPermanent?: boolean;
  endDate?: number;
  basisOfObtaining?: BasisOfObtainingType;
  limitations?: string;
  other?: string;
  scan?: DtoId[];
  storageLocation?: string; //гибридная ссылка
  comments?: string;
};

export type ResidencePermitDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  profile: QuestionType.SELECT_FROM_LIST;
  citizenship?: QuestionType.SELECT_FROM_LIST;
  number?: QuestionType.TEXT;
  cardAccessNumber?: QuestionType.TEXT;
  dateOfIssue?: QuestionType.DATE_TIME;
  isPermanent?: QuestionType.CHECKBOX;
  endDate?: QuestionType.DATE_TIME;
  basisOfObtaining?: QuestionType.SELECT_FROM_LIST;
  limitations?: QuestionType.TEXT;
  other?: QuestionType.TEXT;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT; //гибридная ссылка
  comments?: QuestionType.TEXT_MULTILINE;
};

type ResidencePermitFormType = TDtoWrapper<
  ResidencePermitDtoType,
  ResidencePermitDtoHelpType
>;

export const ResidencePermitForm: ResidencePermitFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Title',
    placeholder: 'Enter document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    placeholder: 'Select country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  profile: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Profile',
    placeholder: 'Select profile',
    items: ProfileList,
    required: true,
    value: '',
    onChangeEvent: {
      name: 'profileChangeEvent',
    },
  },
  names: {
    type: QuestionType.TEXT,
    label: '*First name(s)',
    placeholder: 'Enter your first name',
    required: true,
    value: '',
    drawerOptions: {
      event: {
        name: 'profileChangeEvent',
        listener: (data, fn) => {
          fn(data.detail);
        },
      },
      items: {
        [ProfileType.mainOwner]: {
          items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          filter: (data) => {
            const item = data as PersonalIdentifierDocumentDto;
            return (
              item.personalIdentifierType ===
              PersonalIdentifierType.USER_DETAILS
            );
          },
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },
          dtoType: PersonalIdentifierType.USER_DETAILS,

          __single: true,
        },
        [ProfileType.nextOwner]: {
          items: CollectionName.BENEFICIARIES,
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.beneficiaries?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.firstName);
            setValue('surnames', item.lastName);
          },

          __single: true,
        },
      },
    },
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Last name(s)',
    placeholder: 'Enter your last name',
    required: true,
    value: '',
    drawerOptions: {
      event: {
        name: 'profileChangeEvent',
        listener: (data, fn) => {
          fn(data.detail);
        },
      },
      items: {
        [ProfileType.mainOwner]: {
          items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          filter: (data) => {
            const item = data as PersonalIdentifierDocumentDto;
            return (
              item.personalIdentifierType ===
              PersonalIdentifierType.USER_DETAILS
            );
          },
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },

          __single: true,
        },
        [ProfileType.nextOwner]: {
          items: CollectionName.BENEFICIARIES,
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },

          __single: true,
        },
      },
    },
  },
  citizenship: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Citizenship',
    items: CountriesList,
    withSearch: true,
  },
  number: {
    type: QuestionType.TEXT,
    label: 'Document number',
  },
  cardAccessNumber: {
    type: QuestionType.TEXT,
    label: 'Card access number',
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  isPermanent: {
    type: QuestionType.CHECKBOX,
    label: 'Permanent residence?',
  },
  basisOfObtaining: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Grounds for obtaining',
    items: BasisOfObtainingList,
  },
  limitations: {
    type: QuestionType.TEXT,
    label: 'Limitations',
  },
  other: {
    type: QuestionType.TEXT,
    label: 'Other data',
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My ToDo',
    tooltip:
      'All planned actions on an id, for example: renting out a house, paying annual membership fees, or any other relevant actions',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My ToDo Comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Documents scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
