import { useCallback, useMemo } from 'react';

import { CryptoCore } from '@/shared/lib/secure-json/core/crypto-core';

export const useMnemonic = () => {
  const create = useCallback(async () => {
    const cryptoCore = new CryptoCore();

    const mnemonic = cryptoCore.mnemonic.generateMnemonic();
    const keyPair = await cryptoCore.mnemonic.mnemonicToKeyPair(mnemonic);

    return { mnemonic, keyPair };
  }, []);

  const keyPairFromMnemonic = useCallback(async (mnemonic: string) => {
    const cryptoCore = new CryptoCore();

    return await cryptoCore.mnemonic.mnemonicToKeyPair(mnemonic);
  }, []);

  return useMemo(
    () => ({
      create,
      keyPairFromMnemonic,
    }),
    [create, keyPairFromMnemonic],
  );
};
