import {
  BG,
  CN,
  CZ,
  DE,
  DK,
  EE,
  ES,
  FI,
  FR,
  GB,
  GR,
  HR,
  HU,
  IE,
  IS,
  IT,
  JP,
  KR,
  LT,
  LV,
  MT,
  NL,
  NO,
  PL,
  PT,
  RO,
  RS,
  RU,
  SA,
  SE,
  SI,
  SK,
  TR,
} from 'country-flag-icons/react/3x2';

type LanguagesListType = {
  label: string;
  key: string;
  icon: JSX.Element;
};

export const LanguagesList: LanguagesListType[] = [
  {
    label: 'English',
    key: 'EN',
    icon: <GB width={20} height={20} />,
  },
  {
    label: 'Português',
    key: 'PT',
    icon: <PT width={20} height={20} />,
  },
  {
    label: 'Polski',
    key: 'PL',
    icon: <PL width={20} height={20} />,
  },
  {
    label: 'Français',
    key: 'FR',
    icon: <FR width={20} height={20} />,
  },
  {
    label: 'Deutsch',
    key: 'DE',
    icon: <DE width={20} height={20} />,
  },
  {
    label: 'Italiano',
    key: 'IT',
    icon: <IT width={20} height={20} />,
  },
  {
    label: 'Español',
    key: 'ES',
    icon: <ES width={20} height={20} />,
  },
  {
    label: 'Nederlands',
    key: 'NL',
    icon: <NL width={20} height={20} />,
  },
  {
    label: 'Русский',
    key: 'RU',
    icon: <RU width={20} height={20} />,
  },
  {
    label: '日本語',
    key: 'JP',
    icon: <JP width={20} height={20} />,
  },
  {
    label: '中文',
    key: 'CN',
    icon: <CN width={20} height={20} />,
  },
  {
    label: 'العربية',
    key: 'AR',
    icon: <SA width={20} height={20} />,
  },
  {
    label: '한국어',
    key: 'KR',
    icon: <KR width={20} height={20} />,
  },
  {
    label: 'Türkçe',
    key: 'TR',
    icon: <TR width={20} height={20} />,
  },
  {
    label: 'Ελληνικά',
    key: 'GR',
    icon: <GR width={20} height={20} />,
  },
  {
    label: 'Svenska',
    key: 'SE',
    icon: <SE width={20} height={20} />,
  },
  {
    label: 'Suomi',
    key: 'FI',
    icon: <FI width={20} height={20} />,
  },
  {
    label: 'Norsk',
    key: 'NO',
    icon: <NO width={20} height={20} />,
  },
  {
    label: 'Dansk',
    key: 'DK',
    icon: <DK width={20} height={20} />,
  },
  {
    label: 'Magyar',
    key: 'HU',
    icon: <HU width={20} height={20} />,
  },
  {
    label: 'Čeština',
    key: 'CZ',
    icon: <CZ width={20} height={20} />,
  },
  {
    label: 'Română',
    key: 'RO',
    icon: <RO width={20} height={20} />,
  },
  {
    label: 'Български',
    key: 'BG',
    icon: <BG width={20} height={20} />,
  },
  {
    label: 'Hrvatski',
    key: 'HR',
    icon: <HR width={20} height={20} />,
  },
  {
    label: 'Српски',
    key: 'RS',
    icon: <RS width={20} height={20} />,
  },
  {
    label: 'Slovenščina',
    key: 'SI',
    icon: <SI width={20} height={20} />,
  },
  {
    label: 'Slovenčina',
    key: 'SK',
    icon: <SK width={20} height={20} />,
  },
  {
    label: 'Eesti',
    key: 'EE',
    icon: <EE width={20} height={20} />,
  },
  {
    label: 'Latviešu',
    key: 'LV',
    icon: <LV width={20} height={20} />,
  },
  {
    label: 'Lietuvių',
    key: 'LT',
    icon: <LT width={20} height={20} />,
  },
  {
    label: 'Íslenska',
    key: 'IS',
    icon: <IS width={20} height={20} />,
  },
  {
    label: 'Malti',
    key: 'MT',
    icon: <MT width={20} height={20} />,
  },
  {
    label: 'Gaeilge',
    key: 'IE',
    icon: <IE width={20} height={20} />,
  },
];
