import { useCallback, useState } from 'react';

import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { Synchronizer } from '@/shared/lib/synchronizer/core';
import { Loader } from '@/shared/lib/synchronizer/loaders/types';
import {
  SyncQueueJobMethod,
  useSyncQueueStore,
} from '@/shared/lib/synchronizer/sync-queue.store';
import { log } from '@/shared/utils/log';

export interface ISynchronizer {
  isSyncing: boolean;
  sync: () => Promise<boolean>;
}

export const useSynchronizer = (
  keyPair: KeyPair | undefined,
  loader: Loader,
  // tag: string,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ISynchronizer | undefined => {
  // log.info('Synchronizer: initializing', { tag });
  const sjStore = useSecureJsonCollectionsStore();
  const syncQueueStore = useSyncQueueStore();

  const [isSyncing, setIsSyncing] = useState(false);

  if (!keyPair) {
    return undefined;
  }

  const synchronizer = new Synchronizer(loader, keyPair, sjStore);
  const sync = useCallback(async (): Promise<boolean> => {
    log.trace('Synchronizer: sync called');
    if (isSyncing) {
      // log.debug('Synchronizer: already syncing');
      return false;
    }
    log.debug(
      'Synchronizer: processing available jobs',
      { isSyncing },
      syncQueueStore.jobs,
    );
    setIsSyncing(true);
    let jobs = syncQueueStore.jobs.sort(
      (a, b) =>
        // Sort: oldest first
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    // push first
    jobs = jobs.sort((a) => {
      if (a.method === SyncQueueJobMethod.PUSH) {
        return -1;
      }
      return 1;
    });

    try {
      for (const job of jobs) {
        if (job.method === SyncQueueJobMethod.PULL) {
          const collectionNameArray = Object.values(CollectionName);
          for (const collectionName of collectionNameArray) {
            await synchronizer.pull(collectionName);
          }

          syncQueueStore.removeJob(job.id);
          continue;
        }
        if (job.method === SyncQueueJobMethod.SINGLE_PULL) {
          for (const collection of job.collections) {
            // log.debug(
            //   `Synchronizer: processing SINGLE PULL job ${job.id} for collection ${collection.collectionName}`,
            // );
            await synchronizer.pull(collection.collectionName);
          }

          syncQueueStore.removeJob(job.id);
          continue;
        }
        for (const collection of job.collections) {
          // log.debug(
          //   `Synchronizer: processing PUSH job ${job.id} for collection ${collection.collectionName}`,
          // );
          if (job.method === SyncQueueJobMethod.PUSH) {
            await synchronizer.push(
              collection.collectionName,
              collection.collection,
            );

            // delay to avoid mix up order of jobs
            const DELAY_MS = 150;
            await new Promise((resolve) => setTimeout(resolve, DELAY_MS));

            syncQueueStore.removeJob(job.id);
          }
        }
      }
    } catch (e) {
      setIsSyncing(false);
      log.warn('Synchronizer: error while processing jobs', e);
      return false;
    }

    setIsSyncing(false);

    return true;
  }, [isSyncing, syncQueueStore.jobs]);

  return {
    isSyncing,
    sync,
  };
};
