import forge from 'node-forge';

import { SymmetricKey } from '@/shared/lib/secure-json/core/crypto-core/types';

export type DecryptedFile = File;
export type EncryptedFile = string;

export class Aes {
  // eslint-disable-next-line no-magic-numbers
  private readonly ivSize = 16;
  // eslint-disable-next-line no-magic-numbers
  private readonly keySize = 32;

  private readonly ENCRYPTED_FILE_DELIMITER =
    '<!_!>ENCRYPTED_FILE_DELIMITER<!_!>';

  public generateKey(): SymmetricKey {
    return forge.util.encode64(forge.random.getBytesSync(this.keySize));
  }

  public encrypt(plaintext: string, key: SymmetricKey): string {
    const iv = forge.random.getBytesSync(this.ivSize);
    const aesKey = forge.md.sha256.create().update(key).digest().getBytes();
    const cipher = forge.cipher.createCipher('AES-CTR', aesKey);
    cipher.start({ iv });
    cipher.update(forge.util.createBuffer(plaintext, 'utf8'));
    cipher.finish();
    // return the iv and ciphertext concatenated
    return forge.util.encode64(iv + cipher.output.getBytes());
  }

  public decrypt(ciphertext: string, key: SymmetricKey): string {
    const raw = forge.util.decode64(ciphertext);
    const iv = raw.slice(0, this.ivSize);
    const payload = raw.slice(this.ivSize);
    const aesKey = forge.md.sha256.create().update(key).digest().getBytes();
    const decipher = forge.cipher.createDecipher('AES-CTR', aesKey);
    decipher.start({ iv });
    decipher.update(forge.util.createBuffer(payload));
    decipher.finish();
    return decipher.output.toString();
  }

  // file encryption

  private chunkArray(input: Uint8Array, chunkSize: number): Uint8Array[] {
    let index = 0;
    const arrayLength = input.length;
    const chunks = [];

    while (index < arrayLength) {
      chunks.push(input.slice(index, index + chunkSize));
      index += chunkSize;
    }

    return chunks;
  }

  public async encryptFile(
    file: File,
    key: SymmetricKey,
  ): Promise<EncryptedFile> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const plaintext = new Uint8Array(reader.result as ArrayBuffer);
        const chunks = this.chunkArray(plaintext, 1000);
        const encodedData = chunks.map((chunk) =>
          forge.util.binary.raw.encode(chunk),
        );
        const dataWithMimeType = `${file.type}${
          this.ENCRYPTED_FILE_DELIMITER
        }${encodedData.join('')}`;

        resolve(this.encrypt(dataWithMimeType, key));
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  public async decryptFile(
    file: EncryptedFile,
    key: SymmetricKey,
    fileName?: string,
  ): Promise<DecryptedFile> {
    const dataWithMimeType = this.decrypt(file, key);
    const [mimeType, data] = dataWithMimeType.split(
      this.ENCRYPTED_FILE_DELIMITER,
    );
    const arrayBuffer = new Uint8Array(forge.util.binary.raw.decode(data))
      .buffer;
    return new File([arrayBuffer], fileName || 'decrypted', { type: mimeType });
  }

  fileToUrl(decryptedFile: File): string {
    return URL.createObjectURL(decryptedFile);
  }
}
