import { generateMnemonic, mnemonicToSeed } from 'bip39';
import { pki, random } from 'node-forge';

import {
  KeyPair,
  PrivateKey,
} from '@/shared/lib/secure-json/core/crypto-core/types';

import { Rsa } from './rsa';

export class Mnemonic {
  public generateMnemonic(): string {
    return generateMnemonic();
  }
  public async mnemonicToKeyPair(mnemonic: string): Promise<KeyPair> {
    const seed = await mnemonicToSeed(mnemonic);
    const seedString = seed.toString('hex');

    const prng = random.createInstance();
    prng.seedFileSync = (): string => seedString;

    const { privateKey, publicKey } = pki.rsa.generateKeyPair({
      bits: 2048,
      prng,
    });

    return {
      privateKey: Rsa.trimKey<PrivateKey>(pki.privateKeyToPem(privateKey)),
      publicKey: Rsa.trimKey(pki.publicKeyToPem(publicKey)),
    } as KeyPair;
  }
}
