import { ApolloLink, FetchResult, Observable } from '@apollo/client';

import { log } from '@/shared/utils/log';

export class BlockLink extends ApolloLink {
  request() {
    return new Observable<FetchResult>((observer) => {
      log.debug('BlockLink request');
      observer.error(new Error('Requests in exported mode are not allowed'));
    });
  }
}
