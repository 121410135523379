import { PropsWithChildren, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Tour, TourProps } from 'antd';

import { TUTORIALS } from '@/shared/constants/tutorials';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

import styles from './tutorial.module.css';

export const TutorialsProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState<boolean>(false);
  const { pathname } = useRouter();
  const { collections, generateId } = useSJDatabase();

  const [steps, setSteps] = useState<TourProps['steps']>([]);
  const [nextId, setNextId] = useState(0);
  const { keyPair } = useKeyPairStore();

  const onClose = () => {
    const IDS = TUTORIALS.PAGE_TO_IDS[pathname];
    if (Array.isArray(IDS) && IDS.length - 1 > nextId) {
      setNextId((prev) => prev + 1);
      return;
    }
    setOpen(false);
    const tutorialInDb = collections.tutorials?.findOne(
      (tutorial) => tutorial.key === pathname,
    );
    if (tutorialInDb) {
      collections.tutorials?.update({ ...tutorialInDb, isViewd: true });
      return;
    }
    if (keyPair?.publicKey) {
      collections.tutorials?.create(
        {
          id: generateId(CollectionName.TUTORIALS),
          key: pathname,
          isViewd: true,
        },
        [keyPair?.publicKey],
      );
    }
  };

  useEffect(() => {
    if (!collections.tutorials) return;
    let IDS = TUTORIALS.PAGE_TO_IDS[pathname];

    if (Array.isArray(IDS)) {
      IDS = IDS[nextId];
    }

    const tutorialInDb = collections.tutorials?.findOne(
      (tutorial) => tutorial.key === pathname,
    );
    if (IDS && !tutorialInDb?.isViewd) {
      const _steps: TourProps['steps'] = [];

      for (const id of Object.values(IDS)) {
        const element = document.getElementById(id);
        if (element) {
          _steps.push({
            ...(TUTORIALS.IDS_TO_TEXTS[id] ?? {}),
            target: element,
          });
        }
      }

      const realIds = TUTORIALS.PAGE_TO_IDS[pathname];

      setSteps(_steps);
      if (_steps.length) {
        setOpen(true);
      } else if (Array.isArray(realIds) && realIds.length - 1 > nextId) {
        setNextId((prev) => prev + 1);
      }
    }
  }, [pathname, collections.tutorials, nextId]);

  return (
    <>
      {children}
      {open && steps?.length && (
        <Tour
          open={open}
          onClose={onClose}
          steps={steps}
          rootClassName={styles.tour}
        />
      )}
    </>
  );
};
