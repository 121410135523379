import { useCallback, useMemo } from 'react';

import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

export const useMultiCurrencies = () => {
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();

  const getCurrencies = useMemo(() => {
    return sjDb.collections.currencies?.findMany(() => true) || [];
  }, [sjDb]);

  const getCurrency = useCallback(
    (currencyCode: string) => {
      return sjDb.collections.currencies?.findOne(
        (cur) => cur.currencyCode === currencyCode,
      );
    },
    [sjDb],
  );

  const getDefaultCurrency = useMemo(() => {
    return sjDb.collections.currencies?.findOne(
      (cur) => cur.isDefault || false,
    );
  }, [sjDb]);

  const setDefaultCurrency = useCallback(
    (currencyCode: string) => {
      const defaultCurrency = sjDb.collections.currencies?.findOne(
        (cur) => cur.isDefault || false,
      );
      const currency = sjDb.collections.currencies?.findOne(
        (cur) => cur.currencyCode === currencyCode,
      );
      if (defaultCurrency) {
        sjDb.collections.currencies?.update({
          ...defaultCurrency,
          isDefault: false,
        });
      }
      if (currency) {
        sjDb.collections.currencies?.update({
          ...currency,
          isDefault: true,
        });
      } else {
        addCurrency(currencyCode, 0, false, true);
      }
    },
    [sjDb],
  );

  const addCurrency = useCallback(
    (
      currencyCode: string,
      value: number,
      isCustom: boolean,
      isDefault?: boolean,
    ) => {
      if (!keyPair?.publicKey) throw new Error('Public key is not defined');
      sjDb.collections.currencies?.create(
        {
          id: sjDb.generateId(CollectionName.CURRENCIES),
          currencyCode: currencyCode,
          value: value,
          isCustom: isCustom,
          ...(isDefault && { isDefault: isDefault }),
        },
        [keyPair.publicKey],
      );
    },
    [sjDb],
  );

  const setCustomCurrency = useCallback(
    (currencyCode: string, value: number) => {
      const currency = sjDb.collections.currencies?.findOne(
        (cur) => cur.currencyCode === currencyCode,
      );
      if (currency) {
        sjDb.collections.currencies?.update({
          ...currency,
          value: value,
          isCustom: true,
        });
      } else {
        addCurrency(currencyCode, value, true, false);
      }
    },
    [sjDb],
  );

  return useMemo(
    () => ({
      addCurrency,
      getCurrency,
      getCurrencies,
      setDefaultCurrency,
      setCustomCurrency,
      getDefaultCurrency,
    }),
    [
      addCurrency,
      getCurrency,
      getCurrencies,
      setDefaultCurrency,
      setCustomCurrency,
      getDefaultCurrency,
    ],
  );
};
