import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export interface ContactDto extends BaseDto {
  nickname: string;
  name?: string;
  lasName?: string;
  selectedCountry: string;
  type: ContactType;
  phone?: {
    Dropdown: string;
    Input: string;
  };

  email?: string;

  address?: string;

  organization?: string;
  position?: string;
  assetId?: DtoId;
  assetCommentary?: string;

  role?: string;
  commentary?: string;
  myTodo?: boolean;
  myTodoComments?: string;
  myTodoFavorite?: boolean;

  photo?: string;
  businessCard?: string;
  contactPerson?: string;

  isFavorite?: boolean;

  isTest?: boolean;
}

export enum ContactType {
  BROKER = 'BROKER',
  BANK = 'BANK',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INDIVIDUAL = 'INDIVIDUAL',
  PERSONAL = 'PERSONAL',
}
