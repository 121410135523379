import { Migrator } from '@/shared/lib/migrator/migrator';
import { KeyPair } from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { ISecureJsonCollectionsStore } from '@/shared/lib/stores/secure-json-collections.store';
import { Loader } from '@/shared/lib/synchronizer/loaders/types';

import { SerializedCollection } from './types';

export class Synchronizer {
  private readonly migrator: Migrator;

  constructor(
    private readonly loader: Loader,
    private readonly keyPair: KeyPair,
    private readonly collectionStore: ISecureJsonCollectionsStore,
  ) {
    this.migrator = new Migrator(keyPair.publicKey);
  }

  public async push(
    collectionName: CollectionName,
    collection: SerializedCollection,
  ): Promise<boolean> {
    await this.loader.push(collectionName, collection);

    return true;
  }

  public async pull(collectionName: CollectionName): Promise<boolean> {
    const serializedCollection: SerializedCollection | null =
      await this.loader.pull(collectionName);
    const collections = new Map<CollectionName, SecureJsonBase>();

    const defaultCollection = new SecureJsonBase(this.keyPair.privateKey);
    const collection = serializedCollection
      ? await defaultCollection.fromStringAsync(serializedCollection)
      : defaultCollection;

    collections.set(collectionName, collection);

    // todo make async migration async and process only one collection at a time
    const migratedCollections = this.migrator.up(collections);
    this.collectionStore.setCollection(
      collectionName,
      migratedCollections.get(collectionName)!,
    );

    return true;
  }
}

// todo добавить колбэки на пуш пул чтобы можно было ловить события и отображать/скрывать лоадеры фризящие интерфейс
