import { ReactElement } from 'react';
import NextHead from 'next/head';

import appInfo from '../../../../app-info.json';

type PropertiesType = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
};

const defaults: PropertiesType = {
  title: `${appInfo.name}`,
  description: `${appInfo.name} App`,
  image: `${process.env.NEXT_PUBLIC_SELF_URL_BASE}/api/logo?titleFirst=${appInfo.name}&titleSecond=`,
  url: process.env.NEXT_PUBLIC_SELF_URL_BASE,
};

export const Meta = (_properties: PropertiesType): ReactElement => {
  const properties: PropertiesType = { ...defaults, ..._properties };

  return (
    <NextHead>
      <title>{properties.title}</title>
      <meta name="description" key={'desc'} content={properties.description} />

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta itemProp="name" content={properties.title} />
      <meta itemProp="description" content={properties.description} />
      <meta itemProp="image" content={properties.image} />

      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content={properties.title} />
      <meta property="og:title" content={properties.title} />
      <meta property="og:description" content={properties.description} />
      <meta property="og:image" content={properties.image} />
      <meta property="og:url" content={properties.url} />
      <meta property="og:type" content="website" />
      <meta property="og:image:width" content="467" />
      <meta property="og:image:height" content="263" />
      {/*<!-- Twitter Meta Tags -->*/}
      <meta name="twitter:title" key={'ttitle'} content={properties.title} />
      <meta
        name="twitter:description"
        key={'tdesc'}
        content={properties.description}
      />
      <meta name="twitter:image" key={'timg'} content={properties.image} />
      <meta name="twitter:card" key={'tcard'} content="summary_large_image" />
      <meta name="twitter:site" content={properties.url} />
      <meta name="twitter:creator" content={properties.url} />
    </NextHead>
  );
};
