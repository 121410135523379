import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export interface QuestionDto extends BaseDto {
  groupId: DtoId;

  label: string;
  type: QuestionType;
  required?: boolean;

  expression?: QuestionExpressionType;
}

export enum QuestionType {
  FILE_UPLOAD = 'FILE_UPLOAD',
  SELECT_FROM_LIST = 'SELECT_FROM_LIST',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  TEXT_MULTILINE = 'TEXT_MULTILINE',
  NUMBER = 'NUMBER',
  PICK_ARRAY_OF_DTO = 'PICK_ARRAY_OF_DTO',
  DATE_TIME = 'DATE_TIME',
  CURRENCY_AMOUNT = 'CURRENCY_AMOUNT',
  TIME_DURATION = 'TIME_DURATION',
  PHONE_NUMBER = 'PHONE_NUMBER',
  URL = 'URL',
  CHECKBOX = 'CHECKBOX',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CURRENCY_CALCULATOR = 'CURRENCY_CALCULATOR',
  RENDER_NEW_BUTTON = 'RENDER_NEW_BUTTON',
  // link ?
  // formula ?
}

//expressions

export type QuestionExpressionType =
  | {
      relevanceType: RelevanceEnum.AND | RelevanceEnum.OR;
      value: QuestionExpressionValue[];
    }
  | {
      relevanceType: RelevanceEnum.ALONE;
      value: QuestionExpressionValue;
    };

export type QuestionExpressionValue = { relevanceId: DtoId } & (
  | {
      type:
        | QuestionExpressionEnum.LENGTH_LESS
        | QuestionExpressionEnum.LENGTH_EQUAL
        | QuestionExpressionEnum.LENGTH_MORE
        | QuestionExpressionEnum.NUMBER;
      compareData: number;
    }
  | {
      type: QuestionExpressionEnum.TEXT;
      compareData: string;
    }
  | {
      type: QuestionExpressionEnum.YES_NO;
      compareData: boolean;
    }
);

export enum QuestionExpressionEnum {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  LENGTH_MORE = 'LENGTH_MORE',
  LENGTH_LESS = 'LENGTH_LESS',
  LENGTH_EQUAL = 'LENGTH_EQUAL',
  YES_NO = 'YES_NO',
}

export enum RelevanceEnum {
  AND = 'AND',
  OR = 'OR',
  ALONE = 'ALONE',
}
