import { ReactNode } from 'react';
import { UpOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse, Typography } from 'antd';
import styled from 'styled-components';

export const Collapse = ({
  title,
  description,
  children,
  descriptionColor,
}: {
  title?: string;
  description?: string;
  children: ReactNode;
  descriptionColor?: string;
}) => {
  return (
    <StyledCollapse
      ghost
      defaultActiveKey={['1']}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <IconArrow>
          <UpOutlined rotate={isActive ? 0 : 180} style={{ fontSize: '9px' }} />
        </IconArrow>
      )}
      items={[
        {
          key: '1',
          label: (
            <>
              {!!title && (
                <Typography.Title
                  level={4}
                  style={{ marginBottom: description ? '5px' : '0' }}
                >
                  {title}
                </Typography.Title>
              )}
              {description ? (
                <Typography.Text
                  style={{
                    color: descriptionColor,
                  }}
                >
                  {description}
                </Typography.Text>
              ) : null}
            </>
          ),
          children: children,
        },
      ]}
    />
  );
};

const StyledCollapse = styled(AntdCollapse)`
  .ant-collapse-header {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;
export const IconArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-top: 5px;
  background-color: #faf9f5;
  border-radius: 8px;
`;
