import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export interface SoweDto extends BaseDto {
  name: string;
  recipient?: string;
  recipientCountry: string;
  soweType: SoweType;
  language: string;
  sentDate: string;
  isMasterVersion?: boolean;
  isUpdateRequired?: boolean;
  updateDate?: string;
  updateReason?: string;
  hasChangesToTheMasterVersion?: boolean;
  changesToTheMasterVersion?: string;
  hasMyActions?: boolean;
  myActionsComments?: string;
  note?: string;
  profileId?: DtoId;
  soweAttachmentIds?: DtoId[];
}

export enum SoweType {
  SOWE = 'SOWE',
  SUMMARY = 'SUMMARY',
  SOWE_AND_SUMMARY = 'SOWE_AND_SUMMARY',
}
