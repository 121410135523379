/** @type {import('next-i18next').UserConfig} */
const i18n = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  loadPath: '/locales/{{lng}}/{{ns}}.json',
  addPath: '/locales/{{lng}}/{{ns}}.missing.json',
  serializeConfig: false,
  saveMissing: false,
};

module.exports = i18n;
