import { useCallback, useMemo } from 'react';

import { useMnemonic } from '@/entities/mnemonic';
import { useShared } from '@/entities/shared/use-shared';
import { Permission } from '@/shared/lib/secure-json/core/secure-json-collection/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { SharedSpacesDto } from '@/shared/lib/sj-orm/models/shared-spaces.dto';
import { useKeyPairStore } from '@/shared/store/decrypted-keypair.store';

export const useSharedSpaces = () => {
  const sjDb = useSJDatabase();
  const { keyPair } = useKeyPairStore();
  const { getSharedNextOwners } = useShared();
  const { keyPairFromMnemonic } = useMnemonic();

  const getSharedSpaces = useMemo(() => {
    return sjDb.collections.sharedSpaces?.findMany(() => true) || [];
  }, [sjDb]);

  const getSharedSpace = useCallback(
    (id: string) => {
      return sjDb.collections.sharedSpaces?.findOne((space) => space.id === id);
    },
    [sjDb],
  );

  const getSharedSpaceDtoIds = useCallback(
    (id: string) => {
      const sharedSpace = sjDb.collections.sharedSpaces?.findOne(
        (space) => space.id === id,
      );
      return sharedSpace ? sharedSpace.dtoIds.map((i) => i.id) : [];
    },
    [sjDb],
  );

  const getSharedSpaceDtoIdsWithTypes = useCallback(
    (id: string) => {
      const sharedSpace = sjDb.collections.sharedSpaces?.findOne(
        (space) => space.id === id,
      );
      return sharedSpace ? sharedSpace.dtoIds : [];
    },
    [sjDb],
  );

  const addSharedSpace = useCallback(
    (name: string) => {
      if (!keyPair?.publicKey) throw new Error('Public key is not defined');
      sjDb.collections.sharedSpaces?.create(
        {
          id: sjDb.generateId(CollectionName.SHARED_SPACES),
          name: name,
          dtoIds: [],
          nextOwners: [],
        },
        [keyPair.publicKey],
      );
    },
    [sjDb],
  );

  const renameSharedSpace = useCallback(
    (id: string, name: string) => {
      const sharedSpace = sjDb.collections.sharedSpaces?.findOne(
        (space) => space.id === id,
      );
      if (sharedSpace)
        sjDb.collections.sharedSpaces?.update({
          ...sharedSpace,
          name: name,
        });
    },
    [sjDb],
  );

  const addDtoToSharedSpace = useCallback(
    (id: string, dtoId: string, type: CollectionName) => {
      const sharedSpace = sjDb.collections.sharedSpaces?.findOne(
        (space) => space.id === id,
      );
      if (sharedSpace)
        sjDb.collections.sharedSpaces?.update({
          ...sharedSpace,
          dtoIds: [...sharedSpace.dtoIds, { id: dtoId, type: type }],
        });
    },
    [sjDb],
  );

  const deleteSharedSpace = useCallback(
    async (id: string) => {
      const sno = getSharedNextOwners(id, CollectionName.SHARED_SPACES)
        .then((result) => result)
        .catch((error) => error);

      const sharedNextOwners = await sno;

      const existAssistant = sjDb.collections.assistantMnemonic?.findOne(
        (assistant) => assistant?.name === id,
      );

      const sharedSpace = getSharedSpace(id);

      if (sharedSpace) {
        for (const dtoId of sharedSpace.dtoIds) {
          if (sharedNextOwners && sharedNextOwners.length > 0) {
            sharedNextOwners.forEach((item: { publicKey: string }) => {
              sjDb.collections[dtoId.type]?.deletePublicKey(
                dtoId.id,
                item.publicKey,
              );
              sjDb.collections[dtoId.type]?.deletePermission(
                dtoId.id,
                item.publicKey,
                Permission.READ,
              );
              sjDb.collections[dtoId.type]?.deletePermission(
                dtoId.id,
                item.publicKey,
                Permission.WRITE,
              );
            });
          }

          if (existAssistant) {
            const { publicKey } = await keyPairFromMnemonic(
              existAssistant.mnemonic,
            );
            sjDb.collections[dtoId.type]?.deletePublicKey(dtoId.id, publicKey);
            sjDb.collections[dtoId.type]?.deletePermission(
              dtoId.id,
              publicKey,
              Permission.READ,
            );
            sjDb.collections[dtoId.type]?.deletePermission(
              dtoId.id,
              publicKey,
              Permission.WRITE,
            );
          }
        }
      }

      sjDb.collections.sharedSpaces?.remove({ id } as SharedSpacesDto);
    },
    [sjDb],
  );

  const deleteDtoFromSharedSpace = useCallback(
    async (spaceId: string, dtoId: string, type: CollectionName) => {
      const sno = getSharedNextOwners(spaceId, CollectionName.SHARED_SPACES)
        .then((result) => result)
        .catch((error) => error);

      const sharedNextOwners = await sno;

      const existAssistant = sjDb.collections.assistantMnemonic?.findOne(
        (assistant) => assistant?.name === spaceId,
      );

      const sharedSpace = getSharedSpace(spaceId);

      if (sharedSpace) {
        if (sharedNextOwners && sharedNextOwners.length > 0) {
          sharedNextOwners.forEach((item: { publicKey: string }) => {
            console.log(type, dtoId, item.publicKey);
            sjDb.collections[type]?.deletePublicKey(dtoId, item.publicKey);
            sjDb.collections[type]?.deletePermission(
              dtoId,
              item.publicKey,
              Permission.READ,
            );
            sjDb.collections[type]?.deletePermission(
              dtoId,
              item.publicKey,
              Permission.WRITE,
            );
          });
        }

        if (existAssistant) {
          const { publicKey } = await keyPairFromMnemonic(
            existAssistant.mnemonic,
          );
          sjDb.collections[type]?.deletePublicKey(dtoId, publicKey);
          sjDb.collections[type]?.deletePermission(
            dtoId,
            publicKey,
            Permission.READ,
          );
          sjDb.collections[type]?.deletePermission(
            dtoId,
            publicKey,
            Permission.WRITE,
          );
        }

        sjDb.collections.sharedSpaces?.update({
          ...sharedSpace,
          dtoIds: sharedSpace.dtoIds.filter((item) => item.id !== dtoId),
        });
      }
    },
    [sjDb],
  );

  const deleteDtoFromSharedSpaceWhenDeleteCollection = useCallback(
    (dtoId: string) => {
      const sharedSpaces = getSharedSpaces;
      if (sharedSpaces) {
        sharedSpaces.forEach((space) => {
          const dtoInSharedSpace = space.dtoIds.find(
            (item) => item.id === dtoId,
          );
          if (dtoInSharedSpace) {
            sjDb.collections.sharedSpaces?.update({
              ...space,
              dtoIds: space.dtoIds.filter(
                (item) => item.id !== dtoInSharedSpace.id,
              ),
            });
          }
        });
      }
    },
    [sjDb],
  );

  const addNextOwnersToSharedSpace = useCallback(
    (id: string, nextOwnerIds: number[]) => {
      const sharedSpace = sjDb.collections.sharedSpaces?.findOne(
        (space) => space.id === id,
      );
      if (sharedSpace)
        sjDb.collections.sharedSpaces?.update({
          ...sharedSpace,
          nextOwners: [...sharedSpace.nextOwners, ...nextOwnerIds],
        });
    },
    [sjDb],
  );

  return useMemo(
    () => ({
      getSharedSpaces,
      getSharedSpace,
      getSharedSpaceDtoIds,
      getSharedSpaceDtoIdsWithTypes,
      addSharedSpace,
      renameSharedSpace,
      deleteSharedSpace,
      deleteDtoFromSharedSpace,
      deleteDtoFromSharedSpaceWhenDeleteCollection,
      addDtoToSharedSpace,
      addNextOwnersToSharedSpace,
    }),
    [
      getSharedSpaces,
      getSharedSpace,
      getSharedSpaceDtoIds,
      getSharedSpaceDtoIdsWithTypes,
      addSharedSpace,
      renameSharedSpace,
      deleteSharedSpace,
      deleteDtoFromSharedSpace,
      deleteDtoFromSharedSpaceWhenDeleteCollection,
      addDtoToSharedSpace,
      addNextOwnersToSharedSpace,
    ],
  );
};
