import { DefaultIconsSize, IconType } from '@/shared/icons/types';

export const Clan = ({
  width = DefaultIconsSize.width,
  height = DefaultIconsSize.height,
  ...rest
}: IconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    width={`${width}px`}
    fill="#000000"
    {...rest}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7 2v11h3v9l7-12h-4l3-8z" />
  </svg>
);
