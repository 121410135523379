import { BankContactForm } from '@/shared/lib/forms/contacts/bank';
import { BrokerContactForm } from '@/shared/lib/forms/contacts/broker';
import { IndividualContactForm } from '@/shared/lib/forms/contacts/individual';
import { LegalEntityContactForm } from '@/shared/lib/forms/contacts/legal-entity';
import { PersonalContactForm } from '@/shared/lib/forms/contacts/personal';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';
import { ContactType } from '@/shared/lib/sj-orm/models/contact.dto';

export const ContactCategoryToText: Record<ContactType, string> = {
  [ContactType.INDIVIDUAL]: 'contacts:contactType.individual',
  [ContactType.LEGAL_ENTITY]: 'contacts:contactType.legalEntity',
  [ContactType.BANK]: 'contacts:contactType.bank',
  [ContactType.BROKER]: 'contacts:contactType.broker',
  [ContactType.PERSONAL]: 'contacts:contactType.personal',
};

type OmitedBaseDto = Omit<BaseDto, 'id'>;

export const ContactsForms: Record<
  ContactType,
  TDtoWrapper<OmitedBaseDto, NonNullable<unknown>> | null
> = {
  [ContactType.INDIVIDUAL]: IndividualContactForm,
  [ContactType.LEGAL_ENTITY]: LegalEntityContactForm,
  [ContactType.BANK]: BankContactForm,
  [ContactType.BROKER]: BrokerContactForm,
  [ContactType.PERSONAL]: PersonalContactForm,
};
