import { useCallback, useEffect, useMemo } from 'react';

import {
  AssetCategoryToText,
  AssetsForms,
} from '@/entities/assets/helpers/assets-helpers';
import { useSharedSpaces } from '@/entities/shared-spaces';
import {
  ScenarioStatus,
  useScenariosLazyQuery,
} from '@/shared/generated/graphql';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { AssetCategory, AssetDto } from '@/shared/lib/sj-orm/models/asset.dto';
import { BeneficiaryDto } from '@/shared/lib/sj-orm/models/beneficiary.dto';

export const useAssets = (props?: { delay: number } | undefined) => {
  const store = useSJDatabase();
  const { deleteDtoFromSharedSpaceWhenDeleteCollection } = useSharedSpaces();
  const [getScenarios, { data, loading: scenariosLoading }] =
    useScenariosLazyQuery();

  useEffect(() => {
    setTimeout(() => getScenarios(), props?.delay ?? 0);
  }, [getScenarios, props?.delay]);

  const getAssetIdFromScenario = (dtoIds?: string[] | null) => {
    return dtoIds?.find((dtoId) => dtoId.startsWith(CollectionName.ASSETS));
  };

  const attachNextOwnerToAsset = (asset: AssetDto) => {
    const scenario = data?.scenarios.find(
      (scenario_) => getAssetIdFromScenario(scenario_.dtoIds) === asset.id,
    );

    const nextOwner = store.collections.beneficiaries?.findOne((nextOnwer) =>
      scenario?.beneficiaries
        ? scenario?.beneficiaries
            .map((no) => no.beneficiaryId)
            .includes(nextOnwer.centralDbProfileId)
        : false,
    );

    return {
      ...asset,
      nextOwner,
    };
  };

  const getAssetsList = useCallback(
    (
      category: AssetCategory | 'all',
      includeTest: boolean = false,
    ): Array<AssetDto & { nextOwner: BeneficiaryDto | undefined }> => {
      if (category === 'all') {
        const result = [];

        for (const category_ of Object.keys(AssetCategoryToText)) {
          result.push(
            ...(store.collections.assets?.findMany((asset) => {
              if (includeTest) {
                return asset.category === category_;
              } else {
                return (
                  asset.category === category_ &&
                  (asset.isTest === undefined || !asset.isTest)
                );
              }
            }) || []),
          );
        }
        return result.map(attachNextOwnerToAsset);
      }

      return (
        store.collections.assets
          ?.findMany((asset) => asset.category === category)
          .map(attachNextOwnerToAsset) || []
      );
    },
    [store, data?.scenarios],
  );

  const getAsset = useCallback(
    (id: string) => {
      return store.collections.assets?.findOne((asset) => asset.id === id);
    },
    [store],
  );

  const getAssetInformation = useCallback(
    (id: string) => {
      return store.collections.assetInformationDocument?.findOne(
        (document) => document.assetDtoId === id,
      );
    },
    [store],
  );

  const getAssetContacts = useCallback(
    (id: string) => {
      return store.collections.contacts?.findMany(
        (contact) => contact.assetId === id,
      );
    },
    [store],
  );

  const getNumberOfAssetFormFields = useCallback(
    (id: string) => {
      const asset = store.collections.assets?.findOne((item) => item.id === id);
      if (asset) {
        const form = AssetsForms[asset.category]?.reduce(
          (r, c) => Object.assign(r, c),
          {},
        );
        return form ? Object.keys(form).length : undefined;
      } else {
        return undefined;
      }
    },
    [store],
  );

  const getAssetPrivateDocuments = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string) => {
      const privateDocumentsIds: string[] = [];
      const result = new Set();
      const asset = store.collections.assets?.findOne((item) => item.id === id);

      if (asset) {
        Object.values(asset).forEach((item) => {
          if (
            typeof item === 'string' &&
            item.startsWith('privateDocuments-')
          ) {
            privateDocumentsIds.push(item);
          } else if (Array.isArray(item)) {
            item.forEach((as) => {
              console.log({ as });
              if (
                typeof as === 'string' &&
                as?.startsWith('privateDocuments-')
              ) {
                privateDocumentsIds.push(as);
              }
            });
          }
        });

        if (privateDocumentsIds.length) {
          privateDocumentsIds.forEach((documentId) => {
            const privateDocument = store.collections.privateDocuments?.findOne(
              (document) => {
                return document.id === documentId;
              },
            );
            if (privateDocument) result.add(privateDocument);
          });
        }
        return result;
      } else {
        return undefined;
      }
    },
    [store],
  );

  const getAssetPersonalIdentifiers = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    (id: string) => {
      const personalIdentifiersDocumentsIds: string[] = [];
      const result = new Set();
      const asset = store.collections.assets?.findOne((item) => item.id === id);

      if (asset) {
        Object.values(asset).forEach((item) => {
          if (
            typeof item === 'string' &&
            item.startsWith('personalIdentifiersDocuments-')
          ) {
            personalIdentifiersDocumentsIds.push(item);
          } else if (Array.isArray(item)) {
            item.forEach((as) => {
              if (
                typeof as === 'string' &&
                as.startsWith('personalIdentifiersDocuments-')
              ) {
                personalIdentifiersDocumentsIds.push(as);
              }
            });
          }
        });

        if (personalIdentifiersDocumentsIds.length) {
          personalIdentifiersDocumentsIds.forEach((documentId) => {
            const personalIdentifier =
              store.collections.personalIdentifiersDocuments?.findOne(
                (document) => {
                  return document.id === documentId;
                },
              );
            if (personalIdentifier) result.add(personalIdentifier);
          });
        }
        return result;
      } else {
        return undefined;
      }
    },
    [store],
  );

  const deleteAsset = useCallback(
    (id: string) => {
      store.collections.assets?.remove({ id } as AssetDto);

      deleteDtoFromSharedSpaceWhenDeleteCollection(id);
    },
    [store],
  );

  const isAssetWithScenario = useCallback(
    (assetId: string, status?: ScenarioStatus) => {
      return !!data?.scenarios.some((s) => {
        const result = s.dtoIds?.includes(assetId);

        if (result) {
          return status ? s.status === status : true;
        }

        return result;
      });
    },
    [data],
  );

  const isAssetWithAcceptedScenario = useCallback(
    (assetId: string) => {
      return !!data?.scenarios.some(
        (s) => s.dtoIds?.includes(assetId) && s.status === ScenarioStatus.Done,
      );
    },
    [data],
  );

  const isAssetWithBeneficiary = useCallback(
    (assetId: string, beneficiaries: number[]) => {
      return !!data?.scenarios.some(
        (s) =>
          s.dtoIds?.includes(assetId) &&
          (s.beneficiaries
            ? beneficiaries.some(
                (no) =>
                  s.beneficiaries?.map((i) => i.beneficiaryId).includes(no),
              )
            : false),
      );
    },
    [data],
  );

  const allBeneficiariesWithScenarios = useMemo(() => {
    const beneficiaries: number[] = [];
    for (const el of data?.scenarios ?? []) {
      if (Array.isArray(el.beneficiaries)) {
        for (const no of el.beneficiaries) {
          beneficiaries.push(no.beneficiaryId);
        }
      }
    }

    return beneficiaries ? [...new Set(beneficiaries)] : [];
  }, [data]);

  const allCoOwners = useMemo(() => {
    const assets = store.collections.assets?.findMany(Boolean);

    if (!assets) return [];

    let coOwners: Array<string> = [];

    for (const asset of assets) {
      if (Array.isArray(asset.ownerDocument)) {
        coOwners = coOwners.concat(asset.ownerDocument);
      }
      if (Array.isArray(asset.coOwners)) {
        for (const coOwner of asset.coOwners) {
          coOwners = coOwners.concat(coOwner.coOwnerDocument);
        }
      }
    }

    if (!coOwners.length) return [];

    return [...new Set(coOwners)];
  }, [store]);

  const getAssetCoowners = useCallback(
    (assetId: string) => {
      const asset = store.collections.assets?.findOne(
        (asset_) => asset_.id === assetId,
      );

      if (!asset) return [];

      let coOwners: Array<string> = [];

      if (Array.isArray(asset.ownerDocument)) {
        coOwners = coOwners.concat(asset.ownerDocument);
      }
      if (Array.isArray(asset.coOwners)) {
        for (const coOwner of asset.coOwners) {
          coOwners = coOwners.concat(coOwner.coOwnerDocument);
        }
      }

      if (!coOwners.length) return [];

      return [...new Set(coOwners)];
    },
    [store],
  );

  const isAssetWithOwners = useCallback(
    (assetId: string, owners: string[]) => {
      const asset = store.collections.assets?.findOne((a) => a.id === assetId);

      if (!asset) return false;

      let coOwners: Array<string> = [];

      if (Array.isArray(asset.ownerDocument)) {
        coOwners = coOwners.concat(asset.ownerDocument);
      }
      if (Array.isArray(asset.coOwners)) {
        for (const coOwner of asset.coOwners) {
          coOwners = coOwners.concat(coOwner.coOwnerDocument);
        }
      }

      return coOwners.some((co) => owners.includes(co));
    },
    [store],
  );

  return useMemo(
    () => ({
      getAssetsList,
      getAsset,
      getAssetInformation,
      getAssetContacts,
      getAssetPrivateDocuments,
      getAssetPersonalIdentifiers,
      getNumberOfAssetFormFields,
      deleteAsset,
      isAssetWithScenario,
      isAssetWithBeneficiary,
      allBeneficiariesWithScenarios,
      allCoOwners,
      isAssetWithOwners,
      loading: scenariosLoading,
      getAssetCoowners,
      isAssetWithAcceptedScenario,
    }),
    [
      getAssetsList,
      getAsset,
      getAssetInformation,
      getAssetContacts,
      getAssetPrivateDocuments,
      getAssetPersonalIdentifiers,
      getNumberOfAssetFormFields,
      deleteAsset,
      isAssetWithScenario,
      isAssetWithBeneficiary,
      allBeneficiariesWithScenarios,
      allCoOwners,
      isAssetWithOwners,
      scenariosLoading,
      getAssetCoowners,
      isAssetWithAcceptedScenario,
    ],
  );
};
