import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { ScanOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { IIconButton } from '@/shared/components/icon-button/types';
import { COLORS } from '@/shared/constants/colors';
import { Browser, Filter, Sort } from '@/shared/icons';
import { AddIcon } from '@/shared/icons/misc/add';
import { ChatIcon } from '@/shared/icons/misc/chat';

const iconToElement: Record<string, JSX.Element> = {
  filter: <Filter />,
  sort: <Sort />,
  browser: <Browser />,
  add: <AddIcon />,
  scan: <ScanOutlined />,
  chat: <ChatIcon />,
};

export function IconButton({
  icon,
  link,
  onClick,
  shape = 'circle',
  size = 'medium',
  colorIcon,
  ...props
}: IIconButton): JSX.Element {
  const { push } = useRouter();

  const iconElement = useMemo(() => {
    const el = iconToElement[icon];
    if (el) return el;
    return icon;
  }, [icon]);

  const clickHandler = useCallback(async () => {
    if (onClick) onClick();
    if (link) await push(link);
  }, [link, onClick]);

  return (
    <Button {...props} onClick={clickHandler} shape={shape} size={size}>
      <Icon color={colorIcon}>{iconElement}</Icon>
    </Button>
  );
}

const Button = styled.button<{
  shape: 'rectangle' | 'circle';
  size: 'small' | 'medium';
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ shape, size }) =>
    size === 'small' ? '18px' : shape === 'rectangle' ? '32px' : '34px'};
  height: ${({ shape, size }) =>
    size === 'small' ? '18px' : shape === 'rectangle' ? '32px' : '34px'};
  border-radius: ${({ shape }) => (shape === 'rectangle' ? '8%' : '50%')};
  box-shadow: ${({ shape }) =>
    shape === 'rectangle'
      ? 'none'
      : '0 2.34307px 6.44343px 0 rgba(0, 0, 0, 0.25)'};
  background-color: transparent;
  border: 0;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.2s;
`;

const Icon = styled.div<{ color?: string }>`
  font-family: 'Material Icons', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: ${({ color }) => (color ? color : COLORS.black)};
`;
