import { PrivateKey } from '@/shared/lib/secure-json/core/crypto-core/types';
import { SecureJsonCollection } from '@/shared/lib/secure-json/core/secure-json-collection';

import { DecryptedData } from './secure-json-collection/types';
import { SecureJsonEncryptor } from './secure-json-encryptor';
import { SecureJsonSerializer } from './secure-json-serializer';

export class SecureJsonBase<T = DecryptedData> extends SecureJsonCollection<T> {
  public static encryptor = new SecureJsonEncryptor();
  public static serializer = new SecureJsonSerializer();

  constructor(private readonly privateKey: PrivateKey) {
    super();
  }

  public toString(): string {
    const encryptedCollection = SecureJsonBase.encryptor.encrypt(this);
    return SecureJsonBase.serializer.toString(encryptedCollection);
  }

  public fromString(serializedCollection: string): this {
    const encryptedCollection =
      SecureJsonBase.serializer.fromString(serializedCollection);

    const decryptedCollection = SecureJsonBase.encryptor.decrypt(
      encryptedCollection,
      this.privateKey,
    );

    this.items = decryptedCollection.items;
    return this;
  }

  public async fromStringAsync(serializedCollection: string): Promise<this> {
    const encryptedCollection =
      SecureJsonBase.serializer.fromString(serializedCollection);

    const decryptedCollection = await SecureJsonBase.encryptor.decryptAsync(
      encryptedCollection,
      this.privateKey,
    );

    this.items = decryptedCollection.items;
    return this;
  }
}
