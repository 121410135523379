import { CountriesList } from '@/shared/constants/countries-list';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { PersonalIdentifierDocumentDto } from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export enum BasisType {
  rental = 'rental',
  ownership = 'ownership',
}

const BasisList = [
  {
    label: 'Rental',
    key: BasisType.rental,
  },
  {
    label: 'Ownership',
    key: BasisType.ownership,
  },
];

export type SubstanceDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  index?: string;
  city?: string;
  streetBuilding?: string;
  basis?: BasisType;
  scan?: string;
  comments?: string;
};

export type SubstanceDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  index?: QuestionType.TEXT;
  city?: QuestionType.TEXT;
  streetBuilding?: QuestionType.TEXT;
  basis?: QuestionType.SELECT_FROM_LIST;
  scan?: QuestionType.FILE_UPLOAD;
  comments?: QuestionType.TEXT_MULTILINE;
};

type SubstanceFormType = TDtoWrapper<SubstanceDtoType, SubstanceDtoHelpType>;

export const SubstanceForm: SubstanceFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Title',
    required: true,
    placeholder: 'Enter document name',
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    placeholder: 'Select country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  names: {
    type: QuestionType.TEXT,
    label: '*First name(s)',
    placeholder: 'Enter your first name',
    required: true,
    value: '',
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Last name(s)',
    placeholder: 'Enter your last name',
    required: true,
    value: '',
  },
  index: {
    type: QuestionType.TEXT,
    label: 'Index',
  },
  city: {
    type: QuestionType.TEXT,
    label: 'City',
  },
  streetBuilding: {
    type: QuestionType.TEXT,
    label: 'Street, building',
  },
  basis: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'Basis',
    items: BasisList,
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My ToDo',
    tooltip:
      'All planned actions on an id, for example: renting out a house, paying annual membership fees, or any other relevant actions',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My ToDo Comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Basis document',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
