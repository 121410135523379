import { CountriesList } from '@/shared/constants/countries-list';
import {
  ProfileList,
  ProfileType,
} from '@/shared/lib/forms/documents/personal-IDs/passport';
import { TDtoWrapper } from '@/shared/lib/forms/form-wrapper.types';
import { dispathEvent } from '@/shared/lib/hooks/use-form-render/misc';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import {
  PersonalIdentifierDocumentDto,
  PersonalIdentifierType,
} from '@/shared/lib/sj-orm/models/document/personal-identifier.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

export type FreeBlockDtoType = Omit<
  PersonalIdentifierDocumentDto,
  'id' | 'personalIdentifierType' | 'type'
> & {
  name: string;
  names: string;
  surnames: string;
  country: string;
  profile: string;
  dateOfIssue?: number;
  endDate?: number;
  scan?: string;
  storageLocation?: string;
  comments?: string;
};

export type FreeBlockDtoHelpType = {
  name: QuestionType.TEXT;
  names: QuestionType.TEXT;
  surnames: QuestionType.TEXT;
  country: QuestionType.TEXT;
  profile: QuestionType.SELECT_FROM_LIST;
  dateOfIssue?: QuestionType.DATE_TIME;
  endDate?: QuestionType.DATE_TIME;
  scan?: QuestionType.FILE_UPLOAD;
  storageLocation?: QuestionType.TEXT;
  comments?: QuestionType.TEXT_MULTILINE;
};

type FreeBlockFormType = TDtoWrapper<FreeBlockDtoType, FreeBlockDtoHelpType>;

export const FreeBlockForm: FreeBlockFormType = {
  name: {
    type: QuestionType.TEXT,
    label: '*Title',
    placeholder: 'Enter document name',
    required: true,
    value: '',
    tooltip: 'Enter a document name for display within the system',
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Country',
    placeholder: 'Select country',
    items: CountriesList,
    withSearch: true,
    required: true,
    value: '',
  },
  profile: {
    type: QuestionType.SELECT_FROM_LIST,
    label: '*Profile',
    placeholder: 'Select profile',
    items: ProfileList,
    required: true,
    value: '',
    onChangeEvent: {
      name: 'profileChangeEvent',
    },
  },
  names: {
    type: QuestionType.TEXT,
    label: '*First name(s)',
    placeholder: 'Enter your first name',
    required: true,
    value: '',
    drawerOptions: {
      event: {
        name: 'profileChangeEvent',
        listener: (data, fn) => {
          fn(data.detail);
        },
      },
      items: {
        [ProfileType.mainOwner]: {
          items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          filter: (data) => {
            const item = data as PersonalIdentifierDocumentDto;
            return (
              item.personalIdentifierType ===
              PersonalIdentifierType.USER_DETAILS
            );
          },
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },
          dtoType: PersonalIdentifierType.USER_DETAILS,

          __single: true,
        },
        [ProfileType.nextOwner]: {
          items: CollectionName.BENEFICIARIES,
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.beneficiaries?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.firstName);
            setValue('surnames', item.lastName);
          },

          __single: true,
        },
      },
    },
  },
  surnames: {
    type: QuestionType.TEXT,
    label: '*Last name(s)',
    placeholder: 'Enter your last name',
    required: true,
    value: '',
    drawerOptions: {
      event: {
        name: 'profileChangeEvent',
        listener: (data, fn) => {
          fn(data.detail);
        },
      },
      items: {
        [ProfileType.mainOwner]: {
          items: CollectionName.PERSONAL_IDENTIFIERS_DOCUMENTS,
          filter: (data) => {
            const item = data as PersonalIdentifierDocumentDto;
            return (
              item.personalIdentifierType ===
              PersonalIdentifierType.USER_DETAILS
            );
          },
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },

          __single: true,
        },
        [ProfileType.nextOwner]: {
          items: CollectionName.BENEFICIARIES,
          onChange: ({ value, setValue, sjDb }) => {
            dispathEvent('chooseIdToAttach', value);
            const item = sjDb.collections.personalIdentifiersDocuments?.findOne(
              (i) => i.id === value,
            );

            if (!item) return;

            setValue('names', item.names ?? item.firstName);
            setValue('surnames', item.surnames ?? item.secondName);
          },

          __single: true,
        },
      },
    },
  },
  dateOfIssue: {
    type: QuestionType.DATE_TIME,
    label: 'Start date',
  },
  endDate: {
    type: QuestionType.DATE_TIME,
    label: 'End date',
  },
  myTodo: {
    type: QuestionType.CHECKBOX,
    label: 'My ToDo',
    tooltip:
      'All planned actions on an id, for example: renting out a house, paying annual membership fees, or any other relevant actions',
  },
  myTodoComments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'My ToDo Comments',
    showIf: {
      myTodo: {
        value: true,
      },
    },
  },
  scan: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Document scan',
  },
  storageLocation: {
    type: QuestionType.TEXT,
    label: 'Storage location',
  },
  comments: {
    type: QuestionType.TEXT_MULTILINE,
    label: 'Comments',
  },
};
