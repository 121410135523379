import { useCallback, useMemo } from 'react';

import { useMultiCurrencies } from '@/entities/currencies';
import { useCurrenciesListQuery } from '@/shared/generated/graphql';

type CalcCurrencyInputType = {
  from: string;
  to?: string;
  amount: number;
};

type CalculateCurrencyResult = {
  result: 'success' | 'error';
  value: number;
};

export const baseCurrencies = ['USD', 'EUR', 'RUB', 'AED', 'CNY'];

export const useCurrencies = () => {
  const { data } = useCurrenciesListQuery();
  const { getCurrency: getCurrencyMulti } = useMultiCurrencies();

  const getCurrency = useCallback(
    (symbol: string) => {
      return data?.currencyRates.find(
        (cur) => cur.currencyCode.toLowerCase() === symbol.toLowerCase(),
      );
    },
    [data],
  );

  const getBaseCurrencies = useMemo(() => {
    return data
      ? data.currencyRates.filter((cur) =>
          baseCurrencies.includes(cur.currencyCode),
        )
      : [];
  }, [data, baseCurrencies]);

  const calculateCurrency = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ({ from, to, amount }: CalcCurrencyInputType): CalculateCurrencyResult => {
      if (!amount) return { result: 'error', value: 0 };

      if (from === to) {
        return {
          value: amount,
          result: 'success',
        };
      }

      const curFrom = from ? getCurrency(from) : undefined;
      const curFromMulti = from ? getCurrencyMulti(from) : undefined;

      if (!curFrom)
        return {
          result: 'error',
          value: 0,
        };

      const curTo = to ? getCurrency(to) : undefined;
      const curToMulti = to ? getCurrencyMulti(to) : undefined;

      if (!curTo)
        return {
          result: 'error',
          value: 0,
        };

      const fromValue =
        curFromMulti && curFromMulti.isCustom
          ? +curFromMulti.value.toFixed(4)
          : curFrom?.currencyCode
          ? +curFrom.value.toFixed(4)
          : 1;

      const toValue =
        curToMulti && curToMulti.isCustom
          ? +curToMulti.value.toFixed(4)
          : curTo?.currencyCode
          ? +curTo.value.toFixed(4)
          : 1;

      return {
        value: (amount / +fromValue.toFixed(4)) * +toValue.toFixed(4),
        result: 'success',
      };
    },
    [getCurrency, data],
  );

  return useMemo(
    () => ({
      list: data?.currencyRates || [],
      getCurrency,
      getBaseCurrencies,
      calculateCurrency,
    }),
    [data, getCurrency, getBaseCurrencies, calculateCurrency],
  );
};
