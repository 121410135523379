import { IconType } from '@/shared/icons/types';

export const ChatIcon = ({ ...rest }: IconType) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M11.975 17.825C12.304 17.825 12.5821 17.7114 12.8093 17.4843C13.0364 17.2571 13.15 16.979 13.15 16.65C13.15 16.321 13.0364 16.0429 12.8093 15.8158C12.5821 15.5886 12.304 15.475 11.975 15.475C11.646 15.475 11.3679 15.5886 11.1408 15.8158C10.9136 16.0429 10.8 16.321 10.8 16.65C10.8 16.979 10.9136 17.2571 11.1408 17.4843C11.3679 17.7114 11.646 17.825 11.975 17.825ZM11.1 14.1H12.85C12.85 13.575 12.9146 13.1583 13.0438 12.85C13.1729 12.5417 13.5167 12.1167 14.075 11.575C14.5084 11.1583 14.8417 10.7563 15.075 10.3688C15.3084 9.98125 15.425 9.525 15.425 9C15.425 8.0908 15.0979 7.39267 14.4438 6.9056C13.7896 6.41854 13.0098 6.175 12.1044 6.175C11.1828 6.175 10.435 6.41459 9.861 6.89375C9.28702 7.37292 8.88336 7.95 8.65002 8.625L10.2303 9.225C10.3101 8.925 10.4938 8.60834 10.7814 8.275C11.0689 7.94167 11.5085 7.775 12.1 7.775C12.6334 7.775 13.0334 7.92084 13.3 8.2125C13.5667 8.50417 13.7 8.825 13.7 9.175C13.7 9.50834 13.6021 9.81667 13.4063 10.1C13.2104 10.3833 12.9667 10.65 12.675 10.9C11.9667 11.525 11.5271 12.0063 11.3563 12.3438C11.1854 12.6813 11.1 13.2667 11.1 14.1Z"
      fill="currentColor"
    />
    <path
      d="M4.25947 16.5917C3.14082 14.7059 2.74905 12.4766 3.15775 10.3224C3.56644 8.16824 4.74748 6.23734 6.47909 4.89231C8.2107 3.54728 10.3738 2.88064 12.5621 3.01758C14.7504 3.15452 16.8135 4.08562 18.3639 5.63603C19.9144 7.18644 20.8455 9.24952 20.9824 11.4379C21.1194 13.6262 20.4527 15.7893 19.1077 17.5209C17.7627 19.2525 15.8318 20.4335 13.6776 20.8422C11.5234 21.2509 9.29412 20.8592 7.40833 19.7405L4.29862 20.6289C4.16996 20.6657 4.03381 20.6674 3.90428 20.6338C3.77475 20.6003 3.65655 20.5327 3.56194 20.4381C3.46732 20.3434 3.39973 20.2252 3.36616 20.0957C3.3326 19.9662 3.33429 19.83 3.37105 19.7014L4.25947 16.5917Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
