import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';

const up = (store: Map<CollectionName, SecureJsonBase>) => {
  const assets = store.get(CollectionName.ASSETS);

  if (assets) {
    for (const [key, item] of assets?.items || []) {
      const value =
        item.decryptedData.purchaseValue &&
        (typeof item.decryptedData.purchaseValue === 'string' ||
          typeof item.decryptedData.purchaseValue === 'number')
          ? `${item.decryptedData.purchaseValue}`
          : undefined;
      assets.set({
        key,
        data: {
          ...item.decryptedData,
          purchaseValue: value
            ? {
                calculatedValue: value,
                from: 'USD',
                value: Number(value),
              }
            : item.decryptedData.purchaseValue,
        },
      });
    }
  }
};

export const migration005: TMigration = {
  version: '0.0.5',
  up,
};
