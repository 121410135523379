import {
  FormItemLabelType,
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { AssetsDocumentsPagePart } from '@/shared/lib/sj-orm/models/asset.dto';
import { PrivateDocumentCategory } from '@/shared/lib/sj-orm/models/document/private.document.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

type AssetsDocumentsPageHelpPart = {
  // title?: FormLabelItem.label;
  // description?: FormLabelItem.text;
  assetAcquisition?: QuestionType.PICK_ARRAY_OF_DTO;
  sourceAcquisition?: QuestionType.PICK_ARRAY_OF_DTO;
  methodProofOfOwnership?: QuestionType.PICK_ARRAY_OF_DTO;
  paymentMethod?: QuestionType.PICK_ARRAY_OF_DTO;
  powersOfAttorney?: QuestionType.PICK_ARRAY_OF_DTO;
  accessLimitations?: QuestionType.PICK_ARRAY_OF_DTO;
  assetProtection?: QuestionType.PICK_ARRAY_OF_DTO;
  otherDocumentsAndPhoto?: QuestionType.FILE_UPLOAD;
};

export type AssetsDocumentsPagePartType = TDtoWrapper<
  AssetsDocumentsPagePart,
  AssetsDocumentsPageHelpPart
>;

export const AssetsDocumentsPageFormPartFull: AssetsDocumentsPagePartType = {
  title: {
    type: FormLabelItem.label,
    label: 'Assets related documents', //todo translate labels
  },
  description: {
    type: FormLabelItem.text,
    label:
      'Link your asset with the necessary documents. It enables tracing its origins. This will simplify passing KYC procedures or crafting a Source of Wealth Essay.',
  },
  assetAcquisition: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Assets Acquisition',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  sourceAcquisition: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Source Acquisition',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  methodProofOfOwnership: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Method Proof Of Ownership (Primary document)',
    items: 'privateDocuments',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  paymentMethod: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Payment Method',
    items: 'privateDocuments',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  powersOfAttorney: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Powers of Attorney or other comparable legal documents',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.POWERS,
  },
  accessLimitations: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Access Limitations',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  assetProtection: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'Asset Protection',
    items: 'privateDocuments',
    mode: 'multiple',
    tooltip: 'tooltip',
    dtoType: PrivateDocumentCategory.BANK_DOCUMENTS,
  },
  otherDocumentsAndPhoto: {
    type: QuestionType.FILE_UPLOAD,
    label: 'Other documents and photo',
    tooltip: 'tooltip',
  },
};

export const AssetsDocumentsPageFormTextsPartFull: {
  [key: string]: FormItemLabelType;
} = {
  profitabilityText: {
    type: FormLabelItem.text,
    label: {
      prefix: 'forms_assets:brokerageAccount.inputs.investedAssets.label',
      fieldKey: 'purchaseValue',
      postfix: '',
    },
  },
};

export const assetsDocumentsPageBuilder = (
  excludeItems?: Array<keyof AssetsDocumentsPagePart>,
) => {
  if (!Array.isArray(excludeItems) || !excludeItems.length) {
    return {
      form: AssetsDocumentsPageFormPartFull,
      texts: AssetsDocumentsPageFormTextsPartFull,
    };
  }

  const copy = Object.assign({}, AssetsDocumentsPageFormPartFull);

  let cursor = 0;
  const length = excludeItems.length;

  while (cursor < length) {
    delete copy[excludeItems[cursor]];
    cursor++;
  }

  return {
    form: copy,
    texts: AssetsDocumentsPageFormTextsPartFull,
  };
};
