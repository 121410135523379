import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { Loader } from '@/shared/lib/synchronizer/loaders/types';
import { log } from '@/shared/utils/log';

import { SerializedCollection, SerializedCollections } from '../core/types';

export class FileLoader implements Loader {
  async push(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _collectionName: CollectionName,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _collection: SerializedCollection,
  ): Promise<boolean> {
    return true;
  }

  async pull(
    collectionName: CollectionName,
  ): Promise<SerializedCollection | null> {
    const data = await this.fetch();

    // convert json to next format: [{[collectionName]: {data: string}}]
    const result = {} as SerializedCollections;

    for (const record of data.collections) {
      result[<CollectionName>record.name] = {
        data: JSON.stringify(record.data),
      };
    }

    log.debug('File loader pull result', result);

    // eslint-disable-next-line security/detect-object-injection
    return result[collectionName]?.data;
  }

  async fetch() {
    const selfUrl = process.env.NEXT_PUBLIC_SELF_URL_BASE as string;
    const url = `${selfUrl}/database/database.json`;

    const response = await fetch(url);
    return await response.json();
  }
}
