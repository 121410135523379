import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';

export interface FileMetaInfoDto extends BaseDto {
  filePath: string; // example: "202311_28_09/e75216fefc267f80ccaace54e562865f.enc"
  name: string;
  extension: `.${string}`;
  source: Source;

  relatedDto?: DtoId | undefined;
}

export enum Source {
  FRONTEND = 'FRONTEND',
  EMAIL = 'EMAIL',
}
