import {
  type MutableRefObject,
  // useCallback,
  useEffect,
  // useRef,
  useState,
} from 'react';
import {
  Control,
  ControllerRenderProps,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Drawer as ANTDDrawer, Select, Typography } from 'antd';
// import { FilterFunc } from 'rc-select/es/Select';
import styled from 'styled-components';

import { COLORS } from '@/shared/constants/colors';
import { resolveCountry } from '@/shared/constants/countries-list';
import { CustomDrawerFilter } from '@/shared/lib/forms/form-wrapper.types';
// import { separator } from '@/shared/lib/hooks/use-form-render-old/constants';
import {
  CollectionName,
  CollectionsTypeValueMap,
  ICollections,
} from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { ArrayOrOne } from '@/shared/types/utility-types';

// import { isEmpty } from '@/shared/utils/misc';
import { Icon } from '../../icon';
import { Input } from '../../input';
import { Container, Row } from '../../layout';

export const DrawerPickArrayOfDto = <
  K extends CollectionName,
  T extends CollectionsTypeValueMap[K],
>({
  label,
  control,
  collection,
  name,
  filter = (item) => !!item,
  mode,
  memoValue,
  setValue,
  // deps,
  type,
  isOpen,
  setIsOpen,
  field,
  takenDtos,
  customDrawerFilter,
}: {
  label: string;
  required?: boolean;
  control?: Control;
  collection: ArrayOrOne<keyof ICollections>;
  name: string;
  filter?: (item: T) => boolean;
  mode?: 'multiple' | 'tags';
  tooltip?: string;
  memoValue: MutableRefObject<Record<string, unknown>>;
  setValue: UseFormSetValue<Record<typeof name, unknown>>;
  deps?: Array<string>;
  type?: string;
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  field?: ControllerRenderProps<FieldValues, string>;
  takenDtos?: string[];
  customDrawerFilter?: CustomDrawerFilter;
}) => {
  const { t } = useTranslation([
    'common',
    'assets',
    'forms_assets',
    'forms_contacts',
  ]);
  const [inputSearch, setInputSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState<Array<string>>([]);
  const [lastList, setLastList] = useState<object[] | null>(null);

  const filterTakenDtos = (item: {
    id: string;
    name?: string;
    nickname?: string;
    nickName?: string;
    shortname?: string;
    createdAt?: Date;
    country?: string;
    isTest?: boolean;
  }) => {
    return takenDtos ? !takenDtos.includes(item.id) : true;
  };

  const sjDb = useSJDatabase();
  let dtos = Array.isArray(collection)
    ? (collection as Array<keyof ICollections>)
        .map(
          (col) =>
            sjDb.collections[col]
              ?.findMany(
                (item: {
                  id: string;
                  name?: string;
                  nickname?: string;
                  nickName?: string;
                  shortname?: string;
                  createdAt?: Date;
                  country?: string;
                  isTest?: boolean;
                }) =>
                  !!(
                    item.name ||
                    item.nickName ||
                    item.nickname ||
                    item.shortname
                  ) &&
                  filter(item as T) &&
                  !item.isTest &&
                  filterTakenDtos(item),
              )
              .map(
                (item: {
                  id: string;
                  name?: string;
                  nickname?: string;
                  nickName?: string;
                  shortname?: string;
                  createdAt?: Date;
                  selectedCountry?: string;
                }) => ({
                  value: item.id,
                  label:
                    item.name ||
                    item.nickName ||
                    item.nickname ||
                    item.shortname,
                  createdAt: item.createdAt,
                  country: item.selectedCountry,
                }),
              ) || [],
        )
        .flat()
    : sjDb.collections[collection as keyof ICollections]
        ?.findMany(
          (item: {
            id: string;
            name?: string;
            nickname?: string;
            nickName?: string;
            shortname?: string;
            createdAt?: Date;
            selectedCountry?: string;
            isTest?: boolean;
          }) =>
            !!(item.name || item.nickName || item.nickname || item.shortname) &&
            filter(item as T) &&
            !item.isTest &&
            filterTakenDtos(item),
        )
        .map(
          (item: {
            id: string;
            name?: string;
            nickname?: string;
            nickName?: string;
            shortname?: string;
            createdAt?: Date;
            selectedCountry?: string;
          }) => ({
            value: item.id,
            label:
              item.name || item.nickName || item.nickname || item.shortname,
            createdAt: item.createdAt,
            country: item.selectedCountry,
          }),
        ) || [];

  const [filters, setFilters] = useState(
    new Array(customDrawerFilter?.options.length).fill(undefined),
  );
  const handleFilterChange = (value: string, index: number) => {
    const newFilters = [...filters];
    newFilters[index] = value;
    setFilters(newFilters);
  };

  const resetFilters = () => {
    const resetedFilters = filters.map(() => undefined);
    setFilters(resetedFilters);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetFilters();
  };

  if (customDrawerFilter) {
    dtos = customDrawerFilter.filterFunc({
      items: dtos,
      sjDb,
      filters: filters,
    });
  }

  // const filterOption: FilterFunc<{
  //   label: string | undefined;
  //   value: string;
  // }> = (input: string, option?: { label: string | undefined; value: string }) =>
  //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  // const isValueSetted = useRef(false);

  const inputHandler = (e: { target: { value: string } }) => {
    const lowerCase = e.target.value.toLowerCase().trim();
    setInputSearch(lowerCase);
  };

  const filteredData = dtos.filter((el) => {
    if (inputSearch === '') {
      return el;
    } else if (el.label) {
      return el.label.toLowerCase().includes(inputSearch);
    } else return el;
  });

  useEffect(() => {
    if (
      lastList === null &&
      (Array.isArray(collection) || sjDb.collections[collection] !== undefined)
    ) {
      setLastList(filteredData);
      return;
    }

    if (lastList !== null && lastList.length !== filteredData.length) {
      const item = filteredData[filteredData.length - 1];
      if (item) {
        handleSelect(item.value);
      }
      setLastList(filteredData);
    }
  }, [filteredData]);

  useEffect(() => {
    if (memoValue.current[name] && !control?._formValues[name]) {
      setValue(name, memoValue.current[name]);
    }
  }, [memoValue.current, name]);

  // const compileDepsFunction = useCallback(
  //   (value: unknown, formValues: Record<string, unknown>) => {
  //     if (!deps?.length) return true;

  //     const realNameArr = name.split(separator);
  //     if (realNameArr.length === 1) {
  //       return isEmpty(value) &&
  //         !isEmpty(
  //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //           // @ts-ignore
  //           formValues[deps[0]],
  //         )
  //         ? 'Required field'
  //         : true;
  //     } else {
  //       return isEmpty(value) &&
  //         !isEmpty(
  //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //           // @ts-ignore
  //           formValues[realNameArr[0]]?.[realNameArr[1]]?.[deps[0]]?.[
  //             realNameArr[3]
  //           ],
  //         )
  //         ? 'Required field'
  //         : true;
  //     }
  //   },
  //   [deps, name],
  // );

  const onSelectChange = (
    value: unknown,
    fieldFn?: (args: unknown) => void,
  ) => {
    memoValue.current[name] = value;
    fieldFn?.(value);
    setValue?.(name, value);
  };

  const handleSelect = (value: string) => {
    if (mode === 'multiple') {
      let result = selectedItem;
      if (selectedItem.includes(value)) {
        result = selectedItem.filter((i) => i !== value);
      } else {
        result.push(value);
      }
      setSelectedItem(result);
      onSelectChange(result, field?.onChange);
      return;
    }
    setSelectedItem([value]);
    onSelectChange(value, field?.onChange);
  };

  const router = useRouter();
  const onAddNewClick = async () => {
    const collectionToCreate = Array.isArray(collection)
      ? collection[0]
      : collection;
    if (!type && collectionToCreate !== CollectionName.BENEFICIARIES) return;
    await router.replace({
      pathname: window.location.pathname,
      query: {
        fastAdd: `${collectionToCreate}:${type}`,
        fastTrack: true,
      },
    });
  };

  const onSelectClick = () => {
    setIsOpen(false);
  };

  return (
    <StyledANTDDrawer
      height={'80vh'}
      placement="bottom"
      open={isOpen}
      onClose={handleClose}
      closeIcon={false}
      footer={
        <Row direction="column" gap={12} paddingX={14}>
          <Button
            type="primary"
            size="large"
            onClick={onSelectClick}
            disabled={!selectedItem}
          >
            Select
          </Button>

          <Button size="large" onClick={onAddNewClick}>
            {t('common:Add')}
          </Button>
        </Row>
      }
    >
      <Row justifyContent="space-between" alignCenter nowrap>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {label}
        </Typography.Title>

        <Icon
          icon="close"
          cursorPointer
          onClick={handleClose}
          color={COLORS.colorLink}
        />
      </Row>

      <Container marginTop={24}>
        <Input placeholder="Search" onChange={inputHandler} />
      </Container>

      {customDrawerFilter && (
        <>
          <Typography.Title level={5} style={{ marginTop: '24px' }}>
            Filter
          </Typography.Title>
          <Container
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              gap: '24px',
            }}
          >
            {customDrawerFilter.options.map((options, index: number) => (
              <Container key={index} style={{ width: '100%' }}>
                <Select
                  value={filters[index]}
                  onChange={(value) => handleFilterChange(value, index)}
                  placeholder={options.placeholder}
                  options={options.items}
                  style={{ width: '100%' }}
                />
              </Container>
            ))}
          </Container>
        </>
      )}

      <Container
        marginTop={24}
        paddingLeft={8}
        className="scrollbar"
        style={{
          overflow: 'hidden scroll',
          maxWidth: '100%',
          maxHeight: '34vh',
        }}
      >
        {filteredData.length === 0 ? (
          <p>
            There is no data added yet, fill the info in the contact section
          </p>
        ) : (
          filteredData.map((item, index) => (
            <div key={index} onClick={() => handleSelect(item.value)}>
              <ListItem
                selectedItem={selectedItem}
                title={item?.label}
                date={item.createdAt}
                country={item?.country}
                value={item?.value}
              />
            </div>
          ))
        )}
      </Container>
    </StyledANTDDrawer>
  );
};

const ListItem = ({
  title,
  date,
  country,
  value,
  selectedItem,
}: {
  title: string | undefined;
  date?: Date;
  country?: string;
  value?: string;
  selectedItem: Array<string>;
}) => {
  const formattedDate = date
    ?.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    })
    .replace(/ /g, ' ');

  return (
    <Item nowrap gap={8}>
      <IconListItem>
        <InfoCircleOutlined style={{ fontSize: 20, color: '#605844' }} />
      </IconListItem>

      <Container width="100%">
        <Row nowrap justifyContent="space-between" alignCenter>
          <Typography.Text strong>{title}</Typography.Text>

          {!!value && selectedItem.includes(value) && (
            <CheckOutlined style={{ fontSize: 18, color: '#605844' }} />
          )}
        </Row>

        <Row nowrap alignCenter>
          <Typography.Text type="secondary">
            {formattedDate} •&nbsp;
          </Typography.Text>
          <Typography.Text type="secondary">
            {country && resolveCountry(country, true, false)}
          </Typography.Text>
        </Row>
      </Container>
    </Item>
  );
};

const StyledANTDDrawer = styled(ANTDDrawer)`
  .ant-drawer-body::-webkit-scrollbar {
    display: none !important;
  }

  .ant-drawer-body {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }
  .ant-drawer-footer {
    padding: 24px !important;
    border-top: unset !important;
  }
`;

const IconListItem = styled.div`
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #5959561f;
`;

const Item = styled(Row)`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
