import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Flex, Row, Typography } from 'antd';

import { useShared } from '@/entities/shared/use-shared';
import { getDtoRelatedToTargetDto } from '@/shared/lib/helpers/asset.helper';
import { Permission } from '@/shared/lib/secure-json/core/secure-json-collection/types';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { useSJDatabase } from '@/shared/lib/sj-orm/hooks/use-sj-database';
import { BaseDto } from '@/shared/lib/sj-orm/models/base.dto';

import { Icon } from '../../icon';
import { Container } from '../../layout';

export const ShareStopDrawer = ({
  isOpen,
  onClose,
  collection,
  item,
  publicKey,
}: {
  isOpen: boolean;
  onClose: (arg: boolean) => void;
  collection: CollectionName;
  item: string;
  publicKey: string;
}) => {
  const onCloseModal = () => {
    onClose(!isOpen);
  };

  const sjDb = useSJDatabase();
  const { deleteShared } = useShared();

  const onStopSharing = () => {
    const shareItem = sjDb.collections[collection]?.findOne(
      (i) => i.id === item,
    );

    if (!shareItem) return;

    const relatedItems = getDtoRelatedToTargetDto(sjDb, shareItem);
    const resultMap = new Map<CollectionName, Array<BaseDto>>();
    for (const [key, value] of relatedItems) {
      resultMap.set(key, Array.from(value));
    }

    for (const [col, dtos] of resultMap.entries()) {
      for (const dto of dtos) {
        sjDb.collections[col]?.deletePublicKey(dto.id, publicKey);
        sjDb.collections[col]?.deletePermission(
          dto.id,
          publicKey,
          Permission.READ,
        );
        sjDb.collections[col]?.deletePermission(
          dto.id,
          publicKey,
          Permission.WRITE,
        );
      }
    }

    sjDb.collections[collection]?.deletePublicKey(item, publicKey);
    sjDb.collections[collection]?.deletePermission(
      item,
      publicKey,
      Permission.READ,
    );
    sjDb.collections[collection]?.deletePermission(
      item,
      publicKey,
      Permission.WRITE,
    );

    deleteShared(item);

    onCloseModal();
  };

  return (
    <Drawer
      placement={'bottom'}
      open={isOpen}
      onClose={onCloseModal}
      closable={false}
      height={'auto'}
    >
      <Row justify={'space-between'} align={'middle'}>
        <Typography.Title level={4} style={{ margin: 0 }}>
          Stop sharing?
        </Typography.Title>

        <Icon
          icon="close"
          cursorPointer
          onClick={onCloseModal}
          color="rgba(6, 1, 54, 1)"
        />
      </Row>

      <Container marginTop={8}>
        <Flex gap={8} align="flex-start">
          <InfoCircleOutlined
            style={{ fontSize: 24, color: 'rgba(6, 1, 54, 1)' }}
          />

          <Typography.Text>
            If you stop sharing, selected Next Owner will not be able to see
            asset, document or contact information and edit it
          </Typography.Text>
        </Flex>
      </Container>

      <Container marginTop={24}>
        <Button block size="large" type="primary" onClick={onStopSharing}>
          Stop sharing
        </Button>
      </Container>
    </Drawer>
  );
};
