import { migration001 } from './migration-0.0.1';
import { migration002 } from './migration-0.0.2';
import { migration003 } from './migration-0.0.3';
import { migration004 } from './migration-0.0.4';
import { migration005 } from './migration-0.0.5';
import { migration006 } from './migration-0.0.6';
import { migration007 } from './migration-0.0.7';
import { migration008 } from './migration-0.0.8';
import { migration009 } from './migration-0.0.9';
import { migration0010 } from './migration-0.0.10';

/**
 * List of migrations
 * ORDER within migration type IS IMPORTANT!
 */

export default [
  migration001,
  migration002,
  migration003,
  migration004,
  migration005,
  migration006,
  migration007,
  migration008,
  migration009,
  migration0010,
];
