import { TMigration } from '@/shared/lib/migrator/types';
import { SecureJsonBase } from '@/shared/lib/secure-json/core/secure-json-base';
import { CollectionName } from '@/shared/lib/sj-orm/constants';
import { log } from '@/shared/utils/log';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const up = (_store: Map<CollectionName, SecureJsonBase>) => {
  log.trace('Init migration applied');
};

export const migration001: TMigration = {
  version: '0.0.1',
  up: up,
};
