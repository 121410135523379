import { CSSProperties } from 'react';

export const ExpandIcon = ({ style }: { style: CSSProperties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect width="24" height="24" rx="8" fill="#FAF9F5" />
    <path
      d="M16 10.47L15.06 9.53003L12 12.5834L8.94 9.53003L8 10.47L12 14.47L16 10.47Z"
      fill="#111111"
    />
  </svg>
);
