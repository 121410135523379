// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable sonarjs/no-duplicate-string,@typescript-eslint/no-explicit-any */
import * as Comlink from 'comlink';

import { IWorker } from '../../../../../../worker';
import { CryptoCore } from '../crypto-core';
import { PrivateKey } from '../crypto-core/types';
import { SecureJsonCollection } from '../secure-json-collection';
import { Value } from '../secure-json-collection/types';
import { SecureJsonSerializer } from '../secure-json-serializer';

export class SecureJsonEncryptor {
  private cryptoCore = new CryptoCore();
  private wrappedWorker: Comlink.Remote<IWorker> | undefined;

  private readonly worker: Worker | undefined;

  constructor() {
    if (typeof window === 'undefined') {
      return;
    }
    this.worker = new Worker(
      new URL('../../../../../../worker/index.ts', import.meta.url),
    );
    this.wrappedWorker = Comlink.wrap<IWorker>(this.worker);
  }

  private stringifyWithCircular(
    obj: any,
    replacer?: (key: string, value: any) => any,
    space?: string | number,
  ): string {
    const seen = new WeakSet();

    return JSON.stringify(
      obj,
      (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return '[Circular Reference]';
          }
          seen.add(value);
        }
        if (replacer) {
          return replacer(key, value);
        }
        return value;
      },
      space,
    );
  }

  public encrypt(collection: SecureJsonCollection): SecureJsonCollection {
    const encryptedCollection = new SecureJsonCollection();
    encryptedCollection.items = new Map();

    for (const [key, item] of collection.items.entries()) {
      if (!item.decryptedData && !item.encryptedData) {
        throw new Error('Item does not have decrypted and encrypted data');
      }

      if (item.decryptedData && !item.decryptedSymmetricKey) {
        throw new Error(
          'Item does not have decrypted data and decrypted symmetric key',
        );
      }

      const newValue: Value = {
        encryptedSymmetricKeys: item.encryptedSymmetricKeys,
        permissions: item.permissions,
      };

      if (item.encryptedData) {
        newValue.encryptedData = item.encryptedData;
      } else {
        if (!item.decryptedSymmetricKey) {
          throw new Error('Item does not have decrypted symmetric key');
        }
        newValue.encryptedData = this.cryptoCore.aes.encrypt(
          this.stringifyWithCircular(item.decryptedData),
          item.decryptedSymmetricKey,
        );
      }

      encryptedCollection.items.set(key, newValue);
    }

    return encryptedCollection;
  }

  public encryptItem(item: Value): Value {
    if (!item.decryptedData && !item.encryptedData) {
      throw new Error('Item does not have decrypted and encrypted data');
    }

    if (item.decryptedData && !item.decryptedSymmetricKey) {
      throw new Error(
        'Item does not have decrypted data and decrypted symmetric key',
      );
    }

    const newValue: Value = {
      encryptedSymmetricKeys: item.encryptedSymmetricKeys,
      permissions: item.permissions,
    };

    if (item.encryptedData) {
      newValue.encryptedData = item.encryptedData;
    } else {
      if (!item.decryptedSymmetricKey) {
        throw new Error('Item does not have decrypted symmetric key');
      }
      newValue.encryptedData = this.cryptoCore.aes.encrypt(
        this.stringifyWithCircular(item.decryptedData),
        item.decryptedSymmetricKey,
      );
    }

    return newValue;
  }

  public decrypt(
    encryptedCollection: SecureJsonCollection,
    privateKey: PrivateKey,
  ): SecureJsonCollection {
    const publicKey = this.cryptoCore.rsa.derivePublicKey(privateKey);
    const decryptedCollection = new SecureJsonCollection();
    decryptedCollection.items = new Map();

    for (const [key, item] of encryptedCollection.items.entries()) {
      if (!item.encryptedData) {
        throw new Error('Item does not have encrypted data');
      }

      const newValue: Value = {
        encryptedSymmetricKeys: item.encryptedSymmetricKeys,
        permissions: item.permissions,
      };

      const encryptedSymmetricKey = item.encryptedSymmetricKeys.get(publicKey);
      if (!encryptedSymmetricKey) {
        newValue.encryptedData = item.encryptedData;
        decryptedCollection.items.set(key, newValue);
        continue;
      }

      const decryptedSymmetricKey = this.cryptoCore.rsa.decrypt(
        encryptedSymmetricKey,
        privateKey,
      );

      newValue.decryptedSymmetricKey = decryptedSymmetricKey;
      newValue.decryptedData = JSON.parse(
        this.cryptoCore.aes.decrypt(item.encryptedData, decryptedSymmetricKey),
        (_, value): unknown => {
          // eslint-disable-next-line camelcase
          if (
            typeof value === 'string' &&
            SecureJsonSerializer.isISO8601_Z.test(value)
          ) {
            return new Date(value);
          }

          return value;
        },
      );

      decryptedCollection.items.set(key, newValue);
    }

    return decryptedCollection;
  }

  public async decryptAsync(
    encryptedCollection: SecureJsonCollection,
    privateKey: PrivateKey,
  ) {
    if (!this.wrappedWorker) {
      throw new Error('Worker is not initialized');
    }

    const publicKey = await this.wrappedWorker.rsaDerivePublicKey(privateKey);
    const decryptedCollection = new SecureJsonCollection();
    decryptedCollection.items = new Map();

    for (const [key, item] of encryptedCollection.items.entries()) {
      if (!item.encryptedData) {
        throw new Error('Item does not have encrypted data');
      }

      const newValue: Value = {
        encryptedSymmetricKeys: item.encryptedSymmetricKeys,
        permissions: item.permissions,
      };

      const encryptedSymmetricKey = item.encryptedSymmetricKeys.get(publicKey);
      if (!encryptedSymmetricKey) {
        newValue.encryptedData = item.encryptedData;
        decryptedCollection.items.set(key, newValue);
        continue;
      }

      const decryptedSymmetricKey = await this.wrappedWorker.rsaDecrypt(
        encryptedSymmetricKey,
        privateKey,
      );

      newValue.decryptedSymmetricKey = decryptedSymmetricKey;
      newValue.decryptedData = JSON.parse(
        await this.wrappedWorker.aesDecrypt(
          item.encryptedData,
          decryptedSymmetricKey,
        ),
        (_, value): unknown => {
          // eslint-disable-next-line camelcase
          if (
            typeof value === 'string' &&
            SecureJsonSerializer.isISO8601_Z.test(value)
          ) {
            return new Date(value);
          }

          return value;
        },
      );

      decryptedCollection.items.set(key, newValue);
    }

    return decryptedCollection;
  }
}
