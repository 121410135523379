import {
  assetsOwnershipBuilder,
  AssetsOwnershipPartType,
} from 'src/entities/assets/helpers/assets-ownership-builder';
import {
  assetsStatisticsBuilder,
  AssetsStatisticsPartType,
} from 'src/entities/assets/helpers/assets-statistics-builder';
import * as yup from 'yup';

import {
  assetsDocumentsPageBuilder,
  AssetsDocumentsPagePartType,
} from '@/entities/assets/helpers/assets-documents-page-builder';
import { CountriesList } from '@/shared/constants/countries-list';
import {
  FormLabelItem,
  TDtoWrapper,
} from '@/shared/lib/forms/form-wrapper.types';
import { BaseDto, DtoId } from '@/shared/lib/sj-orm/models/base.dto';
import { QuestionType } from '@/shared/lib/sj-orm/models/form/question.dto';

import { AssetCategory, AssetDto } from '../../sj-orm/models/asset.dto';
import { ContactType } from '../../sj-orm/models/contact.dto';

export type Land = LandFormPart1Type &
  LandFormPart2Type &
  AssetsOwnershipPartType &
  AssetsStatisticsPartType;

export enum PermittedUseType {
  recreationalAndSanitary = 'recreationalAndSanitary',
  industrial = 'industrial',
  agricultural = 'agricultural',
  residentialDevelopment = 'residentialDevelopment',
  public = 'public',
  commercial = 'commercial',
  commonUseLand = 'commonUseLand',
  generalPurposeLandPlots = 'generalPurposeLandPlots',
  other = 'other',
}

const PermittedUseList = [
  {
    label: 'forms_assets:land.enums.useType.recreationalAndSanitary',
    key: PermittedUseType.recreationalAndSanitary,
  },
  {
    label: 'forms_assets:land.enums.useType.industrial',
    key: PermittedUseType.industrial,
  },
  {
    label: 'forms_assets:land.enums.useType.agricultural',
    key: PermittedUseType.agricultural,
  },
  {
    label: 'forms_assets:land.enums.useType.residentialDevelopment',
    key: PermittedUseType.residentialDevelopment,
  },
  {
    label: 'forms_assets:land.enums.useType.public',
    key: PermittedUseType.public,
  },
  {
    label: 'forms_assets:land.enums.useType.commercial',
    key: PermittedUseType.commercial,
  },
  {
    label: 'forms_assets:land.enums.useType.commonUseLand',
    key: PermittedUseType.commonUseLand,
  },
  {
    label: 'forms_assets:land.enums.useType.generalPurposeLandPlots',
    key: PermittedUseType.generalPurposeLandPlots,
  },
  {
    label: 'forms_assets:land.enums.useType.other',
    key: PermittedUseType.other,
  },
];

type LandPart1 = Omit<BaseDto, 'id'> & {
  nickName: string;
  country: string;
  purchaseValue: number;
};

type LandPart2 = Omit<BaseDto, 'id'> & {
  numberName?: string;
  number?: number;
  additionalNumber: number;
  // otherAddressInfo?: string;
  commencementDate?: string;
  landArea?: number;
  permittedUse?: PermittedUseType;
  otherPermittedUse?: string;
  compositionOfObjects?: string;
  buildingInformationOnTheLandPlot?: boolean;
  chooseBuilding?: DtoId[];
  contacts?: DtoId[];
  district?: string;
  addressLabel?: string;
  index?: string;
  city?: string;
  street?: string;
  buildingNumber?: string;
  apartmentNumber?: string;
};

type LandHelpPart1 = {
  nickName: QuestionType.TEXT;
  country: QuestionType.SELECT_FROM_LIST;
  purchaseValue: QuestionType.CURRENCY_CALCULATOR;
};

type LandHelpPart2 = {
  numberName?: QuestionType.TEXT;
  number?: QuestionType.NUMBER;
  additionalNumber: QuestionType.NUMBER;
  // otherAddressInfo?: QuestionType.TEXT_MULTILINE;
  commencementDate?: QuestionType.DATE_TIME;
  landArea?: QuestionType.NUMBER;
  permittedUse?: QuestionType.SELECT_FROM_LIST;
  otherPermittedUse?: QuestionType.TEXT;
  compositionOfObjects?: QuestionType.TEXT;
  buildingInformationOnTheLandPlot?: QuestionType.CHECKBOX;
  chooseBuilding?: QuestionType.PICK_ARRAY_OF_DTO;
  contacts?: QuestionType.PICK_ARRAY_OF_DTO;
  district?: QuestionType.TEXT;
  index?: QuestionType.TEXT;
  city?: QuestionType.TEXT;
  street?: QuestionType.TEXT;
  buildingNumber?: QuestionType.TEXT;
  apartmentNumber?: QuestionType.TEXT;
};

type LandFormType = [
  LandFormPart1Type,
  LandFormPart2Type,
  AssetsOwnershipPartType,
  AssetsStatisticsPartType,
  AssetsDocumentsPagePartType,
];

type LandFormPart1Type = TDtoWrapper<LandPart1, LandHelpPart1>;
type LandFormPart2Type = TDtoWrapper<LandPart2, LandHelpPart2>;

const LandFormPart1: LandFormPart1Type = {
  nickName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:common.inputs.nickname.label',
    value: '',
    required: true,
    yupScheme: yup
      .string()
      .required('forms_assets:common.inputs.nickname.errors.required'),
  },
  country: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:common.inputs.country.label',
    tooltip: 'forms_assets:common.inputs.country.tooltip',
    value: '',
    required: true,
    items: CountriesList,
    withSearch: true,
  },
  purchaseValue: {
    type: QuestionType.CURRENCY_CALCULATOR,
    label: 'forms_assets:land.inputs.purchaseValue.label',
    tooltip: 'forms_assets:common.inputs.purchaseValue.tooltip',
    equivalentValueTooltip:
      'forms_assets:common.inputs.equivalentValue.tooltip',
    value: 0,
    required: true,
  },
};

const LandFormPart2: LandFormPart2Type = {
  numberName: {
    type: QuestionType.TEXT,
    label: 'forms_assets:land.inputs.numberName.label',
    order: 1,
  },
  number: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:land.inputs.number.label',
    order: 2,
    min: 0,
  },
  additionalNumber: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:land.inputs.additionalNumber.label',
    order: 2,
    min: 0,
  },
  // otherAddressInfo: {
  //   type: QuestionType.TEXT_MULTILINE,
  //   label: 'forms_assets:land.inputs.otherAddressInfo.label',
  // },
  commencementDate: {
    type: QuestionType.DATE_TIME,
    label: 'forms_assets:land.inputs.commencementDate.label',
    tooltip: 'forms_assets:land.inputs.commencementDate.tooltip',
    order: 3,
  },
  permittedUse: {
    type: QuestionType.SELECT_FROM_LIST,
    label: 'forms_assets:land.inputs.permittedUse.label',
    items: PermittedUseList,
    order: 4,
  },
  otherPermittedUse: {
    type: QuestionType.TEXT,
    label: 'forms_assets:land.inputs.otherPermittedUse.label',
    showIf: {
      permittedUse: {
        value: PermittedUseType.other,
      },
    },
    order: 4,
  },
  landArea: {
    type: QuestionType.NUMBER,
    label: 'forms_assets:land.inputs.landArea.label',
    order: 5,
    min: 0,
  },
  compositionOfObjects: {
    type: QuestionType.TEXT,
    label: 'forms_assets:land.inputs.compositionOfObjects.label',
    order: 6,
  },
  buildingInformationOnTheLandPlot: {
    type: QuestionType.CHECKBOX,
    label: 'forms_assets:land.inputs.buildingInformationOnTheLandPlot.label',
    order: 7,
  },
  chooseBuilding: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:land.inputs.chooseBuilding.label',
    items: 'assets',
    mode: 'multiple',
    showIf: {
      buildingInformationOnTheLandPlot: {
        value: true,
      },
    },
    filter: (data) => {
      const item = data as AssetDto;
      return (
        item.category === 'COMMERCIAL_REAL_ESTATE' ||
        item.category === 'RESIDENTIAL_REAL_ESTATE'
      );
    },
    dtoType: AssetCategory.RESIDENTIAL_REAL_ESTATE,
    order: 8,
  },
  contacts: {
    type: QuestionType.PICK_ARRAY_OF_DTO,
    label: 'forms_assets:common.inputs.contacts.label',
    items: 'contacts',
    mode: 'multiple',
    order: 9,
    dtoType: ContactType.BANK,
  },
  addressLabel: {
    type: FormLabelItem.label,
    label: 'forms_assets:common.inputs.addressLabel.label',
    tooltip: 'forms_assets:common.inputs.addressLabel.tooltip',
  },
  district: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:land.inputs.district.label',
    },
    value: '',
  },
  city: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.city.label',
    },
    value: '',
  },
  street: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.street.label',
    },
    value: '',
  },
  buildingNumber: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.buildingNumber.label',
    },
    value: '',
  },
  apartmentNumber: {
    type: QuestionType.TEXT,
    label: 'forms_assets:residentialRealEstate.inputs.apartment.label',
    value: '',
  },
  index: {
    type: QuestionType.TEXT,
    label: {
      prefix: '',
      fieldKey: '',
      postfix: 'forms_assets:common.inputs.zipCode.label',
    },
    value: '',
  },
};

const LandForm: LandFormType = [
  LandFormPart1,
  LandFormPart2,
  assetsOwnershipBuilder().form,
  assetsStatisticsBuilder([
    'taxAgentWithBroker',
    'taxAgent',
    'publicFundTaxAgent',
    'taxAgentOther',
    'taxAgentWithBrokerOther',
    'publicTaxAgentOther',
    'profitabilityFact',
    'profitabilityPlan',
  ]).form,
  assetsDocumentsPageBuilder().form,
];

export { LandForm };
