import { TMigrationVersion } from '@/shared/lib/migrator/types';

class MigrationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Migration error';
  }
}

export const migrationError = (message: string) => {
  return new MigrationError(`[MIGRATION ERROR]: ${message}`);
};

/**
 * @return v1 > v2
 *
 **/
export const compare = (v1: TMigrationVersion, v2: TMigrationVersion) => {
  const v1arr = v1.split('.');
  const v2arr = v2.split('.');

  if (v1arr.length !== 3 || v2arr.length !== 3) {
    throw migrationError(`Wrong version: v1 - ${v1}, v2 - ${v2}`);
  }

  if (v1[0] !== v2[0]) return v1[0] > v2[0];
  if (v1[1] !== v2[1]) return v1[1] > v2[1];
  return v1[2] > v2[2];
};
